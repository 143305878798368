import designService from '../services/design.service';

export default {
  computed: {
    // services
    _designService() {
      return designService;
    },

    // styling

    backgroundColor() {
      return this._designService.backgroundColor;
    },

    buttonBackgroundColor() {
      return this._designService.buttonBackgroundColor;
    },

    buttonBorderColor() {
      return this._designService.buttonBorderColor;
    },

    buttonTextColor() {
      return this._designService.buttonTextColor;
    },

    buttonStyle() {
      return this._designService.buttonStyle;
    },

    linkColor(): string {
      return this._designService.linkColor;
    },

    linkStyle () {
      return this._designService.linkStyle;
    },

    modalHeaderStyle () {
      return this._designService.getModalHeaderStyling();
    },

    pageBackgroundColor() {
      return this._designService.pageBackgroundColor;
    },

    textColor() {
      return this._designService.textColor;
    }
  },
  methods: {
    getButtonClass(): string {
      return this._designService.getButtonClass();
    },

    getButtonStyle(fontSize?: string): string {
      let style: any = this._designService.getButtonStyle();

      if (fontSize) {
        style['font-size'] = fontSize;
      }

      return style;
    }
  }
};
