import subscriberMixin from '../../common/mixins/subscriber.mixin';
import restaurants from '../../restaurants/models/Restaurants';

export default {
  mixins: [subscriberMixin],
  props: {
    isCurbsideOrder: Boolean
  },
  computed: {
    // models

    restaurants() {
      return restaurants;
    },

    // getters

    restaurant() {
      return restaurants.selectedRestaurant;
    }
  },
  methods: {
    getOrderConfirmationMessage() {
      if (!this.restaurant.communicationSettings || !this.restaurant.communicationSettings.orderConfirmationMessage) {
        return '';
      }

      return this.restaurant.communicationSettings.orderConfirmationMessage;
    }
  }
}
