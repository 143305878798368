export default function decodeText (value: string): string {
    if (!value || value.length <= 0) {
        return value;
    }

    let text: string = value;

    text = text.replace(/&amp;/g, '&');
    text = text.replace(/&lt;/g, '<');
    text = text.replace(/&gt;/g, '>');
    text = text.replace(/&quot;/g, '"');
    text = text.replace(/&#39;/g, '\'');

    return text;
}
