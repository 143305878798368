import {Util} from '../services/Util';
import TolSelect from '../components/TOLSelect.vue';
import cart from '../../cart/models/Cart';
import analyticsManager from '../../common/services/analytics-manager.service';
import { IRestaurant } from '../../restaurants/types/restaurant.types';

const moment = require('moment');

export default {
    components: {
        TolSelect
    },
    props: {
        currentTime: {
            type: String,
            required: true
        },
        isDeliveryOpen: {
            type: Boolean,
            required: false
        },
        isOpen: {
            type: Boolean,
            required: false
        },
        restaurant: {
            type: Object as () => IRestaurant,
            required: true
        }
    },
    computed: {
        // abstract

        /* orderTime(): string {
            return null;
        },

        orderType(): string {
            return null;
        }, */

        // getters

        cartObject() {
          return cart.cart;
        },

        // properties

        dayObject: {
            get() {
                if (!this.scheduleDays) {
                    return null;
                }

                return this.scheduleDays.find(d => d.value === this.day);
            },
            set(value) {
                if (!value) {
                    return;
                }

                this.day = value.value;
            }
        },

        defaultTime() {
            const nowTime = this.getDefaultScheduleTime(false);

            if (nowTime) {
                return {
                    day: this.formatDayCode(nowTime),
                    hour: this.formatHourCode(nowTime),
                    minute: this.formatMinuteCode(nowTime),
                    amPm: this.formatAmPmCode(nowTime)
                };
            } else {
                return null;
            }
        },

        hourObject: {
            get() {
                if (!this.scheduleHours) {
                    return null;
                }

                return this.scheduleHours.find(d => d.value === this.hour);
            },
            set(value) {
                if (!value) {
                    return;
                }

                this.hour = value.value;
            }
        },

        isAsapButtonVisible(): boolean {
            if (!this.restaurant || !this.restaurant.hours || !this.currentTime) {
                return false;
            }

            return Util.isOrderTimeValid(
                this.orderType,
                this.restaurant.hours,
                this.restaurant.promiseTimes,
                this.currentTime,
                this.restaurant.usePromiseTime
            );
        },

        scheduleDays() {
            if (this.restaurant && this.restaurant.hours && this.currentTime) {
                const defaultTimeForDays = this.getDefaultScheduleTime(false);

                if (defaultTimeForDays) {
                    return this.getScheduleDays(defaultTimeForDays);
                }
            }

            return null;
        },

        scheduleHours() {
            if (this.restaurant && this.restaurant.hours && this.currentTime) {
                const defaultTime = this.getDefaultScheduleTime();

                if (defaultTime) {
                    return this.getScheduleHours(defaultTime);
                }
            }

            return null;
        },

        scheduleMinutes() {
            if (this.restaurant && this.restaurant.hours && this.currentTime) {
                const defaultTime = this.getDefaultScheduleTime();

                if (defaultTime) {
                    return this.getScheduleMinutes();
                }
            }

            return null;
        }
    },
    data() {
        return {
            amPm: null,
            amPmList: [
                {
                    name: 'AM',
                    value: 'am'
                },
                {
                    name: 'PM',
                    value: 'pm'
                }
            ],
            day: null,
            hour: null,
            minute: null
        };
    },
    created() {
        let setDefaultTimeInterval;
        const self = this;

        setDefaultTimeInterval = setInterval(() => {
            const defaultTime = this.getDefaultScheduleTime();

            if (defaultTime && setDefaultTimeInterval) {
                clearInterval(setDefaultTimeInterval);
                setDefaultTimeInterval = null;

                self.initialize();
            }
        }, 100);
    },
    methods: {
        initialize() {
            const defaultTime = this.getDefaultScheduleTime();

            if (defaultTime) {
                const defaultTimeForDays = this.getDefaultScheduleTime(false);
                this.day = this.formatDayCode(defaultTimeForDays);

                this.hour = this.formatHourCode(defaultTime);
                this.minute = this.formatMinuteCode(defaultTime);
                this.amPm = this.formatAmPmCode(defaultTime);
            }
        },

        formatAmPmCode(time) {
            return time.format('a');
        },

        formatDayCode(time) {
            return time.format('YYYY-MM-DD');
        },

        getScheduleDays(firstOpenTime): Object[] {
            const myDays = [];

            const getDayObject = time => ({
                name: time.format('ddd, MMM Do'),
                value: this.formatDayCode(time)
            });

            // And the rest of the week
            for (let index: number = 0; index < 14; index++) {
                const obj = getDayObject(firstOpenTime);

                myDays.push(obj);

                firstOpenTime.add(1, 'days');
            }

            return myDays;
        },

        getMeridianHour(time) {
            let nowHour: number = time.hour();
            const nowMinute: number = this.getNowMinute(time);

            if (time.minute() > 55 && nowMinute === 0) {
                nowHour = nowHour + 1;
            }

            if (nowHour > 12) {
                nowHour = nowHour - 12;
            }

            return nowHour;
        },

        formatHourCode(time) {
            return this.pad(this.getMeridianHour(time));
        },

        getScheduleHours(): Object[] {
            const myHours: Object[] = [];

            for (let index: number = 1; index <= 12; index++) {
                myHours.push({
                    name: index,
                    value: this.pad(index)
                });
            }

            return myHours;
        },

        getNowMinute(defaultTime?) {
            if (!defaultTime) {
                defaultTime = this.getDefaultScheduleTime();
            }

            if (!defaultTime) {
                return null;
            }

            let nowMinute = defaultTime.minute();

            if (nowMinute % 5 !== 0) {
                nowMinute = nowMinute + (5 - (nowMinute % 5));
            }

            if (nowMinute >= 60) {
                nowMinute = nowMinute - 60;
            }

            return nowMinute;
        },

        formatMinuteCode(time) {
            const nowMinute: number = this.getNowMinute(time);

            if (nowMinute === null) {
                return null;
            }

            return this.pad(nowMinute);
        },

        getScheduleMinutes(): Object[] {
            const myMinutes: Object[] = [];

            for (let index: number = 0; index < 60; index += 5) {
                myMinutes.push(this.pad(index));
            }

            return myMinutes;
        },

        pad(index: number): string {
            let value: string = index.toString();

            if (value.length === 1) {
                value = '0' + value;
            }

            return value;
        },

        getDefaultScheduleTime(useOrderTime: boolean = true) {
            if (!this.restaurant || !this.restaurant.hours || !this.currentTime) {
                return null;
            }

            if (useOrderTime && this.orderTime) {
                if (Util.isOrderTimeValid(
                    this.orderType,
                    this.restaurant.hours,
                    this.restaurant.promiseTimes,
                    this.currentTime,
                    this.restaurant.usePromiseTime,
                    this.orderTime)) {
                    return moment(this.orderTime);
                }
            }

            const nextOpenTime = Util.getNextOpenTime(moment(this.currentTime), this.restaurant.hours, this.orderType);

            if (nextOpenTime) {
                if (this.restaurant.usePromiseTime) {
                    const promiseTime = Util.getPromiseTime(this.orderType, this.restaurant.promiseTimes);

                    nextOpenTime.add(promiseTime, 'minutes');
                }

                return nextOpenTime;
            }

            return null;
        },

        convertTo24Hour(hour: number, amPm: string): string {
            let hour24: string = this.pad(hour);

            if ((amPm === 'pm' && hour < 12) || (amPm === 'am' && hour === 12)) {
                hour24 = this.pad(hour + 12);
            }

            if (hour24 === '24') {
                hour24 = '00';
            }

            return hour24;
        },

        setOrderTime() {
            if (this.isAsapButtonVisible
                && this.day === this.defaultTime.day
                && this.hour === this.defaultTime.hour
                && this.minute === this.defaultTime.minute
                && this.amPm === this.defaultTime.amPm) {
                this.scheduleAsap();

                return;
            }

            let day: string = this.day;
            let hour: string = this.hour;
            let minute: string = this.minute;
            let amPm: string = this.amPm;

            hour = this.pad(parseInt(hour));
            hour = this.convertTo24Hour(parseInt(hour), amPm);

            const time = hour + ':' + minute + ':00';

            this.$emit('setTime', {
                orderType: this.orderType,
                date: day,
                time
            });

            let promiseTime;

            if (this.restaurant.usePromiseTime) {
                promiseTime = Util.getPromiseTime(this.orderType, this.restaurant.promiseTimes);
            }

            analyticsManager.track('Schedule order', {
                cartId: cart.cart.objectId,
                date: day,
                isStoreOpen: this.isOpen,
                locationId: this.restaurant.objectId,
                locationName: this.restaurant.name,
                promiseTime,
                time
            });
        },

        scheduleAsap() {
            this.$emit('asap', {
                date: this.currentTime.split('T')[0],
                time: this.currentTime.split('T')[1]
            });

            /* analyticsManager.track('Asap order', {
                cartId: cart.cart.objectId,
                locationId: this.restaurant.objectId,
                locationName: this.restaurant.name
            });*/
        }
    }
};
