import { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { onIntercept } from "../helpers/auth.helper";

const axios = require('axios').default;
axios.interceptors.request.use(onIntercept);

export enum RequestMethod {
  Get = 'get',
  Post = 'post',
  Put = 'put',
  Delete = 'delete',
  Options = 'options',
  Head = 'head',
  Patch = 'patch'
}

interface IRequestOptionsArgs {
  url?: string;
  method?: Method;
  search?: string;
  headers?: Object;
  body?: any;
  withCredentials?: boolean;
}

export interface IRequestArgs extends IRequestOptionsArgs {
  url: string;
}

export type Response = AxiosResponse;

export default class HttpService {
  request(request: IRequestArgs) {
    const options: AxiosRequestConfig = {
      method: request.method,
      url: request.url + (request.search ? '?' + request.search : ''),
      headers: request.headers,
      data: request.body
    };

    return axios(options);
  }
}
