
  import Vue from 'vue';
  import StyledModal from '../../common/components/StyledModal.vue';
  import {IMenuItem} from '../models/Item';
  import designService from '../../common/services/design.service';

  export default Vue.extend({
    components: {
      StyledModal
    },
    computed: {
      // services

      _designService() {
        return designService;
      },

      // refs

      unableToPriceItemModal() {
        return this.$refs.unableToPriceItemModal;
      }
    },
    data() {
      return {
        retriedPricingFailure: false,
        zeroPricedItem: null // IMenuItem
      };
    },
    methods: {
      open(item: IMenuItem) {
        this.zeroPricedItem = item;

        this.unableToPriceItemModal.open();
      },

      close() {
        this.unableToPriceItemModal.close();
      },

      retryZeroPricedItem(retry: boolean): void {
        this.$emit('removeZeroPricedItem', this.zeroPricedItem.objectId);

        if (retry) {
          this.retriedPricingFailure = true;
          this.$emit('addZeroPricedItem', this.zeroPricedItem);
        } else {
          this.retriedPricingFailure = false;
        }

        this.unableToPriceItemModal.close();

        if ($('.modal-backdrop').length > 0) {
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');
        }
      },

      // Styling

      getButtonStyle(): any {
        return this._designService.getButtonStyle();
      },

      getButtonClass(): any {
        return this._designService.getButtonClass();
      }
    }
  });
