import baseMixin from '../../common/mixins/base-component.mixin';

export default {
  mixins: [baseMixin],
  computed: {
    // getters

    hasNoItemImages() {
      return this.items.every(item => !item.showImage || !item.imageLink);
    }
  }
};
