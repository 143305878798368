import { IMenuItem, ITopping, IToppingList } from '../models/Item';
import { SPLIT_SIDES } from '../menu.constants';

const MAX_TOPPING_QUANTITY = 3;

export const ACTIONS = {
    INCREASE: 'increase',
    DECREASE: 'decrease'
};

export const FRACTION_TYPES = {
    LEFT: 'LEFT',
    WHOLE: 'WHOLE',
    RIGHT: 'RIGHT'
};

interface IShowAlert {
    (string): void;
}

export default class ToppingsManager {
    _item: IMenuItem;
    showAlert: IShowAlert;
    toppings: ITopping[];

    constructor(item: IMenuItem, showAlert: IShowAlert) {
        this.item = item;
        this.showAlert = showAlert;
    }

    get item() {
        return this._item;
    }

    set item(value) {
        this._item = value;

        this.toppings = this.item.includedItems.slice(0);
    }

    getToppings(topping: ITopping, fractionType?: string) {
        return this.toppings.filter((t: ITopping) => {
            if (t.quantity === 0) {
                return false;
            }

            if (fractionType && t.fractionType !== fractionType) {
                return false;
            }

            return t.itemId === topping.itemId;
        });
    }

    getOnlyIncludedToppings(toppingList: IToppingList, splitSide: string = SPLIT_SIDES.LEFT): IToppingList {
      const includedToppingIds = splitSide === SPLIT_SIDES.LEFT ?
        this.item.standard.map(s => s.itemId) :
        this.item.split.right.standard.map(s => s.itemId);
      const toppingKeys = Object.keys(toppingList.toppings);
      const includedToppings = {toppings: []};
      // Get only toppings that are included with the item
      for (let i = 0; i < toppingKeys.length; i++) {
        includedToppings.toppings[toppingKeys[i]] = [...toppingList.toppings[toppingKeys[i]]
          .filter(t => includedToppingIds.includes(t.itemId))];
      }
      return Object.assign({}, toppingList, includedToppings);
    }


    getToppingQuantity(topping: ITopping, fractionType?: string, consolidate: boolean = false): number {
        const toppings = this.getToppings(topping, fractionType);

        if (consolidate) {
            const leftToppings = toppings.filter(t => t.fractionType === 'LEFT');
            const rightToppings = toppings.filter(t => t.fractionType === 'RIGHT');
            const wholeToppings = toppings.filter(t => t.fractionType === 'WHOLE');

            let result = Math.abs(leftToppings.length - rightToppings.length);

            if (leftToppings.length > 0 && rightToppings.length > 0) {
                result++;
            }

            result += wholeToppings.length;

            return result;
        } else {
            return toppings.length;
        }
    }

    hasTopping(topping: ITopping, splitSide?: string): boolean {
        return this.getToppingQuantity(topping, splitSide) > 0;
    }

    validateMaxToppings(fractionType: string, topping: ITopping) {
        if (topping.limited && this.getToppingQuantity(topping, fractionType) >= topping.maxAllowed) {
            return false;
        }

        if (!this.item.maxToppingMap) {
            return true;
        }

        let styleId: string = this.item.size.styleId;

        if (this.item.maxToppingMap[styleId] === undefined || this.item.maxToppingMap[styleId] === null) {
            return true;
        }

        let baseToppingCount: number = 0;

        if (this.item.pricingMethod && this.item.pricingMethod.toUpperCase() === 'TOPPING_BASED') {
            baseToppingCount = this.item.toppingCount;
        }

        let maxToppings: number = this.item.maxToppingMap[styleId];
        let leftCount: number = baseToppingCount;
        this.toppings.filter(included => !included.standard)
            .filter(included =>
                included.fractionType.toUpperCase() === FRACTION_TYPES.LEFT
                || included.fractionType.toUpperCase() === FRACTION_TYPES.WHOLE)
            .forEach(() => leftCount++);

        let rightCount: number = baseToppingCount;
        this.toppings.filter(included => !included.standard)
            .filter(included =>
                included.fractionType.toUpperCase() === FRACTION_TYPES.RIGHT
                || included.fractionType.toUpperCase() === FRACTION_TYPES.WHOLE)
            .forEach(() => rightCount++);

        switch (fractionType) {
            case FRACTION_TYPES.WHOLE:
                return Math.max(leftCount, rightCount) + 1 <= maxToppings;
            case FRACTION_TYPES.LEFT:
                return leftCount + 1 <= maxToppings;
            case FRACTION_TYPES.RIGHT:
                return rightCount + 1 <= maxToppings;
            default:
                return false;
        }
    }

    addTopping(topping: ITopping, fractionType: string): boolean {
        let toppingCopy: ITopping = Object.assign({}, topping, {
            fractionType
        });

        if (!this.validateMaxToppings(FRACTION_TYPES.WHOLE, topping)) {
            if (this.showAlert) {
                this.showAlert('Max number of toppings reached for ' + topping.name + '.');
            }

            return false;
        }

        this.toppings.push(toppingCopy);

        return true;
    }

    clearTopping(topping: ITopping, fractionType?: string) {
        if (fractionType) {
            this.toppings = this.toppings.filter(includedItem => {
                return includedItem.itemId !== topping.itemId || (includedItem.itemId === topping.itemId
                    && includedItem.fractionType !== fractionType);
            });
        } else {
            this.toppings = this.toppings.filter(includedItem => {
                return includedItem.itemId !== topping.itemId;
            });
        }
    }

    removeTopping(topping: ITopping, fractionType: string) {
        const firstToppingIndex = this.toppings.findIndex(item => {
            return item.itemId === topping.itemId && item.fractionType === fractionType;
        });

        if (firstToppingIndex !== -1) {
            this.toppings.splice(firstToppingIndex, 1);
        }
    }

    pruneIncludedItems(topping: ITopping): void {
        this.clearTopping(topping, FRACTION_TYPES.LEFT);
        this.clearTopping(topping, FRACTION_TYPES.RIGHT);
    }

    checkForCombineToppings(topping: ITopping): void {
        let leftCount: number = this.getToppingQuantity(topping, FRACTION_TYPES.LEFT);
        let rightCount: number = this.getToppingQuantity(topping, FRACTION_TYPES.RIGHT);

        if ((leftCount === 0 || rightCount === 0) || (leftCount !== rightCount)) {
            return;
        }

        while (leftCount > 0 && rightCount > 0) {
            let copy: ITopping = Object.assign({}, topping, {
                fractionType: FRACTION_TYPES.WHOLE
            });

            this.toppings.push(copy);
            this.pruneIncludedItems(topping);

            leftCount--;
            rightCount--;
        }
    }

    adjustToppingQuantity(topping: ITopping, fractionType: string, change: number) {
        // remove other fractions of this topping
        this.toppings = this.toppings.filter(t => {
            if (t.itemId === topping.itemId && t.fractionType !== fractionType) {
                return false;
            } else {
                return true;
            }
        });

        const sameToppings = this.getToppings(topping, fractionType);

        let i;

        if (change > 0) {
            if (sameToppings.length < MAX_TOPPING_QUANTITY) {
                for (i = 0; i < change; i++) {
                    this.addTopping(topping, fractionType);
                }
            } else {
                this.clearTopping(topping);
            }
        } else {
            for (i = 0; i > change; i--) {
                this.removeTopping(topping, fractionType);
            }
        }

        if (this.item && !this.item.alreadySplit) {
            this.checkForCombineToppings(topping);
        }
    }
}
