
  import Vue from 'vue';
  import { ICoupon } from '../../coupons/models/Coupon';
  import loyaltyService from '../../coupons/services/loyalty.service';
  import { ICategory } from '../stores/menu.store';
  import { IRestaurant } from '../../restaurants/types/restaurant.types';

  export default Vue.extend({
    props: {
      subheaderCategory: {
        type: Object as () => ICategory
      },

      subheaderCoupon: {
        type: Object as () => ICoupon
      },

      restaurant: {
        type: Object as () => IRestaurant,
        required: true
      }
    },
    computed: {
      loyaltyService () {
        return loyaltyService;
      }
    },
    methods: {
      chooseCoupon(coupon: ICoupon) {
        loyaltyService.chooseCoupon(coupon, false);
      },
      getCategoryImageStyle() {
        return 'background-image: url("' + this.subheaderCategory.imageLink + '");';
      }
    }
  });
