import baseMixin from './base-component.mixin';
import menu from '../../menu/models/Menu';
import couponWalkthrough from '../../coupons/models/CouponWalkthrough';
import {IMenuItem} from '../../menu/models/Item';
import {IStyle} from '../../coupons/models/Coupon';
import cart from '../../cart/models/Cart';

export default {
    mixins: [baseMixin],
    computed: {
        menu () {
            return menu;
        },
        couponWalkthrough () {
            return couponWalkthrough;
        }
    },
    methods: {
        editItem(item: IMenuItem) {
            this.couponWalkthrough.clearSelectedCoupon();
            this.couponWalkthrough.clearSelectedItemsMap();
            cart.selectItem(item);
            this.menu.loadStyleList(item.itemId);

            const style = this.getNonSizeStyle(item);
            this.menu.loadToppingsList(item.itemId, item.size.styleId, style ? style.styleId : null);
            this.routeService.route('CustomizeItem', {itemId: item.itemId});

            this.closeCart(null);
        },

        getNonSizeStyle(item: IMenuItem): IStyle {
            return item.styles.find(style => style.type.toLowerCase() !== 'size');
        }
    }
};
