import couponWalkthrough from '../models/CouponWalkthrough';
import { getValidations } from '../helpers/coupon.helpers';
import routeService from '../../common/services/route.service';
import cartModel from '../../cart/models/Cart';
import { ICoupon, ICouponGroup } from '../models/Coupon';
import modalService from '../../common/services/modal.service';
import { Util } from '../../common/services/Util';
import profile from '../../profile/models/Profile';
import restaurantLoader from '../../restaurants/services/restaurant-loader.service';
import storageService from '../../common/services/storage.service';
import { Subscriber } from '../../common/services/Subscriber';
import restaurants from '../../restaurants/models/Restaurants';
import environment from '../../common/services/environment.service';
import { navigateToMenu } from '../../common/mixins/base-component.mixin';
import notifierService from '../../common/services/notifier.service';

export class LoyaltyService extends Subscriber {
    cancelCouponSubscription: any;
    closeCouponModalSubscription: any;
    couponPayload: { offerId: number, locationId: number };
    isLinkOfferFulfilled: boolean;
    linkOffer: ICoupon;

    constructor() {
        super();

        Util.waitUntil(() => environment).then(() => {
            if (environment.launchNotificationPayload) {
                const offerId = environment.launchNotificationPayload['offerId'];
                const locationId = environment.launchNotificationPayload['locationId'];

                if (offerId && locationId) {
                    this.startCouponWalkthrough({
                        offerId: offerId,
                        locationId: locationId
                    });
                }
            }

            environment.addAppResumedListener(() => {
                let id = restaurants.selectedRestaurantId;

                if (id) {
                    // This will cause the user object with coupons to reload
                    profile.switchLocations(id);
                }
            });
        });

        $(window).on('pushnotification', event => {
            this.startCouponWalkthrough({
                offerId: event['offerId'],
                locationId: event['locationId']
            });
        });

        this.subscribe(modalService.selectOrderType$, () => {
            this.startPendingCouponWalkthrough();
        });

        this.subscribe(modalService.closeOrderTypeModal$, () => {
            this.couponPayload = null;
        });

        this.subscribe(modalService.closeDeliveryModal$, () => {
            // TODO: wait until schedule order modal finishes
            this.startPendingCouponWalkthrough();
        });

        this.subscribe(modalService.closedDeliveryModal$, () => {
            this.couponPayload = null;
        });
    }

    // getters

    get currentTime() {
        return restaurants.currentTime;
    }

    get restaurant() {
        return restaurants.selectedRestaurant;
    }

    get updating() {
        return cartModel.updating;
    }

    // methods

    chooseCoupon(coupon: ICoupon, addCoupon: boolean = true) {
        let messages: string[] = getValidations(coupon, cartModel.cart, this.restaurant, this.currentTime, !addCoupon);

        if (messages && messages.length > 0) {
            modalService.selectCouponErrorModal(coupon, messages);

            return;
        }

        modalService.selectCouponModal(coupon);

        if (addCoupon) {
            cartModel.addCoupon(coupon.couponId, coupon.promoId);
        }

        this.cancelCouponSubscription = modalService.cancelCoupon$.subscribe(
          (c: ICoupon) => {
              cartModel.removeItem(c.couponId);
              couponWalkthrough.clearSelectedCoupon();

              this.endSubscriptions();
          });

        this.closeCouponModalSubscription = modalService.closeCouponModal$.subscribe(() => {
            this.closeCouponModal()
        });
    }

    addCouponItems(coupon: ICoupon) {
        let wait: number = 0;

        if (coupon.groups && coupon.groups.length > 0) {
            for (let group of coupon.groups) {
                if (group.includes && group.includes.length > 1) {
                    continue;
                } else if (group.includes && group.includes.length === 1) {
                    if (!group.includes[0].groupType || group.includes[0].groupType !== 'ITEM') {
                        continue;
                    }

                    if (!group.includes[0].item || !group.includes[0].item.objectId) {
                        continue;
                    }
                }

                this.submitAdd(group, wait + 50);
            }
        }

        this.endSubscriptions();
    }

    submitAdd(group: ICouponGroup, wait: number) {
        window.setTimeout(() => {
            if (this.updating) {
                this.submitAdd(group, wait + 50);

                return;
            }

            let styleId: string;

            if (group.includes[0].styles && group.includes[0].styles[0] && group.includes[0].styles[0].styleId) {
                styleId = group.includes[0].styles[0].styleId;
            }

            cartModel.addItem({
                item: group.includes[0].item,
                sizeId: styleId
            });
        },
        wait);
    }

    closeCouponModal() {
        this.endSubscriptions();
    }

    endSubscriptions() {
        if (this.cancelCouponSubscription) {
            this.cancelCouponSubscription.unsubscribe();
        }

        if (this.closeCouponModalSubscription) {
            this.closeCouponModalSubscription.unsubscribe();
        }
    }

    getAvailableCoupons(): ICoupon[] {
        if (!profile.user) {
            return null;
        }

        if (!profile.user.coupons || !cartModel.cart || !cartModel.cart.coupons) {
          return [];
        }

        return profile.user.coupons.filter(coupon => {
            return !cartModel.cart.coupons.find(cartCoupon => {
                return cartCoupon.couponId === coupon.couponId;
            });
        });
    }

    startPendingCouponWalkthrough () {
        if (this.couponPayload) {
            this.startCouponWalkthrough(this.couponPayload);

            this.couponPayload = null;
        }
    }

    startCouponWalkthrough(payload: { offerId: number, locationId: number }) {
        let selectedRestaurantId: string = JSON.parse(storageService.localStorage.getItem('selectedRestaurant'));

        if (payload.locationId.toString() === selectedRestaurantId) {
            if (routeService.currentRoute.name === 'MainMenu') {
                if (!cartModel.cart.orderType) {
                    this.couponPayload = payload;

                    modalService.selectOrderTypeModal();

                    return;
                }
            } else {
                this.couponPayload = payload;

                navigateToMenu();

                return;
            }
        } else {
            restaurantLoader.chooseRestaurantById(payload.locationId.toString()).then(() => {
                this.couponPayload = payload;
            });

            return;
        }

        profile.loadCoupon(payload).then(coupon => {
            if (coupon) {
                this.chooseCoupon(coupon);
            }
        });
    }
}

export default new LoyaltyService();
