import VueRouter from 'vue-router';

let router

function getRouter() {
  return router;
}

export default getRouter;

export function setUpRouter(routes) {
  router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior: function (to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      } else {
        return { x: 0, y: 0 }
      }
    }
  });
}
