import { Subscribable } from './Subscribable';

export class Subject<T> extends Subscribable<T> {
  constructor(value?: T) {
    super();

    this.next(value);
  }

  next(value: T) {
    this.notify(value);
  }
}
