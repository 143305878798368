
  import Vue from 'vue';
  import subscriberMixin from '../../common/mixins/subscriber.mixin';
  import {ICoupon, ICouponGroup} from '../models/Coupon';
  import {Util} from '../../common/services/Util';
  import cart from '../../cart/models/Cart';
  import modalService from '../../common/services/modal.service';
  import { CouponWalkthrough } from '../models/CouponWalkthrough';
  import designService from '../../common/services/design.service';
  import restaurants from '../../restaurants/models/Restaurants';
  import designMixin from '../../common/mixins/design.mixin';
  import ModalModern from '../../common/components/ModalModern.vue';
  import { getIncludeDescription, getValidations, isEntireOrderCoupon } from '../helpers/coupon.helpers';
  import routeService from '../../common/services/route.service';
  import loyaltyService from '../services/loyalty.service';
  import notifierService from '../../common/services/notifier.service';
  import { navigateToMenu } from '../../common/mixins/base-component.mixin';
  import { THIS_OFFER_REQUIRES_ORDER_TYPE } from '../coupon.constants';
  import { ICart } from "../../cart/cart.types";

  export default Vue.extend({
    mixins: [subscriberMixin, designMixin],
    components: {
      ModalModern
    },
    computed: {
      // models

      _designService() {
        return designService;
      },

      _modalService() {
        return modalService;
      },

      restaurants() {
        return restaurants;
      },

      // getters

      cart() {
        return cart.cart;
      },

      currentTime() {
        return restaurants.currentTime;
      },

      isMissingOrderType() {
        if (this.selectedWalkthroughCoupon && this.selectedWalkthroughCoupon.orderTypes
          && this.selectedWalkthroughCoupon.orderTypes.length > 0 && !this.cart.orderType) {
          return true;
        } else {
          return false;
        }
      },

      restaurant() {
        return restaurants.selectedRestaurant;
      },

      title() {
        let result = 'This coupon is not valid:';

        if (this.isMissingOrderType) {
          result += ' Missing Order Type';
        }

        return result;
      },

      linkOffer() {
        return loyaltyService.linkOffer;
      }
    },
    data() {
      return {
        errorMessages: null, // string[]
        errorCoupon: null, // ICoupon
        selectedWalkthroughCoupon: null
      };
    },
    created() {
      this.subscribe(this._modalService.selectCouponModal$, (coupon: ICoupon) => {
        this.open(coupon);
        this._designService.setModalStyling();
      });

      this.subscribe(this._modalService.selectCouponErrorModal$, (payload: { coupon: ICoupon, messages: string[] }) => {
        this.errorMessages = payload.messages;
        this.errorCoupon = payload.coupon;
        this.$refs.errorModal.open();
        this._designService.setModalStyling();
      });
    },
    methods: {
      open(coupon: ICoupon) {
        if (coupon) {
          this.selectedWalkthroughCoupon = coupon;
        }

        this.$refs.modal.open();
      },

      close() {
        this.$refs.modal.close();

        this.$emit('close');
      },

      addCoupon() {
        const coupon = this.selectedWalkthroughCoupon;

        cart.addCoupon(coupon.couponId, coupon.promoId).then((result) => {
          if (this.validateAddCoupon(result)) {
            if (coupon.entireOffer) {
              notifierService.success(`Added ${coupon.name} to order`, undefined, false);
            }
          }
        });

        this.closeCouponModal();
      },

      validateAddCoupon(cart: ICart): boolean {
        if (cart) {
          const validationError = cart.validations.some((validation) => {
            if (validation.type === "COUPON") {
              notifierService.error(validation.message);
              return true;
            }
            return false;
          });
          return !validationError;
        }
        return true;
      },

      addCouponItems(coupon: ICoupon) {
        loyaltyService.addCouponItems(coupon);

        this.close();
      },

      cancelCouponWalkthrough() {
        cart.removeItem(this.selectedWalkthroughCoupon.objectId);
        this.close();

        if (routeService.currentRoute.name === 'SelectCouponItem') {
          navigateToMenu();
        }
      },

      closeCouponModal() {
        this.close();
        this._modalService.closeCouponModal();
      },

      cancelCoupon(coupon: ICoupon) {
        this.close();
        this._modalService.cancelCoupon(coupon);
      },

      initiateCouponWalkthrough(coupon: ICoupon) {
        return cart.addCoupon(coupon.couponId, coupon.promoId).then(() => {
          let result;

          if (routeService.currentRoute.name === 'SelectCouponItem') {
            result = Promise.resolve(); // already there
          } else {
            result = CouponWalkthrough.initiateCouponWalkthrough(coupon);
          }

          this.close();

          return result;
        });
      },

      cancelCouponError(coupon: ICoupon, close: boolean = true) {
        if (close) {
          this.$refs.errorModal.close();

          this.$emit('close');
        }

        this._modalService.cancelCoupon(coupon);

        const hasOrderTypeError = this.errorMessages.find(error => error.indexOf(THIS_OFFER_REQUIRES_ORDER_TYPE) !== -1);

        if (hasOrderTypeError) {
          this._modalService.selectOrderTypeModal();
        }
      },

      getEntireOrderCouponText(coupon: ICoupon): string {
        if (!coupon) {
          return '';
        }

        let str: string = '';

        if (coupon.freeDelivery) {
          str += 'This offer allows free delivery. ';
        }

        if (coupon.entireOffer) {
          switch (coupon.couponType) {
            case 'DOLLAR':
              str += 'This offer gives you $' + coupon.defaultAmount.toFixed(2) + ' off your order';

              break;
            case 'PERCENT':
              str += 'This offer gives you ' + coupon.defaultAmount + '% off your order';

              break;
            case 'FIXED':
              str += 'This offer makes your order fixed at $' + coupon.defaultAmount.toFixed(2);

              break;
            default:
              str += '';

              break;
          }

          if (coupon.minPurchase) {
            str += ' after a $' + coupon.minPurchase.toFixed(2) + ' minimum purchase';
          }
        }

        return str;
      },

      getCouponItemText(group: ICouponGroup) {
        if (!group) {
          return;
        }

        let str: string = '';

        if (group.includes && group.includes.length > 1) {
          let descriptions: string[] = [];

          for (let include of group.includes) {
            let description: string = getIncludeDescription(group, include);

            if (description.indexOf('null') < 0) {
              descriptions.push(description);
            }
          }

          str += descriptions.join(' or ');
        } else if (group.includes && group.includes.length === 1) {
          str += getIncludeDescription(group, group.includes[0]);
        }

        return str;
      },

      getCouponValidations(coupon: ICoupon): string[] {
        if (!coupon) {
          return [];
        }

        return getValidations(coupon, this.cart, this.restaurant, this.currentTime);
      },

      isEntireOrderCoupon(coupon: ICoupon): boolean {
        if (!coupon) {
          return false;
        }

        return isEntireOrderCoupon(coupon);
      },

      isSingleItemCoupon(coupon): boolean {
        if (Util.isEmpty(coupon) || !coupon.groups || coupon.groups.length <= 0) {
          return false;
        }

        let isSingleItem: boolean = true;

        for (let group of coupon.groups) {
          if (group.includes && group.includes.length > 1) {
            isSingleItem = false;
          } else if (group.includes && group.includes.length === 1) {
            if (!group.includes[0].groupType || group.includes[0].groupType !== 'ITEM') {
              isSingleItem = false;
            }
          } else if (!group.includes || group.includes.length <= 0) {
            isSingleItem = false;
          }
        }

        return isSingleItem;
      },

      hasGroups(coupon): boolean {
        return !(Util.isEmpty(coupon) || !coupon.groups || coupon.groups.length <= 0 || !coupon.groups.includes
          || coupon.groups.includes.length <= 0);
      },

      isFulfilled(coupon): boolean {
        return coupon && coupon.price && coupon.price < 0;
      },

      // event handlers

      onHide() {
        this._modalService.closeCouponModal();
      }
    }
  });
