import Vuex from 'vuex';
import account from './app/account/stores/account.store';
import cart, { setUp as setUpCart } from './app/cart/stores/cart.store';
import coupon from './app/coupons/stores/coupon.store';
import maps from './app/maps/stores/maps.store';
import menu from './app/menu/stores/menu.store';
import order from './app/order/stores/order.store';
import profile from './app/profile/stores/profile.store';
import restaurants from './app/restaurants/stores/restaurants.store';
import notifications from './app/common/stores/notifications.store'
import modernTheme from './app/common/stores/modernTheme.store'
import notificationService from './app/common/messaging/notification.service';

const store = new Vuex.Store({
  modules: {
    [account.name]: account,
    [cart.name]: cart,
    [coupon.name]: coupon,
    [maps.name]: maps,
    [menu.name]: menu,
    [order.name]: order,
    [profile.name]: profile,
    [restaurants.name]: restaurants,
    [notifications.name]: notifications,
    [modernTheme.name]: modernTheme
  }
});

export default store;

notificationService.addObserver('notify', ({type, message, id, excludeOnMenu, timeToHide}) => {
  store.commit('notifications/ADD', {type, message, id, excludeOnMenu, timeToHide});
});

setUpCart(store);
