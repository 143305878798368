
  import Vue from 'vue';
  const H = require('@here/maps-api-for-javascript').default;

  declare var CONSTANTS : any;

  export default Vue.extend({
    name: 'HereMap',
    props: {
      zoom: {
        type: Number,
        required: false,
        default: () => {
          return 1;
        }
      },
      center: Object, // center object { lat: number, lng: number }
      markers: Array // marker object { lat: number, lng: number, label: string }
    },
    data() {
      return {
        platform: null,
        map: null
      };
    },
    mounted() {
      this.loadStylesheet('https://js.api.here.com/v3/3.1/mapsjs-ui.css');

      // Initialize the platform object:
      const platform = new H.service.Platform({
        apikey: CONSTANTS.HERE_API_KEY
      });
      this.platform = platform;
      this.initializeHereMap();
    },
    destroyed() {
      removeEventListener('resize', this.resizeMap);
    },
    methods: {
      loadStylesheet(uri) {
        const link = document.createElement('link');
        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.href = uri;
        document.head.appendChild(link);
      },
      initializeHereMap() {
        const mapContainer = this.$refs.hereMap;
        // Obtain the default map types from the platform object
        let maptypes = this.platform.createDefaultLayers();

        // Instantiate (and display) a map object:
        this.map = new H.Map(mapContainer, maptypes.vector.normal.map, {
          zoom: this.zoom,
          center: this.center
        });

        addEventListener('resize', this.resizeMap);

        // add behavior control
        new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));

        // add markers
        this.createMarkers(this.markers);

        // add UI
        H.ui.UI.createDefault(this.map, maptypes);

        // wait a fraction of a second to resize the map to the current viewport
        setTimeout(this.resizeMap, 0);
      },
      resizeMap() {
        this.map.getViewPort().resize();
      },
      createMarkers(markers) {
        for (let i = 0; i < markers.length; i++) {
          const container = document.createElement('div');
          let divText = document.createElement('div');
          divText.style.color = '#fff';
          divText.style.textShadow = '1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000';

          if (markers[i].label) {
            divText.innerHTML = markers[i].label;
            container.appendChild(divText);
          }

          const domIcon = new H.map.DomIcon(container);
          const mapMarker = new H.map.Marker({lat: markers[i].lat, lng: markers[i].lng});
          const textMarker = new H.map.DomMarker({lat: markers[i].lat, lng: markers[i].lng}, {
            icon: domIcon
          });
          this.map.addObjects([mapMarker, textMarker]);
        }
      }
    },
    watch: {
      center(newCenter) {
        this.map.setCenter(newCenter);
        this.map.setZoom(this.zoom);
      },
      markers(newMarkers) {
        this.map.removeObjects(this.map.getObjects());
        this.createMarkers(newMarkers);
      }
    }
  });
