import couponService from '../services/coupon.service';
import { createSetterMutations, getSetterMutationName } from '../../common/data/store.helpers';
import notifierService from '../../common/services/notifier.service';
import { ICouponGroup } from '../models/Coupon';
import Vue from 'vue';
import { IMenuItem } from '../../menu/models/Item';

export const COUPON_STORE = 'coupons';

const initialState = {
  couponDescription: undefined,
  couponGroup: undefined,
  itemsByGroupMap: {},
  requirementsMet: false,
  requirementsMetMap: undefined,
  selectedCoupon: undefined,
  selectedItemsMap: {}
};

export default {
  name: COUPON_STORE,

  namespaced: true,

  state: initialState,

  mutations: {
    ...createSetterMutations(initialState),

    SELECT_COUPON_ITEM(state, { itemNumber, item }) {
      if (!state.selectedItemsMap[itemNumber].items) {
        Vue.set(state.selectedItemsMap[itemNumber], 'items', []);
      }

      state.selectedItemsMap[itemNumber].items.push(item);
    },

    SET_IN_ITEMS_BY_GROUP_MAP(state, { groupId, items }) {
      Vue.set(state.itemsByGroupMap, groupId, items);
    },

    SET_IN_SELECTED_ITEMS_MAP(state, { itemNumber, group }) {
      Vue.set(state.selectedItemsMap, itemNumber, group);
    }
  },

  actions: {
    buildSelectedItemsMap({ commit, state }, groups: ICouponGroup[]) {
      let selectedItemsMap = state.selectedItemsMap;
      let mapHasValues = false;

      const keys = Object.keys(selectedItemsMap);

      keys.forEach(key => {
        const itemMap = selectedItemsMap[key];

        if (itemMap.items !== null) {
          mapHasValues = true;
        }
      });

      if (!mapHasValues) {
        let itemNumber: number = 1;

        for (let group of groups) {
          commit('SET_IN_SELECTED_ITEMS_MAP', { itemNumber, group: { group, items: null } });

          itemNumber++;
        }
      }
    },

    clearSelectedCoupon({ commit }) {
      commit(getSetterMutationName('selectedCoupon'), undefined);
      commit(getSetterMutationName('couponGroup'), undefined);
      commit(getSetterMutationName('itemsByGroupMap'), {});
    },

    clearSelectedItemsMap({ commit }) {
      commit(getSetterMutationName('selectedItemsMap'), {});
    },

    loadCouponByPLU({ commit, state }, payload: { locationId, plu }) {
      return couponService.getCouponByPLU(payload);
    },

    loadItemsForCoupon({ commit, state }, { groupId, couponId }) {
      let itemsByGroupMap = state.itemsByGroupMap[groupId];

      if (!itemsByGroupMap || itemsByGroupMap.length <= 0) {
        return couponService.getItemsForCoupon({
          couponId,
          groupId
        }).then(response => {
          if (!response.success && response.error) {
            notifierService.error('There was an error retrieving items for this coupon.  Please try again.');

            return null;
          } else {
            if (response.length === 0) {
              commit(getSetterMutationName('itemsByGroupMap'), {});

              return;
            }

            let menuItems = [];

            response.forEach(include => {
              menuItems = menuItems.concat(include.menuItems);
            });

            commit('SET_IN_ITEMS_BY_GROUP_MAP', { groupId, items: menuItems });

            return menuItems;
          }
        });
      } else {
        return null;
      }
    },

    selectCoupon({ commit }, payload) {
      commit(getSetterMutationName('selectedCoupon'), payload);
    },

    selectCouponGroup({ commit }, payload) {
      commit(getSetterMutationName('couponGroup'), payload);
    },

    selectCouponItem({ commit, state }, payload: { itemNumber: number, item: IMenuItem }) {
      commit('SELECT_COUPON_ITEM', payload);
    }
  }
};
