/*
 *  Documentation, defaults and computation for customizable colors.
 *
 *  {
 *    description, // Indications to the user on where that color is used.
 *    default, // Default color value.
 *    compute? // Optional Method. Used to compute the shade based on another color.
 *  }
 *
 * // TODO: move this to its own repo/package so we use the same file for Admin, TCC & Slice.
 *     Probably we might want to use yarn 2 workspaces.
 */
const tinycolor = require('tinycolor2');

module.exports = {
  'background': {
    description: 'Overall background of the page.',
    default: 'white'
  },
  //
  'primary': {
    description: 'Brand color of the company.',
    default: '#C53030'
  },
  'primary-100': {
    description: 'Darker Primary color. Usage: Action buttons changes color when passing mouse over.',
    default: '#9B2C2C',
    compute (currentColorValues) {
      return tinycolor(currentColorValues['primary']).darken(10).toString()
    }
  },
  //
  'primary-contrast': {
    description: 'Contrasts the Primary color when used as background.',
    default: '#ffffff'
  },
  'primary-contrast-100': {
    description: 'Slightly unlit shade of Primary Contrast. Also, lightest shade of the Primary color.'
      + ' Used more commonly on disabled fields.',
    default: '#FED7D7',
    compute (currentColorValues) {
      const primaryHsl = tinycolor(currentColorValues['primary']).toHsl()
      return tinycolor({h: primaryHsl.h, s: 0.95, l: 0.92}).toString()
    }
  },
  'primary-contrast-200': {
    description: 'Not so unlit shade of Primary Contrast. Also, light shade of the Primary color. Used more commonly'
      + ' for accessibility, on the outline of a field when focused.',
    default: '#F56565',
    compute (currentColorValues) {
      const primaryHsl = tinycolor(currentColorValues['primary']).toHsl()
      return tinycolor({h: primaryHsl.h, s: 0.88, l: 0.68}).toString()
    }
  },
  //
  'secondary': {
    description: "Serves as background of the Primary Color. Commonly used as all the containers' background.",
    default: 'white'
  },
  'secondary-100': {
    description: 'Used for very subtle UI outlines and separators like borders.',
    default: '#f5f5f5'
  },
  'secondary-200': {
    description: 'More commonly used on subtle instruction texts.',
    default: '#eeeeee'
  },
  'secondary-300': {
    description: 'More commonly used on Fields. Borders and instructional placeholders.',
    default: '#e0e0e0'
  },
  //
  'secondary-contrast': {
    description: 'Contrasts the Secondary color when used as background. Used for overall text.',
    default: '#424242'
  },
  'secondary-contrast-100': {
    description: 'Lightest contrast, for field labels.',
    default: '#9e9e9e'
  },
  'secondary-contrast-200': {
    description: 'Used on more accented field labels and instructional Icons.',
    default: '#757575'
  },
  'secondary-contrast-300': {
    description: "Lighter shade of Secondary Contrast. Used to slightly accent text.",
    default: '#616161'
  },
  'secondary-contrast-400': {
    description: 'Accent normal text.',
    default: '#212121'
  },
  'secondary-contrast-500': {
    description: 'Strongly accented text.',
    default: '#0a0a0a'
  },
  //
  'tertiary': {
    description: 'Nav bars, back buttons, container top tittle.',
    default: '#443D40'
  },
  'tertiary-contrast': {
    description: 'For text when Tertiary is used as background.',
    default: 'white'
  }
};
