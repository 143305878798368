const emojiPattern = /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g;

export function hasEmojis(text) {
  if (!text) {
    return false;
  }

  return !!text.match(emojiPattern);
}

export function removeEmojis(text) {
  if (!text) {
    return text;
  }

  return text.replace(emojiPattern, '');
}

export function isValidName(text) {
  return text && text.length <= 255 && !hasEmojis(text)
    && /[A-Za-z]/.test(text) // at least 1 letter
    && text !== 'XXXXXXXXXXXXXXXX'; // masked credit card number
}
