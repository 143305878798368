import subscriberMixin from '../../common/mixins/subscriber.mixin';
import account from '../../account/models/Account';
import restaurants from '../models/Restaurants';
import util, { Util } from '../../common/services/Util';
import RestaurantList from '../components/RestaurantList.vue';
import {IAddress} from '../../common/models/Address';
import environmentService from '../../common/services/environment.service';
import restaurantLoader from '../services/restaurant-loader.service';
import menu from '../../menu/models/Menu';
import cart from '../../cart/models/Cart';
import routeService from '../../common/services/route.service';
import notifierService from '../../common/services/notifier.service';
import maps from '../../maps/models/Maps';
import storageService from '../../common/services/storage.service';
import { LatLngLiteral } from '../../common/models/LatLng';
import { navigateToInnerLandingPage } from '../../common/mixins/base-component.mixin';
import { IRestaurant, ORDER_TYPES } from '../types/restaurant.types';
import order from '../../order/models/Order';

export default {
  mixins: [subscriberMixin],
  components: { RestaurantList },
  props: {
    automaticallySetLocation: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    // models

    cart () {
      return cart;
    },

    environment () {
      return environmentService;
    },

    maps () {
      return maps;
    },

    menu () {
      return menu;
    },

    notifier () {
      return notifierService;
    },

    restaurantLoader () {
      return restaurantLoader;
    },

    restaurants () {
      return restaurants;
    },

    routeService () {
      return routeService;
    },

    util () {
      return util;
    },

    // getters

    accountInfo() {
      return account.accountInfo;
    },

    defaultCoordinates () {
      return util.usaCenter;
    },

    // props

    isLoading() {
      return this.restaurants.loading;
    },

    isSelected() {
      return this.restaurants.selected;
    },

    locationFinderActive () {
      return account.locationFinderActive;
    },

    locations () {
      if (!this.findLocations && this.deliveryLocations) {
        return this.deliveryLocations;
      }

      return this.restaurantList;
    },

    restaurantList() {
      return this.restaurants.list;
    },

    selectedRestaurant() {
      return this.restaurants.selectedRestaurant;
    }
  },
  data () {
    return {
      calculateClosestRestaurantIntervalId: null,
      deliveryAddress: null,
      deliveryLocations: null,
      findLocations: false,
      geocodedOrigin: null,
      geocodingError: false,
      orderType: null,
      showNoLocationsFoundMessage: false,
      showMultipleLocationsFoundMessage: false,
      userInputOrigin: null,
      userLocation: null
    };
  },
  methods: {
    calculateClosestRestaurant() {
      if (this.restaurantList && this.restaurantList.length >= 1) {
        return maps.loadUserLocation().then(loc => {
          if (loc) {
            return this.maps.calculateClosestRestaurant(loc, this.restaurantList).catch(() => {
              return;
            });
          } else {
            throw false;
          }
        });
      } else {
        return Promise.reject();
      }
    },

    chooseRestaurant(payload: {restaurant: IRestaurant}, routeToMenu: boolean = true) {
        return this.restaurantLoader.chooseRestaurant(payload.restaurant, routeToMenu);
    },

    onSelectRestaurant(restaurant) {
      this.chooseRestaurant({restaurant}).then( () => {
        if (this.orderType) {
          if (this.orderType === ORDER_TYPES.DELIVERY) {
            this.setDeliveryOrderTypeAndZone(restaurant);
          } else {
            this.cart.setOrderType(this.orderType);
          }
        }
      });
    },

    onSelectModalRestaurant(payload: { restaurant: IRestaurant }) {
      if (!this.redirectIfAvailable(payload.restaurant)) {
        this.onSelectRestaurant(payload.restaurant);
      }
    },

    setDeliveryOrderTypeAndZone(restaurant: IRestaurant) {
      if (!this.redirectIfAvailable(restaurant)) {
        return this.cart.setOrderType(ORDER_TYPES.DELIVERY, false, restaurant).then(() => {
          return this.cartHelperService.chooseDelivery(false, order.tenderRequest.deliveryAddress, false, restaurant);
        }).catch(() => {
          // no cart to set order type on yet
        });
      }
    },

    submitDeliveryAddress(payload: { address: IAddress, saveDeliveryAddress: boolean }) {
      this.findLocations = false;
      this.deliveryAddress = payload.address;
      this.geocodedOrigin = null;
      this.orderType = ORDER_TYPES.DELIVERY;

      return maps.validateDeliveryAddress(payload.address).then((restaurantList: IRestaurant[]) => {
          this.geocodedOrigin = { lat: payload.address.latitude, lng: payload.address.longitude };

          storageService.sessionStorage.setItem('deliveryAddress', JSON.stringify(payload.address));
          const address = payload.address;

          if (payload.saveDeliveryAddress) {
            const user = this.profile.user;

            if (user && user.objectId) {
              const saveAddressPayload = {
                userId: user.objectId,
                addressLine: address.addressLine,
                addressLine2: address.addressLine2,
                city: address.city,
                stateCode: address.stateCode,
                stateName: address.stateName,
                postalCode: address.postalCode,
                type: 'DELIVERY'
              };

              this.profile.saveAddress(saveAddressPayload);
            }
          }

          if (restaurantList.length === 0) {
            this.deliveryLocations = restaurantList;
            this.showNoLocationsFoundMessage = true;
            this.showMultipleLocationsFoundMessage = false;
          } else if (restaurantList.length === 1) {
            this.chooseRestaurant({ restaurant: restaurantList[0] }).then(() => {
              this.setDeliveryOrderTypeAndZone(restaurantList[0]);
            });
          } else {
            this.deliveryLocations = restaurantList;
            this.showNoLocationsFoundMessage = false;
            this.showMultipleLocationsFoundMessage = true;
          }

          return restaurantList;
        }).catch(result => {
          if (result) {
            let notification: string = '';

            if ((typeof result) === 'string') {
              notification = result;
            } else if (result && (typeof result) === 'object' && result.length && result.length > 0) {
              this.profile.setSuggestedAddresses(result);

              return;
            } else {
              notification = result;
            }

            if ((Array.isArray(result) && result.length === 0) || notification === 'ZERO_RESULTS') {
              notification = 'We were unable to find an address based on what you entered. Please try again.';
            }

            this.notifier.error(notification, null, null, true);
          }
        });
    },

    findLocationsNearMe(origin: string) {
      this.findLocations = true;
      this.deliveryLocations = null;
      this.orderType = ORDER_TYPES.DEFAULT;

      this.userInputOrigin = origin;
      this.geocodedOrigin = null;
      this.geocodingError = false;
      this.showMultipleLocationsFoundMessage = false;
      this.showNoLocationsFoundMessage = false;

      const me = this;

      return maps.geocodeToLatLng(origin)
          .then(geocoded => {
            me.geocodedOrigin = geocoded;

            return geocoded;
          })
          .catch(() => {
            me.geocodingError = true;
          });
    },

    redirectIfAvailable(restaurant: IRestaurant): boolean {
      if (restaurant.redirectUrl) {
        this.environment.open(restaurant.redirectUrl);

        return true;
      } else {
        return false;
      }
    },

    onSelectClosestLocation() {
      this.calculateClosestRestaurant().then(restaurant => {
        if (restaurant) {
          this.selectClosestRestaurant();
        }
      });
    },

    selectClosestRestaurant() {
      const restaurant = this.restaurants.getClosestRestaurant();

      if (!this.redirectIfAvailable(restaurant)) {
        this.restaurants.selectClosestRestaurant().then(selectedRestaurant => {
          let restaurantId: string = selectedRestaurant.objectId;
          this.restaurants.checkCurrentTime(restaurantId);
          this.restaurants.getSizeMap(restaurantId);
          this.menu.loadCategoryDesigns(restaurantId);
          this.restaurantLoader.fetchHistoricalOrders(restaurantId);
          this.profile.switchLocations(restaurantId);

          return Util.waitUntil(() => this.cart.cart).then(() => {
            this.restaurants.checkIsOpen(restaurantId);
            this.restaurants.checkIsDeliveryOpen(restaurantId);

            if (this.orderType) {
              if (this.orderType === ORDER_TYPES.DELIVERY) {
                return this.setDeliveryOrderTypeAndZone(restaurant).then(() => {
                  return navigateToInnerLandingPage();
                });
              } else {
                return this.cart.setOrderType(this.orderType, undefined, restaurant).then(() => {
                  return navigateToInnerLandingPage();
                });
              }
            } else {
              return navigateToInnerLandingPage();
            }
          });
        });
      }
    },

    updateUserLocation(loc: LatLngLiteral) {
      this.userLocation = loc;
    }
  }
};
