import tabsMixin from './tabs.mixin';
import sectionHeadingsMixin from './section-headings.mixin';

export default {
    mixins: [tabsMixin, sectionHeadingsMixin],
    methods: {
        selectTab(elementId: string): void {
            this.selectedTabId = elementId;
            let element: any = document.querySelector(elementId);
            element.scrollIntoView();
        },

        // Styling
        getTabStyle(elementId: string): any {
            return elementId === this.selectedTabId ? this.getItemTabSelectedStyle() : this.getItemTabStyle();
        }
    }
};
