import { Modal } from 'uiv';
import designService from '../services/design.service';

export default {
  components: { Modal },
  computed: {
    // services

    designService () {
      return designService;
    },

    // styling

    bodyStyle() {
      return {};
    }
  },
  data () {
    return {
      isOpen: false
    };
  },
  methods: {
    open () {
      this.isOpen = true;

      if (this.$refs.modalModern) {
        this.$refs.modalModern.open();
      }
    },
    close () {
      this.isOpen = false;

      if (this.$refs.modalModern) {
        this.$refs.modalModern.close();
      }
    }
  }
};
