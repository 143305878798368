import { IMenuItem } from './Item';
import { ISuggestionItem } from '../../common/models/Suggestion';
import menuBus, { ITrackSuggestionRequest } from '../menu.bus';
import analyticsManager from '../../common/services/analytics-manager.service';
import { ICategory, MENU_STORE } from '../stores/menu.store';
import store from '../../../store';
import { VuexModel } from '../../common/data/VuexModel';
import restaurants from '../../restaurants/models/Restaurants';

export class Menu extends VuexModel {
  // getters

  get categories() {
    return this.state.entities.categories;
  }

  get categoryDesign() {
    return this.state.entities.categoryDesign;
  }

  get categoryDesigns() {
    return this.state.entities.categoryDesigns;
  }

  get currentSuggestions() {
    return this.state.currentSuggestions;
  }

  get defaultItemDesign() {
    return this.state.entities.defaultItemDesign;
  }

  get featuredItems() {
    return this.state.entities.featuredItems;
  }

  get itemDesign() {
    return this.state.entities.itemDesign;
  }

  get itemDesigns() {
    return this.state.itemDesigns;
  }

  get itemMap() {
    return this.state.itemMap;
  }

  get items(): IMenuItem[] {
    return store.getters[this.storeName + '/items'];
  }

  get loading() {
    return this.state.loading;
  }

  get selectedCategory() {
    return this.state.entities.selectedCategory;
  }

  get selectedItem() {
    return this.state.entities.selectedItem;
  }

  get selectedStyleList() {
    return this.state.entities.selectedStyleList;
  }

  get selectedToppingsList() {
    return this.state.entities.selectedToppingsList;
  }

  get splitToppingsList() {
    return this.state.entities.splitToppingsList;
  }

  get splitItemChoices() {
    return this.state.entities.splitItemChoices;
  }

  get storeName() {
    return MENU_STORE;
  }

  get suggestions() {
    return this.state.suggestions;
  }

  get suggestionsShown() {
    return this.state.suggestionsShown;
  }

  // methods

  loadCategories(locationId: string) {
    return this.dispatch('loadCategories', locationId);
  }

  loadFeaturedItems(locationId: string) {
    return this.dispatch('loadFeaturedItems', locationId);
  }

  loadStyleList(itemId: string) {
    return this.dispatch('loadStyleList', itemId);
  }

  loadToppingsList(itemId: string, sizeId: string, styleId: string) {
    return this.dispatch('loadToppingsList', { itemId, sizeId, styleId });
  }

  loadSplitToppingsList(itemId: string) {
    return this.dispatch('loadSplitToppingsList', itemId);
  }

  viewCategoryById(categoryId: string) {
    let payload: any = {};

    const categories = this.state.entities.categories;

    if (categories) {
      payload = categories.filter((category: ICategory) => {
        return category.objectId === categoryId;
      });
    }

    if (payload) {
      return this.viewCategory(payload[0]);
    } else {
      return false;
    }
  }

  viewCategory(category: ICategory) {
    const result = this.dispatch('selectCategory', category);

    if (!category.otherCategoryIdList || category.otherCategoryIdList.length === 0) {
      this.loadCoupons(category.objectId);
    }

    this.loadCategoryDesign(category.objectId);

    /* analyticsManager.track('Selecting a category', {
      categoryName: category.name,
      categoryId: category.objectId,
      locationId: restaurants.selectedRestaurant.objectId,
      locationName: restaurants.selectedRestaurant.name,
      sortOrder: category.sortOrder,
      type: category.type
    });*/

    return result;
  }

  clearItemOptions() {
    return this.dispatch('clearItemOptions');
  }

  loadCategoryDesign(categoryId: string) {
    return this.dispatch('loadCategoryDesign', categoryId);
  }

  loadCategoryDesigns(locationId: string) {
    return this.dispatch('loadCategoryDesigns', locationId);
  }

  loadItemsForSplit(departmentId: string) {
    return this.dispatch('loadItemsForSplit', departmentId);
  }

  clearSplitItemChoices() {
    return this.dispatch('clearSplitItemChoices');
  }

  loadSuggestions(cartId: string, itemId: string) {
    let payload: any = {
      cartId,
      itemId
    };

    return this.dispatch('loadSuggestions', payload);
  }

  showSuggestions(suggestions: ISuggestionItem[]) {
    this.dispatch('showSuggestions', suggestions);

    menuBus.showSuggestions$.next(suggestions);
  }

  trackSuggestion(payload: ITrackSuggestionRequest) {
    return this.dispatch('trackSuggestion', payload);
  }

  loadCoupons(categoryId: string) {
    return this.dispatch('loadCoupons', categoryId);
  }

  loadItemMap(locationId: string) {
    return this.dispatch('loadItemMap', locationId);
  }
}

export default new Menu();
