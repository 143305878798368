
  import designService from '../../common/services/design.service';
  import {Util} from '../../common/services/Util';
  import Vue from 'vue';
  import designMixin from '../../common/mixins/design.mixin';
  import { IRestaurant, IStoreHours, ORDER_TYPES } from '../types/restaurant.types';
  import restaurants from '../models/Restaurants';

  const moment = require('moment');

  type HoursList = Array<{
    hours: string,
    day: string
  }>;

  export function getConsistentLocationHours(storeHours: IStoreHours, orderType: string) {
    let orderTypeString;

    if (orderType === ORDER_TYPES.DELIVERY) {
      orderTypeString = 'Delivery';
    } else {
      orderTypeString = 'default';
    }

    const hourRanges = storeHours[orderTypeString].ranges;
    const day = Object.keys(hourRanges)[0];

    return Util.getFormattedHourRange(day, hourRanges, orderType);
  }

  export function getLocationHoursList(storeHours: IStoreHours, orderType: string): HoursList {
    let orderTypeString;

    if (orderType === ORDER_TYPES.DELIVERY) {
      orderTypeString = 'Delivery';
    } else {
      orderTypeString = 'default';
    }

    const hourRanges = storeHours[orderTypeString].ranges;

    let hoursList: HoursList = [];

    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const nowMoment = moment(restaurants.currentTime);
    const nowDayOfWeek = nowMoment.format('dddd');

    days.forEach(day => {
      // allow holiday hours to override today's hours
      if (nowDayOfWeek === day) {
        hoursList.push({
          day,
          hours: Util.getFormattedHourRange(nowMoment, storeHours, orderType).replace('\n', '<br />')
        });
      } else if (hourRanges.hasOwnProperty(day)) {
        hoursList.push({
          day,
          hours: Util.getFormattedHourRange(day, storeHours, orderType).replace('\n', '<br />')
        });
      } else {
        hoursList.push({
          day,
          hours: 'Closed'
        });
      }
    });

    let sortedHoursList = _sortHoursListByDayOfTheWeek(hoursList);
    let groupedHoursList = _groupHoursList(sortedHoursList);

    return groupedHoursList;
  }

  export function _sortHoursListByDayOfTheWeek(hoursList): HoursList {
    let daysList: string[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      sortedHoursList: HoursList = [];

    for (let day of daysList) {
      for (let hour of hoursList) {
        if (hour.day === day) {
          sortedHoursList.push(hour);
          break;
        }
      }
    }

    return sortedHoursList;
  }

  export function _groupHoursList(hoursList): HoursList {
    if (hoursList.length === 0) {
      return hoursList;
    }

    let result: HoursList = [];
    let firstDay = hoursList[0];
    let lastHour;

    function addGroup() {
      let label = firstDay.day;

      if (lastHour) {
        label += ' - ' + lastHour.day;
      }

      result.push({day: label, hours: firstDay.hours});
    }

    for (let i = 1; i < hoursList.length; i++) {
      let hour = hoursList[i];

      if (firstDay.hours === hour.hours) {
        lastHour = hour;
      } else {
        addGroup();

        firstDay = hour;
        lastHour = null;
      }
    }

    addGroup();

    return result;
  }

  export default Vue.extend({
    mixins: [designMixin],
    props: {
      containerStyle: {
        type: Object,
        required: false
      },
      restaurant: {
        type: Object as () => IRestaurant,
        required: true
      }
    },
    computed: {
      // services

      _designService() {
        return designService;
      },

      // getters

      areLocationHoursConsistent(): boolean {
        return this.restaurant.hours.default.hoursAreConsistent;
      },

      areDeliveryHoursConsistent(): boolean {
        return this.restaurant.hours.Delivery.hoursAreConsistent;
      },

      consistentLocationHours(): string {
        return getConsistentLocationHours(this.restaurant.hours, ORDER_TYPES.DEFAULT).replace('\n', '<br />');
      },

      consistentDeliveryHours(): string {
        return getConsistentLocationHours(this.restaurant.hours, ORDER_TYPES.DELIVERY).replace('\n', '<br />');
      },

      locationHoursList(): HoursList {
        if (this.restaurant.hours.default.hoursAreConsistent) {
          return null;
        } else {
          return getLocationHoursList(this.restaurant.hours, ORDER_TYPES.DEFAULT);
        }
      },

      deliveryHoursList(): HoursList {
        if (this.restaurant.hours.Delivery.hoursAreConsistent) {
          return null;
        } else {
          return getLocationHoursList(this.restaurant.hours, ORDER_TYPES.DELIVERY);
        }
      },

      hasDeliveryEnabled(): boolean {
        return this.restaurant.orderTypes.hasOwnProperty(ORDER_TYPES.DELIVERY);
      },

      // styling

      textColor(): string {
        return this._designService.popupMainTextColor;
      }
    }
  });
