import {EventEmitter} from '../data/EventEmitter';
import {IFulfilledCoupon, IMenuItem} from '../../menu/models/Item';
import {ICoupon} from '../../coupons/models/Coupon';
import { IAddress } from '../models/Address';
import { IUnavailableMessage } from '../../cart/cart.types';

export class ModalService {
    public addItem$: EventEmitter<IMenuItem>;
    public cancelClearCartModal$: EventEmitter<any>;
    public cancelCoupon$: EventEmitter<ICoupon>;
    public cancelDelivery$: EventEmitter<any>;
    public changeRestaurant$: EventEmitter<any>;
    public changeRestaurantModal$: EventEmitter<any>;
    public clearCart$: EventEmitter<any>;
    public clearCartModal$: EventEmitter<any>;
    public closeChangeRestaurantModal$: EventEmitter<any>;
    public closeCouponModal$: EventEmitter<any>;
    public closeDeliveryModal$: EventEmitter<any>;
    public customizeItem$: EventEmitter<IMenuItem>;
    public endDeliverySubscriptions$: EventEmitter<any>;
    public errorModal$: EventEmitter<string[]>;
    public progressBarModal$: EventEmitter<any>;
    public removeCoupon$: EventEmitter<IFulfilledCoupon>;
    public removeCouponModal$: EventEmitter<IFulfilledCoupon>;
    public selectCouponErrorModal$: EventEmitter<{ coupon: ICoupon, messages: string[] }>;
    public selectCouponModal$: EventEmitter<ICoupon>;
    public selectDeliveryModal$: EventEmitter<any>;
    public closedDeliveryModal$: EventEmitter<any>;
    public selectItemModal$: EventEmitter<IMenuItem>;
    public selectOrderType$: EventEmitter<string>;
    public selectOrderTypeModal$: EventEmitter<any>;
    public closeOrderTypeModal$: EventEmitter<any>;
    public submitDelivery$: EventEmitter<{ saveDeliveryAddress: boolean, deliveryAddress: IAddress }>;
    public unableToPriceItemModal$: EventEmitter<IMenuItem>;
    public openScheduleOrderModal$: EventEmitter<boolean>;
    public closeScheduleOrderModal$: EventEmitter<any>;
    public feedbackModal$: EventEmitter<any>;
    public itemUnavailableModal$: EventEmitter<IUnavailableMessage[]>;

    constructor() {
        this.addItem$ = new EventEmitter<IMenuItem>(null);
        this.cancelClearCartModal$ = new EventEmitter<any>(false);
        this.cancelCoupon$ = new EventEmitter<ICoupon>(null);
        this.cancelDelivery$ = new EventEmitter<any>(false);
        this.changeRestaurant$ = new EventEmitter<any>(false);
        this.changeRestaurantModal$ = new EventEmitter<any>(false);
        this.clearCart$ = new EventEmitter<any>(false);
        this.clearCartModal$ = new EventEmitter<any>(false);
        this.closeChangeRestaurantModal$ = new EventEmitter<any>(false);
        this.closeCouponModal$ = new EventEmitter<any>(false);
        this.closeDeliveryModal$ = new EventEmitter<any>(false);
        this.customizeItem$ = new EventEmitter<IMenuItem>(null);
        this.endDeliverySubscriptions$ = new EventEmitter<any>(false);
        this.errorModal$ = new EventEmitter<string[]>(null);
        this.progressBarModal$ = new EventEmitter<any>(false);
        this.removeCoupon$ = new EventEmitter<IFulfilledCoupon>(null);
        this.removeCouponModal$ = new EventEmitter<IFulfilledCoupon>(null);
        this.selectCouponErrorModal$ = new EventEmitter<{ coupon: ICoupon, messages: string[] }>(null);
        this.selectCouponModal$ = new EventEmitter<ICoupon>(null);
        this.selectDeliveryModal$ = new EventEmitter<any>(false);
        this.closedDeliveryModal$ = new EventEmitter<any>(false);
        this.selectItemModal$ = new EventEmitter<IMenuItem>(null);
        this.selectOrderType$ = new EventEmitter<string>(null);
        this.selectOrderTypeModal$ = new EventEmitter<any>(false);
        this.closeOrderTypeModal$ = new EventEmitter<any>(false);
        this.submitDelivery$ = new EventEmitter<{ saveDeliveryAddress: boolean, deliveryAddress: IAddress }>(null);
        this.unableToPriceItemModal$ = new EventEmitter<IMenuItem>();
        this.openScheduleOrderModal$ = new EventEmitter<any>(false);
        this.closeScheduleOrderModal$ = new EventEmitter<any>(false);
        this.feedbackModal$ = new EventEmitter<any>(false);
        this.itemUnavailableModal$ = new EventEmitter<IUnavailableMessage[]>(null);
    }

    addItem(item: IMenuItem): void {
        this.addItem$.emit(item);
    }

    cancelClearCart(): void {
        this.cancelClearCartModal$.emit(null);
    }

    cancelCoupon(coupon: ICoupon): void {
        this.cancelCoupon$.emit(coupon);
    }

    cancelDelivery(): void {
        this.cancelDelivery$.emit(null);
    }

    closedDeliveryModal(): void {
        this.closedDeliveryModal$.emit(null);
    }

    changeRestaurant(): void {
        this.changeRestaurant$.emit(null);
    }

    changeRestaurantModal(): void {
        this.changeRestaurantModal$.emit(null);
    }

    clearCart(): void {
        this.clearCart$.emit(null);
    }

    clearCartModal(): void {
        this.clearCartModal$.emit(null);
    }

    closeChangeRestaurantModal(): void {
        this.closeChangeRestaurantModal$.emit(null);
    }

    closeCouponModal(): void {
        this.closeCouponModal$.emit(null);
    }

    closeDeliveryModal(): void {
        this.closeDeliveryModal$.emit(null);
    }

    customizeItem(item: IMenuItem): void {
        this.customizeItem$.emit(item);
    }

    endDeliverySubscriptions(): void {
        this.endDeliverySubscriptions$.emit(null);
    }

    progressBarModal(): void {
        this.progressBarModal$.emit(null);
    }

    removeCoupon(coupon: IFulfilledCoupon): void {
        this.removeCoupon$.emit(coupon);
    }

    removeCouponModal(coupon: IFulfilledCoupon): void {
        this.removeCouponModal$.emit(coupon);
    }

    selectCouponModal(coupon: ICoupon): void {
        this.selectCouponModal$.emit(coupon);
    }

    openScheduleOrderModal(isCheckingOut: boolean = false) {
        this.openScheduleOrderModal$.emit(isCheckingOut);
    }

    closeScheduleOrderModal() {
        this.closeScheduleOrderModal$.emit(null);
    }

    selectDeliveryModal(): void {
        this.selectDeliveryModal$.emit(null);
    }

    selectItemModal(item: IMenuItem): void {
        this.selectItemModal$.emit(item);
    }

    selectOrderType(orderType: string): void {
        this.selectOrderType$.emit(orderType);
    }

    selectOrderTypeModal(): void {
        this.selectOrderTypeModal$.emit(null);
    }

    closeOrderTypeModal(): void {
        this.closeOrderTypeModal$.emit(null);
    }

    selectCouponErrorModal(coupon: ICoupon, messages: string[]): void {
        this.selectCouponErrorModal$.emit({ coupon, messages });
    }

    showErrorModal(errors: string[]) {
        this.errorModal$.emit(errors);
    }

    submitDelivery(payload: { saveDeliveryAddress: boolean, deliveryAddress: IAddress }): void {
        this.submitDelivery$.emit(payload);
    }

    unableToPriceItem(item: IMenuItem): void {
        this.unableToPriceItemModal$.emit(item);
    }

    openItemUnavailableModal(unavailableMessages: IUnavailableMessage[]): void {
      this.itemUnavailableModal$.emit(unavailableMessages);
    }
}

export default new ModalService();
