
import Vue from 'vue';
import LoadingCircle from '../../common/components/LoadingCircle.vue';

export default Vue.extend({
  components: { LoadingCircle },

  props: {
    geocodingError: {
      type: Boolean,
      required: true
    },

    searchingForLocations: {
      type: Boolean,
      required: true
    }, // LatLngLiteral | boolean

    submitButtonClass: {
      type: String,
      default: 'btn-primary'
    },

    userLocation: {}
  },

  data: () => ({
    calculateClosestRestaurantIntervalId: null,
    invalidOriginValue: false,
    originInput: ''
  }),

  methods: {
    findALocationNearMe() {
      if (this.originInput === '') {
        this.invalidOriginValue = true;
      } else {
        this.$emit('onFindLocationsNearMe', this.originInput);
      }
    },

    onSelectClosestLocation() {
      this.$emit('onSelectClosestLocation');
    }
  }
});
