import { IMenuItem } from '../models/Item';
import decodeText from '../../common/filters/DecodeText';
import filtersMixin from '../../common/mixins/filters.mixin';

export default {
  mixins: [filtersMixin],
  props: {
    hasNoItemImages: {
      type: Boolean,
      required: true
    },
    image: {
      type: String,
      required: false
    },
    item: {
      type: Object as () => IMenuItem,
      required: true
    }
  },
  computed: {
    hasImage() {
      return this.item.showImage && this.image && !this.isImageBroken;
    }
  },
  data() {
    return {
      isImageBroken: false
    };
  },
  methods: {
    onImageError() {
      this.isImageBroken = true;
    }
  }
};
