import cart from '../../cart/models/Cart';
import restaurants from '../../restaurants/models/Restaurants';
import { ISuggestionItem } from '../models/Suggestion';
import { IMenuItem, IMenuItemSize } from '../../menu/models/Item';
import { Util } from './Util';
import { Subscriber } from './Subscriber';
import menu from '../../menu/models/Menu';
import menuBus, { ITrackSuggestionRequest } from '../../menu/menu.bus';
import routeService from './route.service';
import { navigateToMenu } from '../mixins/base-component.mixin';
import { IRestaurant } from '../../restaurants/types/restaurant.types';
import SuggestionsManager from './suggestions-manager';
import profile from '../../profile/models/Profile';
import account from '../../account/models/Account';
import { ICart } from '../../cart/cart.types';
import { MENU_STORE } from '../../menu/stores/menu.store';
import store from '../../../store';
import analyticsManager from '../../common/services/analytics-manager.service';

declare var dataLayer: any;

export class SuggestionService extends Subscriber {
    suggestionsManager = new SuggestionsManager(
      suggestionItems => menu.showSuggestions(suggestionItems),
      () => store.state[MENU_STORE].suggestionsShown
    );

    constructor() {
        super();

        this.subscribe(menuBus.trackSuggestion$, (trackRequest: ITrackSuggestionRequest) => {
            menu.trackSuggestion(trackRequest);
        });


        Object.defineProperty(this.suggestionsManager, 'restaurant', {
            get() {
                return restaurants.selectedRestaurant;
            }
        });


        Object.defineProperty(this.suggestionsManager, 'user', {
            get() {
                return profile.user;
            }
        });
    }

    // getters

    get suggestionsShown() {
        return menu.suggestionsShown;
    }

    // methods

    checkForSuggestions(item?: IMenuItem, restaurant?: IRestaurant, addItem?: Function): Promise<boolean> {
        return this.suggestionsManager.checkForSuggestions(
          () => Promise.resolve(menu.suggestions),
          cart.cart.items,
          item,
          restaurant,
          addItem);
    }

    // suggestion modal event handlers

    addSuggestion(suggestion: ISuggestionItem, size?: IMenuItemSize): void {
        let payload: ITrackSuggestionRequest = this._buildTrackingRequest(suggestion, true);

        menuBus.trackSuggestion$.next(payload);

        // Google tag manager tracking
        dataLayer.push({
          event: 'suggestion',
          data: {
            itemId: suggestion.itemId,
            itemName: suggestion.name,
            cartId: cart.cart.objectId,
            userId: this.suggestionsManager.user ? this.suggestionsManager.user.objectId : null,
            locationId: this.suggestionsManager.restaurant.objectId,
            locationName: this.suggestionsManager.restaurant.name
          }
        });

        // tracking
        /* analyticsManager.track('Item suggestion accepted and added to cart', {
          itemId: suggestion.itemId,
          itemName: suggestion.name,
          cartId: cart.cart.objectId,
          userId: this.suggestionsManager.user ? this.suggestionsManager.user.objectId : null,
          locationId: this.suggestionsManager.restaurant.objectId,
          locationName: this.suggestionsManager.restaurant.name
        });*/

        return cart.addItem({ item: suggestion, sizeId: size ? size.objectId : null }).then((cartObject: ICart) => {
            Util.decideToCustomizeItem(
                suggestion,
                null,
                restaurants.sizeMap,
                restaurants.selectedRestaurant.itemSelectedPostTask,
                () => {
                    let data: any = {
                      itemId: suggestion.objectId
                    };

                    routeService.fromSuggestion = true;
                    routeService.route('CustomizeItem', data);
                }, () => {
                    navigateToMenu();
                }, null
            );

            return cartObject;
        });
    }

    rejectSuggestion(suggestion: ISuggestionItem): void {
        let payload: ITrackSuggestionRequest = this._buildTrackingRequest(suggestion, false);

        menuBus.trackSuggestion$.next(payload);
    }

    _buildTrackingRequest(suggestion: ISuggestionItem, accepted: boolean): ITrackSuggestionRequest {
        return {
            cartId: cart.cart.objectId,
            sourceItemId: this._getSourceItemId(),
            suggestItemId: suggestion.objectId,
            personalized: suggestion.personalized,
            accepted
        };
    }

    _getSourceItemId(): string {
        return cart.selectedItem.itemId;
    }
}

export default new SuggestionService();
