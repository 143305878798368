export function formatCurrency (value, currencyCode, symbolDisplay) {
    if (!currencyCode) {
        currencyCode = 'USD';
    }

    var result = '';

    result += Number(value).toFixed(2);

    if (symbolDisplay) {
        var symbol;

        if (currencyCode === 'USD')
            symbol = '$';

        // put symbol after minus sign
        if (result.substr(0, 1) === '-') {
            result = '-' + symbol + result.substr(1);
        } else {
            result = symbol + result;
        }
    }

    return result;
}
