import {BaseApiService} from '../../common/services/BaseApiService';
import {
  API_SUBMIT_LOGIN_REQUEST_URL,
  API_SUBMIT_REGISTER_REQUEST_URL,
  API_UPDATE_PROFILE_URL,
  API_CHANGE_PASSWORD_URL,
  API_LOAD_ADDRESSES_URL,
  API_LOAD_PHONES_URL,
  API_LOAD_ADDRESS_TYPES_URL,
  API_CREATE_ADDRESS_URL,
  API_UPDATE_ADDRESS_URL,
  API_REMOVE_ADDRESS_URL,
  API_SET_PRIMARY_ADDRESS_URL,
  API_LOAD_PHONE_TYPES_URL,
  API_CREATE_PHONE_URL,
  API_UPDATE_PHONE_URL,
  API_REMOVE_PHONE_URL,
  API_LOAD_CREDIT_CARDS_URL,
  API_SAVE_CREDIT_CARD_URL,
  API_DELETE_ACCOUNT_URL,
  API_REMOVE_CREDIT_CARD_URL,
  API_SWITCH_LOCATION_URL,
  API_SET_DEVICE_TOKEN_URL,
  API_RECEIVED_NOTIFICATION_URL,
  API_UPDATE_EXPIRATION_DATE_URL,
  API_RESET_PASSWORD_URL,
  API_CHANGE_PASSWORD_WITH_TOKEN_URL,
  API_LOAD_COUPON_URL,
  API_UPDATE_LOCATION_URL,
  API_REFRESH_URL, API_LOGOUT_URL, API_GET_DETAILS_URL
} from '../profile.constants';
import { IUpdateExpirationDateRequest } from '../profile.bus';
import {RequestMethod} from '../../common/services/axios-http.service';
import { ILoginRequest, IRegisterRequest } from '../stores/profile.store';
import { IAddress } from '../../common/models/Address';

export class ProfileService extends BaseApiService {
    getUserDetails(accountId: string, locationId: string) {
      return this.request({
        method: RequestMethod.Get,
        search: `accountId=${accountId}&locationId=${locationId}`,
        url: API_GET_DETAILS_URL
      });
    }

    logIn(loginRequest: ILoginRequest) {
        return this.request({
            method: RequestMethod.Post,
            body: loginRequest,
            url: API_SUBMIT_LOGIN_REQUEST_URL
        });
    }

    register(registerRequest: IRegisterRequest) {
        return this.request({
            method: RequestMethod.Post,
            body: registerRequest,
            url: API_SUBMIT_REGISTER_REQUEST_URL
        });
    }

    loadAddresses(userId: string) {
        return this.request({
            method: RequestMethod.Get,
            url: API_LOAD_ADDRESSES_URL,
            search: `user=${userId}`
        });
    }

    loadPhones(userId: string) {
        return this.request({
            method: RequestMethod.Get,
            url: API_LOAD_PHONES_URL,
            search: `user=${userId}`
        });
    }

    updateProfile(payload: {userId: string, firstName: string, lastName: string, rewards: boolean}) {
        return this.request({
            method: RequestMethod.Post,
            url: API_UPDATE_PROFILE_URL,
            body: payload,
            search: `user=${payload.userId}`
        });
    }

    changePassword(payload: {userId: string, password: string, currentPassword: string}) {
        return this.request({
            method: RequestMethod.Post,
            url: API_CHANGE_PASSWORD_URL,
            body: payload,
            search: `user=${payload.userId}`
        });
    }

    loadAddressTypes() {
        return this.request({
            method: RequestMethod.Get,
            url: API_LOAD_ADDRESS_TYPES_URL
        });
    }

    createAddress(payload: any) {
        return this.request({
            method: RequestMethod.Post,
            url: API_CREATE_ADDRESS_URL,
            body: payload,
            search: `user=${payload.userId}`
        });
    }

    updateAddress(payload: any) {
        return this.request({
            method: RequestMethod.Post,
            url: API_UPDATE_ADDRESS_URL,
            body: payload,
            search: `user=${payload.userId}&instance=${payload.objectId}`
        });
    }

    removeAddress(payload: any) {
        return this.request({
            method: RequestMethod.Get,
            url: API_REMOVE_ADDRESS_URL,
            search: `user=${payload.userId}&instance=${payload.objectId}`
        });
    }

    loadPhoneTypes() {
        return this.request({
            method: RequestMethod.Get,
            url: API_LOAD_PHONE_TYPES_URL
        });
    }

    createPhone(payload: any) {
        return this.request({
            method: RequestMethod.Get,
            url: API_CREATE_PHONE_URL,
            search: `user=${payload.userId}&number=${payload.number}&type=${payload.type}`
        });
    }

    updatePhone(payload: any) {
        return this.request({
            method: RequestMethod.Get,
            url: API_UPDATE_PHONE_URL,
            search: `user=${payload.userId}&instance=${payload.objectId}&number=${payload.number}&type=${payload.type}`
        });
    }

    removePhone(payload: any) {
        return this.request({
            method: RequestMethod.Get,
            url: API_REMOVE_PHONE_URL,
            search: `user=${payload.userId}&instance=${payload.objectId}`
        });
    }

    setPrimaryAddress(payload: any) {
        return this.request({
            method: RequestMethod.Get,
            url: API_SET_PRIMARY_ADDRESS_URL,
            search: `user=${payload.userId}&instance=${payload.objectId}&primary=${payload.primaryAddress}`
        });
    }

    setDeviceToken(payload: any) {
        return this.request({
            method: RequestMethod.Post,
            url: API_SET_DEVICE_TOKEN_URL,
            search: `user=${payload.userId}&token=${payload.token}`
        });
    }

    unsetDeviceToken(payload: any) {
        return this.request({
            method: RequestMethod.Delete,
            url: API_SET_DEVICE_TOKEN_URL,
            search: `user=${payload.userId}&token=${payload.token}`
        });
    }

    receivedNotification(notificationId: string) {
        return this.request({
            method: RequestMethod.Post,
            url: API_RECEIVED_NOTIFICATION_URL,
            search: `uuid=${notificationId}`
        });
    }

    logout() {
      return this.request({
        method: RequestMethod.Get,
        url: API_LOGOUT_URL
      });
    }

    deleteAccount(userId: string) {
        return this.request({
            method: RequestMethod.Get,
            url: API_DELETE_ACCOUNT_URL,
            search: `user=${userId}`
        });
    }

    saveCreditCard(payload: {
        userId: string,
        encryptedCardInfo: string,
        creditCardId: string,
        billingAddress: IAddress,
        locationId: string
    }) {
        let params = `user=${payload.userId}&creditCard=${payload.creditCardId}&location=` + payload.locationId;

        return this.request({
            method: RequestMethod.Post,
            url: API_SAVE_CREDIT_CARD_URL,
            body: payload,
            search: params
        });
    }

    removeCreditCard(payload: any) {
        return this.request({
            method: RequestMethod.Get,
            url: API_REMOVE_CREDIT_CARD_URL,
            search: `user=${payload.userId}&instance=${payload.creditCardId}`
        });
    }

    loadCreditCards(userId: string) {
        return this.request({
            method: RequestMethod.Get,
            url: API_LOAD_CREDIT_CARDS_URL,
            search: `user=${userId}`
        });
    }

    switchLocation(payload: any) {
        return this.request({
            method: RequestMethod.Get,
            url: API_SWITCH_LOCATION_URL,
            search: `user=${payload.userId}&location=${payload.locationId}`
        });
    }

    resetPassword(payload: any) {
        return this.request({
          method: RequestMethod.Post,
          url: API_RESET_PASSWORD_URL,
          body: payload,
          search: `account=${payload.accountId}&location=${payload.locationId}`
        });
    }

    updateExpirationDate(payload: IUpdateExpirationDateRequest) {
        return this.request({
          method: RequestMethod.Get,
          url: API_UPDATE_EXPIRATION_DATE_URL,
          search: `user=${payload.userId}&expiration=${payload.expirationDate}&instance=${payload.creditCardId}`
        });
    }

    changePasswordWithToken(payload: { password: string, token: string }) {
        return this.request({
            method: RequestMethod.Post,
            url: API_CHANGE_PASSWORD_WITH_TOKEN_URL,
            body: { password: payload.password },
            search: `token=${payload.token}`
        });
    }

    loadCoupon(payload: { offerId: number, locationId: number }) {
        return this.request({
            method: RequestMethod.Get,
            url: API_LOAD_COUPON_URL,
            body: payload,
            search: `location=${payload.locationId}&offer=${payload.offerId}`
        });
    }

    updateLocation(payload: { firstName: string, lastName: string, location: string, rewards: boolean, userId: string }) {
        return this.request({
            method: RequestMethod.Post,
            url: API_UPDATE_LOCATION_URL,
            body: payload,
            search: `user=${payload.userId}`
        });
    }

    refresh(payload: { userId: string, locationId: string }) {
      return this.request({
        method: RequestMethod.Get,
        url: API_REFRESH_URL,
        body: payload,
        search: `user=${payload.userId}&location=${payload.locationId}`
      });
    }
}

export default new ProfileService();
