
  import Vue from 'vue';
  import cartHelper from '../../common/mixins/cart-helpers.mixin';
  import filtersMixin from '../../common/mixins/filters.mixin';

  export default Vue.extend({
    mixins: [cartHelper, filtersMixin],

    computed: {
      cartTotal() {
        return this.$store.state.cart.cart.grandTotal
      }
    },

    methods: {
      showErrorAlert(error) {
        this.$emit('showErrorAlert', error);
      },

      reviewOrder() {
        const result = this.cartHelperService.reviewOrder(true);

        if (result && !result.success && result.failureMessage && result.itemId) {
          this.showErrorAlert({ error: result.failureMessage, itemId: result.itemId });
        }
      }
    }
  });
