import designService, { DesignService } from '../services/design.service';
import environmentService, {EnvironmentService} from '../services/environment.service';
import profile, { Profile } from '../../profile/models/Profile';
import routeService, { RouteService } from '../services/route.service';
import subscriberMixin from './subscriber.mixin';
import designMixin from './design.mixin';
import maps from '../../maps/models/Maps';
import { IRestaurant } from '../../restaurants/types/restaurant.types';
import filtersMixin from './filters.mixin';
import couponWalkthrough, { CouponWalkthrough } from '../../coupons/models/CouponWalkthrough';
import loyaltyService from '../../coupons/services/loyalty.service';
import cart from '../../cart/models/Cart';
import { decideNavigationToInnerLandingPage } from '../../common/helpers/navigation.helpers';
import { isEntireOrderCoupon } from '../../coupons/helpers/coupon.helpers';

export function navigateToInnerLandingPage(routeToMenu?) {
    return decideNavigationToInnerLandingPage(
      environmentService.directRoute,
      environmentService.offerId,
      plu => couponWalkthrough.loadCouponByPLU(plu),
      () => loyaltyService.linkOffer,
    coupon => {
        loyaltyService.linkOffer = coupon;
        loyaltyService.isLinkOfferFulfilled = false;
        environmentService.offerId = null;
      },
      route => routeService.route(route),
      routeToMenu,
      navigateToMenu,
      c => {
          const found = cart.cart.coupons.find(c => c.couponId === loyaltyService.linkOffer.couponId);

          if (found) {
              navigateToMenu();
          } else {
              if (isEntireOrderCoupon(c)) {
                  loyaltyService.chooseCoupon(c, false);
              } else {
                  CouponWalkthrough.initiateCouponWalkthrough(c);
              }
          }
      },
      () => cart.cart,
      loyaltyService.isLinkOfferFulfilled
    );
}

export function navigateToMenu() {
    return routeService.route('MainMenu');
}

export default {
    mixins: [subscriberMixin, designMixin, filtersMixin],
    computed: {
        // models

        environment(): EnvironmentService {
            return environmentService;
        },

        profile(): Profile {
            return profile;
        },

        routeService(): RouteService {
            return routeService;
        },

        // getters

        isAndroid(): boolean {
            return this.environment.isAndroid;
        },

        isDirectRoute() {
            return !!environmentService.directRoute;
        },

        isiOS(): boolean {
            return this.environment.isiOS;
        },

        isMobile(): boolean {
            return this.environment.isMobile;
        },

        isMobileApp(): boolean {
            return this.environment.isMobileApp;
        },

        locationId(): string {
            if (typeof localStorage !== 'undefined') {
                let result = localStorage.getItem('selectedRestaurant');

                if (result) {
                    return JSON.parse(result);
                }
            }

            return null;
        },

        requiresAuthentication(): boolean {
            return false;
        }
    },
    data () {
        return {
            isInitialized: false
        };
    },
    created() {
        if (this.requiresAuthentication && !this.profile.user) {
            this.logIn({ source: routeService.currentRoute.name });

            return;
        }

        if (this.loadData() && this.environment && this.environment.isMobileApp) {
            this.environment.addAppResumedListener(() => {
                this.loadData();
            });
        }

        this.isInitialized = true;
    },
    methods: {
        loadData(): boolean {
            return false;
        },

        isLoggedIn(): boolean {
            return this.profile.user && this.profile.user.objectId && this.profile.user.objectId.length > 0;
        },

        logIn(data?: any): void {
            this.routeService.route('Login', data, true);
        },

        register(params) {
          this.routeService.route('Register', params, !!params);
        },

        logOut() {
            return this.profile.logOut();
        },

        // navigation

        navigateToInnerLandingPage() {
            navigateToInnerLandingPage();
        },

        navigateToMenu() {
            return navigateToMenu();
        },

        goBack() {
            history.back();
        },

        // Styling

        getAlertStyle(): any {
            return this._designService.getAlertStyling();
        },

        getButtonBackgroundColor(): string {
            return this._designService.getButtonBackgroundColor();
        },

        getButtonClass(): string {
            return this._designService.getButtonClass();
        },

        getButtonStyle(fontSize?: string): string {
            let style: any = this._designService.getButtonStyle();

            if (fontSize) {
                style['font-size'] = fontSize;
            }

            return style;
        },

        getLinkColor(): string {
            return this.linkColor;
        },

        getModalHeaderBackgroundColor(): string {
            return this._designService.modalHeaderBackgroundColor;
        },

        getModalHeaderForegroundColor(): string {
            return this._designService.modalHeaderForegroundColor;
        },

        getModalHeaderStyle(): any {
            return {
                'background-color': this.getModalHeaderBackgroundColor(),
                'color': this.getModalHeaderForegroundColor(),
                'font-size': 'large',
                'font-family': this._designService.getDesign().fontHeadline
            };
        },

        getTextColor(): string {
            return this._designService.popupMainTextColor;
        },

        getTriangleColor(): any {
            let color: string = this._designService.backgroundColor,
              colorString = 'transparent transparent ' + color + ' transparent';

            return {
                'border-color': colorString
            };
        },

        // mobile functions

        callPhone($event, restaurant: IRestaurant): boolean {
            maps.callPhone(restaurant.address.phone);

            $event.stopPropagation();

            return false;
        },

        mapAddress($event, restaurant: IRestaurant): boolean {
            if (this.environment.isMobile) {
                maps.showMap(restaurant.address);
            }

            $event.stopPropagation();

            return false;
        }
    }
};
