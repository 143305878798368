
    import Vue from 'vue';
    import ModalModern from '../../common/components/ModalModern.vue';
    import StateSelect from '../../common/components/StateSelect.vue';
    import {Address, IAddress} from '../../common/models/Address';
    import profile from '../models/Profile';
    import designService from '../../common/services/design.service';
    import notifierService from '../../common/services/notifier.service';
    import util from '../../common/services/Util';
    import googleService from '../../common/services/google.service';
    import designMixin from '../../common/mixins/design.mixin';
    import SelectModern from '../../common/components/SelectModern.vue';
    import maps from '../../maps/models/Maps';
    import { IUser } from '../stores/profile.store';

    export default Vue.extend({
      mixins: [designMixin],
        components: {
            ModalModern,
            SelectModern,
            StateSelect
        },
        props: {
            addresses: {
                type: Array as () => Array<IAddress>,
                required: true
            },
            suggestedAddresses: {
                required: true
            },
            types: {
                type: Array as () => Array<String>,
                required: true
            },
            user: Object as () => IUser
        },
        computed: {
            _designService () {
                return designService;
            },

            _notifier () {
                return notifierService;
            },

            _googleService () {
                return googleService;
            },

            profile () {
                return profile;
            },

            isValid (): boolean {
                return this.isStreetValid && this.isPostalCodeValid && this.isCityValid;
            },

            isStreetValid (): boolean {
                return this.address.addressLine;
            },

            isPostalCodeValid (): boolean {
                return this.address.postalCode && !this.postalCodeError;
            },

            isCityValid (): boolean {
                return this.address.city;
            },

            isStateValid (): boolean {
                return this.address.stateCode;
            }
        },
        data () {
            return {
                address: null,
                addressToRemove: null,
                states: null,
                provinces: null,
                cannotParseStreetNumber: false,
                geocodeError: '',
                initialized: false,
                lastCheckedPostalCode: '',
                postalCodeError: false,
                submitted: false,
                tooLongApartment: false
            };
        },
        created () {
            this.initialized = true;
            this.states = util.dropdowns.states;
            this.provinces = util.dropdowns.provinces;
        },
        methods: {
            open() {
                this.$refs.removeAddressModal.open();
            },

            close() {
                this.$refs.removeAddressModal.close();
            },

            makePrimary(address: IAddress) {
                let payload: any = {
                    userId: this.user.objectId,
                    objectId: address.objectId,
                    primaryAddress: !address.primaryAddress
                };

                this.$emit('setPrimary', payload);
            },

            editAddress(address?: IAddress) {
                if (address) {
                    this.address = JSON.parse(JSON.stringify(address));
                } else {
                    this.address = new Address();
                    this.address.type = this.types[0];
                }
            },

            saveAddress(event) {
                event.stopPropagation();
                event.preventDefault();

                let ready: boolean = true;

                if (this.address.addressLine2 && this.address.addressLine2.length > 20) {
                    this.tooLongApartment = true;
                    ready = false;
                } else {
                    this.tooLongApartment = false;
                }

                if (this.address.addressLine && this.address.addressLine.substr(0, 21).indexOf(' ') < 1) {
                    this.cannotParseStreetNumber = true;
                    ready = false;
                } else {
                    this.cannotParseStreetNumber = false;
                }

                if (this.address.addressLine && this.address.addressLine.length > 250) {
                    ready = false;
                }

                if (this.address.addressLine2 && this.address.addressLine2.length > 20) {
                    ready = false;
                }

                if (this.address.city && this.address.city.length > 200) {
                    ready = false;
                }

                if (this.address.postalCode && this.address.postalCode.length > 50) {
                    ready = false;
                }

                ready = ready && this.isValid;
                ready = ready && this.address.type !== undefined && this.address.type !== null
                    && this.address.type !== '';

                if (!ready) {
                    this.submitted = true;

                    return;
                }

                let payload = new Address({
                    userId: this.user.objectId,
                    objectId: this.address.objectId,
                    type: this.address.type,
                    addressLine: this.address.addressLine,
                    addressLine2: this.address.addressLine2,
                    city: this.address.city,
                    phone: this.address.phone,
                    postalCode: this.address.postalCode,
                    stateName: this.address.stateName,
                    stateCode: this.address.stateCode
                });

                maps.verifyAddress(payload)
                    .then(() => {
                        profile.saveAddress(payload).then(() => this.checkForSuccess());
                        this.submitted = false;
                    })
                    .catch((result) => {
                        this.geocodeError = '';

                        if ((typeof result) === 'string') {
                            this.geocodeError = result;
                        } else if (result && (typeof result) === 'object' && result.length && result.length > 0) {
                            this.$emit('setSuggestedAddresses', result);

                            return;
                        } else if (result && result.hasOwnProperty('length') && result.length === 0) {
                            this.geocodeError = 'Address not found.';
                        } else {
                            this.geocodeError = this._googleService.getNotificationMessage(result);
                        }

                        this.submitted = false;
                    });
            },

            removeAddress(address: IAddress) {
                this.addressToRemove = address;
                this.open();
                this._designService.setModalStyling();
            },

            remove() {
                let payload = {
                    userId: this.user.objectId,
                    objectId: this.addressToRemove.objectId
                };

                profile.removeAddress(payload);

                this.cancelModal();

                this.checkForSuccess();
            },

            cancel(event) {
                this.address = null;

                if (event) {
                    event.stopPropagation();
                    event.preventDefault();
                }
            },

            cancelModal() {
                this.close();
                this.addressToRemove = null;
            },

            checkForSuccess() {
                const success = this.profile.profileSuccess;

                if (success) {
                    this._notifier.success('Update successful!');
                    this.cancel();
                } else {
                    this._notifier.error('Update failed!');
                }
            },

            chooseSuggestedAddress(chosenAddress: IAddress) {
                if (!chosenAddress)
                    return;

                const type = this.address.type;
                this.address = chosenAddress;
                this.address.type = type;

                this.$emit('clearSuggestedAddresses');
            },

            getFormattedAddress(address: any) {
                return maps.getFormattedAddress(address);
            },

            onPostalCodeBlur () {
                if (!this.address || !this.address.postalCode) {
                  return;
                }

                if (this.lastCheckedPostalCode && this.lastCheckedPostalCode === this.address.postalCode) {
                  return;
                }

                this.lastCheckedPostalCode = this.address.postalCode;

                maps.verifyPostalCode(this.address.postalCode)
                    .then((result) => {
                        let validatedAddress = result;

                        if (validatedAddress.city) {
                            Vue.set(this.address, 'city', validatedAddress.city);
                        }

                        if (validatedAddress.stateCode) {
                            Vue.set(this.address, 'stateCode', validatedAddress.stateCode);
                            Vue.set(this.address, 'stateName', validatedAddress.stateName);
                        }

                        this.postalCodeError = false;
                    }).catch(() => {
                      this.postalCodeError = true;
                    });
            },

            onStateChange(event) {
                this.address.stateCode = event.target.value;
            }
        }
    });
