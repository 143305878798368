
    import Vue from 'vue';
    import designMixin from '../../common/mixins/design.mixin';
    import modalMixin from '../../common/mixins/modal.mixin';
    import subscriberMixin from '../../common/mixins/subscriber.mixin';
    import ScheduleOrder from './ScheduleOrder.vue';
    import restaurants from '../../restaurants/models/Restaurants';
    import {Util} from '../../common/services/Util';
    import modalService from '../../common/services/modal.service';
    import designService from '../../common/services/design.service';
    import cart from '../../cart/models/Cart';
    import cartHelperService from '../../cart/services/cart-helper.service';
    import notificationService from '../../common/messaging/notification.service';
    import ModalModern from '../../common/components/ModalModern.vue';
    import { ORDER_TYPES } from '../../restaurants/types/restaurant.types';
    import { OPEN_SCHEDULE_MODAL } from '../../common/messaging/notifications';

    const moment = require('moment');

    export default Vue.extend({
        mixins: [designMixin, subscriberMixin, modalMixin],
        components: {
            ModalModern,
            ScheduleOrder
        },
        computed: {
            // services

            cart() {
                return cart;
            },

            cartHelperService() {
                return cartHelperService;
            },

            designService() {
                return designService;
            },

            modalService() {
                return modalService;
            },

            notificationService() {
                return notificationService;
            },

            restaurants() {
                return restaurants;
            },

            // getters

            cartObject() {
                return cart.cart;
            },

            currentTime() {
                return this.restaurants.currentTime;
            },

            hours(): string {
                if (!this.restaurant || !this.restaurant.hours || !this.day) {
                    return null;
                }

                return Util.getFormattedHourRangeFromHours(this.dayObj, this.restaurant.hours, this.orderType).replace('\n', '<br />');
            },

            isDelivery(): boolean {
                return this.cartObject.orderType === ORDER_TYPES.DELIVERY;
            },

            isDeliveryOpen() {
                return this.restaurants.isDeliveryOpen;
            },

            isStoreOpen() {
                return this.restaurants.isOpen;
            },

            orderType(): string {
                return this.cartObject.orderType;
            },

            orderTypes() {
                return this.restaurant.orderTypes;
            },

            restaurant() {
                return this.restaurants.selectedRestaurant;
            }
        },
        data() {
            return {
                day: null,
                dayObj: null,
                error: null, // string
                isCheckingOut: false,
                isModalOpen: false
            };
        },
        created() {
            this.subscribe(this.modalService.openScheduleOrderModal$, (isCheckingOut: boolean) => {
                if (this.isModalOpen) {
                    return;
                }

                this.isCheckingOut = isCheckingOut;
                this.cartHelperService.clearAlert();

                this.open();

                this.designService.setModalStyling();
            });

            this.observe(OPEN_SCHEDULE_MODAL, () => {
                if (this.isModalOpen) {
                  return;
                }

                this.isCheckingOut = false;
                this.cartHelperService.clearAlert();

                this.open();

                this._designService.setModalStyling();
            });
        },
        methods: {
            open() {
                this.isModalOpen = true;

                let self = this;

                if (this.$refs.modalModern) {
                    setTimeout(() => {
                        if (self.$refs.scheduleOrderModern) {
                            // force default field values to recalculate
                            self.$refs.scheduleOrderModern.initialize();
                        }
                    });

                    this.$refs.modalModern.open();
                } else {
                    setTimeout(() => {
                        if (self.$refs.scheduleOrder) {
                            // force default field values to recalculate
                            self.$refs.scheduleOrder.initialize();
                        }
                    });
                }
            },

            close() {
                this.isModalOpen = false;

                if (this.$refs.modalModern) {
                    this.$refs.modalModern.close();
                }
            },

            onHide() {
                this.modalService.closeScheduleOrderModal();

                setTimeout(() => {
                    this.cartHelperService.checkOrderTime(null, false, false);
                }, 750);
            },

            finish(success: boolean = false): void {
              setTimeout(() => {
                this.close();

                if (success && this.isCheckingOut) {
                  this.cartHelperService.reviewOrder();
                }
              }, 500);
            },

            scheduleAsap() {
                this.cart.scheduleAsap();

                this.finish(true);
            },

            setTime(payload: any) {
                const orderTime = payload.date + ' ' + payload.time;

                if (!Util.isOrderTimeValid(
                    this.orderType,
                    this.restaurant.hours,
                    this.restaurant.promiseTimes,
                    this.currentTime,
                    this.restaurant.usePromiseTime,
                    orderTime)) {
                    this.error = 'Please choose a time during ordering hours.';

                    return false;
                }

                this.cart.setOrderTime(payload.date, payload.time);

                this.finish(true);
            },

            setDay(day: string) {
                this.dayObj = moment(day);
                this.day = this.dayObj.format('dddd');
            }
        }
    });
