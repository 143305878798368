function getDisplayClasses(screenValues?) {
  const classes = ['tw-hidden'];
  if (typeof screenValues === 'object' && screenValues !== null) {
    Object.keys(screenValues).forEach((screen) => {
      if (screen === 'md' || screen === 'lg' || screen === 'xl') {
        classes.push(screen + ':' + screenValues[screen]);
      }
    });
  } else {
    classes.push('md:display-inherit');
  }
  return classes;
}
/*
* Vue directive to hide elements on small screens (e.g. phones) using Tailwind utility classes.
*
* `v-sm-hidden` without arguments adds the classes `tw-hidden md:display-inherit` to the element.
* `v-sm-hidden="{'md': 'block'}"` adds the classes `tw-hidden md:block` to the element.
* `v-sm-hidden="{'xl': 'flex'}"` adds the classes `tw-hidden xl:flex` to the element.
* `v-sm-hidden="{'md': 'table', 'xl': 'flex'}"` adds the classes `tw-hidden md:table xl:flex` to the element.
*
* Note: Originally Tailwind uses the class `hidden` but for compatibility issues with our legacy codebase,
* we add the `tw-` prefix.
* The `display-inherit` is custom utility class to set the css `display: inherit` on the element.
* */
export default {
  bind(el, binding, vnode) {
    const classes = getDisplayClasses(binding.value);
    el.classList.add(...classes);

    /* We observe the "class" attribute on the element to re-add our responsive classes in case
    Vue removes it due using the ":class" directive. */
    binding.observer = new MutationObserver(mutations => {
      for (const m of mutations) {
        const newValue = (m.target as Element).getAttribute(m.attributeName);

        vnode.context.$nextTick(() => {
          if (!classes.every(c => newValue.includes(c))) {
            el.classList.add(...classes);
          }
        });
      }
    });

    binding.observer.observe(el, {
      attributes: true,
      attributeOldValue : true,
      attributeFilter: ['class'],
    });
  },
  unbind (el, binding) {
    if (binding.observer) {
      binding.observer.disconnect();
    }
  }
};
