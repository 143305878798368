import { IRegisterRequest } from '../stores/profile.store';

export class RegisterRequest implements IRegisterRequest {
    username: string = '';
    password: string = '';
    account: string = '';
    location: string = '';
    firstName: string = '';
    lastName: string = '';
    rewards: boolean = false;
    isThirteen: boolean = false;
    dateOfBirth: string = '';
    phoneNumber: string = '';

    constructor(opts?) {
        if (opts) {
            for (let key in opts) {
                if (opts.hasOwnProperty(key)) {
                    this[key] = opts[key];
                }
            }
        }
    }
}