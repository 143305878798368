
import Vue from 'vue';
import designService from '../../common/services/design.service';
import {ITenderRequest} from '../models/TenderRequest';
import designMixin from '../../common/mixins/design.mixin';
import {ICart} from '../../cart/cart.types';
import {IRestaurant, ORDER_TYPES} from '../../restaurants/types/restaurant.types';
import filtersMixin from '../../common/mixins/filters.mixin';
import { getCurrencyType } from '../../common/helpers/currency.helpers';

export default Vue.extend({
        mixins: [designMixin, filtersMixin],
        props: {
            cart: {
                type: Object as () => ICart,
                required: true
            },
            restaurant: {
                type: Object as () => IRestaurant,
                required: true
            },
            tenderRequest: {
                type: Object as () => ITenderRequest,
                required: true
            }
        },

        computed: {
            // services

            _designService () {
                return designService;
            },

            // getters

            allowTips() {
                return this.restaurant.tipSettings.allowTips;
            },

            currencyType() {
                return getCurrencyType(this.restaurant.address.country);
            },

            showTipCalculator () {
                return this.restaurant.tipSettings.showTipCalculator;
            },

            tipAmounts() {
                return this.restaurant.tipSettings.tipPercentages.map(amount => amount / 100);
            },

            tipString: {
                get(): string {
                    let result: any = this.tenderRequest.tip;

                    if (result) {
                        return this.getFormattedCurrency(result);
                    } else {
                        return '';
                    }
                },

                set(value: string) {
                    this.tip = value ? Math.abs(parseFloat(value)) + '' : null;
                }
            }
        },

      data() {
            return {
                tip: null
            };
        },
        methods: {
            getDollarAmount(dollars: number): number {
                return dollars === null ? 0 : Math.round(dollars * 100) / 100;
            },

            getKeys(obj: any): string[] {
                if (obj === null) return [];

                return Object.keys(obj);
            },

            getTipAmount(amount: number): number {
                return this.cart.subtotal * amount;
            },

            getTipButtonText(amount: number): string {
                let tipAmount: number = this.cart.subtotal * amount;

                return `${amount * 100}% tip (${this.getFormattedCurrency(tipAmount)})`;
            },

            getTotalPlusTip(total: number, tip: string) {
                let grandTotal: number = total;
                const tipAmount: number = parseFloat(tip);

                if (!isNaN(tipAmount) && tipAmount >= 0)
                    grandTotal = total + tipAmount;

                return this.getDollarAmount(grandTotal);
            },

            hasConvenienceFee() {
                let convenienceFee: number = this.cart.convenienceFee;
                return convenienceFee > 0;
            },

            isDeliveryOrder() {
                if (!this.restaurant || !this.restaurant.orderTypes || !this.cart || !this.cart.orderType) return false;
                return this.restaurant.orderTypes[this.cart.orderType].objectId === ORDER_TYPES.DELIVERY;
            },

            showTax(): boolean {
                return this.cart.tax !== null && this.cart.tax !== 0;
            },

            getRoundedTip(tipAmount: number): number {
                let result;

                if (tipAmount >= 0)
                    result = this.getFormattedCurrency(tipAmount);
                else
                    result = this.getFormattedCurrency(-1 * tipAmount);

                return parseFloat(result);
            },

            getFormattedCurrency(value: number) {
                return value.toFixed(2);
            },

            chooseCustomTip () {
                this.tenderRequest.tip = Math.min(this.getRoundedTip(parseFloat(this.tip)), 999.99);

                this.$emit('chooseTipAmount', this.tenderRequest.tip);
            },

            choosePresetTip(value) {
                this.tenderRequest.tip = this.getRoundedTip(this.getTipAmount(value));

                this.$emit('chooseTipAmount', this.tenderRequest.tip);
            },

            // Styling
            getButtonStyle(): any {
                return this._designService.getButtonStyle();
            },

            getButtonClass(): any {
                return this._designService.getButtonClass();
            }
        }
    });
