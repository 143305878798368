interface IObserver {
    (value): void;
}

export class NotificationService {
    observers = {};

    addObserver(notificationName: string, observer: IObserver) {
        let items = this.observers[notificationName];

        if (!items) {
            items = this.observers[notificationName] = [];
        }

        if (items.indexOf(observer) === -1) {
            items.push(observer);
        }

        return () => {
            this.removeObserver(notificationName, observer);
        };
    }

    notify(notificationName: string, payload?) {
        const items = this.observers[notificationName];

        if (!items || items.length === 0) {
            return;
        }

        items.forEach(observer => {
            observer(payload);
        });
    }

    removeObserver(notificationName: string, observer?: IObserver) {
        if (observer) {
            const items = this.observers[notificationName];

            if (!items) {
                return;
            }

            const i = items.indexOf(observer);

            if (i !== -1) {
                items.splice(i, 1);
            }
        } else {
            delete this.observers[notificationName];
        }
    }
}

export default new NotificationService();
