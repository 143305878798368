
import Vue from 'vue';
import designService from '../services/design.service';

export default Vue.extend({
  props: {
    /* TODO: For re-usability it would be good to expand `options` to support an Array of Objects.
        E.g. {label: 'Brand new Toyota', value: 'NEW_TOYOTA}'
        this would require to also support properties to map the label and value keys.
        E.g. track-by="value" label="label",
    */
    options: {
      type: Array as () => Array<string>,
      required: true
    },
    value: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      required: false
    },
    placeholder: {
      default: 'Select your option'
    }
  },
  data: () => ({
    showOptions: false,
    selectedOption: null
  }),
  watch: {
    value(val) {
      this.text = val;
    }
  },
  methods: {
    toggleOptions($event?) {
      if ($event) {
        $event.preventDefault();
      }
      this.showOptions = !this.showOptions;
    },
    selectOption(option: String) {
      this.selectedOption = option;
      this.toggleOptions();
      this.$emit('input', option);
    },
    keyDown() {
      this.toggleOptions();
      let options = <Element>this.$refs.Options;
      Vue.nextTick(() => {
        (<HTMLElement>options.firstChild).focus();
      });
    },
    /* If accessibility support needs to be expanded, there are good guidelines for that:
       https://www.w3.org/TR/wai-aria-practices/examples/listbox/listbox-collapsible.html
     */
    onSelectChange(event) {
      this.$emit('input', event.target.value);
    }
  }
});
