
  import Vue from 'vue';
  import { TOAST_STATES } from '../../common/services/notifier.service';
  import { mapMutations } from 'vuex';

  export default Vue.extend({
    name: 'NotificationsModern',

    computed: {
      notifications() {
        return this.$store.state.notifications.notifications.filter(it => !it.excludeOnMenu);
      }
    },

    data: () => ({
      alert: {clickable: false},
      TYPES: TOAST_STATES
    }),

    watch: {
      notifications(value, oldValue) {
        if (value.length === oldValue.length || value.length === 0) {
          return;
        }

        const newNotification = value[value.length - 1];

        if (newNotification.timeToHide > 0) {
          setTimeout(() => this.close(newNotification), newNotification.timeToHide);
        }
      }
    },

    methods: {
      ...mapMutations('notifications', {close: 'REMOVE'}),

      onNotificationClick(notification) {
        this.$emit('click', notification);
      }
    },

    beforeDestroy() {
      if (this.notifications.length > 0) {
        this.$store.commit('notifications/CLEAR');
      }
    }
  });
