
  import Vue from 'vue';

  export default Vue.extend({
    props: {
      ariaLabelledby: String,
      disabled: Boolean,
      id: String,
      includeEmpty: Boolean,
      name: String,
      options: {
        type: Array as () => Array<any>,
        required: false
      },
      labelKey: String,
      value: {},
      valueKey: String
    },
    mounted () {
      // Set first option as the value by default
      if (!this.includeEmpty && !this.value && this.options) {
        this.$emit('input', this.getValue(this.options[0]));
      }
    },
    methods: {
      getLabel(item) {
        if (this.labelKey) {
          return item[this.labelKey];
        } else {
          return item;
        }
      },

      getValue(item) {
        if (this.valueKey) {
          return item[this.valueKey];
        } else {
          return item;
        }
      },

      onChange(event) {
        this.$emit('input', event.target.value);
      }
    }
  });
