import LoginContainer from './containers/LoginContainer.vue';
import RegisterContainer from './containers/RegisterContainer.vue';
import RegisterConfirmation from './components/RegisterConfirmation.vue';
import UserContainer from './containers/UserContainer.vue';
import LandingContainer from '../landing/containers/LandingContainer.vue';
import PasswordResetContainer from './containers/PasswordResetContainer.vue';

export default [
    {path: '', name: 'Login', component: LoginContainer, alias: 'login'},
    {path: 'register', name: 'Register', component: RegisterContainer},
    {path: 'registerconfirmation', name: 'RegisterConfirmation', component: RegisterConfirmation},
    {path: 'user', name: 'User', component: UserContainer},
    {path: 'landing', name: 'Landing', component: LandingContainer},
    {path: 'password/reset/:token', name: 'PasswordReset', component: PasswordResetContainer}
];
