import {IMenuItem, ITopping} from '../models/Item';
import ToppingsManager from '../services/toppings-manager.service';

export default {
    props: {
        item: {
            type: Object as () => IMenuItem,
            required: true
        }
    },
    computed: {
        _notifier () {
            return this.notifierService;
        }
    },
    data() {
        return {
            toppingsManager: new ToppingsManager(this.item, this.showAlert)
        };
    },
    watch: {
        item() {
            this.toppingsManager.item = this.item;
        }
    },
    methods: {
        getConsolidatedToppingQuantity(topping: ITopping, fractionType?: string): number {
            return this.toppingsManager.getToppingQuantity(topping, fractionType, true);
        },

        getToppingQuantity(topping: ITopping, fractionType?: string): number {
            return this.toppingsManager.getToppingQuantity(topping, fractionType);
        },

        showAlert(message: string) {
            this._notifier.error(message);
        }
    }
};
