import designMixin from '../../common/mixins/design.mixin';
import DateInput from '../../common/components/DateInput.vue';
import { IRegisterRequest } from '../stores/profile.store';
import designService from '../../common/services/design.service';
import { Util } from '../../common/services/Util';
import { ILoyalty } from '../../restaurants/types/restaurant.types';

declare var moment: any;

export default {
  mixins: [designMixin],
    components: { DateInput },
  props: {
    forceLoyalty: Boolean,
    registerRequest: {
      type: Object as () => IRegisterRequest,
        required: true
    },
    loyaltySettings: Object as () => ILoyalty,
    loyaltyProgramDescription: String,
    showDateOfBirth: Boolean,
    submitted: Boolean
  },
  computed: {
    _designService () {
      return designService;
    },
    isPasswordValid () {
      return this.registerRequest.password && this.registerRequest.password.length >= 6;
    },
    isConfirmPasswordValid () {
      if (!this.registerRequest.password || !this.confirmPassword || !this.registerRequest.password
        || !this.confirmPassword) {
        return false;
      }

      return this.registerRequest.password === this.confirmPassword;
    },
    valid () {
      return this.isPasswordValid && this.isConfirmPasswordValid && this.isDateOfBirthValid();
    },
    errorMessage: {
      get() {
        return this._errorMessage;
      },
      set (value) {
        this._errorMessage = value;

        this.$emit('onError', value);
      }
    }
  },
  data () {
    return {
      confirmPassword: null,
      isPasswordChanged: false,
      isConfirmPasswordChanged: false,
      _errorMessage: null,
      dateOfBirth: null,
      passwordError: 'Passwords required.',
      passwordLengthError: 'Password must be at least 6 characters long.',
      passwordMatchError: 'Passwords must match.'
    };
  },
  watch: {
    loyaltySettings() {
      if (this.forceLoyalty && this.loyaltySettings && this.loyaltySettings.externalProgram) {
        this.registerRequest.rewards = true;
      }
    }
  },
  methods: {
    isDateOfBirthValid(): boolean {
      if (this.registerRequest.rewards) {
        return Util.parseDate(this.registerRequest.dateOfBirth) !== null;
      } else {
        return true;
      }
    },

    submit () {
      this.$emit('submit', this.registerRequest);
    },

    isPasswordError(): boolean {
      if (!this.registerRequest.rewards) {
        return false;
      }

      return ((this.isPasswordChanged || this.submitted) && !this.registerRequest.password)
        || ((this.isConfirmPasswordChanged || this.submitted) && !this.confirmPassword);
    },

    updateErrorMessage() {
      this.errorMessage = null;

      if (!this.registerRequest.password) {
        this.errorMessage = this.passwordError;

        return;
      }

      if (this.registerRequest.password.length < 6) {
        this.errorMessage = this.passwordLengthError;

        return;
      }

      if (this.registerRequest.password !== this.confirmPassword) {
        this.errorMessage = this.passwordMatchError;

        return;
      }

      if (this.registerRequest.rewards && this.registerRequest.dateOfBirth) {
        const thirteenYearsAgo = moment().subtract(13, 'years');

        this.registerRequest.isThirteen = moment(this.registerRequest.dateOfBirth).isBefore(thirteenYearsAgo);

        if (!this.registerRequest.isThirteen) {
          this.errorMessage = 'You must be at least 13 years old to register.';

          return;
        }
      }

      if (!this.errorMessage) {
        if (this.registerRequest.rewards || this.showDateOfBirth) {
          if (!this.isDateOfBirthValid()) {
            this.errorMessage = 'Please enter a valid date of birth.';
          } else if (!this.registerRequest.isThirteen) {
            this.errorMessage = 'You must be at least 13 years old to register.';
          }
        } else {
          if (!this.registerRequest.isThirteen) {
            this.errorMessage =
              'Please confirm you are at least 13 years old by checking the appropriate box.';
          }
        }

        return;
      }
    },

    onDateOfBirthChange (value) {
      if (value) {
        value = Util.parseDate(value);
      }

      if (value) {
        this.registerRequest.dateOfBirth = Util.getDateString(value);
      } else {
        this.registerRequest.dateOfBirth = null;
      }

      this.$emit('onDateOfBirthValidChanged', this.isDateOfBirthValid());

      this.onChange();
    },

    onPasswordChange() {
      this.isPasswordChanged = true;

      this.onChange();
    },

    onConfirmPasswordChange() {
      this.isConfirmPasswordChanged = true;

      this.onChange();
    },

    onJoinChange () {
      this.onChange();

      this.$emit('onJoinChange', this.registerRequest.rewards);
    },

    onChange() {
      this.updateErrorMessage();

      this.$emit('onValidChange', this.valid);
    },

    // Styling

    getAlertStyle(): any {
      return this._designService.getAlertStyling();
    }
  }
};
