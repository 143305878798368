/* modernThemeColors Vuex Store */
const themeColors = require('../../../theme-colors.js');
const _ = require('lodash');

export default {
  name: 'modernTheme',
  namespaced: true,
  state: {
    colors: {},
    customizeTheme: false
  },
  getters: {
    colorsCssVariables: (state) => {
      const cssVariables = {};
      Object.keys(state.colors).forEach(colorName => {
        cssVariables['--color-' + colorName] = state.colors[colorName];
      });
      return cssVariables;
    }
  },
  mutations: {
    'SET_COLORS': (state, colors) => {
      state.colors = {...state.colors, ...colors};
    },
    'SET_CUSTOMIZE_THEME': (state, value) => {
      state.customizeTheme = !!value;
    },
  },
  actions: {
    initColorsFromJSON({commit, state}, colorsJSON) {
      const colors = {};
      let parsedColors;

      try {
        parsedColors = JSON.parse(colorsJSON);
      } catch (e) {
        parsedColors = {};
      }

      Object.keys(themeColors).forEach(colorName => {
        if (!_.has(state.colors, colorName)) { // Only set if the color hasn't been already initialized
          colors[colorName] = _.get(parsedColors, colorName) || themeColors[colorName].default;
        }
      });

      commit('SET_COLORS',  colors);
    },
    initColors({commit, state}, colors) {
      Object.keys(themeColors).forEach(colorName => {
        // Make sure that the right themeColors are being set. If not, use defaults.
        if (!_.get(colors, colorName)) {
          colors[colorName] = themeColors[colorName].default;
        }
      });
      commit('SET_COLORS',  colors);
    },
    updateColors({commit, state}, colors) {
      commit('SET_COLORS', colors);

      // Compute colors that are computable, except the ones we're updating
      const computedColors = {};
      Object.keys(themeColors)
        .filter(c => Object.keys(colors).indexOf(c) === -1)
        .forEach(colorName => {
        if (typeof themeColors[colorName].compute !== 'undefined' ) {
          computedColors[colorName] = themeColors[colorName].compute(state.colors);
        }
      });

      if (Object.keys(computedColors).length > 0) {
        commit('SET_COLORS', computedColors);
      }
    }
  }
};
