
  import Vue from "vue";
  import cartHelper from '../../common/mixins/cart-helpers.mixin';
  import StickyActions from '../../common/components/StickyActions.vue';
  import filtersMixin from '../../common/mixins/filters.mixin';

  export default Vue.extend({
    mixins: [cartHelper, filtersMixin],
    components: {
      StickyActions
    }
  });
