import { ISubscription } from './ISubscription';

export class Subscription implements ISubscription {
  unsubscribeCallback: () => void;

  constructor(unsubscribe: () => void) {
    this.unsubscribeCallback = unsubscribe;
  }

  unsubscribe() {
    this.unsubscribeCallback();
  }
}
