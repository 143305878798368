
  import Vue from 'vue';
  import baseMixin from '../../common/mixins/base-component.mixin';
  import RestaurantInfo from './RestaurantInfo.vue';
  import { IRestaurant } from '../types/restaurant.types';

  export default Vue.extend({
    mixins: [baseMixin],
    components: {RestaurantInfo},
    props: {
      currentTime: {
        type: String,
        required: true
      },
      restaurant: {
        type: Object as () => IRestaurant,
        required: true
      }
    },
    computed: {
      isMobile(): boolean {
        return this.environment.isMobile;
      },

      // styling

      backgroundStyle() {
        return {
          'background-color': this.pageBackgroundColor
        };
      }
    },
    methods: {
      loadData(): boolean {
        return false;
      }
    }
  });
