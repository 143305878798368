
    import Vue from 'vue';
    import order from '../models/Order';
    import {ITenderRequest} from '../models/TenderRequest';
    import CheckboxModern from '../../common/components/CheckboxModern.vue';
    import designMixin from '../../common/mixins/design.mixin';
    import { IUser } from '../../profile/stores/profile.store';
    import analyticsManager from '../../common/services/analytics-manager.service';
    import cartModel from '../../cart/models/Cart';

    export default Vue.extend({
      mixins: [designMixin],
        components: {
          CheckboxModern
        },
        props: {
          buttonClass: {
              type: Object,
              required: true
          },

          locationId: {
              type: String,
              required: true
          },

          recaptchaToken: {
            required: true,
            type: String
          },

          tenderRequest: {
              type: Object as () => ITenderRequest,
              required: true
          },

          user: {
              type: Object as () => IUser,
              required: false
          }
        },

        data() {
          return {
            hasReturned: false,
            hostedPaymentUrl: null, // SafeUrl,
            idleTimer: null,
            isComplete: false,
            paymentId: null, // string
            loaded: false
          };
        },
        mounted () {
            this.load();
        },
        methods: {
            clearIdleTimer() {
                if (this.idleTimer) {
                    clearTimeout(this.idleTimer);
                    this.idleTimer = null;
                }
            },

            onHostedPaymentLoad(event): void {
                if (!event.target.contentDocument) {
                    return;
                }

                this.loaded = true;
                const url = event.target.contentDocument.location.href;

                analyticsManager.track('HostedPaymentRedirect', {
                    cartId: cartModel.cart.objectId,
                    tenderRequest: this.tenderRequest,
                    url,
                    user: this.user
                });

                if (/\/hostedpayment\.html/.test(url)) {
                    this.clearIdleTimer();

                    const params = getQueryParams(url);

                    analyticsManager.track('HostedPaymentReturned', {
                        cartId: cartModel.cart.objectId,
                        params,
                        tenderRequest: this.tenderRequest,
                        user: this.user
                    });

                    if (params['HostedPaymentStatus'] === 'Complete') {
                        this.$emit('complete', {
                            response: getQueryString(url),
                            paymentId: this.paymentId
                        });

                        this.isComplete = true;
                    } else if (params['HostedPaymentStatus'] === 'Cancelled') {
                        this.$emit('cancel');
                    } else if (params['HostedPaymentStatus'] === 'Error') {
                      this.$emit('error');
                    }
                }
            },

            startIdleTimer() {
              this.clearIdleTimer();

              this.idleTimer = setTimeout(() => {
                  analyticsManager.track('HostedPaymentTookTooLong', {
                      cartId: cartModel.cart.objectId,
                      tenderRequest: this.tenderRequest,
                      user: this.user
                  });
              }, 2 * 60 * 1000);
            },

            load(): void {
              this.hasReturned = false;

              this.startIdleTimer();

              order.preauthorizePayment({
                locationId: this.locationId,
                recaptchaToken: this.recaptchaToken,
                tenderRequest: this.tenderRequest,
              }).then(payload => {
                this.paymentId = payload['paymentId'];
                this.hostedPaymentUrl = payload['url'];
              }).catch(error => {
                this.$emit('error', error);
              });
            }
        },
        destroyed() {
          this.clearIdleTimer();
        }
    });

    export function getQueryString(url: string): String {
        const i = url.indexOf('?');

        if (i === -1)
            return '';

        return url.substring(i + 1);
    }

    export function getQueryParams(url: string): Object {
        const result = {};
        const queryString = getQueryString(url);

        if (!queryString)
            return result;

        const parts = queryString.split('&');

        parts.forEach(str => {
            const tmp = str.split('=');
            result[tmp[0]] = tmp[1];
        });

        return result;
    }
