import { IFeedbackRequest } from '../types/account.types';

export class FeedbackRequest implements IFeedbackRequest {
    account: string = '';
    location: string = '';
    user: string = '';
    browserInfo: string = '';
    firstName: string = '';
    lastName: string = '';
    email: string = '';
    category: string = '1';
    message: string = '';

    constructor(opts?: IFeedbackRequest) {
        if (opts) {
            for (let key in opts) {
                if (opts.hasOwnProperty(key) && key === 'accountId') {
                    this[key] = opts[key];
                }
            }
        }
    }
}
