import { Record } from 'immutable';
import { IAddress } from '../../common/models/Address';
import { LoginRequest } from '../models/LoginRequest';
import { RegisterRequest } from '../models/RegisterRequest';
import { ICoupon } from '../../coupons/models/Coupon';
import { createSetterMutations, setStateThroughMutation, setStateThroughMutationFromPromise } from '../../common/data/store.helpers';
import profileService from '../services/profile.service';
import notifierService from '../../common/services/notifier.service';

export interface ILoginRequest {
  username: string;
  password: string;
  account: string;
  location: string;
}

export interface IRegisterRequest {
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  rewards: boolean;
  account: string;
  location: string;
  isThirteen: boolean;
  dateOfBirth: string;
  currentPassword?: string;
  phoneNumber: string;
}

export const UserRecord = Record({
  email: '',
  firstName: '',
  lastName: '',
  fullName: '',
  rewards: false,
  objectId: '',
  coupons: undefined,
  points: undefined,
  salesBuilderId: '',
  dateOfBirth: '',
  token: null,
  favoriteLocationId: null
});

export interface IUser {
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  rewards: boolean;
  objectId: string;
  coupons: ICoupon[];
  points: number;
  salesBuilderId: string;
  dateOfBirth: string;
  token: string;
  favoriteLocationId: string;
}

export const PROFILE_STORE = 'profile';

export const initialState = {
  loginError: undefined,
  loginRequest: new LoginRequest(),
  loginSuccess: undefined,
  registerError: undefined,
  registerRequest: new RegisterRequest(),
  registerSuccess: undefined,
  user: undefined,
  userAddresses: undefined,
  userCreditCards: undefined,
  userPhones: undefined,
  addressTypes: undefined,
  phoneTypes: undefined,
  profileSuccess: undefined,
  profileInfoError: undefined,
  profileAddressError: undefined,
  profileCreditError: undefined,
  profilePhoneError: undefined,
  passwordResetError: undefined,
  suggestedAddresses: undefined,
  resetToken: undefined,
  resetError: undefined
};

const setLogInResponse = (commit, state, response) => {
  if (response && response.success !== undefined && !response.success) {
    setStateThroughMutation(commit, 'loginError', response.error.message);
    setStateThroughMutation(commit, 'loginSuccess', false);

    return false;
  }

  const user = new UserRecord(response);
  setStateThroughMutation(commit, 'user', user);
  setStateThroughMutation(commit, 'loginRequest', new LoginRequest(state.loginRequest));
  setStateThroughMutation(commit, 'loginSuccess', true);
  setStateThroughMutation(commit, 'loginError', undefined);

  return user;
};

const resetState = commit => {
  setStateThroughMutation(commit, 'loginSuccess', undefined);
  setStateThroughMutation(commit, 'loginError', undefined);
  setStateThroughMutation(commit, 'registerSuccess', undefined);
  setStateThroughMutation(commit, 'registerError', undefined);
  setStateThroughMutation(commit, 'profileSuccess', undefined);
  setStateThroughMutation(commit, 'profileInfoError', undefined);
  setStateThroughMutation(commit, 'profileAddressError', undefined);
  setStateThroughMutation(commit, 'profilePhoneError', undefined);
};

const resetUserState = (commit, state) => {
  setStateThroughMutation(commit, 'user', undefined);
  setStateThroughMutation(commit, 'loginRequest', new LoginRequest({ account: state.loginRequest.account }));
  setStateThroughMutation(commit, 'registerRequest', new RegisterRequest({ account: state.registerRequest.account }));
  setStateThroughMutation(commit, 'userAddresses', undefined);
  setStateThroughMutation(commit, 'userPhones', undefined);
  setStateThroughMutation(commit, 'userCreditCards', undefined);
};

const updateProfileState = (commit, response) => {
  if (response && response.success === false) {
    setStateThroughMutation(commit, 'profileInfoError', response.error.message);
    setStateThroughMutation(commit, 'profileSuccess', false);

    return false;
  }

  const user = new UserRecord(response);
  setStateThroughMutation(commit, 'user', user);
  setStateThroughMutation(commit, 'profileSuccess', true);
  setStateThroughMutation(commit, 'profileInfoError', undefined);

  return user;
};

const updateAddressState = (commit, response) => {
  if (response && response.success === false) {
    setStateThroughMutation(commit, 'profileAddressError', response.error.message);
    setStateThroughMutation(commit, 'profileSuccess', false);

    return false;
  }

  setStateThroughMutation(commit, 'userAddresses', response);
  setStateThroughMutation(commit, 'profileSuccess', true);
  setStateThroughMutation(commit, 'profileAddressError', undefined);

  return response;
};

const updatePhoneState = (commit, response) => {
  if (response && response.success === false) {
    setStateThroughMutation(commit, 'profilePhoneError', response.error.message);
    setStateThroughMutation(commit, 'profileSuccess', false);

    return false;
  }

  setStateThroughMutation(commit, 'userPhones', response);
  setStateThroughMutation(commit, 'profileSuccess', true);
  setStateThroughMutation(commit, 'profilePhoneError', undefined);

  return response;
};

const updateCreditCardState = (commit, response) => {
  if (response && response.success === false) {
    setStateThroughMutation(commit, 'profileCreditError', response.error.message);
    setStateThroughMutation(commit, 'profileSuccess', false);

    return false;
  }

  setStateThroughMutation(commit, 'userCreditCards', response);
  setStateThroughMutation(commit, 'profileSuccess', true);
  setStateThroughMutation(commit, 'profileCreditError', undefined);

  return response;
};

export default {
  name: PROFILE_STORE,
  namespaced: true,
  state: initialState,
  mutations: {
    ...createSetterMutations(initialState),

    SET_USER_FAVORITE_LOCATION_ID(state, locationId: string) {
      if (state.user) {
        state.user = state.user.set('favoriteLocationId', locationId);
      }
    }
  },
  actions: {
    changePassword({ commit }, payload) {
      resetState(commit);

      return profileService.changePassword(payload).then(response => updateProfileState(commit, response));
    },

    changePasswordWithToken({ commit }, payload) {
      return profileService.changePasswordWithToken(payload).then(response => {
        if (response && response.success === false) {
          setStateThroughMutation(commit, 'resetError', response.error.message);

          return false;
        }

        return response;
      });
    },

    clearProfileState({ commit }) {
      return setStateThroughMutation(commit, 'profileSuccess', undefined);
    },

    clearSuggestedAddresses({ commit }) {
      return setStateThroughMutation(commit, 'suggestedAddresses', undefined);
    },

    createAddress({ commit }, payload) {
      resetState(commit);

      return profileService.createAddress(payload).then(response => updateAddressState(commit, response));
    },

    createPhone({ commit }, payload) {
      resetState(commit);

      return profileService.createPhone(payload).then(response => updatePhoneState(commit, response));
    },

    deleteAccount({ commit, state }, userId: string) {
      return profileService.deleteAccount(userId).then(response => {
        resetState(commit);
        resetUserState(commit, state);

        return response;
      });
    },

    getUserDetails({ commit }, payload: { accountId: string, locationId: string }) {
      const promise = profileService.getUserDetails(payload.accountId, payload.locationId)
        .then(res => {
          if (res) {
            return res.hasOwnProperty('user') ? res.user : res;
          } else {
            return null;
          }
        });

      return setStateThroughMutationFromPromise(commit, promise, 'user');
    },

    initialize({ commit }, accountId: string) {
      setStateThroughMutation(commit, 'loginError', undefined);
      setStateThroughMutation(commit, 'registerError', undefined);
      setStateThroughMutation(commit, 'loginRequest', new LoginRequest({ account: accountId }));
      setStateThroughMutation(commit, 'registerRequest', new RegisterRequest({ account: accountId }));
      setStateThroughMutation(commit, 'loginSuccess', undefined);
      setStateThroughMutation(commit, 'registerSuccess', undefined);
    },

    loadAddresses({ commit }, userId: string) {
      return setStateThroughMutationFromPromise(commit, profileService.loadAddresses(userId), 'userAddresses');
    },

    loadAddressTypes({ commit }) {
      return setStateThroughMutationFromPromise(commit, profileService.loadAddressTypes(), 'addressTypes');
    },

    loadCoupon({ commit }, payload: { offerId: number, locationId: number }) {
      return setStateThroughMutationFromPromise(commit, profileService.loadCoupon(payload), 'couponSuccess');
    },

    loadCreditCards({ commit }, userId: string) {
      return setStateThroughMutationFromPromise(commit, profileService.loadCreditCards(userId), 'userCreditCards');
    },

    loadPhones({ commit }, userId: string) {
      return setStateThroughMutationFromPromise(commit, profileService.loadPhones(userId), 'userPhones');
    },

    loadPhoneTypes({ commit }) {
      return setStateThroughMutationFromPromise(commit, profileService.loadPhoneTypes(), 'phoneTypes');
    },

    logIn({ commit, state }, payload: ILoginRequest) {
      resetState(commit);

      return profileService.logIn(payload).then(response => {
        return setLogInResponse(commit, state, response);
      });
    },

    setLoggedInUser({ commit, state, dispatch }, response) {
      resetState(commit);
      setLogInResponse(commit, state, response);

      dispatch('loadAddresses', response.objectId);
      dispatch('loadPhones', response.objectId);
      dispatch('loadCreditCards', response.objectId);
    },

    logOut({ commit, state }) {
      profileService.logout();
      localStorage.removeItem('auth_token');
      resetState(commit);
      resetUserState(commit, state);
    },

    receivedNotification({ commit }, notificationId: string) {
      return profileService.receivedNotification(notificationId);
    },

    register({ commit, state }, payload: IRegisterRequest) {
      resetState(commit);

      return profileService.register(payload).then(response => {
        if (response && response.success !== undefined && !response.success) {
          setStateThroughMutation(commit, 'registerError', response.error.message);
          setStateThroughMutation(commit, 'registerSuccess', false);

          throw response.error.message;
        }

        const user = new UserRecord(response);
        setStateThroughMutation(commit, 'user', user);
        setStateThroughMutation(commit, 'registerRequest', new RegisterRequest(state.registerRequest));
        setStateThroughMutation(commit, 'registerSuccess', true);
        setStateThroughMutation(commit, 'registerError', undefined);

        return user;
      });
    },

    removeAddress({ commit }, payload) {
      return profileService.removeAddress(payload).then(response => updateAddressState(commit, response));
    },

    removeCreditCard({ commit }, payload) {
      return profileService.removeCreditCard(payload).then(response => updateCreditCardState(commit, response));
    },

    removePhone({ commit }, payload) {
      return profileService.removePhone(payload).then(response => updatePhoneState(commit, response));
    },

    resetPassword({ commit }, payload: { accountId: string, locationId: string, username: string }) {
      resetState(commit);

      return profileService.resetPassword(payload).then(response => {
        if (response && response.success === false) {
          setStateThroughMutation(
            commit,
            'passwordResetError',
            'This email address is not registered to an account. Please try another email address.'
          );
          setStateThroughMutation(commit, 'profileSuccess', false);

          notifierService.error('Unable to find email address.');

          return false;
        } else {
          notifierService.success('An email has been sent to your address. It should arrive any moment now!');
        }

        setStateThroughMutation(commit, 'profileSuccess', true);
        setStateThroughMutation(commit, 'passwordResetError', undefined);

        return response;
      });
    },

    saveCreditCard({ commit }, payload: {
      userId: string,
      encryptedCardInfo: string,
      creditCardId: string,
      billingAddress: IAddress,
      locationId: string
    }) {
      resetState(commit);

      return profileService.saveCreditCard(payload).then(response => updateCreditCardState(commit, response));
    },

    setAddressTypes({ commit }, types) {
      return setStateThroughMutation(commit, 'addressTypes', types);
    },

    setDeviceToken({ commit }, payload: { token: string, userId: string }) {
      return profileService.setDeviceToken(payload);
    },

    setPhoneTypes({ commit }, types) {
      return setStateThroughMutation(commit, 'phoneTypes', types);
    },

    setPrimaryAddress({ commit }, payload) {
      return setStateThroughMutationFromPromise(commit, profileService.setPrimaryAddress(payload), 'userAddresses');
    },

    setResetToken({ commit }, token: string) {
      return setStateThroughMutation(commit, 'resetToken', token);
    },

    setSuggestedAddresses({ commit }, payload) {
      return setStateThroughMutation(commit, 'suggestedAddresses', payload);
    },

    switchLocation({ commit, state }, payload: { locationId: string, userId: string }) {
      return profileService.switchLocation(payload).then(response => setLogInResponse(commit, state, response));
    },

    unsetDeviceToken({ commit }, payload: { token: string, userId: string }) {
      return profileService.unsetDeviceToken(payload);
    },

    updateAddress({ commit }, payload) {
      resetState(commit);

      return profileService.updateAddress(payload).then(response => updateAddressState(commit, response));
    },

    updateExpirationDate({ commit }, payload) {
      return setStateThroughMutationFromPromise(commit, profileService.updateExpirationDate(payload), 'userCreditCards');
    },

    updateLocation({ commit }, payload: { firstName: string, lastName: string, location: string, rewards: boolean, userId: string }) {
      resetState(commit);

      return profileService.updateLocation(payload).then(() => {
        commit('SET_USER_FAVORITE_LOCATION_ID', payload.location);
      });
    },

    updatePhone({ commit }, payload) {
      resetState(commit);

      return profileService.updatePhone(payload).then(response => updatePhoneState(commit, response));
    },

    updateProfile({ commit }, payload) {
      resetState(commit);

      return profileService.updateProfile(payload).then(response => updateProfileState(commit, response));
    },

    refresh({ commit }, payload: { userId: string, locationId: string }) {
      resetState(commit);
      return profileService.refresh(payload).then(response => updateProfileState(commit, response));
    }
  }
};
