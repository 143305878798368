import { createSetterMutations } from '../../common/data/store.helpers';
import * as actions from './restaurants.actions';

export const RESTAURANTS_STORE = 'restaurants';

const initialState = {
  cameFromLocationFinder: false,
  currentTime: undefined,
  entities: {
    list: undefined,
    restaurantCount: undefined,
    selectedRestaurant: undefined,
  },
  inactiveLocations: undefined, // IRestaurant[]
  isDeliveryOpen: undefined,
  isOpen: undefined,
  loading: true,
  loyalty: undefined,
  restaurant: undefined,
  selected: false,
  singleLoyalty: undefined, // ILoyalty
  sizeMap: undefined
};

export const mutations = {
  ...createSetterMutations(initialState),

  SET_INACTIVE_LOCATIONS(state, locations) {
    state.inactiveLocations = locations;
  },

  SET_RESTAURANT_COUNT(state, count) {
    state.entities.restaurantCount = count;
  },

  SET_RESTAURANT_LIST(state, list) {
    state.entities.list = list;
  },

  SET_SELECTED_RESTAURANT(state, restaurant) {
    state.entities.selectedRestaurant = restaurant;
  }
};

export default {
  name: RESTAURANTS_STORE,
  namespaced: true,
  state: initialState,
  mutations,
  actions
};
