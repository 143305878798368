
    import Vue from 'vue';
    import ChangeLocationModal from './ChangeLocationModal.vue';
    import ConfirmCouponRemovalModal from './ConfirmCouponRemovalModal.vue';
    import DeliveryModal from '../../common/components/DeliveryModal.vue';
    import OrderSubmissionTimeoutModal from './OrderSubmissionTimeoutModal.vue';
    import ProgressBarModal from './ProgressBarModal.vue';
    import {IAddress} from '../../common/models/Address';
    import {ITenderRequest} from '../models/TenderRequest';
    import { IUser } from '../../profile/stores/profile.store';
    import { IRestaurant } from '../../restaurants/types/restaurant.types';

    export default Vue.extend({
        components: {
            ChangeLocationModal,
            ConfirmCouponRemovalModal,
            DeliveryModal,
            OrderSubmissionTimeoutModal,
            ProgressBarModal
        },
        props: {
            addresses: {
                type: Array as () => Array<IAddress>,
                required: false
            },
            message: {
                type: String,
                required: false
            },
            progress: {
                type: Number,
                required: false
            },
            restaurant: {
                type: Object as () => IRestaurant,
                required: true
            },
            submitting: {
                type: Boolean,
                required: false
            },
            successful: {
                type: Boolean,
                required: false
            },
            suggestedAddresses: {
                type: Array as () => Array<IAddress>,
                required: false
            },
            tenderRequest: {
                type: Object as () => ITenderRequest,
                required: true
            },
            updating: {
                type: Boolean,
                required: false
            },
            user: {
                type: Object as () => IUser,
                required: false
            }
        },
        methods: {
            // emitters

            clearSuggestedAddresses($event) {
                this.$emit('clearSuggestedAddresses', $event);
            },

            onClose() {
                this.$emit('close');
            },

            orderSuccess(modal) {
                this.$emit('orderSuccess', modal);
            },

            removeDeliveryZone($event) {
                this.$emit('removeDeliveryZone', $event);
            },

            saveAddress($event) {
                this.$emit('saveAddress', $event);
            },

            setDeliveryZone ($event) {
                this.$emit('setDeliveryZone', $event);
            },

            setSuggestedAddresses($event) {
                this.$emit('setSuggestedAddresses', $event);
            }
        }
    });
