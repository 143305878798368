export const API_FIND_CATEGORY_DESIGN_URL = '/slice/menu/category/design/find';
export const API_FIND_CATEGORY_DESIGNS_URL = '/slice/menu/category/design/all/find';
export const API_GET_CATEGORIES_FOR_RESTAURANT_URL = '/slice/menu/category/list';
export const API_GET_DEFAULT_IMAGE_FOR_STORE_URL = '/slice/menu/image/default/find';
export const API_GET_DEFAULT_SIZE_FOR_ITEM_URL = '/slice/menu/size/default/find';
export const API_GET_ITEMS_FOR_CATEGORY_URL = '/slice/menu/item/list';
export const API_GET_ITEMS_FOR_DEPARTMENT_URL = '/slice/menu/department/item/list';
export const API_GET_STYLES_FOR_ITEM_URL = '/slice/menu/style/list';
export const API_GET_SUGGESTIONS_URL = '/slice/menu/suggestion/list';
export const API_TRACK_SUGGESTION_URL = '/slice/menu/suggestion/track';
export const API_LOAD_SIZE_MAP_URL = '/slice/menu/size/all/find';
export const API_LOAD_TOPPINGS_LIST_URL = '/slice/menu/topping/list';
export const API_GET_ITEM_MAP_URL = '/slice/restaurant/item/list';

export const SPLIT_SIDES: any = {
    LEFT: 'left',
    RIGHT: 'right'
};

export const VALIDATIONS: any = {
    AVAILABLE: 'AVAILABLE',
    COUPON: 'COUPON',
    REQUIRED: 'REQUIRED',
    SIZE: 'SIZE',
    STYLE: 'STYLE'
};
