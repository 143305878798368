import baseMixin from '../../common/mixins/base-component.mixin';
import { IAddress } from '../../common/models/Address';
import { emptyAddress, ITenderRequest } from '../../order/models/TenderRequest';
import maps from '../../maps/models/Maps';
import util, { Util } from '../../common/services/Util';
import StateSelect from '../../common/components/StateSelect.vue';
import { IUser } from '../../profile/stores/profile.store';
import restaurants from '../../restaurants/models/Restaurants';

export default {
    mixins: [baseMixin],
    components: {
        StateSelect
    },
    props: {
        addresses: {
            type: Array as () => Array<IAddress>,
            required: false
        },
        geocodingError: {
            type: Boolean,
            required: true
        },
        searchingForLocations: {
            type: Boolean,
            required: true
        },
        suggestedAddresses: Array as () => Array<IAddress>,
        tenderRequest: {
            type: Object as () => ITenderRequest,
            required: true
        },
        user: Object as () => IUser,
        userLocation: {} // LatLngLiteral | boolean
    },
    computed: {
        // services

        util () {
            return util;
        },

        // Styling

        buttonClass(): string {
            return this.getButtonClass();
        },

        buttonStyle(): any {
            return this.getButtonStyle();
        }
    },
    data() {
        return {
            calculateClosestRestaurantIntervalId: null,
            enterNewDeliveryAddress: false,
            findALocationVisible: false,
            invalidDeliveryAddress: false,
            invalidOriginValue: false,
            lastCheckedPostalCode: null,
            originInput: '',
            saveDeliveryAddress: false,
            selectedDeliveryAddress: null,
            validations: {
                addressLine: true,
                addressLine2: true,
                city: true,
                postalCode: true,
                state: true
            }
        };
    },
    watch: {
        addresses() {
            if (this.user && this.user.objectId) {
                if (this.addresses.length === 0 || Util.isEmpty(this.addresses)) {
                    this.enterNewDeliveryAddress = true;
                } else {
                    this.enterNewDeliveryAddress = false;

                    this.selectedDeliveryAddress = this.addresses[0];
                }
            }
        }
    },
    created() {
        restaurants.cameFromLocationFinder = true;
    },
    methods: {
        // Delivery Address Form

        isSelectedDeliveryAddress(address: IAddress): boolean {
            return this.selectedDeliveryAddress && this.selectedDeliveryAddress.objectId === address.objectId;
        },

        changeDeliveryAddress(address: IAddress, event): void {
            if (event.target.checked) {
                this.selectedDeliveryAddress = JSON.parse(JSON.stringify(address));
                this.enterNewDeliveryAddress = false;
            }
        },

        getDeliveryFor(address: IAddress): string {
            return 'Delivery-' + address.objectId;
        },

        changeDeliveryAddressToNew(event): void {
            if (event.target.checked) {
                this.enterNewDeliveryAddress = true;
                this.selectedDeliveryAddress = undefined;
                this.tenderRequest.deliveryAddress = emptyAddress();
            }
        },

        toggleSaveDeliveryAddress(): void {
            this.saveDeliveryAddress = !this.saveDeliveryAddress;
        },

        getFormattedAddress(address): string {
            return maps.getFormattedAddress(address);
        },

        chooseSuggestedAddress(address: IAddress): void {
            if (!address) {
                return;
            }

            const aptNumber: string = this.tenderRequest.deliveryAddress.addressLine2;

            this.tenderRequest.deliveryAddress = address;

            if (aptNumber) {
                this.tenderRequest.deliveryAddress.addressLine2 = aptNumber;
            }

            this.selectedDeliveryAddress = undefined;
            this.enterNewDeliveryAddress = true;
            this.$emit('clearSuggestedAddresses');

            this.submitDeliveryAddress();
        },

        submitDeliveryAddress(): void {
            if (this.selectedDeliveryAddress && this.selectedDeliveryAddress.objectId) {
                this.tenderRequest.deliveryAddress = JSON.parse(JSON.stringify(this.selectedDeliveryAddress));
            } else if (!this._isValidAddress()) {
                this.invalidDeliveryAddress = true;
            }

            if (this._validationsPassed()) {
                this.$emit('submitDelivery', {
                    address: this.tenderRequest.deliveryAddress ,
                    saveDeliveryAddress: this.saveDeliveryAddress
                });
            }
        },

        // Validation Methods

        validateAddressLine(): void {
            this.validations.addressLine = this._isValidRequiredInput(this.tenderRequest.deliveryAddress.addressLine, 250);
        },

        validateAddressLine2(): void {
            this.validations.addressLine2 = this._isValidInput(this.tenderRequest.deliveryAddress.addressLine2, 20);
        },

        validatePostalCode(): void {
            this.validations.postalCode = this._isValidRequiredInput(this.tenderRequest.deliveryAddress.postalCode, 6);
        },

        validateCity(): void {
            this.validations.city = this._isValidRequiredInput(this.tenderRequest.deliveryAddress.city, 200);
        },

        validateState(event): void {
            this.validations.state = this._isValidSelect(event.currentTarget.value);
        },

        // Find A Location Form

        findALocationNearMe() {
            if (this.originInput === '') {
                this.invalidOriginValue = true;
            } else {
                this.$emit('onFindLocationsNearMe', this.originInput);
            }
        },

        onSelectClosestLocation() {
            this.$emit('onSelectClosestLocation');
        },

        // Private Methods

        _isValidAddress(): boolean {
            if (this._hasTenderRequest()) {
                const deliveryAddress = this.tenderRequest.deliveryAddress;

                return this._isValidRequiredInput(deliveryAddress.addressLine, 250)
                    && this._isValidInput(deliveryAddress.addressLine2, 20)
                    && this._isValidRequiredInput(deliveryAddress.city, 200)
                    && this._isValidRequiredInput(deliveryAddress.postalCode, 50)
                    && this._isValidSelect(deliveryAddress.stateCode);
            } else {
                return false;
            }
        },

        _validationsPassed(): boolean {
            return this.validations.addressLine
                && this.validations.addressLine2
                && this.validations.city
                && this.validations.postalCode
                && this.validations.state;
        },

        _isValidRequiredInput(value: string, length: number): boolean {
            return value !== '' && value.length <= length;
        },

        _isValidInput(value: string, length: number): boolean {
            return !value || value.length <= length;
        },

        _isValidSelect(value: string): boolean {
            return value !== '';
        },

        _hasTenderRequest(): boolean {
            return this.tenderRequest && !!this.tenderRequest.deliveryAddress;
        },

        onDeliveryAddressChange() {
            this.tenderRequest.deliveryAddress.latitude = null;
            this.tenderRequest.deliveryAddress.longitude = null;
        }
    }
};
