import baseToppingCustomizationMixin from './base-topping-customization.mixin';
import { IMenuItem, IMenuItemSize, ITopping } from '../models/Item';
import { isToppingInStock } from '../../cart/helpers/cart.helpers';
import cart from '../../cart/models/Cart';

export default {
  mixins: [baseToppingCustomizationMixin],
  props: {
    fractionStyle: {
      type: Object,
      required: true
    },

    getQuantity: {
      type: Function,
      required: true
    },

    isFractionSelected: {
      type: Function,
      required: true
    },

    isToppingSelected: {
      type: Function,
      required: true
    },

    item: {
      type: Object as () => IMenuItem,
      required: true
    },

    size: {
      type: Object as () => IMenuItemSize,
      required: false
    },

    toppings: {
      type: Array as () => Array<ITopping>,
      required: true
    }
  },
  methods: {
    allowsFraction(topping: ITopping): boolean {
      let allowFraction;

      if (this.item.thriveControlCenter) {
        allowFraction = this.item.allowFraction;
      } else {
        allowFraction = topping.allowFraction;
      }

      return allowFraction && (!this.size || this.size.allowFraction);
    },

    isToppingDisabled(topping: ITopping): boolean {
      return !this.isToppingInStock(topping) || topping.price === -1;
    },

    isToppingInStock(topping) {
      return isToppingInStock(this.cart, this.item, topping);
    },

    selectFraction(topping: ITopping, type: string, change?: string): void {
      this.$emit('selectFractionType', { topping: topping, type: type, change: change });
    },

    toppingToggle(topping: ITopping, event: Event, type: string): void {
      this.$emit('toggleTopping', { topping: topping, event: event, type: type });
    }
  }
};
