import { Address, IAddress } from '../models/Address';
import { LatLngLiteral } from '../models/LatLng';
import Vue from 'vue';
import { gmapApi } from 'vue2-google-maps';
import { Util } from './Util';

export class GoogleService {
    google;

    constructor () {
        Util.waitUntil(() => {
            return Vue && Vue['$gmapApiPromiseLazy'];
        }).then(() => {
            Vue['$gmapApiPromiseLazy']().then(() => {
                this.google = gmapApi();
            });
        });
    }

    geocode(address: string): Promise<IAddress[]> {
        return new Promise((resolve, reject) => {
            new this.google.maps.Geocoder().geocode({ address }, (results: any, status: any) => {
                if (status !== 'OK' && !status.OK) {
                    reject(status);
                } else {
                    results = results.map(item => {
                        const result = this.transformAddress(item);

                        result.latitude = item.geometry.location.lat();
                        result.longitude = item.geometry.location.lng();

                        return result;
                    });

                    resolve(results);
                }
            });
        });
    }

    calculateDrivingDistance(userLocation: LatLngLiteral, restaurantLocation: LatLngLiteral): Promise<number> {
        return new Promise((resolve, reject) => {
            let googleMapsLoadedIntervalId: number = window.setInterval(() => {
                if (typeof this.google === 'object') {
                    clearInterval(googleMapsLoadedIntervalId);
                    let service: any = new this.google.maps.DistanceMatrixService();

                    let origin: any = new this.google.maps.LatLng(userLocation.lat, userLocation.lng),
                        destination: any = new this.google.maps.LatLng(restaurantLocation.lat, restaurantLocation.lng);

                    let distanceRequest: any = {
                        destinations: [destination],
                        origins: [origin],
                        travelMode: this.google.maps.TravelMode.DRIVING,
                        unitSystem: this.google.maps.UnitSystem.IMPERIAL
                    };

                    service.getDistanceMatrix(distanceRequest, (distanceMatrixResponse, distanceMatrixStatus) => {
                        if (distanceMatrixStatus === this.google.maps.DistanceMatrixStatus.OK) {
                            const distanceInfo = distanceMatrixResponse.rows[0].elements[0];
                            if (distanceInfo.status === 'ZERO_RESULTS') {
                                let err: Error = new Error('Zero results found.');
                                reject(err);
                            } else {
                                const distanceString = distanceMatrixResponse.rows[0].elements[0].distance.text;
                                let distance = parseFloat(distanceString.replace(/,/g, ''));

                                if (/ft/.test(distanceString)) {
                                    distance *= 0.3048;
                                } else if (/mi/.test(distanceString)) {
                                    distance *= 1600;
                                }

                                resolve(distance);
                            }
                        } else {
                            let err: Error = new Error('Unable to calculate distance.');
                            reject(err);
                        }
                    });
                }
            });
        });
    }

    getNotificationMessage(result: any) { // TODO: phase out
        let notification: string = '';

        switch (result) {
            case this.google.maps.GeocoderStatus.ZERO_RESULTS:
                notification = 'Address not found.';
                break;
            case this.google.maps.GeocoderStatus.ERROR:
            case this.google.maps.GeocoderStatus.INVALID_REQUEST:
            case this.google.maps.GeocoderStatus.OVER_QUERY_LIMIT:
            case this.google.maps.GeocoderStatus.REQUEST_DENIED:
                notification = 'Unable to locate your address.';
                break;
            case this.google.maps.GeocoderStatus.OK:
            case this.google.maps.GeocoderStatus.UNKNOWN_ERROR:
                notification = 'Unknown error locating your address.';
                break;
            default:
                notification = 'Unknown error locating your address.';
                break;
        }

        return notification;
    }

    transformAddress(address: any): IAddress {
        const transformAddress = new Address();

        let streetNumber: string = '';
        let streetName: string = '';

        for (let component of address.address_components) {
            switch (component.types[0]) {
                case 'street_number':
                    streetNumber = component.long_name;
                    break;
                case 'route':
                    streetName = component.short_name;
                    break;
                case 'locality':
                    transformAddress.city = component.long_name;
                    break;
                case 'administrative_area_level_1':
                    transformAddress.stateName = component.long_name;
                    transformAddress.stateCode = component.short_name;
                    break;
                case 'postal_code':
                    transformAddress.postalCode = component.long_name;
                    break;
                default:
                    break;
            }
        }

        transformAddress.addressLine = streetNumber + ' ' + streetName;

        return transformAddress;
    }
}

export default new GoogleService();
