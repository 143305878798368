
    import Vue from 'vue';
    import restaurants from '../../restaurants/models/Restaurants';
    import profile from '../models/Profile';
    import designService from '../../common/services/design.service';
    import notifierService from '../../common/services/notifier.service';
    import designMixin from '../../common/mixins/design.mixin';
    import { IRegisterRequest, IUser } from '../stores/profile.store';
    import { ILoyalty } from '../../restaurants/types/restaurant.types';

    declare var $: any;

    export default Vue.extend({
        mixins: [designMixin],
        props: {
            error: String,
            loyaltySettings: Object as () => ILoyalty,
            profileRequest: {
                type: Object as () => IRegisterRequest,
                required: true
            },
            user: {
                type: Object as () => IUser,
                required: true
            }
        },
        computed: {
            // models

            _designService () {
                return designService;
            },

            _notifier () {
                return notifierService;
            },

            profile () {
              return profile;
            },

            restaurants () {
                return restaurants;
            },

            // getters

            isInfoFormValid (): boolean {
                return this.profileRequest.username && this.profileRequest.username.length <= 255
                    && this.profileRequest.firstName && this.profileRequest.firstName.length <= 255
                    && this.profileRequest.lastName && this.profileRequest.lastName.length <= 255;
            },

            isFirstNameValid (): boolean {
                return this.profileRequest.firstName;
            },

            isLastNameValid (): boolean {
                return this.profileRequest.lastName;
            },

            isEmailValid (): boolean {
                return this.profileRequest.username;
            },

            isCurrentPasswordValid (): boolean {
                return this.profileRequest.currentPassword;
            },

            isPasswordFormValid (): boolean {
                return this.isCurrentPasswordValid && this.isPasswordValid && this.isConfirmPasswordValid;
            },

            isPasswordValid (): boolean {
                return this.profileRequest.password && this.isPasswordLengthValid;
            },

            isPasswordLengthValid (): boolean {
                return this.profileRequest.password.length >= 6 && this.profileRequest.password.length <= 255;
            },

            isConfirmPasswordValid (): boolean {
                return this.checkPassword && this.checkPassword === this.profileRequest.password;
            }
        },
        data () {
            return {
                initialized: false,
                editing: false,
                editingPassword: false,
                checkPassword: undefined,
                submittedProfileChange: false,
                submittedPasswordChange: false
            };
        },
        created () {
            this.editing = false;
            this.editingPassword = false;

            this.profileRequest.username = this.user.email;
            this.profileRequest.firstName = this.user.firstName;
            this.profileRequest.lastName = this.user.lastName;
            this.profileRequest.rewards = this.user.rewards;

            if (this.profileRequest.rewards === undefined) {
                this.profileRequest.rewards = true;
            }

            this.initialized = true;
        },
        methods: {
            initializeTooltips() {
                $(document).ready(function () {
                    $('[data-toggle="tooltip"]').tooltip();
                });
            },

            openEdit(): void {
                this.editingPassword = false;
                this.editing = true;

                this.profileRequest.username = this.user.email;
                this.profileRequest.firstName = this.user.firstName;
                this.profileRequest.lastName = this.user.lastName;
                this.profileRequest.rewards = this.user.rewards;
                this.initializeTooltips();
            },

            openChangePassword(): void {
                this.editing = false;
                this.editingPassword = true;
            },

            readyToUpdate(): boolean {
                return this.isInfoFormValid;
            },

            readyToSavePassword(): boolean {
                return this.isPasswordFormValid;
            },

            update(event): void {
                event.stopPropagation();
                event.preventDefault();

                if (!this.readyToUpdate()) {
                    this.submittedProfileChange = true;

                    return;
                }

                let loyaltyRewards: boolean = false;

                if (this.loyaltySettings && this.loyaltySettings.externalProgram) {
                    loyaltyRewards = this.profileRequest.rewards;
                }

                let selectedRestaurantId: string = this.restaurants.selectedRestaurantId;

                let payload: any = {
                    userId: this.user.objectId,
                    account: this.profileRequest.account,
                    location: selectedRestaurantId,
                    username: this.profileRequest.username,
                    firstName: this.profileRequest.firstName,
                    lastName: this.profileRequest.lastName,
                    rewards: loyaltyRewards
                };

                this.profile.updateProfile(payload).then(() => {
                  const success = profile.profileSuccess;

                  if (success) {
                    this._notifier.success('Update successful!');
                    this.cancel();
                  } else {
                    this._notifier.error('Update failed!');
                  }
                });

                this.submittedProfileChange = false;
            },

            savePasswordChange(event): void {
                event.stopPropagation();
                event.preventDefault();

                if (!this.readyToSavePassword()) {
                    this.submittedPasswordChange = true;

                    return;
                }

                let payload: any = {
                    userId: this.user.objectId,
                    password: this.profileRequest.password,
                    currentPassword: this.profileRequest.currentPassword
                };

                this.profile.changePassword(payload).then(() => { // TODO: test
                  const success = this.profile.profileSuccess;

                  if (success === undefined) {
                    return;
                  }

                  if (success === true) {
                    this._notifier.success('Password change successful!');
                    this.$emit('clearProfileState', {});
                    this.cancel();
                  } else if (success === false) {
                    this._notifier.error('Password change failed!');
                    this.$emit('clearProfileState', {});
                  }
                });

                this.submittedPasswordChange = false;
            },

            cancel(event): void {
                if (event) {
                    event.stopPropagation();
                    event.preventDefault();
                }

                this.editing = false;
                this.editingPassword = false;
                this.profileRequest.username = this.user.email;
                this.profileRequest.firstName = this.user.firstName;
                this.profileRequest.lastName = this.user.lastName;
                this.profileRequest.rewards = this.user.rewards;
                this.profileRequest.currentPassword = '';
                this.profileRequest.password = '';
                this.checkPassword = undefined;
            }
        }
    });
