declare var posthog: any;

class AnalyticsManager {
    track(name: string, data: Object) {
        console.debug('track: ' + name + ' (' + JSON.stringify(data) + ')');

        try {
            posthog.capture(name, data);
        } catch (e) {
            console.error(e);
        }
    }
}

export default new AnalyticsManager();
