import { IRestaurant } from '../../restaurants/types/restaurant.types';
import { Util } from '../../common/services/Util';
import { ERROR_DETAILS } from '../../common/common.constants';
import { isMobile } from '../../common/helpers/environment.helpers';
import { IAccountDesign, IAccountInfo } from '../types/account.types';

const PAGE_TITLE: string = 'Online Ordering';

const matchById = (location: IRestaurant, selectedRestaurantId: string) => {
  const restaurantIdParam = selectedRestaurantId;

  return location.objectId === restaurantIdParam || location.legacyId === restaurantIdParam;
};

function initAccount(
  accountId: string,
  previewMode: boolean,
  initialize: (accountId: string, previewMode: boolean) => Promise<{
    account: IAccountInfo,
    restaurants: IRestaurant[],
    design: IAccountDesign
  }>,
  getSelectedRestaurantId: () => string,
  setSelectedRestaurantId: (id: string) => void,
  localStorage,
  titleSet: boolean,
  setTitle: (title: string) => void,
  applyCustomCSS: (css: string) => void,
  applyHeaderLogo: (logoUrl: string) => void
): Promise<{ account: IAccountInfo, design: IAccountDesign, restaurants: IRestaurant[] }> {
  return initialize(accountId, previewMode).then(result => {
    if (Util.isEmpty(result)) {
      throw result;
    } else {
      const accountInfo = result.account;

      const selectedRestaurantId = getSelectedRestaurantId();
      const restaurant = result.restaurants.find(loc => matchById(loc, selectedRestaurantId));

      // clear saved location id so it isn't used by other logic
      if (!restaurant) {
        setSelectedRestaurantId(null);
      }

      if (!titleSet) {
        setTitle(`${accountInfo.name} | ${PAGE_TITLE}`);

        localStorage.setItem('tolAccountName', accountInfo.name);
      }

      if (result.design.headerLogo) {
        applyHeaderLogo(result.design.headerLogo);
      }

      return result;
    }
  });
}

function navigateToLandingPage(
  restaurantList: IRestaurant[],
  inactiveLocations: IRestaurant[],
  previewMode: boolean,
  directRoute: string,
  showError: (error: string) => void,
  selectRestaurant: (restaurant: IRestaurant) => Promise<void>,
  openUrl: (url: string) => void,
  passwordToken: string,
  resetPassword: (token: string) => void,
  userAgent: string,
  accountDesign: IAccountDesign,
  routeToLanding: () => void,
  routeToMenu: () => void,
  route: (route: string) => void,
  routeToLocationList: () => void,
  getSelectedRestaurantId: () => string,
  navigateToInnerLandingPage: (routeToMenu: () => void) => void
): void {
  let restaurant;

  const selectedRestaurantId = getSelectedRestaurantId();

  if (selectedRestaurantId) {
    restaurant = restaurantList.find(loc => matchById(loc, selectedRestaurantId));

    if (!restaurant) {
      restaurant = inactiveLocations.find(loc => matchById(loc, selectedRestaurantId));

      if (restaurant && !previewMode && !directRoute) {
        showError(ERROR_DETAILS.INACTIVE_LOCATION);

        return;
      }

      if (!restaurant && restaurantList.length === 1) {
        restaurant = restaurantList[0];
      }
    }
  }

  if (!restaurant) {
    if (restaurantList.length === 1) {
      restaurant = restaurantList[0];
    } else if (restaurantList.length === 0) {
      showError(ERROR_DETAILS.INACTIVE_LOCATION);

      return;
    }
  }

  if (restaurant) {
    selectRestaurant(restaurant).then(() => {
      if (restaurant.redirectUrl) {
        openUrl(restaurant.redirectUrl);

        return;
      }

      if (passwordToken) {
        resetPassword(passwordToken);
      } else {
        navigateToInnerLandingPage(() => {
          routeToMenu();
        });
      }
    });
  } else if (passwordToken) {
    resetPassword(passwordToken);
  } else if (directRoute) {
    route(directRoute);

    return;
  } else {
    routeToLocationList();
  }

  if (restaurantList.length === 0) {
    routeToLocationList();
  }
}

export function initializeSiteLoad(
  url: string,
  localStorage,
  sessionStorage,
  setToken: (token: string) => void,
  setTitle: (title: string) => void,
  showError: (error: string) => void,
  setPassword: (password: string) => void,
  getSelectedRestaurantId: () => string,
  setSelectedRestaurantId: (id: string) => void,
  setPreviewMode: (previewMode: boolean) => void,
  setCustomizeTheme: () => void,
  setColors: (colors: string) => void,
  setDirectRoute: (route: string) => void,
  setOrderId: (id: string) => void,
  setForceLocationFinder: (force: boolean) => void,
  setOfferId: (id: string) => void,
  initializeAccount: (accountId: string, previewMode: boolean) => Promise<{
    account: IAccountInfo, design: IAccountDesign, restaurants: IRestaurant[]
  }>,
  getRestaurantList: () => IRestaurant[],
  getInactiveLocations: () => IRestaurant[],
  selectRestaurant: (restaurant: IRestaurant) => Promise<any>,
  openUrl: (url: string) => void,
  resetPassword: (token: string) => void,
  userAgent: string,
  routeToLanding: () => void,
  routeToMenu: () => void,
  route: (route: string) => void,
  routeToLocationList: () => void,
  applyCustomCSS: (css: string) => void,
  applyHeaderLogo: (logoUrl: string) => void,
  navigateToInnerLandingPage: (routeToMenu: () => void) => void
): Promise<{ account: IAccountInfo }> {
  let accountId: string = Util.getQueryParam('accountId', url);
  const token: string = Util.getResetToken(url);

  if (token) {
    setToken(token);
  }

  if (accountId) {
    localStorage.removeItem('tolAccountId');
    localStorage.removeItem('tolAccountName');
  } else {
    const lsAccountId: string = localStorage.getItem('tolAccountId');

    if (lsAccountId) {
      accountId = lsAccountId;
    }

    if (!accountId) {
      localStorage.removeItem('tolAccountId');
      localStorage.removeItem('tolAccountName');
      localStorage.removeItem('selectedRestaurant');
    }
  }

  let restaurantIdParam: string = Util.getQueryParam('locationId', url);

  const accountName: string = localStorage.getItem('tolAccountName');

  if (accountName) {
    setTitle(`${accountName} | ${PAGE_TITLE}`);
  }

  if (accountId === null) {
    showError(ERROR_DETAILS.MISSING_ACCOUNT);

    return;
  }

  localStorage.setItem('tolAccountId', accountId);

  const appPassword = Util.getQueryParam('appPassword', url);

  if (appPassword) {
    setPassword(appPassword);

    return;
  }

  if (restaurantIdParam) {
    setSelectedRestaurantId(restaurantIdParam);
  }

  let previewMode = sessionStorage.getItem('previewMode');

  if (previewMode) {
    previewMode = JSON.parse(previewMode);
  }

  if (previewMode === null || !previewMode) {
    previewMode = url.indexOf('?preview') > -1 || url.indexOf('&preview') > -1;
    sessionStorage.setItem('previewMode', previewMode);
  }

  setPreviewMode(previewMode);

  const customizeTheme = /[?&]customizeTheme/.test(url);

  if (customizeTheme) {
    setCustomizeTheme();
  }

  const encodedModernColors = Util.getQueryParam('modernThemeColors', url);

  if (encodedModernColors) {
    try {
      setColors(atob(encodedModernColors));
    } catch (e) {
      console.error(e);
    }
  }

  let routeName = Util.getQueryParam('route', url);

  if (routeName) {
    if (routeName === 'register') {
      routeName = 'Register';
    } else if (routeName === 'rewards') {
      routeName = 'RewardsList';
    } else if (routeName === 'curbside') {
      routeName = 'CurbsideConfirmation';
    }

    if (setDirectRoute) {
      setDirectRoute(routeName);
    }
  }

  setOrderId(Util.getQueryParam('orderId', url));

  setForceLocationFinder(/[?&]locationFinder/.test(url));

  setOfferId(Util.getQueryParam('offerId', url));

  const result = initAccount(
    accountId,
    previewMode,
    initializeAccount,
    getSelectedRestaurantId,
    setSelectedRestaurantId,
    localStorage,
    !!accountName,
    setTitle,
    applyCustomCSS,
    applyHeaderLogo
  ).then(response => {
    if (accountId) {
      navigateToLandingPage(
        getRestaurantList(),
        getInactiveLocations(),
        previewMode,
        routeName,
        showError,
        selectRestaurant,
        openUrl,
        token,
        resetPassword,
        userAgent,
        response.design,
        routeToLanding,
        routeToMenu,
        route,
        routeToLocationList,
        getSelectedRestaurantId,
        navigateToInnerLandingPage
      );
    }

    return response;
  }).catch(error => {
    showError(error);

    return null;
  });

  let singleLocationCheck = sessionStorage.getItem('singleLocation');

  if (singleLocationCheck) {
    singleLocationCheck = JSON.parse(singleLocationCheck);
  }

  if (singleLocationCheck === null || !singleLocationCheck) {
    const singleLocation: boolean = url.indexOf('&locationId') > 1 && url.indexOf('&sl') > -1;

    sessionStorage.setItem('singleLocation', JSON.stringify(singleLocation));
  }

  return result;
}
