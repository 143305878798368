
  import Vue from 'vue';
  import RestaurantCard from '../../restaurants/components/RestaurantCard.vue';
  import loyaltyService from '../../coupons/services/loyalty.service';
  import { IUser } from '../../profile/stores/profile.store';
  import { ILoyalty, IRestaurant } from '../../restaurants/types/restaurant.types';
  import filtersMixin from '../../common/mixins/filters.mixin';
  import loyaltyMixin from '../../coupons/mixins/loyalty.mixin';

  export default Vue.extend({
      mixins: [filtersMixin, loyaltyMixin],

      components: { RestaurantCard },

      props: {
          userLocation: {}, // LatLngLiteral | boolean,
          nearestRestaurant: Object as () => IRestaurant,
          selectedRestaurant: Object as () => IRestaurant,
          buttonColor: {
              type: String,
              required: true
          },
          textColor: {
              type: String,
              required: true
          },
          user: Object as () => IUser,
          loyalty: Object as () => ILoyalty,
          loyaltyProgramDescription: String,
          hasOtherRestaurantChoices: Boolean
      },

      computed: {
          loyaltyService () {
              return loyaltyService;
          },

          restaurant(): IRestaurant {
              if (this.selectedRestaurant) {
                  return this.selectedRestaurant;
              } else if (this.nearestRestaurant) {
                  return this.nearestRestaurant;
              } else {
                  return null;
              }
          },

          isLoyal(): boolean {
              return this.user && this.user.rewards;
          },

          isLoggedIn(): boolean {
              return !!this.user && !!this.user.objectId;
          },

          couponCount(): number {
              const result = this.loyaltyService.getAvailableCoupons();

              return result ? result.length : 0;
          },

          location() {
              if (this.userLocation === false) {
                  return null;
              }

              return this.userLocation;
          }
      },
      methods: {
          getButtonStyle(): any {
              return { 'background-color': this.buttonColor, color: this.textColor, border: '1px solid ' + this.textColor };
          },

          onRestaurantCardClick(event) {
              if (!event.target.classList.contains('map-navigation')) {
                  this.$emit('selectRestaurant', this.restaurant);
              }
          },

          joinRewards(): void {
              this.$emit('joinLoyalty');
          },

          map(): boolean {
              this.$emit('showMap', this.restaurant);

              return false;
          },

          viewRewards() {
              this.$emit('viewRewards');
          },

          chooseLocation() {
              this.$emit('chooseLocation');
          },

          signIn() {
              this.$emit('signIn');
          },

          startOrder() {
              this.$emit('startOrder');
          }
      }
  });
