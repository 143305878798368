import maps from '../../maps/models/Maps';
import cart from '../../cart/models/Cart';
import profile from '../../profile/models/Profile';
import storageService from '../../common/services/storage.service';
import util, { Util } from '../../common/services/Util';
import notificationService from '../../common/messaging/notification.service';
import analyticsManager from '../../common/services/analytics-manager.service';
import { getSelectedRestaurantId } from '../helpers/restaurant.helpers';
import {
  RESTAURANTS_STORE
} from '../stores/restaurants.store';
import { VuexModel } from '../../common/data/VuexModel';
import routeService from '../../common/services/route.service';
import { CHECKED_RESTAURANT_DELIVERY_OPEN, CHECKED_RESTAURANT_OPEN, ORDER_TYPES } from '../types/restaurant.types';
import store from '../../../store';

class Restaurants extends VuexModel {
  clearCheckTimeTimer: Function;
  clearIsOpenDeliveryTimer: Function;
  clearIsOpenTimer: Function;
  lastLocationId: string;

  // getters

  get cameFromLocationFinder(): boolean {
    return this.state.cameFromLocationFinder;
  }

  set cameFromLocationFinder(value: boolean) {
    store.dispatch(RESTAURANTS_STORE + '/setCameFromLocationFinder', value);
  }

  get count() {
    return this.state.entities.restaurantCount;
  }

  get currentTime() {
    return this.state.currentTime;
  }

  get inactiveLocations() {
    return this.state.inactiveLocations;
  }

  get isOpen() {
    return this.state.isOpen;
  }

  get isDeliveryOpen() {
    return this.state.isDeliveryOpen;
  }

  get list() {
    return this.state.entities.list;
  }

  get loading() {
    return this.state.loading;
  }

  get loyalty() {
    return this.state.loyalty;
  }

  get loyaltySettings() {
    return this.state.loyalty;
  }

  get selected() {
    return this.state.selected;
  }

  get sizeMap() {
    return this.state.sizeMap;
  }

  get restaurantCount() {
    return this.state.entities.restaurantCount;
  }

  get restaurants() {
    return this.state.entities.list;
  }

  get selectedRestaurant() {
    return this.state.entities.selectedRestaurant;
  }

  get selectedRestaurantId(): string {
    return getSelectedRestaurantId();
  }

  set selectedRestaurantId(restaurantId: string) {
    storageService.localStorage.setItem('selectedRestaurant', JSON.stringify(restaurantId));
  }

  get singleLoyalty() {
    return this.state.singleLoyalty;
  }

  get storeName() {
    return RESTAURANTS_STORE;
  }

  // methods

  initialize(restaurantId: string) {
    return this.dispatch('initialize', restaurantId).then(response => {
      if (response === null || (response.hasOwnProperty('success') && !response.success)) {
        routeService.route('RestaurantsList');
      } else {
        Util.waitUntil(() => cart.cart && cart.cart.objectId).then(cartId => {
          /* analyticsManager.track('Menu Initialized', {
            cartId,
            locationId: this.selectedRestaurant.objectId,
            locationName: this.selectedRestaurant.name
          });*/
        });
      }

      return response;
    });
  }

  load(accountId: string, previewMode: boolean) {
    return this.dispatch('load', { accountId, previewMode });
  }

  changeRestaurant() {
    this.lastLocationId = this.selectedRestaurant.objectId;

    storageService.localStorage.removeItem('selectedRestaurant');

    if (this.clearCheckTimeTimer) {
      this.clearCheckTimeTimer();
    }

    if (this.clearIsOpenDeliveryTimer) {
      this.clearIsOpenDeliveryTimer();
    }

    if (this.clearIsOpenTimer) {
      this.clearIsOpenTimer();
    }

    return this.dispatch('changeRestaurant').then(result => {
      cart.fetchBySession();

      return result;
    });
  }

  setSelectedRestaurantIdAndClearCart(restaurantId: string) {
    analyticsManager.track('Change Location', {
      endingLocationId: restaurantId,
      startingLocationId: this.lastLocationId
    });

    this.selectedRestaurantId = restaurantId;

    return cart.fetchBySession().then(() => {
      if (cart.cart) {
        return cart.cart;
      } else {
        throw false;
      }
    });
  }

  async setSelectedRestaurantIdAndGetCartBySession(restaurantId: string) {
    analyticsManager.track('Change Location', {
      endingLocationId: restaurantId,
      startingLocationId: this.lastLocationId
    });

    this.selectedRestaurantId = restaurantId;

    await cart.fetchBySession();

    if (cart.cart) {
      if (profile.user && profile.user.objectId) {
        cart.addUser(profile.user.objectId);
      }
    }
  }

  selectRestaurant(restaurantId: string) {
    const promises = [];

    promises.push(this.setSelectedRestaurantIdAndClearCart(restaurantId));
    promises.push(this.initialize(restaurantId));

    return Promise.all(promises);
  }

  getSizeMap(restaurantId: string) {
    return this.dispatch('getSizeMap', restaurantId);
  }

  checkCurrentTime(restaurantId: string) {
    if (this.clearCheckTimeTimer) {
      this.clearCheckTimeTimer();
    }

    this.clearCheckTimeTimer = util.startTimer(() => {
      this.dispatch('checkCurrentTime', restaurantId);
    }, 60000);
  }

  getRestaurantFromList(id: string) {
    const list = this.list;

    for (let i = 0; i < list.length; i++) {
      let restaurant = list[i];

      if (restaurant.objectId === id) {
        return restaurant;
      }
    }

    return null;
  }

  // TODO: why not use currentTime?
  getCurrentTimeForRestaurant(id: string) {
    const restaurant = this.getRestaurantFromList(id);

    return Util.createMoment(null, restaurant.timeZone);
  }

  fetchIsOpen(actionName: string, postAction: string, restaurantId: string,
              orderTypeId: string, date: string = null, time: string = null) {
    const payload: any = {
      restaurantId,
      orderTypeId,
      date,
      time
    };

    const go = () => {
      this.dispatch(actionName, payload).then(response => {
        notificationService.notify(
          postAction,
          typeof response === 'boolean' ? response : response.success
        );

        return response;
      });
    };

    if (payload.date && payload.time) {
      go();
    } else {
      const now = this.getCurrentTimeForRestaurant(restaurantId);

      if (!date) {
        payload.date = now.format('YYYY-MM-DD');
      }

      if (!time) {
        payload.time = now.format('HH:mm:ss');
      }

      go();
    }
  }

  checkIsOpen(restaurantId: string, date: string = null, time: string = null) {
    if (this.clearIsOpenTimer) {
      this.clearIsOpenTimer();
    }

    this.clearIsOpenTimer = util.startTimer(() => {
      this.fetchIsOpen('fetchIsOpen', CHECKED_RESTAURANT_OPEN, restaurantId, ORDER_TYPES.DEFAULT, date, time);
    }, 60000);
  }

  checkIsDeliveryOpen(restaurantId: string, date: string = null, time: string = null) {
    if (this.clearIsOpenDeliveryTimer) {
      this.clearIsOpenDeliveryTimer();
    }

    this.clearIsOpenDeliveryTimer = util.startTimer(() => {
      this.fetchIsOpen('fetchIsDeliveryOpen', CHECKED_RESTAURANT_DELIVERY_OPEN, restaurantId, ORDER_TYPES.DELIVERY, date, time);
    }, 60000);
  }

  loadLoyalty(locationId: String) {
    return this.dispatch('loadLoyalty', locationId);
  }

  clearLoyalty() {
    return this.dispatch('clearLoyalty');
  }

  selectClosestRestaurant() {
    const closestRestaurant = this.getClosestRestaurant();

    if (closestRestaurant) {
      return this.selectRestaurant(closestRestaurant.objectId).then(() => closestRestaurant);
    } else {
      return Promise.reject();
    }
  }

  getClosestRestaurant() {
    return maps.closestRestaurant;
  }
}

export default new Restaurants();
