
    import Vue from 'vue';
    import designService from '../../common/services/design.service';
    import notifierService from '../../common/services/notifier.service';
    import notificationService from '../../common/messaging/notification.service';
    import util, {Util} from '../../common/services/Util';
    import { IMenuItem, IMenuItemSize } from '../models/Item';
    import { hoverScheme } from '../directives/ItemButtonHover';
    import { ICategory, IItemDesign } from '../stores/menu.store';
    import { ICart } from '../../cart/cart.types';
    import itemCardMixin from '../mixins/item-card.mixin';
    import { OPEN_SCHEDULE_MODAL, SHOW_ALERT } from '../../common/messaging/notifications';
    import { isItemInStock } from '../../cart/helpers/cart.helpers';

    const moment = require('moment');

    const BUTTON_LAYOUTS = {
        VERTICAL: 'VERTICAL',
        HORIZONTAL: 'HORIZONTAL'
    };

    export default Vue.extend({
        mixins: [itemCardMixin],
        directives: { hoverScheme },
        props: {
            buttonClass: {
                type: String,
                required: true
            },
            buttonLayout: {
                type: String,
                required: true
            },
            cart: {
                type: Object as () => ICart,
                required: true
            },
            category: {
                type: Object as () => ICategory,
                required: false
            },
            currentTime: {
                type: String,
                required: true
            },
            defaultItemDesign: {
                type: Object as () => IItemDesign,
                required: true
            },
            forceShort: {
                type: Boolean,
                required: true
            },
            isSplitOption: {
                type: Boolean,
                required: false,
                default: false
            },
            orderTypes: {
                type: Object,
                required: false
            },
            sizes: {
                type: Array as () => Array<IMenuItemSize>,
                required: false
            }
        },
        computed: {
            // services

            _designService () {
                return designService;
            },

            _notifierService () {
                return notifierService;
            },

            _notificationService() {
              return notificationService;
            }
        },
        data() {
            return {
                initialized: false,
                screenWidth: null,
                windowResizeDebouncer: 0
            };
        },
        created () {
            this.initialized = true;
            this._setPageWidth();

            window.addEventListener('resize', () => {
                if (this.windowResizeDebouncer) {
                    window.clearTimeout(this.windowResizeDebouncer);
                }

                this.windowResizeDebouncer = window.setTimeout(
                    () => this._setPageWidth(),
                    250
                );
            });
        },
        methods: {
            chooseSplitItem(itemId: string): void {
                this.$emit('selectItem', itemId);
            },

            determineItemType(sizeId?: string) {
                if (!this.validateAvailable()) {
                    this._notificationService.notify(SHOW_ALERT, {
                      message: `That item is not available at the selected order time. <span class='please-schedule underline'>Click here to schedule your order for another time.</span>`,
                      notification: OPEN_SCHEDULE_MODAL});

                    return;
                }

                if (this.item.menuType === 'COUPON') {
                    this.$emit('selectCoupon', this.item);
                } else {
                    if (this.sizes && this.sizes.length === 1) {
                        sizeId = this.sizes[0].sizeId;
                    }

                    this.$emit('selectItem', {item: this.item, sizeId});
                }
            },

            validateAvailable(): boolean {
                return Util.validateAvailable(this.category, this.cart, this.currentTime);
            },

            getBackground(): any {
                if (!this.image) {
                    return { 'background': 'url("") center / cover no-repeat' };
                }

                return {
                    'background': 'url("' + this.image + '") center / cover no-repeat'
                };
            },

            isCoupon() {
                return this.item.menuType.toLowerCase() === 'coupon';
            },

            isDisplaySizeChooser(): boolean {
                return this.defaultItemDesign && this.defaultItemDesign.displaySizeChooser;
            },

            layoutIsVertical(): boolean {
                return this.buttonLayout === BUTTON_LAYOUTS.VERTICAL;
            },

            // Styling
            getButtonClass() {
                if (!this.defaultItemDesign || !this.defaultItemDesign.design) {
                    return { 'unavailable': !this.isItemInStock(this.item) };
                }

                let buttonClass = this._designService.getButtonClass(this.defaultItemDesign.design.buttonStyle);
                buttonClass['vertical'] = this.buttonLayout === BUTTON_LAYOUTS.VERTICAL;
                buttonClass['horizontal'] = this.buttonLayout === BUTTON_LAYOUTS.HORIZONTAL;
                buttonClass['unavailable'] = !this.isItemInStock(this.item);
                buttonClass['tol-item-single'] = this.buttonClass === 'tol-item-single';
                buttonClass['tol-item-mobile'] = this.buttonClass === 'tol-item-mobile';

                return buttonClass;
            },

            getUnavailableStyle(): any {
                return null;
            },

            getOutOfStockMessage(): string {
                return 'Currently out of stock';
            },

            getItemStyle(): any {
                if (!this.defaultItemDesign || !this.defaultItemDesign.design) {
                    return {};
                }

                let backgroundColor: string =
                    (this.isItemInStock(this.item) ?
                        this._designService.buttonBackgroundColor :
                        '#4e4e4e');

                let thisStyle = {
                    'background-color': backgroundColor,
                    'border-width': this.defaultItemDesign.design.buttonBorderWidth + 'px !important',
                    'color': this._designService.defaultItemDesignTextColor,
                    'font-size': this.defaultItemDesign.design.fontSize,
                    'font-family': this.defaultItemDesign.design.fontStyle,
                    'margin': '3px'
                };

                if (this.forceShort) {
                    thisStyle['height'] = '165px';
                }

                return thisStyle;
            },

            getSizeButtonScheme() {
                return {
                    background: this._designService.pageBackgroundColor,
                    text: this._designService.backgroundColor
                };
            },

            getItemDetailsPosition(): any {
                if (!this.item.showImage) {
                    return {
                        'top': '5%'
                    };
                }
            },

            isItemInStock(item: IMenuItem): boolean {
                return isItemInStock(this.cart, item);
            },

            // Private functions

            _setPageWidth(): void {
                let pageWidth: number = window.innerWidth,
                    MQ = util.MEDIA_QUERIES;

                if (pageWidth < MQ.SM) {
                    this.screenWidth = 'xs';
                } else if (pageWidth >= MQ.SM && pageWidth < MQ.MD) {
                    this.screenWidth = 'sm';
                } else if (pageWidth >= MQ.MD && pageWidth < MQ.LG) {
                    this.screenWidth = 'md';
                } else {
                    this.screenWidth = 'lg';
                }
            }
        }
    });
