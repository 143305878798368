import { ISubscription } from '../data/ISubscription';
import { ISubscriptionCallback, Subscribable } from '../data/Subscribable';

export class Subscriber {
  subscriptions: Array<ISubscription> = [];

  subscribe<T>(observer: Subscribable<T>, handler: ISubscriptionCallback<T> | ((value: T) => void)): ISubscription {
    let sub = observer.subscribe(handler);

    this.subscriptions.push(sub);

    return sub;
  }

  subscribeToData<T>(observer: Subscribable<T>, name: string) {
    this.subscribe(observer, value => {
      this[name] = value;
    });
  }

  unsubscribe(sub?: ISubscription | Array<ISubscription>): void {
    if (!sub) {
      return;
    }

    let i;

    if (Array.isArray(sub)) {
      for (let s of sub) {
        s.unsubscribe();

        i = this.subscriptions.indexOf(s);

        if (i !== -1) {
          this.subscriptions.splice(i, 1);
        }
      }
    } else {
      sub.unsubscribe();

      i = this.subscriptions.indexOf(sub);

      if (i !== -1) {
        this.subscriptions.splice(i, 1);
      }
    }
  }
}
