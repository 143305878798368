
  import Vue from 'vue';
  import designService from '../../common/services/design.service';
  import modalService from '../../common/services/modal.service';
  import notifierService from '../../common/services/notifier.service';
  import cartHelperService from '../services/cart-helper.service';
  import profile from '../../profile/models/Profile';
  import { IFulfilledCoupon, IMenuItem } from '../../menu/models/Item';
  import {formatCurrency} from '../../common/filters/FormatCurrency';
  import LineItem from './LineItem.vue';
  import {ITenderRequest} from '../../order/models/TenderRequest';
  import { ICart } from '../cart.types';
  import { IOrderType, IRestaurant, ORDER_TYPES } from '../../restaurants/types/restaurant.types';
  import filtersMixin from '../../common/mixins/filters.mixin';

  export default Vue.extend({
      mixins: [filtersMixin],
      components: { LineItem },
      props: {
          cart: {
              type: Object as () => ICart,
              required: true
          },
          selectedItem: Object as () => IMenuItem,
          selectedRestaurant: {
              type: Object as () => IRestaurant,
              required: true
          },
          sizeMap: {
              type: Object,
              required: true
          },
          tenderRequest: {
              type: Object as () => ITenderRequest,
              required: true
          },
          buttonClass: {
              type: Object,
              required: true
          },
          buttonStyle: {
              type: Object,
              required: true
          },
          doesCartHaveItems: {
              type: Boolean,
              required: true
          }
      },
      computed: {
          profile () {
              return profile;
          },
          _designService () {
              return designService;
          },
          _modalService () {
              return modalService;
          },
          _notifierService () {
              return notifierService;
          },
          cartHelper () {
              return cartHelperService;
          },

          // styling

          linkColor(): string {
              return this._designService.linkColor;
          }
      },
      data () {
          return {
              initialized: false,
              modalSubscription: null
          }
      },
      created () {
          this.initialized = true;

          if (!this.cart || !this.cart.orderType || !this.selectedRestaurant || !this.selectedRestaurant.orderTypes)
              return;

          window.setTimeout(() => {
              let orderType: IOrderType = this.selectedRestaurant.orderTypes[this.cart.orderType];

              if (orderType && orderType.objectId === ORDER_TYPES.DELIVERY) {
                  let message, isDeliveryError;

                  if (!this.cart.zoneId || this.cart.zoneId === '0') {
                      message = 'Please select a delivery address to continue.';

                      isDeliveryError = true;
                  }

                  const deliveryZoneError = this.cart.validations.find(validation => validation.type.toUpperCase() === 'DELIVERY');

                  if (deliveryZoneError) {
                      isDeliveryError = true;

                      message = deliveryZoneError.message;
                  }

                  // let other code handle this
                  // if (isDeliveryError)
                  //    this.cartHelper.openDeliveryModal();

                  if (!isDeliveryError && message) {
                      this._notifierService.error(message);

                      return;
                  }
              }
          }, 300);
      },
      methods: {
          confirmRemoveCoupon(couponToRemove: IFulfilledCoupon) {
              this._modalService.removeCouponModal(couponToRemove
              );

              this.modalSubscription = this._modalService.removeCoupon$.subscribe(
                  (coupon: IFulfilledCoupon) => {
                      this.confirmRemoval(coupon);
                  });
          },

          confirmRemoval(couponInfo: IFulfilledCoupon) {
              this.$emit('removeCoupon', {itemId: couponInfo.couponObjectId});

              if (this.modalSubscription)
                  this.modalSubscription.unsubscribe();
          },

          getKeys(obj: any): string[] {
              if (obj === null)
                  return [];

              return Object.keys(obj);
          },

          getDollarAmount(dollars: number): number {
              return dollars === null ? 0 : Math.round(dollars * 100) / 100;
          },

          removeItem(payload) {
              this.$emit('removeItem', payload);
          },

          remove(itemId: string) {
              let editingItem: boolean = false;

              if (this.selectedItem && itemId === this.selectedItem.objectId && /\/item\/[0-9]+\/customize/.test(window.location.pathname)) {
                  editingItem = true;
              }

              let payload: any = {
                  itemId,
                  editingItem
              };

              this.removeItem(payload);
          },

          reviewOrder() {
              this.$emit('checkout', true);
          },

          showTax(): boolean {
              return this.cart.tax !== null && this.cart.tax !== 0;
          },

          getRemainingCoupons() {
              let remainingCoupons = [];

              for (let coupon of this.cart.coupons) {
                  let showCoupon: boolean = true;

                  for (let item of this.cart.items) {
                      if (item.fulfilledCoupons.length <= 0 || coupon.entireOffer)
                          continue;

                      for (let fulfilledCoupon of item.fulfilledCoupons) {
                          if (fulfilledCoupon.couponObjectId !== coupon.objectId)
                              continue;

                          showCoupon = false;

                          break;
                      }
                      if (!showCoupon)
                          break;
                  }

                  if (showCoupon)
                      remainingCoupons.push(coupon);
              }

              return remainingCoupons;
          },

          showLineItem(item: IMenuItem): boolean {
              return this.sizeMap[item.itemId] && item.size.styleId ? true : !this.sizeMap[item.itemId];
          },

          editThisItem(payload) {
              this.closeCart();
              this.$emit('editItem', payload);
          },

          closeCart() {
              this.$emit('closeMobileCart');
          },

          clearCart () {
              this.$emit('clearCart');
          },

          setQuantity (item: IMenuItem) {
              this.$emit('setQuantity', item);
          },

          // Styling
          getSidebarStyle(): any {
              return this._designService.getSidebarStyle();
          },

          getHeaderBorderBottom(): any {
              return {
                  'border-bottom': '1px solid ' + this._designService.backgroundColor
              };
          },

          getHeaderBorderTop(): any {
              return {
                  'border-top': '1px solid ' + this._designService.backgroundColor
              };
          }
      }
  })
