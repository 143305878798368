
import Vue from 'vue';
import designMixin from '../../common/mixins/design.mixin';
import subscriberMixin from '../../common/mixins/subscriber.mixin';
import CategoryView from '../components/CategoryView.vue';
import Spinner from '../../common/components/Spinner.vue';
import restaurants from '../../restaurants/models/Restaurants';
import menu from '../models/Menu';
import {IMenuItem} from '../models/Item';
import util, {Util} from '../../common/services/Util';
import account from '../../account/models/Account';
import cart from '../../cart/models/Cart';
import couponWalkthrough from '../../coupons/models/CouponWalkthrough';
import designService from '../../common/services/design.service';
import modalService from '../../common/services/modal.service';
import notifierService from '../../common/services/notifier.service';
import routeService from '../../common/services/route.service';
import menuBus from '../menu.bus';
import suggestionService from '../../common/services/suggestion.service';
import { ICategory } from '../stores/menu.store';
import { ADD_TO_CART_ACTIONS } from '../../common/services/Util';
import loyaltyService from '../../coupons/services/loyalty.service';
import ErrorModal from '../../common/components/ErrorModal.vue';

export default Vue.extend({
        mixins: [subscriberMixin, designMixin],
        components: {
            CategoryView,
            ErrorModal,
            Spinner
        },
        computed: {
            // services

            _designService() {
                return designService;
            },

            _menuBus() {
                return menuBus;
            },

            _modalService() {
                return modalService;
            },

            _notifier() {
                return notifierService;
            },

            routeService() {
                return routeService;
            },

            account() {
                return account;
            },

            cart() {
                return cart;
            },

            cartItems() {
              return this.cart.cart.items;
            },

            couponWalkthrough() {
                return couponWalkthrough;
            },

            menu() {
                return menu;
            },

            restaurants() {
                return restaurants;
            },

            suggestionService() {
                return suggestionService;
            },

            // getters

            categories() {
                return this.menu.categories;
            },

            category() {
                return this.menu.selectedCategory;
            },

            categoryDesign() {
                return this.menu.categoryDesign;
            },

            coupon() {
                return this.couponWalkthrough.selectedCoupon;
            },

            currentTime() {
                return this.restaurants.currentTime;
            },

            defaultItemDesign() {
                return this.menu.defaultItemDesign;
            },

            itemDesigns() {
                return menu.itemDesigns;
            },

            loading() {
                return this.menu.loading;
            },

            restaurant() {
                return this.restaurants.selectedRestaurant;
            },

            selectedCategory() {
                return this.menu.selectedCategory;
            },

            sizeMap() {
                return restaurants.sizeMap;
            },

            updating() {
                return this.cart.updating;
            }
        },
        data() {
            return {
                addItemSubscription: null, // ISubscription;
                categoryId: null, // string
                customizeItemSubscription: null, // ISubscription,
                item: null, // IMenuItem = null,
                modalOpened: false,
                screenWidth: null, // string,
                sizeId: '',
                windowResizeDebouncer: 0
            };
        },
        watch: {
            // reload category items if it changes
            category(category) {
                if (category) {
                    if (this.categoryId !== category.objectId) {
                      this.categoryId = category.objectId;

                      menu.viewCategoryById(category.objectId).then(items => this.items = items);
                    }
                } else {
                    this.items = null;
                }
            },

        },
        mounted() {
            let categoryId: string = this.$route.params.categoryId;

            if (categoryId) {
                this.viewCategoryById(categoryId);
            }

            this._setPageWidth();

            window.addEventListener('resize', () => {
                if (this.windowResizeDebouncer) {
                    window.clearTimeout(this.windowResizeDebouncer);
                }

                this.windowResizeDebouncer = window.setTimeout(
                    () => this._setPageWidth(),
                    250
                );
            });

            let selectedRestaurant: string = this.restaurants.selectedRestaurantId;
            this.restaurants.checkCurrentTime(selectedRestaurant);
        },
        methods: {
            addCouponItem(payload: { item: IMenuItem, sizeId: string }) {
                this.cart.addItem(payload);
            },

            addItem(quantity = 1, showAutoApplyMessages = false) {
                if (this.modalOpened) {
                    this.modalOpened = false;
                }

                this.endSubscriptions();

                const addItem = showSuggestion => {
                    let payload: any = {
                        item: this.item,
                        sizeId: this.sizeId,
                        showSuggestion,
                        showAutoApplyMessages,
                        quantity
                    };

                    return this.cart.addItem(payload);
                };

                this.suggestionService.checkForSuggestions(this.item, this.restaurant, addItem).catch(() => {
                    addItem(false);
                });
            },

            cancelCoupon(couponId: string) {
                this.cart.removeItem(couponId);
            },

            customizeItem(quantity = 1) {
                if (this.modalOpened) {
                    this.modalOpened = false;
                }

                this.endSubscriptions();
                this.$refs.spinner.open();
                this._designService.setModalStyling();

                let payload: any = {
                    item: this.item,
                    sizeId: this.sizeId,
                    quantity
                };

                cart.selectItem(null);

                this.cart.addItem(payload).then(() => {
                  let data: any = {
                    itemId: this.item.objectId
                  };

                  this.routeService.route('CustomizeItem', data);
                });
            },

            getSizesForItem (item: IMenuItem): any {
                return this.sizeMap[item.objectId];
            },

            selectItem(payload: { item: IMenuItem, sizeId: string, quantity?: number,
              addToCartAction?: ADD_TO_CART_ACTIONS}) {
                this.couponWalkthrough.clearSelectedCoupon();
                this.couponWalkthrough.clearSelectedItemsMap();

                this.item = payload.item;
                this.sizeId = payload.sizeId;

                switch (payload.addToCartAction) {
                  case ADD_TO_CART_ACTIONS.FORCE_ADD:
                    return this.addItem(payload.quantity, true);
                  case ADD_TO_CART_ACTIONS.FORCE_CUSTOMIZE:
                    return this.customizeItem(payload.quantity);
                  case ADD_TO_CART_ACTIONS.COMPUTE:
                  default:
                    return Util.decideToCustomizeItem(payload.item, payload.sizeId, this.sizeMap,
                      this.restaurant.itemSelectedPostTask, () => {
                        this.customizeItem(payload.quantity);
                    }, () => {
                        this.addItem(payload.quantity, true);
                    }, () => {
                        this.askToCustomizeItem(payload.quantity);
                    });

                }
            },

            askToCustomizeItem(quantity = 1): void {
                this._modalService.selectItemModal(this.item);
                this.endSubscriptions();
                this.addItemSubscription = this._modalService.addItem$.subscribe(() => this.addItem(quantity));
                this.customizeItemSubscription = this._modalService.customizeItem$.subscribe(() => this.customizeItem(quantity));
                this.modalOpened = true;
            },

            viewCategory(category: ICategory) {
              this.menu.viewCategory(category);
            },

            viewCategoryById(id: string) {
              this.menu.viewCategoryById(id);
            },

            getLoadingMessage(): string {
                return 'Loading your item for customization...';
            },

            getLoadingTitle(): string {
                return 'Loading Item';
            },

            applyPromoCode(promoCode: string) {
                const existingCoupons = this.cart.cart.coupons;

                return this.cart.addCouponCode(promoCode).then(() => {
                  const coupon = cart.cart.coupons.find(coupon => {
                    return !existingCoupons.find(c => c.objectId === coupon.objectId);
                  });

                  if (coupon && coupon.groups) {
                    loyaltyService.chooseCoupon(coupon, false);
                  }
                });
            },

            // Styling
            getButtonStyle(): any {
                return this._designService.getButtonStyle();
            },

            getButtonClass(): any {
                return this._designService.getButtonClass();
            },

            // Private functions

            _setPageWidth(): void {
                let pageWidth: number = window.innerWidth,
                    MQ = util.MEDIA_QUERIES;

                if (pageWidth < MQ.SM) {
                    this.screenWidth = 'xs';
                } else if (pageWidth >= MQ.SM && pageWidth < MQ.MD) {
                    this.screenWidth = 'sm';
                } else if (pageWidth >= MQ.MD && pageWidth < MQ.LG) {
                    this.screenWidth = 'md';
                } else {
                    this.screenWidth = 'lg';
                }
            },

            endSubscriptions() {
                if (this.addItemSubscription) {
                    this.addItemSubscription.unsubscribe();
                    this.addItemSubscription = undefined;
                }

                if (this.customizeItemSubscription) {
                    this.customizeItemSubscription.unsubscribe();
                    this.customizeItemSubscription = undefined;
                }
            }
        },
        beforeDestroy() {
            this.$refs.spinner.close();
        },
        destroyed() {
            this.endSubscriptions();
        },
    });
