var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.notifications.length > 0)?_c('div',{staticClass:"sticky notifications pt-2 top-0 mb-4 z-20"},[_c('h3',{staticClass:"wcag-header"},[_vm._v("Notifications")]),_vm._l((_vm.notifications),function(notification){return _c('div',{staticClass:"w-full flex bg-secondary rounded shadow-md mb-2 border-l-4 px-3 py-2 font-medium rounded items-center cursor-pointer",class:{
      'border-red-700': notification.type === _vm.TYPES.ERROR,
      'border-green-700': notification.type === _vm.TYPES.SUCCESS
    },attrs:{"data-cy":"notification","data-type":notification.type},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onNotificationClick(notification)}}},[_c('i',{staticClass:"fas text-lg mr-2",class:{
        'fa-exclamation-circle text-red-700': notification.type === _vm.TYPES.ERROR,
        'fas fa-check-circle text-green-700': notification.type === _vm.TYPES.SUCCESS
      }}),_c('span',{staticClass:"whitespace-pre-wrap"},[_vm._v(_vm._s(notification.message))]),_c('button',{staticClass:"flex ml-auto pl-4 pr-2 cursor-pointer text-secondary-contrast",attrs:{"aria-label":"Close","role":"button","type":"button"},on:{"click":function($event){return _vm.close(notification)}}},[_c('i',{staticClass:"far fa-times",attrs:{"aria-hidden":"true"}})])])})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }