
    import Vue from 'vue';
    import confirmationMixin from '../mixins/confirmation.mixin';
    import designMixin from '../../common/mixins/design.mixin';
    import cart from '../../cart/models/Cart';
    import { Util } from '../../common/services/Util';
    import { ORDER_TYPES } from '../../restaurants/types/restaurant.types';
    import '@fortawesome/fontawesome-pro';

    const lodashGet = require('lodash.get');
    const moment = require('moment');

    const getSocialMediaImage = (name) => {
      switch (name.toLowerCase()) {
        case 'facebook':
          return 'fa-facebook';
        case 'yelp':
          return 'fa-yelp';
        case 'twitter':
          return 'fa-twitter';
        case 'instagram':
          return 'fa-instagram-square';
      }
    }

    export default Vue.extend({
        mixins: [confirmationMixin, designMixin],
        computed: {
            // services

            cart() {
                return cart;
            },

            // getters

            cartObject() {
                return this.cart.cart;
            },

            isDelivery() {
                return this.cartObject.orderType === ORDER_TYPES.DELIVERY;
            },

            readyTime() {
                return Util.formatDate(moment(this.cart.readyTime));
            },

            restaurant() {
                return this.restaurants.selectedRestaurant;
            },

            socialMediaMessage () {
              const message = lodashGet(this.$store.state.account, 'entities.accountDesign.socialMediaMessage', '');
              return message ? message.trim() : '';
            },

            socialMedias() {
              return lodashGet(this.$store.state.account, 'entities.accountDesign.socialMedia', [])
                .map(sm => ({
                  icon: getSocialMediaImage(sm.name),
                  name: sm.name,
                  link: sm.link
                }));
            }
        },
        methods: {
          getOrderConfirmationMessage(): string {
              if (!this.restaurant.communicationSettings || !this.restaurant.communicationSettings.orderConfirmationMessage) {
                  return '';
              }

              return this.restaurant.communicationSettings.orderConfirmationMessage;
          }
        }
    });
