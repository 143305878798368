
    import Vue from 'vue';
    import baseMixin from '../../common/mixins/base-component.mixin';
    import User from '../components/User.vue';
    import restaurants from '../../restaurants/models/Restaurants';
    import profile from '../models/Profile';

    export default Vue.extend({
        mixins: [baseMixin],
        components: {
            User
        },
        computed: {
            // models

            restaurants () {
                return restaurants;
            },

            // getters

            addressTypes() {
                return this.profile.addressTypes;
            },

            loyalty() {
                return this.restaurants.loyalty;
            },

            phoneTypes() {
                return this.profile.phoneTypes;
            },

            profileAddressError() {
                return this.profile.profileAddressError;
            },

            profileInfoError() {
                return this.profile.profileInfoError;
            },

            profilePhoneError() {
                return this.profile.profilePhoneError;
            },

            registerRequest() {
                return this.profile.registerRequest;
            },

            suggestedAddresses() {
                return this.profile.suggestedAddresses;
            },

            user() {
                return this.profile.user;
            },

            userAddresses() {
                return this.profile.userAddresses;
            },

            userCreditCards() {
                return this.profile.userCreditCards;
            },

            userPhones() {
                return this.profile.userPhones;
            }
        },
        methods: {
            viewMenu() {
                this.navigateToMenu();
            },

            setPrimary(payload: any) {
                this.profile.setPrimaryAddress(payload);
            },

            removeCredit(payload: any) {
                this.profile.removeCreditCard(payload);
            },

            clearSuggestedAddresses() {
                this.profile.clearSuggestedAddresses();
            },

            setSuggestedAddresses(payload: any[]) {
                this.profile.setSuggestedAddresses(payload);
            },

            deleteAccount(userId: string) {
                this.profile.deleteAccount(userId);
            },

            loadData(): boolean {
                return false;
            },

            clearProfileState(): void {
                this.profile.clearProfileState();
            }
        }
    });
