import { Subject } from './Subject';

export class EventEmitter<T> extends Subject<T> {
  constructor(value?: T) {
    super();

    this.emit(value);
  }

  emit(value: T) {
    this.next(value);
  }
}
