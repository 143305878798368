
  import Vue from 'vue';
  import {ERROR_DETAILS} from '../../common/common.constants';

  declare var $: any;
  declare var VENDOR_PREFIX: string;

  export default Vue.extend({
    props: {
      accountDesign: null, // IAccountDesign;
    },
    data() {
      return {
        ERROR_DETAILS: ERROR_DETAILS,
        errorDetails: null // string;
      };
    },
    computed: {
      headerLogo() {
        return this.accountDesign && this.accountDesign.headerLogo ? this.accountDesign.headerLogo : VENDOR_PREFIX + 'images/thrive-logo.png';
      },
      title(): string {
        if (this.isError(ERROR_DETAILS.INACTIVE_LOCATION)) {
          return 'Inactive Location';
        } else if (this.isError(ERROR_DETAILS.INVALID_ACCOUNT)) {
          return 'Invalid Account';
        } else if (this.isError(ERROR_DETAILS.MISSING_ACCOUNT)) {
          return 'Missing Account';
        } else if (this.isError(ERROR_DETAILS.SESSION_EXPIRED)) {
          return 'Session Expired';
        }
      },

      message(): string {
        if (this.isError(ERROR_DETAILS.INACTIVE_LOCATION)) {
          return 'This location is not accepting orders at this time.';
        } else if (this.isError(ERROR_DETAILS.INVALID_ACCOUNT)) {
          return 'We were unable to locate the account provided. Please provide a valid account and try again.';
        } else if (this.isError(ERROR_DETAILS.MISSING_ACCOUNT)) {
          return 'There was no account provided. Please provide an account and try again.';
        } else if (this.isError(ERROR_DETAILS.SESSION_EXPIRED)) {
          return 'You have been logged out.';
        }
      }
    },
    created() {
      $('body').css('display', 'block');
      let details: any = window.location.pathname;

      if (details) {
        details = details.split('/');
        details = details[details.length - 1];
      }

      this.errorDetails = details;
    },
    methods: {
      isError(errorType: string): boolean {
        return errorType === this.errorDetails;
      }
    }
  });
