
  import Vue from 'vue';
  import baseMixin from './common/mixins/base-component.mixin';
  import Footer from './common/components/Footer.vue';
  import Header from './common/components/Header.vue';
  import getRouter from './router.js';
  import account from './account/models/Account';
  import restaurants from './restaurants/models/Restaurants';
  import storageService from './common/services/storage.service';
  import modalBus from './common/messaging/modal.bus';
  import restaurantLoader from './restaurants/services/restaurant-loader.service';
  import {Util} from './common/services/Util';
  import {MODAL_ACTIONS} from './common/common.constants';
  import FeedbackModal from './common/components/FeedbackModal.vue';
  import NavBar from './common/components/NavBar.vue';
  import StyledModal from './common/components/StyledModal.vue';
  import SessionExpiryModal from './common/components/SessionExpiryModal.vue';
  import { ICart } from './cart/cart.types';
  import { IUser } from './profile/stores/profile.store';
  import CustomizeTheme from './common/components/CustomizeTheme.vue'
  import { ILoyalty, IRestaurant } from './restaurants/types/restaurant.types';
  import { IAccountInfo, IFeedbackRequest } from './account/types/account.types';

  declare var $: any;
  declare var VENDOR_PREFIX: string;

  const router = getRouter();

  const app = Vue.extend({
    mixins: [baseMixin],
    components: {
      FeedbackModal,
      Footer,
      Header,
      NavBar,
      StyledModal,
      CustomizeTheme,
      SessionExpiryModal
    },
    router,
    props: {
      accountInfo: {
        type: Object as () => IAccountInfo,
        required: false
      },

      cart: {
        type: Object as () => ICart,
        required: false
      },

      error: {
        type: Boolean,
        required: true
      },

      feedbackRequest: {
        type: Object as () => IFeedbackRequest,
        required: false
      },

      loyalty: {
        type: Object as () => ILoyalty,
        required: false
      },

      restaurantCount: {
        type: Number,
        required: false
      },

      selectedRestaurant: {
        type: Object as () => IRestaurant,
        required: false
      },

      user: {
        type: Object as () => IUser,
        required: false
      }
    },
    computed: {
      // services

      modalBus() {
        return modalBus;
      },

      restaurantLoader() {
        return restaurantLoader;
      },

      restaurants() {
        return restaurants;
      },

      // getters

      design() {
        return account.design;
      },

      introHtml() {
        return this.design && this.design.htmlIntro;
      },

      isMobile(): boolean {
        return this.environment.isMobile;
      },

      // styling

      buttonClass(): any {
        return this.getButtonClass();
      },

      buttonStyle(): any {
        return this.getButtonStyle();
      },

      useLegacyNotifications(): boolean {
        let isRouteInMenu = this.$route.path.includes('/menu');

        return !isRouteInMenu;
      },

      isCustomizeTheme() {
        return this.$store.state.modernTheme.customizeTheme;
      }
    },
    data() {
      return {
        isErrorVisible: false,
        modalMessage: '',
        modalSessionExpiryMessage: '',
        showMobileCart: false
      };
    },
    watch: {
      error(value) {
        this.isErrorVisible = value;
      }
    },
    created() {
      Util.waitUntil(() => account.design).then(() => {
        const design = account.design;

        if (design && design.accountId) {
          $('body').addClass('modern');
          $('.app-component').css('font-family', this.design.modernFont);
          require('../scss/modern.scss');

          // wait until refs are available
          // need to wait for design to style modals properly
          this.$nextTick(() => {
            if (design.htmlIntro !== null && design.htmlIntro !== '') {
              let introKey: string = `sawIntro${account.id}`;
              let sawIntro: boolean = JSON.parse(storageService.localStorage.getItem(introKey));

              if (!sawIntro && !this.environment.directRoute) {
                this.$refs.introModal.open();
                storageService.localStorage.setItem(introKey, JSON.stringify(true));
              }
            }

            this.modalBus.simpleMessageModal$.subscribe(
              (payload: { action: boolean, message?: string }) => {
                if (
                  [
                    'Your cart has expired.',
                    'You’ve been logged out.'
                  ].indexOf(payload.message || '') > -1
                ) {
                  if (payload.action === MODAL_ACTIONS.OPEN) {
                    this.modalSessionExpiryMessage = payload.message;
                    this.$refs.sessionExpiryModal.open();
                  } else if (payload.action === MODAL_ACTIONS.CLOSE) {
                    this.modalSessionExpiryMessage = '';
                    this.closeSessionExpiryModal();
                  }
                } else {
                  if (payload.action === MODAL_ACTIONS.OPEN) {
                    this.modalMessage = payload.message || '';
                    this.$refs.simpleMessageModal.open();
                  } else if (payload.action === MODAL_ACTIONS.CLOSE) {
                    this.modalMessage = '';
                    this.closeSimpleModal();
                  }
                }
              }
            );
          });
        }

        if (account.accountInfo === null) {
          this.isErrorVisible = true;
        } else {
          this.isErrorVisible = this.error;
        }
      });

      if (storageService.sessionStorage.getItem('deliveryAddress')) {
        storageService.sessionStorage.removeItem('deliveryAddress');
      }

      const clickAwayHandler = (evt: Event) => {
        const el: JQuery = $(evt.target);

        if (el.attr('id') === 'js-mobileCartLink')
          return;

        if (el.closest('#js-mobileCartLink').length > 0)
          return;

        if (el.closest('#js-mobileCartTicketContainer').length > 0)
          return;

        if (this.showMobileCart) {
          evt.preventDefault();
          this.showMobileCart = false;
        }
      };

      $('body').on('click', clickAwayHandler);
      $('body').on('touchend', clickAwayHandler);

      $(window).on('popstate', () => {
        this.showMobileCart = false;
      });
    },
    mounted() {
      this.isErrorVisible = this.error;
    },
    methods: {
      // methods

      closeSimpleModal(): void {
        this.$refs.simpleMessageModal.close();
      },

      closeSessionExpiryModal(): void {
        this.logOut().then(() => {
          this.reloadApp();
        });
      },

      closeMobileCart(): void {
        this.showMobileCart = false;
      },

      doSubmitFeedback(event: IFeedbackRequest): void {
        this.$emit('submitFeedback', event);
      },

      toggleMobileCart(): void {
        this.showMobileCart = !this.showMobileCart;
      },

      // event handlers

      saveFeedback(event) {
        this.$emit('submitFeedback', event);
      },

      reloadApp() {
        location.reload();
      }
    }
  });

  export default app;
