import { IMenuItem, IRequiredItem, IRequiredOption } from '../models/Item';
import { Util } from '../../common/services/Util';
import { getMaxRequirements } from '../helpers/item-customization.helpers';
import { isRequiredItemInStock } from '../../cart/helpers/cart.helpers';
import cart from '../../cart/models/Cart';

export default {
    props: {
        badgeStyle: {
            type: Object,
            required: true
        },

        fractionStyle: {
            type: Object,
            required: true
        },

        i: {
            type: Number,
            required: true
        },

        isSelected: {
            type: Boolean,
            required: true
        },

        item: {
            type: Object as () => IMenuItem,
            required: true
        },

        option: {
            type: Object as () => IRequiredOption,
            required: true
        },

        requiredItem: {
            type: Object as () => IRequiredItem,
            required: true
        },

        requiredQty: {
            type: Number,
            required: true
        }
    },
    methods: {
        getMaxRequirements(option: IRequiredOption): number {
            return getMaxRequirements(option);
        },

        getRequiredItemId(requiredItem: IRequiredItem) {
            return Util.getRequiredItemId(requiredItem);
        },

        isRequiredItemInStock(requiredItem: IRequiredItem) {
            return isRequiredItemInStock(cart.cart, this.item, requiredItem);
        }
    }
};
