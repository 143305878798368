
    import Vue from 'vue';
    import {ICoupon} from '../../coupons/models/Coupon';
    import designMixin from '../../common/mixins/design.mixin';
    import filtersMixin from '../../common/mixins/filters.mixin';

    export default Vue.extend({
        mixins: [designMixin, filtersMixin],

        components: {},

        props: {
            coupon: {
                type: Object as () => ICoupon,
                required: true
            },

            image: String
        },

        computed: {
            shouldHideRemoveButton() {
              return this.coupon.autoApply && this.coupon.surchargeOffer;
            }
        },

        methods: {
            removeCoupon() {
                this.$emit('removeCoupon', this.coupon.objectId);
            }
        }
    });
