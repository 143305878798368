
  import Vue from 'vue';
  import baseMixin from '../../common/mixins/base-component.mixin';
  import MainMenu from '../components/MainMenu.vue';
  import cart from '../../cart/models/Cart';
  import restaurants from '../../restaurants/models/Restaurants';
  import account from '../../account/models/Account';
  import menu from '../models/Menu';
  import cartHelperService from '../../cart/services/cart-helper.service';
  import loyaltyService from '../../coupons/services/loyalty.service';
  import menuService from '../services/menu.service';
  import OrderActionsMobile from '../components/OrderActionsMobile.vue';
  import { ICategory, IFeaturedItem } from '../stores/menu.store';
  import NotificationsModern from '../components/NotificationsModern.vue';
  import { Util } from '../../common/services/Util';

  export default Vue.extend({
    mixins: [baseMixin],
    components: {
      MainMenu,
      OrderActionsMobile,
      NotificationsModern
    },
    computed: {
      // services

      account() {
        return account;
      },

      cart() {
        return cart;
      },

      cartHelper() {
        return cartHelperService;
      },

      loyalty() {
        return loyaltyService;
      },

      menu() {
        return menu;
      },

      restaurants() {
        return restaurants;
      },

      _menuService() {
        return menuService;
      },

      // getters

      accountInfo() {
        return this.account.accountInfo;
      },

      cartObject() {
        return cart.cart;
      },

      categoryDesigns() {
        return this.menu.categoryDesigns;
      },

      currentTime() {
        return this.restaurants.currentTime;
      },

      isLoaded(): boolean {
        return this.cartObject && this.categories;
      },

      loading() {
        return this.menu.loading;
      },

      restaurant() {
        return this.restaurants.selectedRestaurant;
      },

      // styling

      buttonStyle(): any {
          let style: any = this.getButtonStyle();
          style['font-size'] = 'large';

          return style;
      }
    },
    data () {
      return {
        cartSubscription: null, // AnonymousSubscription;
        categories: null,
        featuredItems: null,
        subheaderCategory: null,
        subheaderCoupon: null
      };
    },
    created() {
      Util.waitUntil(() => menu.categories && menu.featuredItems).then(() => {
        this.categories = menu.categories;
        this.featuredItems = menu.featuredItems;
        if (this.categories.length > 0) {
          this.subheaderCategory = this.categories.find(cat => cat.type === 'SUBHEADER');
          if (this.subheaderCategory) {
            this._menuService.getItemsForCategory(this.subheaderCategory.objectId).then(items => {
              this.subheaderCoupon = items[0];
            });

            this.categories = this.categories.filter(cat => cat.type !== 'SUBHEADER');
          }
          this.hideCategoriesWithNoItems();
        }
      });
    },

    mounted() {
      if (this.cart.cart.orderType) {
        this.loyalty.startPendingCouponWalkthrough();
      }
    },

    methods: {
      viewCategory(category: ICategory) {
        this.routeService.route('CategoryView', {categoryId: category.objectId});
      },

      clear() {
        this.menu.clearItemOptions();
      },

      checkOut(): void {
        this.cartHelper.reviewOrder(true);
      },

      hideCategoriesWithNoItems(): void {
        const itemIds = Object.keys(menu.itemMap);
        this.categories = this.categories.filter((category) => {
          if (['SUBHEADER', 'COUPON'].indexOf(category.type) > -1) {
            return true;
          }
          const hasItems = this.hasCategoryItems(category.objectId, itemIds);
          if (!hasItems) {
            if (category.otherCategoryIdList && category.otherCategoryIdList.length) {
              return category.otherCategoryIdList.some((otherCategoryId) => this.hasCategoryItems(otherCategoryId, itemIds));
            }
            return false;
          }
          return true;
        });
      },

      hasCategoryItems(categoryId: string, itemIds: string[]): boolean {
        return itemIds.some((itemId) => menu.itemMap[itemId].menuCategoryId === categoryId)
      }
    }
  });
