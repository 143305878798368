import { hasLoyalty, isEnrolledInRewards, isLoyaltyEnabled } from '../helpers/coupon.helpers';
import profile from '../../profile/models/Profile';
import restaurants from '../../restaurants/models/Restaurants';

export default {
  computed: {
    hasLoyalty() {
      return hasLoyalty(profile.user, restaurants.selectedRestaurantId, restaurants.restaurants);
    },

    isEnrolledInRewards() {
      return isEnrolledInRewards(user);
    },

    isLoyaltyEnabled() {
      return isLoyaltyEnabled(restaurants.selectedRestaurantId, restaurants.restaurants);
    }
  }
}
