
    import Vue from 'vue';
    import subscriberMixin from '../../common/mixins/subscriber.mixin';
    import designService from '../../common/services/design.service';
    import modalService from '../../common/services/modal.service';
    import designMixin from '../../common/mixins/design.mixin';
    import ModalModern from '../../common/components/ModalModern.vue';

    export default Vue.extend({
        mixins: [subscriberMixin, designMixin],

        components: {
            ModalModern
        },

        computed: {
            _designService () {
                return designService;
            },

            _modalService () {
                return modalService;
            }
        },

        data() {
            return {};
        },

        created () {
            this.subscribe(this._modalService.changeRestaurantModal$, () => {
                this.open();
                this._designService.setModalStyling();
            });
        },

        methods: {
            changeRestaurant() {
                this.close();
                this._modalService.changeRestaurant();
            },

            close() {
                this.$refs.modal.close();
            },

            closeChangeRestaurantModal() {
                this.close();
                this._modalService.closeChangeRestaurantModal();
            },

            open() {
                this.$refs.modal.open();
            },

            // styling

            getButtonClass(): any {
                return this._designService.getButtonClass();
            },

            getButtonStyle(): any {
                return this._designService.getButtonStyle();
            }
        }
    });
