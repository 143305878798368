import $ from 'jquery';
import routeService from '../services/route.service';

function bindEvent (el, name, handler) {
  el = $(el);

  el.on(name, handler);
  el.on('remove', () => {
    el.off(name, handler);
  });
}

export const routerLink = {
  bind (el, binding) {
    bindEvent(el, 'click', () => {
      let routeName;
      let routeParams;

      if (typeof binding.value === 'string') {
        routeName = binding.value;
      } else if (typeof binding.value === 'object') {
        routeName = binding.value.route;
        routeParams = binding.value.params;
      }

      routeService.route(routeName, routeParams);
    });
  }
}
