import account from '../../account/models/Account';
import restaurants from '../../restaurants/models/Restaurants';
import routeService from '../services/route.service';

export default {
  beforeRouteEnter(to, from, next) {
    let accountId = account.id,
      isErrorPath = /\/error\//.test(window.location.pathname),
      isLandingPath = to.name === 'Landing';

    if (!restaurants.selectedRestaurantId && accountId !== null && routeService.hasInitiallyRouted
      && !isErrorPath && !isLandingPath && to.name !== 'RestaurantsList' && to.name !== 'RestaurantChooser') {
      next({ name: 'RestaurantsList' });

      return;
    }

    next();
  }
};
