
  import Vue from 'vue';
  import selectedRestaurantOutletMixin from '../../common/mixins/selected-restaurant-outlet.mixin';
  import MenuSidebarContainer from '../../menu/containers/MenuSidebarContainer.vue';
  import couponWalkthrough from '../models/CouponWalkthrough';

  export default Vue.extend({
    mixins: [selectedRestaurantOutletMixin],

    components: {
      MenuSidebarContainer
    },

    computed: {
      couponWalkthrough() {
        return couponWalkthrough;
      },

      selectedCoupon() {
        return this.couponWalkthrough.selectedCoupon;
      }
    },

    mounted() {
      this.couponWalkthrough.buildSelectedItemsMap(this.selectedCoupon.groups);
    },

    watch: {
      selectedCoupon(newCoupon, oldCoupon) {
        if (newCoupon == oldCoupon) {
          return;
        }

        this.couponWalkthrough.buildSelectedItemsMap(newCoupon.groups);
      }
    }
  });
