import { API_GET_COUPON_BY_PLU_URL, API_GET_ITEMS_FOR_COUPON_URL } from '../coupon.constants';
import storageService from '../../common/services/storage.service';
import { RequestMethod } from '../../common/services/axios-http.service';
import { BaseApiService } from '../../common/services/BaseApiService';

export class CouponService extends BaseApiService {
    getCouponByPLU(payload: { locationId: string, plu: string }) {
        return this.request({
           method: RequestMethod.Get,
           url: API_GET_COUPON_BY_PLU_URL,
           search: `location=${payload.locationId}&plu=${payload.plu}`
        });
    }

    getItemsForCoupon(payload: { couponId: string, groupId: string }) {
        let restaurantId: string = JSON.parse(storageService.localStorage.getItem('selectedRestaurant'));

        return this.request({
            method: RequestMethod.Get,
            url: API_GET_ITEMS_FOR_COUPON_URL,
            search: `location=${restaurantId}&coupon=${payload.couponId}&group=${payload.groupId}`
        });
    }
}

export default new CouponService();
