
  import Vue from 'vue';
  import { ICoupon, ICouponGroup } from '../../coupons/models/Coupon';
  import { IMenuItem } from '../models/Item';
  import ItemCard from './ItemCard.vue';
  import menuService from '../../menu/services/menu.service';
  import modalService from '../../common/services/modal.service';
  import notificationService from '../../common/messaging/notification.service';
  import notifierService from '../../common/services/notifier.service';
  import { Util } from '../../common/services/Util';
  import ItemCardModern from './ItemCardModern.vue';
  import OrderActionsMobile from './OrderActionsMobile.vue';
  import { ICategory, IItemDesign } from '../stores/menu.store';
  import { ICart } from '../../cart/cart.types';
  import itemCardListMixin from '../mixins/item-card-list.mixin';
  import NotificationsModern from './NotificationsModern.vue';
  import menu from '../../menu/models/Menu';
  import { IRestaurant } from '../../restaurants/types/restaurant.types';
  import loyaltyService from '../../coupons/services/loyalty.service';
  import { OPEN_SCHEDULE_MODAL, SHOW_ALERT } from '../../common/messaging/notifications';
  import cart from '../../cart/models/Cart';

  const sortBy = require('lodash/sortBy');
  const moment = require('moment');

  const BUTTON_LAYOUTS = {
    VERTICAL: 'VERTICAL',
    HORIZONTAL: 'HORIZONTAL'
  };

  export default Vue.extend({
    mixins: [itemCardListMixin],
    components: {
      ItemCard,
      ItemCardModern,
      OrderActionsMobile,
      NotificationsModern
    },
    props: {
      cart: {
        type: Object as () => ICart,
        required: true
      },
      category: {
        type: Object as () => ICategory,
        required: true
      },
      coupon: {
        type: Object as () => ICoupon,
        required: false
      },
      currentTime: {
        type: String,
        required: true
      },
      defaultItemDesign: {
        type: Object as () => IItemDesign,
        required: true
      },
      loading: {
        type: Boolean,
        required: true
      },
      restaurant: {
        type: Object as () => IRestaurant,
        required: true
      },
      screenWidth: {
        type: String
      },
      sizeMap: {
        type: Object,
        required: true
      },
      updating: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      // services

      _menuService() {
        return menuService;
      },

      _modalService() {
        return modalService;
      },

      _notifierService() {
        return notifierService;
      },

      _notificationService() {
        return notificationService;
      },

      // getters

      cartOrderTime(): string {
        return this.cart.orderTimeString;
      },

      isMobile(): boolean {
        return this.environment.isMobile;
      },

      items() {
        return menu.items;
      },

      forceShort(): boolean {
        if (this.items) {
          for (let i = 0; i < this.items.length; i++) {
            let item = this.items[i];

            if (item.imageLink && item.showImage)
              return false;
          }
        }

        return true;
      },

      isContentVisible(): boolean {
        return this.initialized && !this.loading && this.isDesignAvailable;
      },

      isDesignAvailable(): boolean {
        if (!this.defaultItemDesign.design) {
          return false;
        }

        return true;
      },

      sortedItems() {
        return sortBy(this.items, ['departmentSort', 'categorySort', 'sortNumber']);
      }
    },
    data() {
      return {
        initialized: false,
        promoCode: null
      };
    },
    created() {
      window.scrollTo(0, 0);
      // Ensure cart is updated so we know which items are out of stock
      cart.fetchBySession().then(() => {
        this.initialized = true;
      });
    },
    mounted() {
      this.$nextTick(() => {
        if (!this.available()) {
          this.showUnavailableError();
        }
      });
    },
    methods: {
      applyPromo(promoCode: string) {
        this.$emit('applyPromoCode', promoCode);

        this.promoCode = null;
      },

      addItem(coupon: ICoupon) {
        let wait: number = 0;

        if (coupon.groups && coupon.groups.length > 0) {
          for (let group of coupon.groups) {
            if (group.includes && group.includes.length > 1)
              continue;

            if (group.includes && group.includes.length === 1) {
              if (!group.includes[0].groupType || group.includes[0].groupType !== 'ITEM')
                continue;

              if (!group.includes[0].item || !group.includes[0].item.objectId)
                continue;
            }

            this.submitAdd(group, wait + 50);
          }
        }
      },

      submitAdd(group: ICouponGroup, wait: number) {
        window.setTimeout(() => {
            if (this.updating) {
              this.submitAdd(group, wait + 50);

              return;
            }

            let styleId: string;

            if (group.includes[0].styles && group.includes[0].styles[0] && group.includes[0].styles[0].styleId)
              styleId = group.includes[0].styles[0].styleId;

            this.$emit('addCouponItem', {
              item: group.includes[0].item,
              sizeId: styleId
            });
          },
          wait
        );
      },

      chooseCoupon(coupon: ICoupon) {
        return loyaltyService.chooseCoupon(coupon, false);
      },

      getImageUrl(showImage: boolean, imageLink: string, defaultImageLink: string): string {
        if (!showImage)
          return '';

        if (imageLink === null || imageLink === '' || !imageLink)
          return defaultImageLink;

        return imageLink;
      },

      available(): boolean {
        return Util.validateAvailable(this.category, this.cart, this.currentTime);
      },

      getOrderTime(): string {
        return Util.getOrderTimeString(this.cart, this.restaurant.promiseTimes, this.currentTime, this.restaurant.usePromiseTime);
      },

      getFromDisplay(): string {
        if (!this.category.available)
          return '';

        let day: string = this.getOrderTime().split('T')[0];
        return moment(day + 'T' + this.category.available.startString).format('h:mm a');
      },

      getToDisplay(): string {
        if (!this.category.available)
          return '';

        let day: string = this.getOrderTime().split('T')[0];
        return moment(day + 'T' + this.category.available.endString).format('h:mm a');
      },

      chooseItem(item: IMenuItem) {
        this.$emit('selectItem', item);
      },

      showUnavailableError() {
        const msg = `Ordering from ${this.category.name} is currently unavailable.
Ordering will be available from ${this.getFromDisplay()} to ${this.getToDisplay()}. <span class='please-schedule underline'>Click here to schedule your order for another time.</span>`;
        this._notificationService.notify(SHOW_ALERT, {message: msg, notification: OPEN_SCHEDULE_MODAL});
      },

      // Styling
      getButtonStyle(): any {
        return this._designService.getButtonStyle();
      },

      getButtonClass(): any {
        return this._designService.getButtonClass();
      },

      getButtonTypeClass(): string {
        if (this.screenWidth && this.screenWidth === 'xs') {
          return 'tol-item-mobile';
        } else {
          return 'tol-item-single';
        }
      },

      getMenuLayoutClass() {
        let vertical: string = 'col-xs-10 col-xs-offset-1 col-sm-6 col-sm-offset-0 col-lg-4',
          horizontal: string = 'col-xs-12';

        return this.restaurant.buttonLayout === BUTTON_LAYOUTS.HORIZONTAL ? horizontal : vertical;
      }
    },
    watch: {
      cartOrderTime(newOrderTime, oldOrderTime) {
        if (oldOrderTime !== newOrderTime) {
          if (!this.available()) {
            this.showUnavailableError();
          }
        }
      }
    }
  });
