import account from '../../account/models/Account';
import { IAccountDesign } from '../../account/types/account.types';

declare var $: any;

export class DesignService {
    get backgroundColor() {
        return this.design.modernThemeColors.background;
    }

    get buttonBackgroundColor() {
        return this.design ? this.primaryColor : null;
    }

    get buttonBorderColor() {
        return this.design ? this.primaryColor : null;
    }

    get buttonStyle() {
        return {
            padding: '6px 6px 6px 5px',
            'background-color': this.buttonBackgroundColor,
            color: this.buttonTextColor,
            'border-color': this.buttonBorderColor,
            cursor: 'pointer'
        };
    }

    get buttonTextColor(): string {
        return this.design ? this.primaryContrastColor : null;
    }

    get design() {
        return account.design;
    }

    get defaultItemDesignTextColor(): string {
        return this.primaryContrastColor;
    }

    getAlertStyling(): any {
        return {
            'background-color': this.primaryColor,
            color: this.primaryContrastColor + ' !important'
        };
    }

    getButtonBackgroundColor(): string {
        return this.buttonBackgroundColor;
    }

    getButtonClass(buttonStyle: number = 0): any {
        if (!buttonStyle) {
            if (this.design) {
                buttonStyle = this.design.buttonStyle;
            } else {
                return null;
            }
        }

        return {
            'tol-btn-1': buttonStyle <= 1,
            'tol-btn-2': buttonStyle === 2,
            'tol-btn-3': buttonStyle === 3,
            'tol-btn-4': buttonStyle === 4,
            'tol-btn-5': buttonStyle === 5,
            'tol-btn-6': buttonStyle === 6
        };
    }

    getButtonColor(): string {
        return this.buttonBackgroundColor;
    }

    getDesign(): IAccountDesign {
        return this.design;
    }

    getButtonStyle(): any {
        return this.buttonStyle;
    }

    getHeaderForegroundColor(): string {
        return this.getForegroundColor();
    }

    getItemTabSelectedStyle(): any {
        return {
            margin: '2px 2px 0',
            backgroundColor: this.design ? this.popupTabSelectedColor : null,
            color: this.design ? this.popupTabTextColor : null
        };
    }

    getItemTabStyle(): any {
        return {
            margin: '2px 2px 0',
            backgroundColor: this.design ? this.popupTabColor : null,
            color: this.primaryContrastColor
        };
    }

    getSidebarStyle(padding: boolean = true): any {
        let style: any = {
            'border-bottom': this.design ? '1px solid ' + this.primaryContrastColor : null,
            'background-color': this.primaryColor,
            'color': this.primaryContrastColor,
            'font-family': this.design ? this.design.fontBody : null
        };

        if (padding) {
            style['padding'] = '15px';
        }
        return style;
    }

    getSplitTabSelectedStyle(): any {
        return {
            margin: '2px 2px 0',
            backgroundColor: this.design ? this.popupTabSelectedColor : null,
            color: this.design ? this.popupTabTextColor : null
        };
    }

    getSplitTabStyle(): any {
        return {
            margin: '2px 2px 0',
            backgroundColor: this.design ? this.popupTabColor : null,
            color: this.design ? this.popupTabTextColor : null
        };
    }

    getModalHeaderStyling() {
        return this.modalHeaderStyle;
    }

    getModalBodyStyling() {
        return this.modalBodyStyle;
    }

    getForegroundColor(): string {
        return this.tertiaryColor;
    }

    get headerBackgroundColor() {
        return this.primaryContrastColor;
    }

    get headerTextFontColor() {
        return this.primaryColor;
    }

    get linkStyle(): any {
        return {
            color: this.linkColor,
            'text-decoration': 'underline'
        };
    }

    get loyaltyPointsDescription(): string {
        let result;

        if (this.design) {
            result = this.design.loyaltyPointsDescription;

            if (!result) {
                result = 'Earn points with every purchase for great rewards';
            }
        }

        return result;
    }

    get loyaltyProgramDescription(): string {
        let result;

        if (this.design) {
            result = this.design.loyaltyProgramDescription;

            if (!this.design.loyaltyProgramDescription) {
                result = 'Receive great offers and rewards';
            }
        }

        return result;
    }

    get linkColor() {
        return this.tertiaryColor;
    }

    get modalBodyBackgroundColor() {
        return this.design ? this.primaryContrastColor : null;
    }

    get modalBodyStyle() {
        return {
            'background-color': this.modalBodyBackgroundColor,
            'color': this.modalBodyTextColor
        };
    }

    get modalBodyTextColor() {
        return this.tertiaryColor;
    }

    get modalHeaderBackgroundColor(): string {
        return this.design.modernThemeColors['secondary-contrast-500'];
    }

    get modalHeaderBorderColor() {
        return this.tertiaryColor;
    }

    get modalHeaderForegroundColor(): string {
        return this.getHeaderForegroundColor();
    }

    get modalHeaderStyle() {
        return {
            'background-color': this.modalHeaderBackgroundColor,
            'border-color': this.modalHeaderBorderColor,
            color: this.modalHeaderTextColor
        };
    }

    get modalHeaderTextColor() {
        return this.tertiaryColor;
    }

    get pageBackgroundColor() {
        return this.design ? this.primaryContrastColor : null;
    }

    get popupMainTextColor(): string {
        return this.tertiaryColor;
    }

    get popupTabColor(): string {
        return this.backgroundColor;
    }

    get popupTabSelectedColor(): string {
        return this.backgroundColor;
    }

    get popupTabTextColor(): string {
        return this.primaryContrastColor;
    }

    get primaryColor(): string {
        return this.design.modernThemeColors.primary;
    }

    get primaryContrastColor(): string {
        return this.design.modernThemeColors['primary-contrast'];
    }

    get secondaryColor(): string {
        return this.design.modernThemeColors.secondary;
    }

    // sets style of angular modals. vue modals should used StyledModal component
    setModalStyling() {
        // HACK!! Using jquery here because I can't get at the divs to put styles on them until after the modal gets created.
        let styles;

        let modalHeader = $('modal .modal-header');

        if (modalHeader) {
            styles = this.getModalHeaderStyling();

            Object.keys(styles).forEach(name => {
                modalHeader.css(name, styles[name]);
            });
        }

        let modalBody = $('modal .modal-body');

        if (modalBody) {
            styles = this.getModalBodyStyling();

            Object.keys(styles).forEach(name => {
                modalBody.css(name, styles[name]);
            });
        }
    }

    get sloganFontColor(): string {
        return this.tertiaryColor;
    }

    get tertiaryColor(): string {
        return this.design.modernThemeColors.tertiary;
    }

    get textColor() {
        return this.backgroundColor;
    }
}

export default new DesignService();
