
  import Vue from 'vue';
  import toppingChoicesMixin from '../../mixins/topping-choices.mixin';
  import CheckboxModern from '../../../common/components/CheckboxModern.vue';
  import cart from '../../../cart/models/Cart';

  export default Vue.extend({
    mixins: [toppingChoicesMixin],
    components: {CheckboxModern},
    props: {
      splitSide: {
        type: String,
        required: false
      },

      isModifiersTypeNumeric: {
        type: Boolean,
        required: true
      },
    },
    computed: {
      cart() {
        return cart.cart;
      }
    },
    methods: {
      selectAndDecrease(topping) {
        if (this.getQuantity(topping, this.splitSide ? this.splitSide : 'WHOLE') > 0) {
          this.selectFraction(topping, null, 'decrease');
        }
      },

      selectAndIncrease(topping) {
        if (!this.isToppingSelected(topping, !!this.splitSide)) {
          this.toppingToggle(topping, {target:{checked: true}},'whole');
        } else if (this.getQuantity(topping, this.splitSide ? this.splitSide : 'WHOLE') < 3) {
          this.selectFraction(topping, null, 'increase');
        }
      }
    }
  });
