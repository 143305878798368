
  import locationFinderMixin from '../mixins/location-finder.mixin';
  import Vue from 'vue';
  import cartHelperService from '../../cart/services/cart-helper.service';
  import cart from '../../cart/models/Cart';
  import DeliveryAddressModern from './DeliveryAddressModern.vue';
  import FindLocationModern from './FindLocationModern.vue';
  import loyaltyService from '../../coupons/services/loyalty.service';
  import maps from '../../maps/models/Maps';
  import { ORDER_TYPES } from '../types/restaurant.types';

  export default Vue.extend({
    mixins: [locationFinderMixin],
    components: {
      DeliveryAddressModern,
      FindLocationModern
    },
    data() {
      return {
        orderType: null,
        ORDER_TYPES
      };
    },
    computed: {
      // services

      cart() {
        return cart;
      },

      cartHelperService() {
        return cartHelperService;
      },

      loyalty() {
        return loyaltyService;
      },

      accountLogo() {
        return this.$store.state.account.entities.accountInfo.logo;
      }
    },
    methods: {
      toggleEnterAddress(event): void {
        if (event.target.classList.contains('open')) {
        } else {
          this.findALocationVisible = false;
        }
      },

      toggleFindALocation(event): void {
        if (event.target.classList.contains('open')) {
          this.findALocationVisible = false;
        } else {
          this.findALocationVisible = true;
        }
      },

      // Delivery Address Form

      checkPostalCode(): void {
        if (!this.tenderRequest || !this.tenderRequest.deliveryAddress
          || !this.tenderRequest.deliveryAddress.postalCode) {
          return;
        }

        if (this.lastCheckedPostalCode
          && this.lastCheckedPostalCode === this.tenderRequest.deliveryAddress.postalCode) {
          return;
        }

        this.validatePostalCode();

        this.lastCheckedPostalCode = this.tenderRequest.deliveryAddress.postalCode;

        maps.verifyPostalCode(this.tenderRequest.deliveryAddress.postalCode)
          .then(result => {
            this.validations.postalCode = true;

            let validatedAddress = result;

            if (validatedAddress.city) {
              this.tenderRequest.deliveryAddress.city = validatedAddress.city;

              this.validations.city = true;
            }

            if (validatedAddress.stateCode) {
              this.tenderRequest.deliveryAddress.stateCode = validatedAddress.stateCode;
              this.tenderRequest.deliveryAddress.stateName = validatedAddress.stateName;

              this.validations.state = true;
            }

            this.tenderRequest.deliveryAddress = this.deliveryAddress;
          })
          .catch(() => {
            this.validations.postalCode = false;
          });
      },

      setOrderType(orderTypeId: any): void {
        this.orderType = orderTypeId;
        this.$emit('selectedOrderType', orderTypeId);

        if (typeof localStorage !== 'undefined') {
          localStorage.setItem('orderType', orderTypeId);
        }
      },

      getTitle(): string {
        if (this.hasLoyalty) {
          return this.loyalty.programName + ' member';
        }

        return null;
      }
    }
  });
