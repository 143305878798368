import { IUser } from '../../profile/stores/profile.store';

declare var rg4js: any;

export class ReportingService {
    setRaygunUser(user: IUser): void {
        if (typeof rg4js === 'undefined')
            return;

        rg4js('setUser', {
            identifier: user.email,
            isAnonymous: false,
            email: user.email,
            firstName: user.firstName,
            fullName: user.fullName
        });
    }
}

export default new ReportingService();
