export interface IPhone {
    objectId: string;
    userId: string;
    number: string;
    type: string;
}

export class Phone implements IPhone {
    objectId: string = '';
    userId: string = '';
    number: string = '';
    type: string = '';

    constructor(opts?: IPhone) {
        if (opts) {
            for (let key in opts) {
                if (opts.hasOwnProperty(key)) {
                    this[key] = opts[key];
                }
            }
        }
    }
}