
  import Vue from 'vue';
  import designMixin from '../mixins/design.mixin';
  import modalMixin from '../mixins/modal.mixin';

  export default Vue.extend({
    mixins: [designMixin, modalMixin],
    props: {
      closeOnBackdropClick: {
        type: Boolean,
        default: true
      },
      showCloseButton: {
        type: Boolean,
        required: false,
        default: true
      },
      title: String
    },
    methods: {
      onHide() {
        this.$emit('hide');
      },

      onShow() {
        this.$emit('show');
      }
    }
  });
