const get = require('lodash/get');
import Vue from 'vue';

const capitalizeAndUnderscoreWords = text => {
  const words = [];

  let i = text.search(/[A-Z]/);

  if (i === -1) {
    i = text.length;
  }

  do {
    let word = text.substr(0, i).toUpperCase();

    words.push(word);

    text = text.substr(i);
    i = text.substr(1).search(/[A-Z]/);

    if (i === -1) {
      if (text) {
        word = text.toUpperCase();

        words.push(word);
      }
    } else {
      i++;
    }
  } while (i >= 0);

  return words.join('_');
};

export const getSetterMutationName = stateName => {
  let capitalized;

  if (Array.isArray(stateName)) {
    capitalized = stateName.map(name => capitalizeAndUnderscoreWords(name)).join('_');
  } else {
    capitalized = capitalizeAndUnderscoreWords(stateName);
  }

  return 'SET_' + capitalized;
};

export const createSetterMutations = (state, childStateName) => {
  const result = {};

  let keys;

  if (childStateName) {
    keys = Object.keys(state[childStateName]);
  } else {
    keys = Object.keys(state);
  }

  keys.forEach(key => {
    let path;

    if (childStateName) {
      path = [childStateName, key];
    } else {
      path = key;
    }

    const mutationName = getSetterMutationName(path);

    if (childStateName) {
      result[mutationName] = (state, payload) => {
        Vue.set(state[childStateName], key, payload);
      };
    } else {
      result[mutationName] = (state, payload) => {
        Vue.set(state, key, payload);
      };
    }
  });

  return result;
};

export const setStateThroughMutation = (commit, stateName, value) => {
  commit(getSetterMutationName(stateName), value);

  return value;
};

export const setStateThroughMutationFromPromise = (commit, promise, stateName) => {
  return promise.then(response => {
    setStateThroughMutation(commit, stateName, response);

    return response;
  });
};
