import { setStateThroughMutation, setStateThroughMutationFromPromise } from '../../common/data/store.helpers';
import { MENU_STORE } from '../../menu/stores/menu.store';
import restaurantsService from '../services/restaurants.service';
import { LoyaltyRecord, RestaurantRecord } from '../types/restaurant.types';

const _fetchIsOpen = (payload: { restaurantId: string, orderTypeId: string, date: string, time: string }) => {
  return restaurantsService.isOpen(payload).then(response => {
    let result;

    if (typeof response === 'boolean') {
      result = response;
    } else {
      result = response ? response.success : false;
    }

    return result;
  });
};

export const filterInactiveRestaurants = (restaurants, inactiveRestaurants) => {
  return restaurants.filter(restaurant => {
    if (restaurant.active) {
      return true;
    } else {
      inactiveRestaurants.push(restaurant);

      return false;
    }
  });
};

export const changeRestaurant = ({commit, dispatch}) => {
  setStateThroughMutation(commit, 'selected', false);
  commit('SET_SELECTED_RESTAURANT', undefined);

  return dispatch(MENU_STORE + '/reset', null, {root: true});
};

export const clearLoyalty = function ({ commit }) {
  commit('SET_LOYALTY', null);
  commit('SET_SINGLE_LOYALTY', null);
};

export const checkCurrentTime = ({commit}, restaurantId) => {
  return setStateThroughMutationFromPromise(commit, restaurantsService.currentTime(restaurantId), 'currentTime');
};

export const getSizeMap = ({commit}, restaurantId) => {
  return restaurantsService.loadSizeMap(restaurantId).then(response => {
    const result = response.sizes;

    setStateThroughMutation(commit, 'sizeMap', result);

    return response.sizes;
  });
};

export const initialize = ({commit, dispatch}, restaurantId) => {
  dispatch(MENU_STORE + '/setLoading', true, {root: true});

  return restaurantsService.initialize(restaurantId).then(response => {
    if (response !== null) {
      const rest = new RestaurantRecord(response.restaurant);

      commit('SET_SELECTED_RESTAURANT', rest);

      setStateThroughMutation(commit, 'restaurant', rest);
      setStateThroughMutation(commit, 'sizeMap', response.menuSettings.sizes.sizes);
      setStateThroughMutation(commit, 'loyalty', new LoyaltyRecord(response.loyaltySettings));
      setStateThroughMutation(commit, 'selected', true);
    } else {
      commit('SET_SELECTED_RESTAURANT', undefined);
    }

    let result;

    if (response === null || (response.hasOwnProperty('success') && !response.success)) {
      result = null;
    } else {
      result = response;
    }

    dispatch(MENU_STORE + '/initialize', result, {root: true});

    return result;
  });
};

export const fetchIsDeliveryOpen = ({commit}, payload: { restaurantId: string, orderTypeId: string, date: string, time: string }) => {
  return setStateThroughMutationFromPromise(commit, _fetchIsOpen(payload), 'isDeliveryOpen');
};

export const fetchIsOpen = ({commit}, payload: { restaurantId: string, orderTypeId: string, date: string, time: string }) => {
  return setStateThroughMutationFromPromise(commit, _fetchIsOpen(payload), 'isOpen');
};

export const load = ({commit, dispatch}, payload: { accountId, previewMode }) => {
  return dispatch('setLoading', true).then(() => {
    return restaurantsService.loadRestaurants(payload).then(async response => {
      return dispatch('setRestaurants', {restaurants: response});
    });
  });
};

export const loadLoyalty = ({commit}, locationId) => {
  return restaurantsService.findLoyalty(locationId).then(response => {
    const result = new LoyaltyRecord(response);

    setStateThroughMutation(commit, 'singleLoyalty', result);

    return result;
  });
};

export const setCameFromLocationFinder = ({commit}, value: boolean) => {
  return setStateThroughMutation(commit, 'cameFromLocationFinder', value);
};

export const setLoading = ({commit}, value) => {
  return setStateThroughMutation(commit, 'loading', value);
};

export const setRestaurants = ({commit, dispatch}, {restaurants, previewMode}) => {
  commit('SET_RESTAURANT_COUNT', restaurants.length);

  const inactiveRestaurants = [];

  if (!previewMode) {
    restaurants = filterInactiveRestaurants(restaurants, inactiveRestaurants);
  }

  const result = [...restaurants];
  commit('SET_RESTAURANT_LIST', result);
  commit('SET_INACTIVE_LOCATIONS', inactiveRestaurants);
  return dispatch('setLoading', false).then(() => {
    return result;
  });
};
