export function crawlObject (object, callback: (obj, name) => void) {
  if (object) {
    if (Array.isArray(object)) {
      object.forEach(item => {
        crawlObject(item, callback);

        callback(item, null);
      });
    } else if (typeof object === 'object') {
      const keys = Object.keys(object);

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const child = object[key];

        crawlObject(child, callback);

        callback(child, key);
      }
    }
  }
}

export default class CartOptimization {
  static handleResponse (items, itemMap, mutateInPlace: boolean = false) {
    crawlObject(items, obj => {
      if (obj && obj.itemId) {
        const fullItem = itemMap[obj.itemId];

        if (fullItem) {
          if (mutateInPlace) {
            Object.assign(obj, fullItem);
          } else {
            const properties = {};

            let keys = Object.keys(fullItem);

            for (let i = 0; i < keys.length; i++) {
              const key = keys[i];

              properties[key] = {
                configurable: true,
                enumerable: true,
                value: fullItem[key],
                writable: true
              };
            }

            keys = Object.keys(obj);

            for (let i = 0; i < keys.length; i++) {
              const key = keys[i];

              properties[key] = {
                configurable: true,
                enumerable: true,
                value: obj[key],
                writable: true
              };
            }

            const result = Object.create(fullItem, properties); // copy map item with cart item properties
            Object.assign(obj, result); // copy result to cart item
          }
        }
      }
    });
  }
}
