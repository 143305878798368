import MenuContainer from './menu/containers/MenuContainer.vue';
import CouponWalkthroughContainer from './coupons/containers/CouponWalkthroughContainer.vue';
import COUPONS_ROUTES from './coupons/coupons.routes';
import ERROR_ROUTES from './error/error.routes';
import ErrorContainer from './error/containers/ErrorContainer.vue';
import LandingContainer from './landing/containers/LandingContainer.vue';
import OrderContainer from './order/containers/OrderContainer.vue';
import ORDER_ROUTES from './order/order.routes';
import ProfileContainer from './profile/containers/ProfileContainer.vue';
import USER_ROUTES from './profile/profile.routes';
import RestaurantsContainer from './restaurants/containers/RestaurantsContainer.vue';
import RESTAURANTS_ROUTES from './restaurants/restaurants.routes';
import RewardsContainer from './rewards/containers/RewardsContainer.vue';
import REWARDS_ROUTES from './rewards/rewards.routes';
import SiteMapContainer from './sitemap/containers/SiteMapContainer.vue';
import MENU_ROUTES from './menu/menu.routes';

export default [
    {
        path: '/',
        redirect: { name: 'Menu' }
    },
    {
        path: '/coupons',
        name: 'CouponWalkthrough',
        component: CouponWalkthroughContainer,
        children: COUPONS_ROUTES
    },
    {
        path: '/error',
        name: 'Error',
        component: ErrorContainer,
        children: ERROR_ROUTES
    },
    {
        path: '/landing',
        name: 'Landing',
        component: LandingContainer
    },
    {
        path: '/menu',
        name: 'Menu',
        component: MenuContainer,
        children: MENU_ROUTES
    },
    {
        path: '/order',
        name: 'Order',
        component: OrderContainer,
        children: ORDER_ROUTES
    },
    {
        path: '/profile',
        name: 'Profile',
        component: ProfileContainer,
        children: USER_ROUTES
    },
    {
        path: '/restaurants',
        name: 'Restaurants',
        component: RestaurantsContainer,
        children: RESTAURANTS_ROUTES
    },
    {
        path: '/rewards',
        name: 'Rewards',
        component: RewardsContainer,
        children: REWARDS_ROUTES
    },
    {
        path: '/sitemap',
        name: 'SiteMap',
        component: SiteMapContainer
    }
];
