
  import Vue from 'vue';
  import designMixin from '../../common/mixins/design.mixin';
  import { formatPhoneNumber } from '../helpers/phone.helpers';

  const VuePhoneNumberInput = require('vue-phone-number-input/dist/vue-phone-number-input.common');

  VuePhoneNumberInput.computed.theme = () => {
    return {
    };
  };

  declare var $: any;

  export default Vue.extend({
    mixins: [designMixin],
    components: {
      VuePhoneNumberInput
    },
    props: {
      dataCy: String,
      ariaDescribedby: String,
      maxlength: Number,
      placeholder: String,
      required: Boolean,
      value: String
    },
    data() {
      return {
        formattedValue: null
      };
    },
    computed: {
      isValid() {
        return this.value && this.value.length === 10;
      },
      phone: {
        get() {
          return this.formattedValue;
        },
        set(value: string) {
          this.formattedValue = value;

          if (value) {
            value = formatPhoneNumber(value);
          }

          this.$emit('input', value);
        }
      }
    },
    created() {
      this.phone = this.value;
    },
    methods: {
      onBlur(event) {
        this.$emit('blur', event);
      }
    }
  });
