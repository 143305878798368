
  import Vue from 'vue';
  import MenuSidebarContainer from '../../menu/containers/MenuSidebarContainer.vue';
  import cart from '../../cart/models/Cart';
  import restaurants from '../../restaurants/models/Restaurants';
  import notifierService from '../services/notifier.service';
  import modalService from '../services/modal.service';
  import util from '../services/Util';
  import storageService from '../services/storage.service';
  import baseMixin from '../mixins/base-component.mixin';
  import {IMenuItem} from '../../menu/models/Item';
  import cartHelperService from '../../cart/services/cart-helper.service';
  import ModalModern from './ModalModern.vue';
  import { ICart } from '../../cart/cart.types';
  import { IUser } from '../../profile/stores/profile.store';
  import notificationService from '../../common/messaging/notification.service';
  import { SHOW_ALERT } from '../../common/messaging/notifications';
  import { ILoyalty, IRestaurant } from '../../restaurants/types/restaurant.types';
  import routeService from '../../common/services/route.service';
  import profile from '../../profile/models/Profile';
  import { IAccountInfo, IFeedbackRequest } from '../../account/types/account.types';
  import loyaltyService from '../../coupons/services/loyalty.service';
  import { routerLink } from '../directives/RouterLink';
  import '@fortawesome/fontawesome-pro';
  import loyaltyMixin from '../../coupons/mixins/loyalty.mixin';

  export default Vue.extend({
    mixins: [baseMixin, loyaltyMixin],

    directives: {
      routerLink
    },

    components: {
      MenuSidebarContainer,
      ModalModern
    },

    props: {
      accountInfo: {
        type: Object as () => IAccountInfo,
        required: true
      },

      cart: {
        type: Object as () => ICart,
        required: false
      },

      feedbackRequest: {
        type: Object as () => IFeedbackRequest,
        required: true
      },

      loyalty: {
        type: Object as () => ILoyalty,
        required: false
      },

      restaurantCount: {
        type: Number,
        required: false
      },

      selectedRestaurant: {
        type: Object as () => IRestaurant,
        required: false
      },

      showMobileCart: {
        type: Boolean,
        required: true
      },

      user: {
        type: Object as () => IUser,
        required: false
      }
    },

    computed: {
        // models

      _cart() {
        return cart;
      },

      _modalService() {
        return modalService;
      },

      _notifier() {
        return notifierService;
      },

      cartHelper() {
        return cartHelperService;
      },

      restaurants() {
        return restaurants;
      },

      // getters

      cartItemCount(): number {
        if (this.cart && this.cart.items) {
          return this.cart.items.reduce((count, item: IMenuItem) => {
            count += item.quantity;

            return count;
          }, 0);
        } else {
          return 0;
        }
      },

      isMobile(): boolean {
        return this.environment.isMobile;
      }
    },

    data() {
      return {
        _showMobileCart: false,
        cancelClearCartSubscription: null,
        clearCartSubscription: null,
        initialized: false,
        screenWidth: null,
        showChangeLink: true,
        windowResizeDebouncer: null
      };
    },

    watch: {
      showMobileCart(value) {
        this.$data._showMobileCart = value;
      }
    },

    created() {
      if (!!JSON.parse(storageService.sessionStorage.getItem('singleLocation'))) {
        this.showChangeLink = false;
      }

      this.initialized = true;
    },

    methods: {
      closeCart(): void {
        this.$emit('closeMobileCart');
      },

      endSubscriptions(): void {
        if (this.clearCartSubscription)
          this.clearCartSubscription.unsubscribe();

        if (this.cancelClearCartSubscription)
          this.cancelClearCartSubscription.unsubscribe();
      },

      getLoginText(): string {
        if (this.user && this.user.firstName) {
          return 'Profile';
        } else {
          if (this.isLoyaltyEnabled) {
            return 'Sign in to earn Rewards!';
          } else {
            return 'Sign In';
          }
        }
      },

      getMobileCartTicketWidth(): { 'width': string } {
        let width: string;

        if (this.screenWidth === 'xs') {
          width = '85%';
        } else if (this.screenWidth === 'sm') {
          width = '40%';
        }

        return {width};
      },

      getTitle(): string {
        if (this.hasLoyalty && this.loyalty && this.loyalty.programName) {
          return this.loyalty.programName + ' member';
        }

        return null;
      },

      hasClearCart(): boolean {
        return (!this.cart || !this.cart.items || this.cart.items.length <= 0) && (!this.cart || !this.cart.coupons || this.cart.coupons.length <= 0);
      },

      loadData(): boolean {
        return false;
      },

      navigate(route: string, data?: any) {
        if (this.selectedRestaurant && Object.keys(this.selectedRestaurant).length > 0) {
          this.routeService.route(route, data);
        } else {
          this._notifier.error('You must choose a location first!');
        }
      },

      navigateRewards() {
        this.routeService.route('RewardsList');
      },

      navigateToOrders() {
        this.navigate('Historical');
      },

      openFeedback() {
        this._modalService.feedbackModal$.emit(true);
      },

      register() {
        this.routeService.route('Register');
      },

      showAlert(message: string): void {
        notificationService.notify(SHOW_ALERT, { message });

        this.$emit('closeMobileCart');
      },

      toggleCart(): void {
        this.cartHelper.reviewOrder(true);
      },

      // event handlers

      // Private functions

      _setPageWidth(): void {
        let pageWidth: number = window.innerWidth,
          MQ = util.MEDIA_QUERIES;

        if (pageWidth < MQ.SM) {
          this.screenWidth = 'xs';
        } else if (pageWidth >= MQ.SM && pageWidth < MQ.MD) {
          this.screenWidth = 'sm';
        } else if (pageWidth >= MQ.MD && pageWidth < MQ.LG) {
          this.screenWidth = 'md';
        } else {
          this.screenWidth = 'lg';
        }
      },

      logInOrViewProfile(data) {
        if (profile.user) {
          routeService.route('User');
        } else {
          if (loyaltyService.linkOffer && routeService.currentRoute.name === 'SelectCouponItem') {
            if (!data) {
              data = {};
            }

            data.source = routeService.currentRoute.name;
          }

          this.logIn(data);
        }
      },

      // styling

      getHeaderLogo(): any {
        return this._designService.getDesign().headerLogo;
      }
    }
  });
