export class StorageAlternative {
    public storage: any = {};

    getItem(key: string): string {
        return typeof this.storage[key] !== 'undefined' ? this.storage[key] : null;
    }

    removeItem(key: string): void {
        this.storage[key] = undefined;
    }

    setItem(key: string, value: any): void {
        this.storage[key] = value;
    }
}

export class StorageService {
    public localStorage;
    public sessionStorage;

    constructor() {
        try {
            localStorage.setItem('storage', '');
            localStorage.removeItem('storage');
            this.localStorage = localStorage;
            this.sessionStorage = sessionStorage;
        } catch (e) {
            this.localStorage = new StorageAlternative();
            this.sessionStorage = new StorageAlternative();
        }
    }
}

export default new StorageService();
