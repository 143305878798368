import baseMixin from '../../common/mixins/base-component.mixin';
import StateSelect from '../../common/components/StateSelect.vue';
import TolSelect from '../../common/components/TOLSelect.vue';
import {IAddress} from '../../common/models/Address';
import {emptyAddress, ICreditCardInfo, ITenderRequest} from '../models/TenderRequest';
import {ICreditCard} from '../../common/models/CreditCard';
import { IDeleteCardRequest } from '../../profile/profile.bus';
import designService from '../../common/services/design.service';
import util from '../../common/services/Util';
import maps from '../../maps/models/Maps';
import profileBus from '../../profile/profile.bus';
import { IUser } from '../../profile/stores/profile.store';
// import mapsService from '../../maps/services/maps.service';
import { IRestaurant } from '../../restaurants/types/restaurant.types';
import { hasEmojis, isValidName } from '../../common/helpers/text.helpers';
const _has = require('lodash/has');

const moment = require('moment');

export default {
    mixins: [baseMixin],

    components: {
        StateSelect,
        TolSelect
    },

    props: {
        addresses: {
            type: Array as () => Array<IAddress>,
            required: false
        },

        billingAddressOnly: {
            type: Boolean,
            required: true
        },

        cardInfo: {
            type: Object as () => ICreditCardInfo,
            required: true
        },

        creditCards: {
            type: Array as () => Array<ICreditCard>,
            required: false
        },

        isDeliveryOrder: {
            type: Boolean,
            required: true
        },

        restaurant: {
            type: Object as () => IRestaurant,
            required: true
        },

        suggestedAddresses: {
            type: Array as () => Array<IAddress>,
            required: false
        },

        tenderRequest: {
            type: Object as () => ITenderRequest,
            required: true
        },

        user: {
            type: Object as () => IUser,
            required: false
        },

        isHostedPaymentVisible: {
          type: Boolean,
          required: true
        }
    },
    computed: {
        // models

        _profileBus () {
            return profileBus;
        },

        designService () {
            return designService;
        },

        util() {
            return util;
        }
    },
    data() {
        return {
            enterNewBillingAddress: false,
            enterNewCreditCard: false,
            saveBillingAddress: false,
            saveCard: false,
            selectedBillingAddress: null, // IAddress,
            selectedCreditCard: null, // ICreditCard,
            updatedExpirationDate: {
                month: '',
                year: ''
            },
            useDeliveryAddressForBillingAddress: false,
            valid: true,
            validBillingAddress: true,
            validCardExpirationDate: true,
            validCardName: true,
            validCreditCard: true,
            validCVV: true,
            validCVVLength: true
        };
    },
    mounted () {
        if (!this.billingAddressOnly && this.creditCards && this.creditCards.length > 0) {
            this.selectedCreditCard = JSON.parse(JSON.stringify(this.creditCards[0]));
        } else {
            this.enterNewCreditCard = true;
        }

        if (this.addresses && this.addresses.length > 0) {
            this.selectedBillingAddress = JSON.parse(JSON.stringify(this.addresses[0]));
        } else {
            this.enterNewBillingAddress = true;
        }

        this.setBillingAddress();
    },
    methods: {
        setBillingAddress(): void {
            if (this.useDeliveryAddressForBillingAddress) {
                this.tenderRequest.billingAddress = Object.assign({}, this.tenderRequest.deliveryAddress);
            } else if (this.selectedBillingAddress) {
                this.tenderRequest.billingAddress = Object.assign({}, this.selectedBillingAddress);
            } else {
                this.tenderRequest.billingAddress = emptyAddress();
            }
        },

        toggleUseDeliveryAddressForBillingAddress() {
            this.useDeliveryAddressForBillingAddress = !this.useDeliveryAddressForBillingAddress;

            this.setBillingAddress();
        },

        isSelectedCard(creditCard: ICreditCard): boolean {
            return this.selectedCreditCard && this.selectedCreditCard.objectId === creditCard.objectId;
        },

        changeCard(creditCard: ICreditCard, event) {
            if (event.target.checked) {
                this.selectedCreditCard = creditCard;
                this.enterNewCreditCard = false;
                this.cardInfo.cvvCode = '';
                this.cardInfo.expirationDate = {
                    month: '',
                    year: ''
                };

                if (this.selectedCreditCard.billing && this.selectedCreditCard.billing.objectId) {
                    this.selectedBillingAddress = JSON.parse(JSON.stringify(this.selectedCreditCard.billing));
                    this.enterNewBillingAddress = false;
                }
            }
        },

        deleteCard(creditCardId: string): void {
            this.selectedCreditCard = null;

            let payload: IDeleteCardRequest = {
                userId: this.user.objectId,
                creditCardId
            };

            this._profileBus.deleteCard$.next(payload);
        },

        shouldRequireCvv() {
            return !this.enterNewCreditCard && this.selectedCreditCard && this.restaurant.cvvRequired;
        },

        getAlertStyle(): any {
            return this._designService.getAlertStyling();
        },

        isNewCardSelected(): boolean {
            return this.enterNewCreditCard;
        },

        changeCardToNew(event) {
            if (event.target.checked) {
                this.enterNewCreditCard = true;
                this.selectedCreditCard = undefined;
                this.cardInfo.cvvCode = '';
                this.cardInfo.expirationDate = {
                    month: '',
                    year: ''
                };
                this.tenderRequest.creditCardId = null;
            }
        },

        toggleSaveCard() {
            this.saveCard = !this.saveCard;
        },

        isSelectedBillingAddress(address: IAddress): boolean {
            return this.selectedBillingAddress && this.selectedBillingAddress.objectId === address.objectId;
        },

        changeBillingAddress(address: IAddress, event) {
            if (event.target.checked) {
                this.selectedBillingAddress = address;
                this.enterNewBillingAddress = false;

                this.setBillingAddress();
            }
        },

        isNewBillingAddressSelected(): boolean {
            return this.enterNewBillingAddress;
        },

        changeBillingAddressToNew(event) {
            if (event.target.checked) {
                if (_has(this.tenderRequest, 'billingAddress.objectId')) {
                  this.tenderRequest.billingAddress.objectId = null;
                }

                this.enterNewBillingAddress = true;
                this.selectedBillingAddress = undefined;
            }
        },

        chooseSuggestedAddress(address: IAddress) {
            if (!address) {
                return;
            }

            this.tenderRequest.billingAddress = address;
            this.selectedBillingAddress = undefined;
            this.enterNewBillingAddress = true;
            this.$emit('clearSuggestedAddresses', null);
        },

        getFormattedAddress(address: any) {
            return maps.getFormattedAddress(address);
        },

        toggleSaveBillingAddress() {
            this.saveBillingAddress = !this.saveBillingAddress;
        },

        validate (): boolean {
            let valid = true;

            if (!this.selectedCreditCard) {
                if (this.enterNewCreditCard) {
                    if (!this.billingAddressOnly) {
                        valid = valid && this.validateCardNumber();
                        valid = valid && this.validateCardExpirationDate();
                    }
                }
            }

            if (this.selectedCreditCard && this.selectedCreditCard.expired) {
                valid = valid && this.validateCardExpirationDate();
            }

            if (this.restaurant.cvvRequired && (!this.billingAddressOnly || this.selectedCreditCard)) {
                valid = valid && this.validateCVV();
            }

            if (!this.useDeliveryAddressForBillingAddress) {
                if (!this.selectedCreditCard) {
                    valid = valid && this.validateCardName();

                    if (this.enterNewBillingAddress) {
                        valid = valid && this.validateBillingAddress();
                    }
                }
            }

            this.valid = valid;

            return valid;
        },

        validateCardNumber() {
            let card: string = this.cardInfo.cardNumber.replace(/\s/g, '');

            this.validCreditCard = util.mod10(card);

            return this.validCreditCard;
        },

        validateCardExpirationDate() {
            let month: number = moment().month() + 1;
            let year: number = moment().year();
            let cardMonth: string;
            let cardYear: string;

            if (this.selectedCreditCard && this.selectedCreditCard.expired) {
                cardMonth = this.updatedExpirationDate.month;
                cardYear = this.updatedExpirationDate.year;
            } else {
                cardMonth = this.cardInfo.expirationDate.month;
                cardYear = this.cardInfo.expirationDate.year;
            }

            if (!cardMonth || !cardYear) {
                return false;
            }

            this.validCardExpirationDate = (parseInt(cardMonth, 10) >= month
                || parseInt(cardYear, 10) > year)
                && cardMonth !== '' && cardYear !== '';

            return this.validCardExpirationDate;
        },

        validateCVV() {
            if (this.enterNewCreditCard) {
                this.validCVV = !(!this.cardInfo.cvvCode && this.restaurant.cvvRequired);
                this.validCVVLength = /^[0-9]{3,4}$/.test(this.cardInfo.cvvCode);
            } else {
                this.validCVV = !!(this.selectedCreditCard.cvv
                    || this.cardInfo.cvvCode
                    || !this.restaurant.cvvRequired);
                this.validCVVLength = /^[0-9]{3,4}$/.test(
                    this.selectedCreditCard.cvv || this.cardInfo.cvvCode
                );
            }

            return this.validCVV && this.validCVVLength;
        },

        validateBillingAddress(): boolean {
            let address: IAddress = this.tenderRequest.billingAddress;

            return this.validBillingAddress = address.addressLine !== '' && address.city !== ''
                && address.stateCode !== '' && address.postalCode !== ''
                && !hasEmojis(address.addressLine) && !hasEmojis(address.addressLine2)
                && !hasEmojis(address.city) && !hasEmojis(address.stateCode)
                && !hasEmojis(address.postalCode);
        },

        validateCardName() {
            return this.validCardName = isValidName(this.cardInfo.cardholderName);
        },
    },
    watch: {
      enterNewCreditCard(newValue, oldValue) {
        if (newValue !== oldValue) {
            this.$emit('changeNewCard', newValue);
        }
      }
    }
};
