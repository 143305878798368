import {IMenuItem, IRequiredItem, IRequiredOption} from '../models/Item';
import {getMaxRequirements} from '../helpers/item-customization.helpers';
import { isRequiredItemInStock } from '../../cart/helpers/cart.helpers';
import { ICart } from '../../cart/cart.types';

export type RequiredItemPath = {
    itemId?: string;
    nestedItemPath?: RequiredItemPath;
    objectId?: string;
    optionId: string;
};

function matchRequiredItemById(item: IRequiredItem, requiredItemPath: RequiredItemPath) {
    if (item.requirementId !== requiredItemPath.optionId) {
        return false;
    }

    if (requiredItemPath.objectId && requiredItemPath.objectId !== item.objectId) {
        return false;
    }

    if (requiredItemPath.itemId && requiredItemPath.itemId !== item.itemId) {
        return false;
    }

    return true;
}

export function getRequiredItemsFromPath(requiredItemPath: RequiredItemPath, requiredItem: IRequiredItem | IMenuItem): IRequiredItem[] {
    // get all required items that match option id and item id
    const requiredItems = requiredItem.requiredItems.filter(item => {
        return matchRequiredItemById(item, requiredItemPath);
    });

    // find nested requirements
    if (requiredItemPath.nestedItemPath) {
        let result: IRequiredItem[] = [];

        requiredItems.forEach(item => {
            result = result.concat(getRequiredItemsFromPath(requiredItemPath.nestedItemPath, item));
        });

        return result;
    } else {
        return requiredItems;
    }
}

export function getRequiredOptionFromPath(requiredItemPath: RequiredItemPath, requiredItem: IRequiredItem | IMenuItem): IRequiredOption {
    if (requiredItemPath.nestedItemPath) {
        // get all required item that matches option id and item id
        requiredItem = requiredItem.requiredItems.find(item => {
            return matchRequiredItemById(item, requiredItemPath);
        });

        if (!requiredItem) {
            return null; // should never happen
        }

        // find nested required option
        return getRequiredOptionFromPath(requiredItemPath.nestedItemPath, requiredItem);
    } else {
        return requiredItem.requiredOptions.find(option => option.objectId === requiredItemPath.optionId);
    }
}

function getParentItemIdFromPath(requiredItemPath: RequiredItemPath, rootItem: IMenuItem | IRequiredItem) {
    if (requiredItemPath.nestedItemPath) {
        const requiredItems = getRequiredItemsFromPath(Object.assign({}, requiredItemPath, { nestedItemPath: undefined }), rootItem);

        if (requiredItems.length > 0) {
            return getParentItemIdFromPath(requiredItemPath.nestedItemPath, requiredItems[0]);
        } else {
            return null;
        }
    } else {
        return rootItem.objectId;
    }
}

export default class RequirementsManager {
    _item: IMenuItem;

    constructor(item: IMenuItem) {
        this.item = item;
    }

    get item() {
        return this._item;
    }

    set item(value) {
        this._item = value;
    }

    get requirements() {
        return this.item.requiredItemsFlat;
    }

    getQuantity(requiredItemPath: RequiredItemPath): number {
        return getRequiredItemsFromPath(requiredItemPath, this.item).length;
    }

    hasRequirement(requiredItemPath: RequiredItemPath): boolean {
        return this.getQuantity(requiredItemPath) > 0;
    }

    isRequiredItemInStock(cart: ICart, requiredItem: IRequiredItem): boolean {
        return isRequiredItemInStock(cart, this.item, requiredItem);
    }

    // use existing required item instances with objectId when removing
    getRequirement(requiredItemPath: RequiredItemPath): IRequiredItem {
        const requiredItems = getRequiredItemsFromPath(requiredItemPath, this.item);

        if (requiredItems.length > 0) {
            return requiredItems[0];
        } else {
            return null;
        }
    }

    add(cart: ICart, requiredItemPath: RequiredItemPath, requiredItem: IRequiredItem): boolean {
        if (!this.isRequiredItemInStock(cart, requiredItem)) {
            return false;
        }

        const option = getRequiredOptionFromPath(requiredItemPath, this.item);
        const parentId = getParentItemIdFromPath(requiredItemPath, this.item);

        // only 1 required item allowed by required option so remove other items for the same option
        if (getMaxRequirements(option) === 1) {
            this.item.requiredItemsFlat = this.item.requiredItemsFlat.filter(i => {
                return !(i.requirementId === option.objectId && i.parentId === parentId);
            });
        } else if (!this.canIncreaseQuantity(requiredItemPath)) {
            return false;
        }

        this.item.requiredItemsFlat.push(Object.assign({}, requiredItem, {
            objectId: undefined,
            parentId,
            requirementId: option.objectId
        }));

        return true;
    }

    remove(requiredItemPath: RequiredItemPath) {
        const requiredItems = getRequiredItemsFromPath(requiredItemPath, this.item);

        if (requiredItems.length === 0) {
            return false;
        }

        const parentId = getParentItemIdFromPath(requiredItemPath, this.item);

        requiredItems.forEach(item => {
            const index: number = this.item.requiredItemsFlat.findIndex(r => {
                return r.objectId === item.objectId && r.parentId === parentId;
            });

            if (index > -1) {
                this.item.requiredItemsFlat.splice(index, 1);
            }
        });
    }

    increaseQuantity(requiredItemPath: RequiredItemPath, requiredItem: IRequiredItem) {
        if (this.canIncreaseQuantity(requiredItemPath)) {
            const option = getRequiredOptionFromPath(requiredItemPath, this.item);
            const parentId = getParentItemIdFromPath(requiredItemPath, this.item);

            this.item.requiredItemsFlat.push(Object.assign({}, requiredItem, {
                objectId: undefined,
                parentId,
                requirementId: option.objectId
            }));

            return true;
        } else {
            return false;
        }
    }

    decreaseQuantity(requiredItemPath: RequiredItemPath) {
        const requiredItem = this.getRequirement(requiredItemPath);

        if (!requiredItem) {
            return false;
        }

        const index = this.item.requiredItemsFlat.findIndex(r => r.objectId === requiredItem.objectId);

        this.item.requiredItemsFlat.splice(index, 1);

        return true;
    }

    // private

    canIncreaseQuantity(requiredItemPath: RequiredItemPath) {
        const option = getRequiredOptionFromPath(requiredItemPath, this.item);

        if (option.upTo === 2147483647) {
            return true;
        }

        const itemQuantity: number = this.getQuantity(requiredItemPath);

        if ((itemQuantity + 1) > getMaxRequirements(option)) {
            return false;
        } else {
            const requiredItems = getRequiredItemsFromPath(requiredItemPath, this.item);
            const totalQuantity = requiredItems.length;

            if ((totalQuantity + 1) > getMaxRequirements(option)) {
                return false;
            }
        }

        return true;
    }
}
