import {IRequiredOption} from '../models/Item';

export default {
    data () {
        return {
            selectedTabId: null
        };
    },
    methods: {
        getTabId(name: string): string {
            let str: string = name.replace(/\s+/g, '');
            str = str.replace(/[.,\/#!$%\^&*?;:{}=\-_`~()]/g, '');

            return `Tab-${str}Heading`;
        },

        getTabSelector(name: string): string {
            let str: string = name.replace(/\s+/g, '');
            str = str.replace(/[.,\/#!$%\^&*?;:{}=\-_`~()]/g, '');

            return `#Tab-${str}Heading`;
        },

        getTabTitle(option: IRequiredOption): string {
            let str: string = option.name;

            if (option.numChoices > 0) {
                str += ` (pick ${option.numChoices})`;
            }

            str += ` ${this.getRequirementOptionFor(option)}`;

            return str;
        }
    }
};
