import {IMenuItem} from '../../menu/models/Item';
import cart from '../../cart/models/Cart';
import subscriberMixin from './subscriber.mixin';
import order from '../../order/models/Order';
import profile from '../../profile/models/Profile';
import cartHelperService, { CartHelperService } from '../../cart/services/cart-helper.service';

export default {
  mixins: [subscriberMixin],
  computed: {
    // models

    cart() {
      return cart;
    },

    order() {
      return order;
    },

    profile() {
      return profile;
    },

    cartHelperService(): CartHelperService {
      return cartHelperService;
    },

    // getters

    cartItemCount(): number {
      if (this.cartObj && this.cartObj.items) {
        return this.cartObj.items.reduce((count, item: IMenuItem) => {
          count += item.quantity;

          return count;
        }, 0);
      } else {
        return 0;
      }
    },

    cartObj() {
      return cart.cart;
    },

    hasCartItems() {
      return this.cartItemCount > 0;
    },

    historicalOrders() {
      return this.order.historicalOrders;
    },

    isRepeatLastOrderVisible() {
      return this.user && this.user.objectId &&
        this.historicalOrders.length > 0 &&
        this.cartItemCount === 0;
    },

    user() {
      return this.profile.user;
    }
  },
  methods: {
    repeatLastOrder() {
      if (this.historicalOrders.length > 0) {
        this.cart.reorder(this.historicalOrders[0].objectId);
      }
    }
  }
};
