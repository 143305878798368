
  import Vue from 'vue';
  import baseMixin from '../../common/mixins/base-component.mixin';
  import {IAddress} from '../../common/models/Address';
  import { IFulfilledCoupon, IMenuItem } from '../models/Item';
  import restaurants from '../../restaurants/models/Restaurants';
  import {ITenderRequest} from '../../order/models/TenderRequest';
  import {Util} from '../../common/services/Util';
  import {ICoupon} from '../../coupons/models/Coupon';
  import modalService from '../../common/services/modal.service';
  import notifierService from '../../common/services/notifier.service';
  import menuBus from '../menu.bus';
  import modalBus from '../../common/messaging/modal.bus';
  import storageService from '../../common/services/storage.service';
  import order from '../../order/models/Order';
  import ChangeLocationModal from '../../common/components/ChangeLocationModal.vue';
  import ConfirmCouponRemovalModal from './ConfirmCouponRemovalModal.vue';
  import CouponModal from '../../coupons/components/CouponModal.vue';
  import CustomizeModal from './CustomizeModal.vue';
  import DeliveryModal from '../../common/components/DeliveryModal.vue';
  import ItemUnavailableModal from './ItemUnavailableModal.vue';
  import OrderTypeModal from './OrderTypeModal.vue';
  import ScheduleOrderModal from '../../order/components/ScheduleOrderModal.vue';
  import SuggestionsModal from './SuggestionsModal.vue';
  import UnableToPriceModal from './UnableToPriceModal.vue';
  import { IItemDesign } from '../stores/menu.store';
  import { ICart } from '../../cart/cart.types';
  import { IUser } from '../../profile/stores/profile.store';
  import ErrorModal from '../../common/components/ErrorModal.vue';
  import { IRestaurant, ORDER_TYPES } from '../../restaurants/types/restaurant.types';
  import loyaltyService from '../../coupons/services/loyalty.service';

  export default Vue.extend({
    mixins: [baseMixin],
    components: {
      ItemUnavailableModal,
      ChangeLocationModal,
      ConfirmCouponRemovalModal,
      CouponModal,
      CustomizeModal,
      DeliveryModal,
      ErrorModal,
      OrderTypeModal,
      ScheduleOrderModal,
      SuggestionsModal,
      UnableToPriceModal
    },
    props: {
      addresses: {
        type: Array as () => Array<IAddress>,
        required: false
      },
      cart: {
        type: Object as () => ICart,
        required: true
      },
      couponToRemove: {
        type: Object as () => IFulfilledCoupon,
        required: false
      },
      currentSuggestions: {
        type: Array as () => IMenuItem[],
        required: false
      },
      currentTime:{
        type: String,
        required: true
      },
      defaultItemDesign: {
        type: Object as () => IItemDesign,
        required: true
      },
      restaurantCount: {
        type: Number,
        required: true
      },
      selected: {
        type: Boolean,
        required: true
      },
      selectedItem: {
        type: Object as () => IMenuItem,
        required: false
      },
      selectedRestaurant: {
        type: Object as () => IRestaurant,
        required: true
      },
      sizeMap: {
        type: Object,
        required: true
      },
      suggestedAddresses: {
        type: Array as () => Array<IAddress>,
        required: false
      },
      tenderRequest: {
        type: Object as () => ITenderRequest,
        required: true
      },
      updating: {
        type: Boolean,
        required: true
      },
      user: {
        type: Object as () => IUser,
        required: false
      }
    },
    computed: {
      // services

      _menuBus() {
        return menuBus;
      },

      _modalBus() {
        return modalBus;
      },

      _modalService() {
        return modalService;
      },

      _notifierService() {
        return notifierService;
      },

      order() {
        return order;
      },

      restaurants() {
        return restaurants;
      },

      // refs

      clearCartModal() {
        return this.$refs.clearCartModal;
      },

      confirmItemAddModal() {
        return this.$refs.confirmItemAddModal;
      },

      confirmCouponRemovalModal() {
        return this.$refs.confirmCouponRemovalModal;
      },

      unableToPriceItemModal() {
        return this.$refs.unableToPriceItemModal;
      }
    },
    data() {
      return {
        orderTypeIntervalId: 0,
        orderTypeIntervalId2: 0
      };
    },
    created() {
      this.subscribe(this._modalService.clearCartModal$, () => {
        this.clearCartModal.open();
        this._designService.setModalStyling();
      });

      this.subscribe(this._modalService.removeCouponModal$, (coupon: IFulfilledCoupon) => {
        this.$emit('setCouponToRemove', coupon);
        this.confirmCouponRemovalModal.open();
        this._designService.setModalStyling();
      });

      this.subscribe(this._modalService.selectItemModal$, (item: IMenuItem) => {
        this.$emit('setSelectedItem', item);

        this.confirmItemAddModal.open();

        this._designService.setModalStyling();
      });

      this.subscribe(this._modalService.unableToPriceItemModal$, (item: IMenuItem) => {
        this.unableToPriceItemModal.open(item);
        this._designService.setModalStyling();
      });

      this.subscribe(this._modalService.errorModal$, errors => {
        this.$refs.errorModal.open(errors);
      });
    },
    mounted: function () {
      let deliveryAddress = storageService.sessionStorage.getItem('deliveryAddress');

      if (deliveryAddress) {
        deliveryAddress = JSON.parse(deliveryAddress);
      }

      const configureTenderRequestOrOpenOrderTypeModal = () => {
        const orderTypesCount = Object.values(this.selectedRestaurant.orderTypes)
          .filter((type: any) => type.active)
          .length;

        if (deliveryAddress) {
          this.configureTenderRequestForDelivery(deliveryAddress);
        } else if (!loyaltyService.linkOffer
          && this.cart.orderType
          && this.restaurants.cameFromLocationFinder
          && this.cart.orderType !== ORDER_TYPES.DELIVERY
          && orderTypesCount > 2
        ) {
          this.openOrderTypeModal();
        } else if (!this.cart.orderType) {
          this.openOrderTypeModal();
        }

        // always reset this so it doesn't open the order type modal later
        if (this.restaurants.cameFromLocationFinder) {
          restaurants.cameFromLocationFinder = false;
        }
      };

      if (Util.isEmpty(this.cart)) {
        this.orderTypeIntervalId = setInterval(() => {
          if (!this.cart) {
            return;
          }

          if (!this.cart.hasOwnProperty('size')) {
            clearInterval(this.orderTypeIntervalId);

            configureTenderRequestOrOpenOrderTypeModal();
          }
        }, 250);
      } else {
        this.orderTypeIntervalId2 = setInterval(() => {
          if (!this.cart) {
            return;
          }

          if (this.cart.locationId === this.restaurants.selectedRestaurantId) {
            clearInterval(this.orderTypeIntervalId2);

            configureTenderRequestOrOpenOrderTypeModal();
          }
        }, 250);
      }

      let modal = this.$route.params.modal;

      if (modal === 'delivery') {
        this.deliveryModal.openDeliveryModal();
      }
    },
    methods: {
      configureTenderRequestForDelivery(address: IAddress) {
        this.tenderRequest.deliveryAddress = address;
        this.order.update(this.tenderRequest);
      },

      openOrderTypeModal(): void {
        this._modalService.selectOrderTypeModal();
      },

      cancelClearCart() {
        this.clearCartModal.close();
        this._modalService.cancelClearCart();
      },

      clearCart(): void {
        setTimeout(() => {
          if (this.clearCartModal) {
            this.clearCartModal.close();
          }
        }, 300);

        this._modalService.clearCart();
      },

      chooseRestaurant(): void {
        this.$emit('changeRestaurant', null);
      },

      // event handlers

      onClearCartModalClose() {
        this._modalService.cancelClearCart();
      },

      setSelectedMenuItem(item: IMenuItem) {
        this.$emit('setSelectedItem', item);
      },

      clearSuggested(event) {
        this.$emit('clearSuggestedAddresses', event);
      },

      removeZone(event) {
        this.$emit('removeDeliveryZone', event);
      },

      saveDeliveryAddress(event) {
        this.$emit('saveAddress', event);
      },

      setZone(event) {
        this.$emit('setDeliveryZone', event);
      },

      setSuggested(event) {
        this.$emit('setSuggestedAddresses', event);
      },

      removeCoupon(coupon: ICoupon) {
        this.$emit('setCouponToRemove', coupon);
      },

      // Styling

      getButtonStyle(): any {
        return this._designService.getButtonStyle();
      },

      getButtonClass(): any {
        return this._designService.getButtonClass();
      },

      getHeaderStyle(): any {
        return {
          color: this._designService.backgroundColor,
          'font-family': this._designService.getDesign().fontHeadline,
          'font-size': 'large'
        };
      },

      getText(): any {
        return {
          color: this._designService.backgroundColor,
          'font-family': this._designService.getDesign().fontBody
        };
      },

      getAlertStyle(): any {
        return this._designService.getAlertStyling();
      },

      getLinkStyle(): any {
        return {
          color: this._designService.tertiaryColor,
          'text-decoration': 'underline'
        };
      }
    },
    destroyed() {
      if (this.orderTypeIntervalId) {
        clearInterval(this.orderTypeIntervalId);
      }

      if (this.orderTypeIntervalId2) {
        clearInterval(this.orderTypeIntervalId2);
      }
    }
  });
