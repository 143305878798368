import { Util } from '../../common/services/Util';

export function sortLocations(restaurantList, userInputOrigin, restaurantDistances) {
  let postalCode;

  if (userInputOrigin) {
    postalCode = Util.normalizePostalCode(userInputOrigin);
  }

  return restaurantList.slice(0).sort((a, b) => {
    if (postalCode) {
      const aPostalCode = Util.normalizePostalCode(a.address.postalCode);
      const bPostalCode = Util.normalizePostalCode(b.address.postalCode);

      if (aPostalCode !== bPostalCode) {
        if (aPostalCode === postalCode) {
          return -1;
        } else if (bPostalCode === postalCode) {
          return 1;
        }
      }
    }

    let aDistance = restaurantDistances[a.objectId];
    let bDistance = restaurantDistances[b.objectId];

    if (isNaN(aDistance)) {
      aDistance = 10000;
    }

    if (isNaN(bDistance)) {
      bDistance = 10000;
    }

    if (aDistance < bDistance) {
      return -1;
    } else if (aDistance > bDistance) {
      return 1;
    } else {
      return 0;
    }
  });
}
