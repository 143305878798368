import { formatCurrency } from '../filters/FormatCurrency';
import decodeText from '../filters/DecodeText';
import titleCase from '../filters/TitleCase';

export default {
  methods: {
    formatCurrency(val, currency, format) {
      return formatCurrency(val, currency, format);
    },

    decodeText(val) {
      return decodeText(val);
    },

    titleCase(val) {
      return titleCase(val);
    }
  }
};
