import {Address, IAddress} from '../../common/models/Address';
import {AxiosRequestConfig, AxiosResponse} from 'axios';

const axios = require('axios').default;

export class MapsService {
  request(request: AxiosRequestConfig): Promise<AxiosResponse> {
    return axios({
      method: 'get',
      ...request
    });
  }

  handleResponse(response: AxiosResponse): IAddress[] {
    let result = response.data;

    if (!result || !result.geocodes) {
      throw null;
    }

    result = result.geocodes;

    result = result.map(item => new Address(item.address));

    return result;
  }

  // get results
  geocode(address: string): Promise<IAddress[]> {
    return this.request({url: '/slice/geocoding/geocode/' + encodeURIComponent(address)}).then(response => {
      return this.handleResponse(response);
    });
  }

  geocodeAddress(address: IAddress): Promise<IAddress[]> {
    const params = {
      street: address.addressLine,
      city: address.city,
      state: address.stateCode,
      postalCode: address.postalCode
    };

    return this.request({url: '/slice/geocoding/geocode', params}).then(response => {
      return this.handleResponse(response);
    });
  }
}

export default new MapsService();
