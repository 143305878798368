
  import Vue from 'vue';
  import subscriberMixin from '../../common/mixins/subscriber.mixin';
  import designMixin from '../../common/mixins/design.mixin';
  import notificationService from '../../common/messaging/notification.service';
  import designService from '../../common/services/design.service';
  import modalService from '../../common/services/modal.service';
  import notifierService from '../../common/services/notifier.service';
  import storageService from '../../common/services/storage.service';
  import cartHelperService from '../../cart/services/cart-helper.service';
  import googleService from '../../common/services/google.service';
  import {Util} from '../../common/services/Util';
  import {ITenderRequest} from '../../order/models/TenderRequest';
  import modalBus from '../../common/messaging/modal.bus';
  import {MODAL_ACTIONS} from '../../common/common.constants';
  import { ICart } from '../../cart/cart.types';
  import { IUser } from '../../profile/stores/profile.store';
  import { IOrderType, IRestaurant, ORDER_TYPES } from '../../restaurants/types/restaurant.types';

  const moment = require('moment');

  export default Vue.extend({
    mixins: [subscriberMixin, designMixin],
    props: {
      cart: {
        type: Object as () => ICart,
        required: true
      },
      cartError: String,
      currentTime: String,
      restaurant: {
        type: Object as () => IRestaurant,
        required: true
      },
      tenderRequest: {
        type: Object as () => ITenderRequest,
        required: true
      },
      updating: {
        type: Boolean,
        required: true
      },
      user: Object as () => IUser
    },

    computed: {
      // services

      _cartHelper() {
        return cartHelperService;
      },

      _designService() {
        return designService;
      },

      _googleService() {
        return googleService;
      },

      _modalService() {
        return modalService;
      },

      _modalBus() {
        return modalBus;
      },

      _notifierService() {
        return notifierService;
      },

      notificationService() {
        return notificationService;
      },

      // getters

      isDelivery(): boolean {
        return this.orderType === ORDER_TYPES.DELIVERY;
      },

      isOrderScheduled(): boolean {
        return !!this.cart.orderTimeString;
      },

      isSchedulingAllowed(): boolean {
        return this.restaurant.allowScheduling;
      },

      orderType(): string {
        return this.cart.orderType;
      },

      orderTypes(): Object {
        return this.restaurant.orderTypes;
      }
    },

    data() {
      return {
        isOrderTimeValid: false,
        mouseOverType: false,
        showOrderTypeDiv: false,
        uniqueId: Math.floor(Math.random() * 1000)
      };
    },

    watch: {
      cart() {
        this.updateIsOrderTimeValid();
      },

      currentTime() {
        this.updateIsOrderTimeValid();
      },

      restaurant() {
        this.updateIsOrderTimeValid();
      }
    },

    created() {
      this.updateIsOrderTimeValid();
    },

    methods: {
      updateIsOrderTimeValid() {
        this.isOrderTimeValid = this._cartHelper.isOrderTimeValid();
      },

      shouldShowError(): boolean {
        return this.cartError && this.cartError.indexOf('Order Time Invalid: ') >= 0;
      },

      getCartError(): string {
        if (this.cartError && this.cartError.indexOf(':') >= 0) {
          return this.cartError.substring(this.cartError.indexOf(':') + 1);
        }

        return '';
      },

      getExpectedTime(): string {
        if (!this.cart || !this.cart.expectedTimeString)
          return Util.formatDate(moment(this.currentTime));

        return Util.formatDate(moment(this.cart.expectedTimeString));
      },

      getCurrentTime(): string {
        return Util.formatDate(moment(this.currentTime));
      },

      getOrderType() {
        if (!this.cart || !this.orderType || !this.restaurant || !this.orderTypes)
          return '';

        if (this.cart.locationId !== JSON.parse(storageService.localStorage.getItem('selectedRestaurant')))
          return '';

        return this.orderTypes[this.orderType];
      },

      toggleSetOrderType() {
        this.showOrderTypeDiv = !this.showOrderTypeDiv;
        this.mouseOverType = false;
      },

      getOrderTypeChangeText(): string {
        if (this.showOrderTypeDiv) {
          return '(Close)';
        } else {
          return '(Change)';
        }
      },

      chooseOrderType(type: IOrderType) {
        if (type.objectId === ORDER_TYPES.DELIVERY) {
          this.$emit('closeOrderSettingsModal');
        }

        this.$emit('chooseType', {
          restaurantId: this.restaurant.objectId,
          orderTypeId: type.objectId
        });

        this.showOrderTypeDiv = false;
      },

      updateDeliveryZone(result) {
        window.setTimeout(() => {
          if (this.updating) {
            this.updateDeliveryZone(result);

            return;
          }

          this.$emit('setDeliveryZone', result);
        }, 50);
      },

      getOrderTypesAsArray(): IOrderType[] {
        let values: IOrderType[] = [];

        if (this.orderTypes) {
          for (let key of Object.keys(this.orderTypes))
            values.push(this.orderTypes[key]);
        }

        return values;
      },

      scheduleOrder(): void {
        this.$emit('closeOrderSettingsModal');

        /* Show OrderType Dialog if no OrderType is selected */
        if (!this.orderType) {
          this._modalBus.selectOrderTypeModal$.next(MODAL_ACTIONS.OPEN);

          this._modalService.selectOrderType$.take(1).
          subscribe((orderType) => {
              if (orderType) {
                this._modalService.openScheduleOrderModal();
              }
            }
          );
        } else {
          this._modalService.openScheduleOrderModal();
        }
      },

      // Styling Legacy

      getButtonClass(): any {
        return this._designService.getButtonClass();
      },

      getButtonStyle(): any {
        return this._designService.getButtonStyle();
      },

      getSidebarStyle(): any {
        return this._designService.getSidebarStyle();
      }
    }
  })
