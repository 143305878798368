
    import Vue from 'vue';
    import util from '../services/Util';
    import SelectModern from './SelectModern.vue';
    import designMixin from '../mixins/design.mixin';

    export default Vue.extend({
        components: { SelectModern },

        mixins: [designMixin],

        props: {
            disabled: Boolean,
            id: String,
            name: String,
            value: String
        },

        computed: {
            countryOfOrigin() {
                return this.$store.state.restaurants.entities.selectedRestaurant ? this.$store.state.restaurants.entities.selectedRestaurant.address.country : 'US';
            },

            util() {
                return util;
            }
        },

        methods: {
            onChange(event) {
                this.$emit('input', event.target.value);
            }
        }
    });
