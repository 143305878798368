import accountService from '../services/account.service';
import { FeedbackRequest } from '../models/FeedbackRequest';
import { Record } from 'immutable';
import { createSetterMutations, getSetterMutationName } from '../../common/data/store.helpers';
import { PROFILE_STORE } from '../../profile/stores/profile.store';
import storageService from '../../common/services/storage.service';

declare var VENDOR_PREFIX: string;

export const ACCOUNT_STORE = 'account';

function setFavIcon(url) {
  if (!url) {
    url = VENDOR_PREFIX + 'images/favicon.png';
  }

  const head = $('head');

  let link = head.find('link[rel=icon]');

  if (link.length === 0) {
    link = $('<link>');
    link.attr('rel', 'icon');

    head.append(link);
  }

  link.attr('href', url);
}

export const AccountDesignRecord = Record({
  accountId: '',
  sloganFontStyle: '',
  sloganFontSize: '',

  headerBackgroundImage: 0,
  headerSlogan: '',
  headerLogo: '',
  headerText: '',
  headerTextFontSize: '',
  headerTextFontStyle: '',

  socialMediaMessage: '',

  buttonStyle: '',

  fontHeadline: '',
  fontBody: '',

  htmlHeader: '',
  htmlFooter: '',
  htmlIntro: null,

  itemCustomizationLayout: '',
  socialMedia: [],

  loyaltyProgramDescription: '',
  loyaltyPointsDescription: '',
  loyaltyOrderToggleEnabled: false,

  favicon: null,

  modernFont: 'futura-pt',
  modernThemeColors: {},
  thumbnailSize: 'DEFAULT'
});

export const AccountRecord = Record({
  category: null,
  imageLink: null,
  logo: null,
  name: null,
  objectId: null,
  orderSubmissionTimeout: 120,
  customCss: null,
  primaryAddress: {},
  slogan: null,
  type: null,
  website: null,
  locationFinderActive: false,
  appStoreUrl: null,
  googlePlayUrl: null,
  tagContainerId: null
});

const initialState = {
  entities: {
    accountInfo: undefined,
    accountDesign: undefined
  },
  feedbackRequest: undefined,
  id: undefined,
  iosLogo: '',
  loading: false
};

export default {
  name: ACCOUNT_STORE,
  namespaced: true,
  state: initialState,
  mutations: {
    ...createSetterMutations(initialState),
    RESET_FEEDBACK(state) {
      state.feedbackRequest = new FeedbackRequest();
    },

    SET_ACCOUNT(state, payload) {
      if (payload !== null) {
        state.loading = false;
        state.id = payload.objectId;
        state.entities.accountInfo = new AccountRecord(payload);
      } else {
        state.entities.accountInfo = null;
      }
    },

    SET_ACCOUNT_DESIGN(state, payload) {
      state.entities.accountDesign = payload;

      setFavIcon(payload.favicon);
    }
  },
  actions: {
    initialize({ commit, dispatch, rootState }, payload: { accountId, previewMode }) {
      commit(getSetterMutationName('loading'), true);
      commit('RESET_FEEDBACK');

      return accountService.initialize(payload).then(response => {
        if (response && response.success) {
          storageService.localStorage.setItem('tolAccountId', response.account.objectId);
          commit('SET_ACCOUNT', new AccountRecord(response.account));

          commit('SET_ACCOUNT_DESIGN', new AccountDesignRecord(response.design));
          if (!rootState.modernTheme.customizeTheme) {
            dispatch('modernTheme/initColors', response.design.modernThemeColors, {root: true});
          }

          commit(getSetterMutationName('iosLogo'), response.iosLogo);
        } else {
          commit('SET_ACCOUNT', null);
        }

        dispatch(PROFILE_STORE + '/setAddressTypes', response.addressTypes, { root: true });
        dispatch(PROFILE_STORE + '/setPhoneTypes', response.phoneTypes, { root: true });

        return response;
      });
    },

    loadAccount({ commit }, accountId) {
      commit(getSetterMutationName('loading'), true);
      commit('RESET_FEEDBACK');

      return accountService.findAccount(accountId).then(response => {
        commit('SET_ACCOUNT', response);

        return response;
      });
    },

    loadAccountDesign({ commit }, accountId) {
      return accountService.findAccountDesign(accountId).then(response => {
        const result = new AccountDesignRecord(response);

        commit('SET_ACCOUNT_DESIGN', result);

        return result;
      });
    },

    loadIOSLogo({ commit }, accountId) {
      return accountService.findIosLogo(accountId).then(response => {
        commit(getSetterMutationName('iosLogo'), response);

        return response;
      });
    },

    submitFeedback({ commit }, payload) {
      return accountService.submitFeedback(payload).then(response => {
        commit('RESET_FEEDBACK');

        return response;
      });
    },
  }
};
