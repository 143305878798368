
    import Vue from 'vue';
    import ModalModern from '../../common/components/ModalModern.vue';
    import designService from '../../common/services/design.service';
    import notifierService from '../../common/services/notifier.service';
    import {IPhone, Phone} from '../../common/models/Phone';
    import profile from '../models/Profile';
    import designMixin from '../../common/mixins/design.mixin';
    import SelectModern from '../../common/components/SelectModern.vue';
    import { IUser } from '../stores/profile.store';

    export default Vue.extend({
        mixins: [designMixin],
        components: {
            ModalModern,
            SelectModern
        },
        props: {
            error: String,
            phones: {
                type: Array as () => Array<IPhone>,
                required: true
            },
            types: {
                type: Array as () => Array<String>,
                required: true
            },
            user: {
                type: Object as () => IUser,
                required: true
            }
        },
        computed: {
            _designService () {
                return designService;
            },
            _notifier () {
                return notifierService;
            },
            profile () {
                return profile;
            },
            isValid (): boolean {
                return this.phone.number && this.phone.type;
            }
        },
        data () {
            return {
                editing: false,
                initialized: false,
                invalidFormat: false,
                phone: null,
                submitted: false
            }
        },
        created () {
            this.phone = new Phone();
            this.initialized = true;
        },
        methods: {
            open() {
                this.$refs.removePhoneModal.open();
            },

            close() {
                this.$refs.removePhoneModal.close();
            },

            editPhone(phone: IPhone): void {
                if (!phone) {
                    this.phone = new Phone();
                    this.phone.type = this.types[0];
                } else {
                    this.phone = JSON.parse(JSON.stringify(phone));
                }

                this.editing = true;
                this.invalidFormat = false;
            },

            removePhone(phone: IPhone) {
                this.editing = false;
                this.phone = phone;
                this.open();
                this._designService.setModalStyling();
            },

            remove() {
                let payload = {
                    userId: this.user.objectId,
                    objectId: this.phone.objectId
                };

                this.profile.removePhone(payload).then(() => this.checkForSuccess());

                this.close();

                this.phone = new Phone();
            },

            savePhone(event): void {
                event.stopPropagation();
                event.preventDefault();

                let ready: boolean = true;

                if (this.phone.number &&
                    (this.phone.number.length < 10
                        || this.phone.number.length > 20
                        || /^\d+$/.test(this.phone.number) === false)) {
                    this.invalidFormat = true;
                    ready = false;
                } else {
                    this.invalidFormat = false;
                }

                ready = ready && this.isValid;

                if (!ready) {
                    this.submitted = true;

                    return;
                }

                let payload: any = {
                    userId: this.user.objectId,
                    objectId: this.phone.objectId,
                    number: this.phone.number,
                    type: this.phone.type
                };

                this.profile.savePhone(payload).then(() => this.checkForSuccess());

                this.submitted = false;
            },

            checkForSuccess() {
                const success = this.profile.profileSuccess;

                if (success) {
                    this._notifier.success('Update successful!');
                    this.cancel();
                } else {
                    this._notifier.error('Update failed!');
                }
            },

            cancel(event): void {
                if (event) {
                    event.stopPropagation();
                    event.preventDefault();
                }

                this.editing = false;
                this.phone = new Phone(null);
            },

            cancelModal() {
                this.phone = new Phone();
                this.close();
            }
        }
    });
