import {Record} from 'immutable';
import {ICoupon, ICouponGroup} from '../coupons/models/Coupon';

export const ORDER_TYPES = {
  DELIVERY: '7'
};

export const CartRecord = Record({
  coupons: [],
  createTimestamp: null,
  deliveryCharge: null,
  convenienceFee: null,
  expectedTimeString: '',
  grandTotal: null,
  items: [],
  locationId: '',
  objectId: '',
  orderName: '',
  orderTimeString: '',
  orderType: '',
  outOfStock: [],
  selectedItem: '',
  sessionId: '',
  subtotal: null,
  tax: null,
  taxesByType: {},
  updateTimeStamp: null,
  userId: '',
  validations: [],
  zoneId: ''
});

export interface ICart {
  coupons: ICoupon[];
  createTimestamp: number;
  deliveryCharge: number;
  convenienceFee: number;
  expectedTimeString: string;
  grandTotal: number;
  items: any[];
  locationId: string;
  objectId: string;
  orderName: string;
  orderTimeString: string;
  orderType: string;
  outOfStock: IOutOfStock;
  selectedItem: string;
  sessionId: string;
  size: number;
  subtotal: number;
  tax: number;
  taxesByType: any;
  updateTimeStamp: number;
  userId: string;
  validations: IValidation[];
  zoneId: string;
}

export interface IOutOfStock {
  items: any;
  categories: any;
  departments: any;
}

export interface IValidation {
  message: string;
  objectId: string;
  type: string;
  group: ICouponGroup;
}

export interface IUnavailableMessage {
  objectId: string;
  message: string;
  availableTime: string;
}
