import {BaseApiService} from '../../common/services/BaseApiService';
import {
    API_INITIALIZE_ACCOUNT,
    API_FIND_ACCOUNT_URL,
    API_FIND_ACCOUNT_DESIGN_URL,
    API_SUBMIT_FEEDBACK_URL,
    API_FIND_IOS_LOGO_URL
} from './../account.constants';
import {RequestMethod} from '../../common/services/axios-http.service';
import { IFeedbackRequest } from '../types/account.types';

export class AccountService extends BaseApiService {
    initialize(payload: { accountId: string, previewMode: boolean }) {
        let query = `account=${payload.accountId}`;

        if (payload.previewMode) {
            query += '&preview=true';
        }

        return this.request({
            method: RequestMethod.Get,
            url: API_INITIALIZE_ACCOUNT,
            search: query
        });
    }

    findAccount(accountId: string) {
        return this.request({
            method: RequestMethod.Get,
            url: API_FIND_ACCOUNT_URL,
            search: `account=${accountId}`
        });
    }

    findAccountDesign(accountId: string) {
        return this.request({
            method: RequestMethod.Get,
            url: API_FIND_ACCOUNT_DESIGN_URL,
            search: `account=${accountId}`
        });
    }

    findIosLogo(accountId: string) {
        return this.request({
            method: RequestMethod.Get,
            url: API_FIND_IOS_LOGO_URL,
            search: `account=${accountId}`
        });
    }

    submitFeedback(payload: IFeedbackRequest) {
        return this.request({
            method: RequestMethod.Post,
            url: API_SUBMIT_FEEDBACK_URL,
            body: payload,
            search: `account=${payload.account}`
        });
    }
}

export default new AccountService();
