import { MONTHS_LIST, STATE_LIST, PROVINCE_LIST } from '../common.constants';
import { IAddress } from '../models/Address';
import { ICategory } from '../../menu/stores/menu.store';
import { ICart } from '../../cart/cart.types';
import { IMenuItem, IMenuItemSize, IRequiredItem } from '../../menu/models/Item';
import { IStoreHours } from '../../restaurants/types/restaurant.types';

declare var require: any;
const moment = require('moment');

export const DAYS_OF_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const ORDER_TYPES = {
    DELIVERY: '7'
};
export enum ADD_TO_CART_ACTIONS {
  COMPUTE, // Compute using the method Util.decideToCustomizeItem
  FORCE_ADD, // Force add to cart
  FORCE_CUSTOMIZE // Force Customize first
}
export class Util {
    public MEDIA_QUERIES = {
        SM: 768,
        MD: 992,
        LG: 1200,
    };
    public dropdowns = {
        months: [],
        states: [],
        provinces: [],
        years: []
    };
    public usaCenter = {};
    public mod10 = require('fast-luhn');

    static getRequiredItemId(requiredItem: IRequiredItem): string {
        return requiredItem.itemId;
    }

    static isEmpty(val) {
        return !val || (Array.isArray(val) && val.length === 0);
    }

    static parseDate(date: string): Date {
        if (!date) {
            return null;
        }

        let match = date.match(/^(\d{4})-([01]?\d)-([0-3]?\d)$/);
        let day, month, year;

        if (match !== null) {
            day = match[3];
            month = match[2];
            year = match[1];
        } else {
            match = date.match(/^([01]?\d)\/([0-3]?\d)\/(\d{4})$/);

            if (match === null) {
                return null;
            }

            day = match[2];
            month = match[1];
            year = match[3];
        }

        day = parseInt(day);
        month = parseInt(month);
        year = parseInt(year);

        if (parseInt(day) > 31) {
            return null;
        }

        if (parseInt(month) > 12) {
            return null;
        }

        if (parseInt(year) >= new Date().getFullYear()) {
            return null;
        }

        return new Date(year, month - 1, day);
    }

    static getDateString(date: Date): string {
        let month = date.getMonth() + 1;
        let monthString;

        if (month < 10) {
            monthString = '0' + month;
        } else {
            monthString = month;
        }

        let day = date.getDate();
        let dayString;

        if (day < 10) {
            dayString = '0' + day;
        } else {
            dayString = day;
        }

        return date.getFullYear() + '-' + monthString + '-' + dayString;
    }

    static formatDate(momentDate) {
        return momentDate.format('ddd, MMM Do, h:mm a');
    }

    static formatHourRange(startTime, endTime) {
        return `${startTime.format('h:mm a')} - ${endTime.format('h:mm a')}`;
    }

    static getFormattedHourRange(day: string, storeHours: IStoreHours, orderType: string): string {
        return Util.getFormattedHourRangeFromHours(day, storeHours, orderType);
    }

    static isHourSlotsClosed(hourSlots, isHoliday: boolean = false): boolean {
        const startTime = Util.getStartMoment(hourSlots);
        const endTime = Util.getEndMoment(hourSlots);

        const startHour = startTime.get('hour');
        const startMinute = startTime.get('minute');
        const endHour = endTime.get('hour');
        const endMinute = endTime.get('minute');

        if (startHour === endHour && startMinute === endMinute) {
            return isHoliday && startHour === 0 && startMinute === 0;
        }

        if (Util.isMomentAfter(endTime, startTime)) {
            const diff = Math.abs(moment.duration(startTime.diff(endTime)).asMinutes());

            if (diff < 20) {
                return true;
            }
        }

        return false;
    }

    static getStartMoment(hourSlots: object[] | object, time = null) {
        if (!Array.isArray(hourSlots)) {
            hourSlots = [hourSlots];
        }

        let startHour = Util.getNumber(hourSlots[0].start[0]);
        let startMinute = Util.getNumber(hourSlots[0].start[1]);

        if (time) {
            time = time.clone();
        } else {
            time = moment();
        }

        let startTime = time.set({
            hour: startHour,
            minute: startMinute,
        });

        return startTime;
    }

    static getEndMoment(hourSlots: object[] | object, time = null) {
        let hourSlotsArray;

        if (Array.isArray(hourSlots)) {
            hourSlotsArray = hourSlots;
        } else {
            hourSlots = hourSlotsArray = [hourSlots];
        }

        let endHour = Util.getNumber(hourSlots[hourSlotsArray.length - 1].end[0]);
        let endMinute = Util.getNumber(hourSlots[hourSlotsArray.length - 1].end[1]);

        if (time) {
            time = time.clone();
        } else {
            time = moment();
        }

        let endTime = time.set({
            hour: endHour,
            minute: endMinute,
        });

        return endTime;
    }

    static isHourRangeClosedAllDay(day: string | any, storeHours: IStoreHours, orderType: string): boolean {
      const holidayHours = Util.getHolidayHourSlots(day, storeHours);

      if (holidayHours) {
        return Util.isHourSlotsClosed(holidayHours, true);
      } else {
          const hourSlots = Util.getHourSlotsForOrderType(day, orderType, storeHours);

          if (hourSlots) {
              return Util.isHourSlotsClosed(hourSlots, false);
          } else {
              return true;
          }
      }
    }

    static getFormattedHourRangeFromHours(day: string | any, storeHours: IStoreHours, orderType: string): string {
        let hourSlots = Util.getHourSlotsForOrderType(day, orderType, storeHours);
        const holidayHours = Util.getHolidayHourSlots(day, storeHours);

        if (holidayHours) {
            hourSlots = holidayHours;
        }

        if (!hourSlots || Util.isHourSlotsClosed(hourSlots, !!holidayHours)) {
            return 'Closed';
        } else {
            if (hourSlots.length === 1) {
                const startTime = Util.getStartMoment(hourSlots);
                const endTime = Util.getEndMoment(hourSlots);

                const isAllDay = startTime.get('hour') === 0 && startTime.get('minute') === 0
                  && endTime.get('hour') === 23 && endTime.get('minute') === 59;
                const isSameTime = startTime.get('hour') === endTime.get('hour') && startTime.get('minute') === endTime.get('minute');

                if (isAllDay || isSameTime) {
                    return 'Open 24 Hours';
                }
            }

            const formattedHourRanges = hourSlots.map(function (slot) {
                const startTime = Util.getStartMoment(slot);
                const endTime = Util.getEndMoment(slot);

                return Util.formatHourRange(startTime, endTime);
            });

            return formattedHourRanges.join('\n');
        }
    }

    static getParamsFromArray (params: string[]): any {
        let result = {};

        for (let i = 0; i < params.length; i++) {
            let part = params[i];
            let item = part.split('=');
            let key = item[0];
            let value = decodeURIComponent(item[1]);

            result[key] = value;
        }

        return result;
    }

    static getNumber(value): number {
        if (typeof value === 'undefined') {
            value = 0;
        }

        return value;
    }

    static compareTimes(a, b) {
        const aYear = a.year();
        const bYear = b.year();

        if (aYear < bYear) {
            return -1;
        } else if (aYear > bYear) {
            return 1;
        }

        const aMonth = a.month();
        const bMonth = b.month();

        if (aMonth < bMonth) {
            return -1;
        } else if (aMonth > bMonth) {
            return 1;
        }

        const aDay = a.date();
        const bDay = b.date();

        if (aDay < bDay) {
            return -1;
        } else if (aDay > bDay) {
            return 1;
        }

        const aHour = a.hour();
        const bHour = b.hour();

        if (aHour < bHour) {
            return -1;
        } else if (aHour > bHour) {
            return 1;
        }

        const aMinute = a.minute();
        const bMinute = b.minute();

        if (aMinute < bMinute) {
            return -1;
        } else if (aMinute > bMinute) {
            return 1;
        } else {
            return 0;
        }
    }

    static getOpenTime(day, dayOffset: number, hourSlots) {
        const result = Util.getStartMoment(hourSlots, day);

        if (dayOffset !== 0) {
            result.add(dayOffset, 'days');
        }

        return result;
    }

    static getCloseTime(day, dayOffset: number, hourSlots) {
        const closeTime = Util.getEndMoment(hourSlots, day);

        if (dayOffset !== 0) {
            closeTime.add(dayOffset, 'days');
        }

        // close time on next day
        const openTime = Util.getOpenTime(day, dayOffset, hourSlots);

        if (Util.compareTimes(openTime, closeTime) === 1) {
            closeTime.add(1, 'day');
        }

        return closeTime;
    }

    static getHourSlotForTime(time, dayOffset: number, hourSlots, nextHourSlot?: { value: object }) {
        let slot = hourSlots[0];

        const comparison = Util.compareTimeToHourSlot(time, dayOffset, slot);

        // before or during first open slot
        if (comparison === -1) {
            if (nextHourSlot) {
                nextHourSlot.value = slot;
            }

            return null;
        } else if (comparison === 0) {
            return slot;
        }

        // later slots
        for (let i = 1; i < hourSlots.length; i++) {
            slot = hourSlots[i];

            const result = Util.compareTimeToHourSlot(time, dayOffset, slot);

            // before slot
            if (result === -1) {
                if (nextHourSlot) {
                    nextHourSlot.value = slot;
                }

                return null;
            } else if (result === 0) { // during slot
                if (nextHourSlot) {
                    nextHourSlot.value = slot;
                }

                return slot;
            }
        }

        // not before or during open so must be after close
        return null;
    }

    static compareTimeToHourSlots(time, dayOffset: number, hourSlots, nextHourSlot?: { value: object }) {
        let slot = Util.getHourSlotForTime(time, dayOffset, hourSlots, nextHourSlot);

        if (slot) {
            return 0;
        }

        slot = hourSlots[0];

        let comparison = Util.compareTimeToHourSlot(time, dayOffset, slot);

        // before first open slot
        if (comparison === -1) {
            return comparison;
        }

        slot = hourSlots[hourSlots.length - 1];

        comparison = Util.compareTimeToHourSlot(time, dayOffset, slot);

        // after last open slot
        if (comparison === 1) {
            return comparison;
        }

        // between open slots
        return 0;
    }

    static compareTimeToHourSlot(time, dayOffset: number, hourSlot) {
        const openTime = Util.getOpenTime(time, dayOffset, hourSlot);

        // before open
        let result = Util.compareTimes(time, openTime);

        if (result === -1) {
            return -1;
        }

        const closeTime = Util.getCloseTime(time, dayOffset, hourSlot);

        result = Util.compareTimes(time, closeTime);

        // after close
        if (result >= 0) {
            return 1;
        }

        return 0;
    }

    static isTimeInHourSlots(time, dayOffset: number, hourSlots): boolean {
        return !!Util.getHourSlotForTime(time, dayOffset, hourSlots);
    }

    static getHolidayHourSlots(day: string | any, storeHours: IStoreHours) {
        if (storeHours.default.holidays) {
            if (typeof day !== 'string') {
                day = day.format('YYYY-MM-DD');
            }

            const range = storeHours.default.holidays[day];

            if (range) {
                return [range];
            }
        }

        return null;
    }

    static getHourSlotsForOrderType(day: string | any, orderType: string, storeHours: IStoreHours) {
        let hours;

        if (orderType === ORDER_TYPES.DELIVERY) {
            hours = storeHours.Delivery;
        }

        if (!hours) {
            hours = storeHours.default;
        }

        if (!hours) {
            return null;
        }

        let result = Util.getHolidayHourSlots(day, storeHours);

        if (result) {
            return result;
        }

        if (typeof day === 'string') {
            result = hours.ranges[day];
        } else {
            result = hours.ranges[DAYS_OF_WEEK[day.day()]];
        }

        if (!result) {
            return null;
        }

        const now = moment();
        const open = Util.getOpenTime(now, 0, result);
        const fifteenMinutesAfterOpen = open.clone().add(15, 'minutes');
        const close = Util.getCloseTime(now, 0, result);

        if (!Util.isMomentAfter(close, fifteenMinutesAfterOpen)) {
            return null;
        }

        return result;
    }

    static isTimeOpenOnDay(time, dayOffset: number, hourSlots): boolean {
        const start = Util.getOpenTime(time, dayOffset, hourSlots);
        const closedThreshold = start.add(15, 'minutes');

        const end = Util.getCloseTime(time, dayOffset, hourSlots);

        if (!Util.isMomentAfter(end, closedThreshold)) {
            return false;
        }

        return Util.isTimeInHourSlots(time, dayOffset, hourSlots);
    }

    static isTimeOpen(time, storeHours: IStoreHours, orderType: string): boolean {
        const holidayHourSlots = Util.getHolidayHourSlots(time, storeHours)

        // let holiday hours override other hours
        if (holidayHourSlots && !Util.isTimeOpenOnDay(time, 0, holidayHourSlots)) {
            return false;
        }

        // current time falls within yesterday's hours (past midnight)
        let hourSlots = Util.getHourSlotsForOrderType(time.clone().subtract(1, 'day'), orderType, storeHours);

        if (hourSlots && Util.isTimeOpenOnDay(time, -1, hourSlots)) {
            return true;
        }

        // current time falls within today's hours
        hourSlots = Util.getHourSlotsForOrderType(time, orderType, storeHours);

        if (!hourSlots) {
            return false;
        }

        return Util.isTimeOpenOnDay(time, 0, hourSlots);
    }

    static getTZFromTimeZone(timeZone: string): string {
        const map = {
            'EST': 'America/New_York',
            'EDT': 'America/New_York',
            'CST': 'America/Chicago',
            'CDT': 'America/Chicago',
            'MST': 'America/Denver',
            'MDT': 'America/Denver',
            'PST': 'America/Los_Angeles',
            'PDT': 'America/Los_Angeles',
            'HST': 'America/Adak',
            'HDT': 'Pacific/Honolulu',
            'AKST': 'America/Anchorage',
            'AKDT': 'America/Anchorage',
            'AST': 'America/Halifax',
            'SST': 'Pacific/Pago_Pago',
            'AEST': 'Australia/Melbourne'
        };

        return map[timeZone];
    }

    static createMoment(str: string, timeZone: string) {
        let result;

        if (str) {
            result = moment(str);
        } else {
            result = moment();
        }

        if (result.tz) {
            const tz = Util.getTZFromTimeZone(timeZone);

            return result.tz(tz);
        } else {
            return result;
        }
    }

    static getNextOpenTimeOnDay(currentTime, dayOffset, hourSlots) {
        const nextHourSlot = { value: null };
        const result = Util.compareTimeToHourSlots(currentTime, dayOffset, hourSlots, nextHourSlot);

        // before open
        if (result === -1) {
            return Util.getOpenTime(currentTime, dayOffset, hourSlots);
        } else if (result === 0) { // between open and close
            if (Util.isTimeOpenOnDay(currentTime, dayOffset, hourSlots)) { // during an open slot
                return currentTime;
            } else if (nextHourSlot.value) { // find next open slot
                return Util.getStartMoment(nextHourSlot.value);
            } else {
                return currentTime;
            }
        } else {
            return null;
        }
    }

    static getNextOpenTime(currentTime, storeHours: IStoreHours, orderType: string) {
        if (Util.isTimeOpen(currentTime, storeHours, orderType)) {
            return currentTime;
        }

        let hourSlots = Util.getHourSlotsForOrderType(currentTime.clone().subtract(1, 'day'), orderType, storeHours);

        let nextOpenTime;

        if (hourSlots) {
            nextOpenTime = Util.getNextOpenTimeOnDay(currentTime, -1, hourSlots);

            if (nextOpenTime) {
                return nextOpenTime;
            }
        }

        hourSlots = Util.getHourSlotsForOrderType(currentTime, orderType, storeHours);

        if (hourSlots) {
            nextOpenTime = Util.getNextOpenTimeOnDay(currentTime, 0, hourSlots);

            if (nextOpenTime) {
                return nextOpenTime;
            }
        }

        // go through next 6 days of week and find an open time
        const day = currentTime.clone();

        for (let i = 1; i <= 7; i++) {
            day.add(1, 'd');
            day.set({
                hour: 0,
                minute: 0,
                second: 0
            });

            hourSlots = Util.getHourSlotsForOrderType(day, orderType, storeHours);

            if (hourSlots) {
                nextOpenTime = Util.getNextOpenTimeOnDay(day, 0, hourSlots);

                if (nextOpenTime) {
                    return nextOpenTime;
                }
            }
        }

        // has no open times any day of week?
        return null;
    }

    static getPromiseTime(orderType, promiseTimes) {
        let promiseTime: number = 20; // Default to 20 minutes if we can't get the time from the server

        if (orderType && promiseTimes) {
            promiseTime = promiseTimes[orderType];
        }

        return promiseTime;
    }

    static waitUntil(until: Function, interval: number = 100): Promise<void> {
        return new Promise(resolve => {
            let timer;

            const startTimer = () => {
                timer = setTimeout(() => {
                    const result = until();

                    if (result) {
                        resolve(result);
                    } else {
                        startTimer();
                    }
                }, interval);
            };

            startTimer();
        });
    }

    static isMomentAfter(a, b) {
        return a.isAfter(b, 'minute');
    }

    static isMomentBefore(a, b) {
        return a.isBefore(b, 'minute');
    }

    static isOrderTimeValid(
        orderType: string,
        hours: IStoreHours,
        promiseTimes: Map<string, number>,
        currentTime: string,
        usePromiseTime: boolean,
        orderTime?: string): boolean {
        let orderMoment;

        if (orderTime) {
            orderMoment = moment(orderTime);
        } else {
            orderMoment = moment(currentTime);

            // add promise time if open for asap orders
            if (usePromiseTime && Util.isTimeOpen(orderMoment, hours, orderType)) {
                const promiseTime = Util.getPromiseTime(orderType, promiseTimes);

                orderMoment.add(promiseTime, 'minutes');
            }
        }

        const isOrderTimeOpen = Util.isTimeOpen(orderMoment, hours, orderType);

        if (!isOrderTimeOpen) {
            return false;
        }

        const nextOpenTime = Util.getNextOpenTime(moment(currentTime), hours, orderType);

        if (!nextOpenTime) {
            return false;
        }

        const earliestOrderTime = nextOpenTime;

        if (usePromiseTime) {
            const promiseTime = Util.getPromiseTime(orderType, promiseTimes);

            earliestOrderTime.add(promiseTime, 'minutes');
        }

        if (Util.isMomentBefore(orderMoment, earliestOrderTime)) {
            return false;
        }

        return true;
    }

    static getReadyTime(currentTime: string, promiseTimes: Map<number, number>, orderType: string, usePromiseTime: boolean) {
        const result = moment(currentTime);

        if (usePromiseTime) {
            const promiseTime = Util.getPromiseTime(orderType, promiseTimes);

            result.add(promiseTime, 'minutes');
        }

        return result;
    }

    static getReadyTimeString(
        currentTime: string,
        promiseTimes: Map<number, number>,
        orderType: string,
        usePromiseTime: boolean): string {
        return Util.getReadyTime(currentTime, promiseTimes, orderType, usePromiseTime).format().substr(0, 19);
    }

    static getOrderTimeString(cart: ICart, promiseTimes: Map<number, number>, currentTime: string, usePromiseTime: boolean): string {
        if (cart.orderTimeString) {
            return cart.orderTimeString;
        } else {
            return Util.getReadyTimeString(currentTime, promiseTimes, cart.orderType, usePromiseTime);
        }
    }

    static isOrderTimeToday(currentTime: string, orderType: string, hours: IStoreHours, orderTime: string): boolean {
        if (!orderTime) {
            return false;
        }

        const currentTimeMoment = moment(currentTime);
        const hourSlots = Util.getHourSlotsForOrderType(currentTimeMoment, orderType, hours);

        if (!hourSlots) {
            return false;
        }

        const nextOpenTime = Util.getNextOpenTimeOnDay(currentTimeMoment, 1, hourSlots);

        return !Util.isMomentBefore(nextOpenTime, moment(orderTime));
    }

    static isOrderScheduledPastToday(currentTime: string, orderType: string, hours: IStoreHours, orderTime: string): boolean {
        return !Util.isOrderTimeToday(currentTime, orderType, hours, orderTime);
    }

    static validateAvailable(category: ICategory, cart: ICart, currentTime: string): boolean {
        if (!category) {
            return true;
        }

        if (!category.available || !category.available.startString || !category.available.endString) {
            return true;
        }

        let orderTimeString;

        if (cart.orderTimeString) {
            orderTimeString = cart.orderTimeString;
        } else {
            orderTimeString = currentTime;
        }

        if (!orderTimeString) {
            return true;
        }

        const today = orderTimeString.substring(0, orderTimeString.indexOf('T'));
        const startTime = moment(today + 'T' + category.available.startString);
        const endTime = moment(today + 'T' + category.available.endString);

        const orderTime = moment(orderTimeString);

        if (category.available.end[0] > category.available.start[0]) {
            if (Util.isMomentBefore(orderTime, startTime) || Util.isMomentAfter(orderTime, endTime)) {
                return false;
            }
        } else if (Util.isMomentBefore(orderTime, startTime)) { // Range crosses midnight and we are before the start
            // We'll try to see if we're before the end time
            if (Util.isMomentAfter(orderTime, endTime)) {
                return false;
            }
        }

        return true;
    }

    static decideToCustomizeItem(item: IMenuItem, sizeId: string, sizeMap: object, itemSelectedPostTask: string,
                                 customizeItem: Function, addItem: Function, askToCustomizeItem: Function,
                                 sizes?: Array<IMenuItemSize>): void {
        if (!sizes && typeof sizeMap === 'object') {
            sizes = sizeMap[item.objectId];
        }

        const hasSizes = sizes && sizes.length > 0;

        if (!item.hasDefaultSize && !sizeId && hasSizes) {
            // force them to choose a size
            if (customizeItem) {
                customizeItem();
            }
        } else if (item.customizable) {
            if (item.hasRequirements || (hasSizes && !sizeId && !item.hasDefaultSize) ||
              (item.hasStyles && !item.hasDefaultStyle) || itemSelectedPostTask === 'SHOW') {
                // required options
                if (customizeItem) {
                    customizeItem();
                }
            } else if (itemSelectedPostTask === 'ORDER_IMMEDIATELY' || !askToCustomizeItem) {
                // configured to add immediately
                if (addItem) {
                    addItem();
                }
            } else {
                // otherwise ask what they want to do
                if (askToCustomizeItem) {
                    askToCustomizeItem();
                }
            }
        } else {
            if (addItem) {
                addItem();
            }
        }
    }

    static normalizePostalCode(postalCode: string): string {
        if (!postalCode) {
            return null;
        }

        postalCode = postalCode.toUpperCase();

        if (!postalCode.match(/[0-9A-Z- ]{5,10}/)) {
            return null;
        }

        return postalCode.replace(/[^0-9A-Z-]/g, '');
    }

    public static getQueryParam(field: string, url?: string): string {
        let href: string = url ? url : window.location.href;
        let reg: RegExp = new RegExp('[?&]' + field + '=([^?&=#]+)', 'i');
        let params: RegExpExecArray = reg.exec(href);
        return params ? params[1] : null;
    }

    public static getResetToken(url?: string): string {
        if (!url) {
            url = window.location.href;
        }

        const regex: RegExp = new RegExp(/[?&]token=([a-zA-Z0-9]+)/);
        const params: RegExpExecArray = regex.exec(url);
        return params ? params[1] : null;
    }

    constructor() {
        this.dropdowns.months = MONTHS_LIST;

        this.dropdowns.states = STATE_LIST.map(state => {
            let result = Object.assign(state, {});

            result['country'] = 'USA';

            return result;
        });

        this.dropdowns.provinces = PROVINCE_LIST.map(state => {
            let result = Object.assign(state, {});

            result['country'] = 'CAN';

            return result;
        });

        this.usaCenter = {
            lat: 38.0000,
            lng: -97.0000
        };

        // Populate years 25 years into the future
        let currentYear: number = moment().year();

        for (let index = 0; index < 25; index++) {
            this.dropdowns.years.push((currentYear + index).toString());
        }
    }

    getResetToken(url?: string): string {
        return Util.getResetToken(url);
    }

    public pluralize(word: string, count: number): string {
        return count === 1 ? `${word}` : `${word}s`;
    }

    public getFullAddress(address: IAddress): string {
        let parts = [];
        let result = '';

        if (address.addressLine) {
            parts.push(address.addressLine);
        }

        if (address.addressLine2) {
            parts.push(address.addressLine2);
        }

        if (parts.length > 0) {
            result += parts.join(' ');
        }

        parts.splice(0);

        if (address.city) {
            parts.push(address.city);
        }

        if (address.stateCode) {
            parts.push(address.stateCode);
        }

        if (parts.length > 0) {
            if (result.length > 0) {
                result += ', ';
            }

            result += parts.join(', ');
        }

        if (address.postalCode) {
            if (result.length > 0) {
                result += ' ';
            }

            result += address.postalCode;
        }

        return result;
    }

    startTimer(callback: () => void, timeout: number): () => void {
        let enabled = true;
        let schedule;

        const run = () => {
            if (!enabled) {
                return;
            }

            callback();

            schedule();
        };

        schedule = () => {
            setTimeout(run, timeout);
        };

        run();

        return () => {
            enabled = false;
        };
    }
}

export default new Util();
