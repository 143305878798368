declare var $: any;

function bindEvent (el, name, handler) {
    el = $(el);

    el.on(name, handler);
    el.on('remove', () => {
        el.off(name, handler);
    });
}

export const hoverScheme = {
    bind (el, binding) {
        bindEvent(el, 'mouseenter', () => {
            if (binding.value) {
                $(el.nativeElement).css('backgroundColor', binding.value.background);
                $(el.nativeElement).css('color', binding.value.text);
            }
        });

        bindEvent(el, 'mouseleave', () => {
            if (binding.value) {
                $(el.nativeElement).css('backgroundColor', '');
                $(el.nativeElement).css('color', '');
            }
        });
    }
};
