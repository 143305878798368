
  import Vue from 'vue';
  import baseMixin from '../../common/mixins/base-component.mixin';
  import Landing from '../components/Landing.vue';
  import { LatLngLiteral } from '../../common/models/LatLng';
  import maps from '../../maps/models/Maps';
  import restaurants from '../../restaurants/models/Restaurants';
  import restaurantLoader from '../../restaurants/services/restaurant-loader.service';
  import { IRestaurant } from '../../restaurants/types/restaurant.types';

  export default Vue.extend({
    mixins: [baseMixin],
    components: {
      Landing
    },
    props: {},
    computed: {
      // services

      maps() {
        return maps;
      },

      restaurantLoader() {
        return restaurantLoader;
      },

      restaurants() {
        return restaurants;
      },

      // getters

      hasOtherRestaurantChoices(): boolean {
        return this.restaurantList && this.restaurantList.length > 1;
      },

      loyalty() {
        return this.restaurants.loyalty;
      },

      loyaltyProgramDescription(): string {
        return this._designService.loyaltyProgramDescription;
      },

      nearestRestaurant() {
        return this.maps.closestRestaurant;
      },

      selectedRestaurant() {
        return this.restaurants.selectedRestaurant;
      },

      userLocation() {
        return this.maps.userLocation;
      }
    },
    data() {
      return {
        calculateClosestRestaurantTimeoutId: 0,
        locationSubscription: null, // ISubscription;
        restaurantList: null
      };
    },
    created() {
      maps.loadUserLocation().then((loc: LatLngLiteral) => {
        this.userLocation = loc;
      }).catch(() => {
        if (!this.restaurants.selectedRestaurantId) {
          this.chooseLocation();
        }
      });

      this.maps.loadUserLocation();
    },
    methods: {
      // abstract implementations

      loadData(): boolean {
        this.restaurantList = this.restaurants.list;

        if (this.restaurantList.length > 0) {
          if (this.restaurantList.length > 1) {
            this.calculateClosestRestaurant();
          } else if (!this.restaurants.selectedRestaurant) {
            this.restaurantLoader.selectRestaurant(this.restaurantList[0]);
          }
        }

        if (this.selectedRestaurant) {
          this.restaurantLoader.reloadSelectedRestaurant();
        }

        return true;
      },

      // methods

      calculateClosestRestaurant(): void {
        this.calculateClosestRestaurantTimeoutId = null;

        if (this.restaurantList) {
          if (this.restaurantList.length === 1) {
            this.nearestRestaurant = this.restaurantList[0];

            return;
          } else if (this.restaurantList.length > 1 && this.userLocation) {
            // tslint:disable-next-line:no-empty
            this.maps.calculateClosestRestaurant(this.userLocation, this.restaurantList.toArray()).catch(() => {
              // TODO: handle this
            });

            return;
          }
        }

        this.calculateClosestRestaurantTimeoutId = setTimeout(() => { this.calculateClosestRestaurant(); }, 250);
      },

      startOrder() {
        if (this.selectedRestaurant) {
          this.navigateToMenu();
        } else if (this.nearestRestaurant) {
          this.chooseRestaurant(this.nearestRestaurant);
        } else {
          this.chooseLocation();
        }
      },

      chooseLocation() {
        this.routeService.route('RestaurantsList');
      },

      logIn(): void {
        if (!this.selectedRestaurant && this.nearestRestaurant) {
          this.restaurantLoader.selectRestaurant(this.nearestRestaurant);
        }

        this.routeService.route('Login');
      },

      showMap(restaurant: IRestaurant) {
        maps.showMap(restaurant.address);
      },

      viewRewards() {
        this.routeService.route('RewardsList');
      },

      joinLoyalty() {
        if (this.profile && this.profile.user && this.profile.user.objectId) {
          this.routeService.route('RewardsEnrollment');
        } else {
          this.routeService.route('Register');
        }
      },

      chooseRestaurant(restaurant: IRestaurant): void {
        if (restaurant.redirectUrl !== null && restaurant.redirectUrl !== '') {
          this.environment.open(restaurant.redirectUrl);
        } else {
          this.restaurantLoader.chooseRestaurant(restaurant);
        }
      }
    },
    destroyed() {
      clearTimeout(this.calculateClosestRestaurantTimeoutId);
      this.calculateClosestRestaurantTimeoutId = null;

      this.locationSubscription.unsubscribe();
    }
  });
