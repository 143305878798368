import { LatLngLiteral } from '../../common/models/LatLng';
import { MAPS_STORE } from '../stores/maps.store';
import { VuexModel } from '../../common/data/VuexModel';
import util, { Util } from '../../common/services/Util';
import {gmapApi} from 'vue2-google-maps';
import Vue from 'vue';
import { Address, IAddress } from '../../common/models/Address';
import storageService from '../../common/services/storage.service';
import environmentService from '../../common/services/environment.service';
import mapsService from '../services/maps.service';
import restaurants from '../../restaurants/models/Restaurants';
import { calculateDrivingDistance, calculateClosestRestaurant, validateDeliveryAddress, validateSameAddress } from '../helpers/map.helpers';
import analyticsManager from '../../common/services/analytics-manager.service';
import { IRestaurant, IZoneArea } from '../../restaurants/types/restaurant.types';

export class Maps extends VuexModel {
  google;

  constructor() {
    super();

    Util.waitUntil(() => {
      return Vue && Vue['$gmapApiPromiseLazy'];
    }).then(() => {
      Vue['$gmapApiPromiseLazy']().then(() => {
        this.google = gmapApi();
      });
    });
  }

  // getters

  get closestRestaurant() {
    return this.state.closestRestaurant;
  }

  get storeName() {
    return MAPS_STORE;
  }

  get userLocation() {
    return this.state.userLocation;
  }

  get countryOfOrigin() {
    return this.state.countryOfOrigin;
  }

  // methods

  calculateClosestRestaurant(userLocation: LatLngLiteral, restaurantsList: IRestaurant[]): Promise<IRestaurant> {
    return new Promise(resolve => {
      const closestRestaurant = calculateClosestRestaurant(userLocation, restaurantsList);

      if (closestRestaurant) {
        this.dispatch('setClosestRestaurant', closestRestaurant).then(() => {
          resolve(closestRestaurant);
        });
      }
    });
  }

  loadUserLocation() {
    return this.getUserLocation().then((userLocation: LatLngLiteral) => {
      return this.dispatch('setUserLocation', userLocation);
    }, () => {
      this.dispatch('setUserLocation', false);

      throw false; // let callers handle promise rejection instead of treating false as a location
    });
  }

  trackGeocodeResponse(results: IAddress[]) {
    /* if (results.length > 0) {
      const geocodeType = results[0]['geocodeAPI'];
      const geocodeResponse = JSON.stringify(results);

      analyticsManager.track('geocode', {
        city: results[0].city,
        geocodeResponse,
        geocodeType,
        locationId: restaurants.selectedRestaurant ? restaurants.selectedRestaurant.objectId : null,
        locationName: restaurants.selectedRestaurant ? restaurants.selectedRestaurant.name : null,
        postalCode: results[0].postalCode,
        state: results[0].stateCode,
        street: results[0].addressLine
      });
    }*/
  }

  // get lat/lng
  geocodeToLatLng(address: string | IAddress): Promise<LatLngLiteral> {
    if (typeof address === 'string') {
      return mapsService.geocode(address).then(results => {
        this.trackGeocodeResponse(results);

        if (results && results.length > 0) {
          return { lat: results[0].latitude, lng: results[0].longitude };
        } else {
          return null;
        }
      });
    } else {
      return mapsService.geocodeAddress(address).then(results => {
        this.trackGeocodeResponse(results);

        if (results && results.length > 0) {
          return { lat: results[0].latitude, lng: results[0].longitude };
        } else {
          return null;
        }
      });
    }
  }

  validateSameAddress(results: IAddress[], address: IAddress): boolean {
    return validateSameAddress(results, address);
  }

  // Geocoding for use independent of a restaurant, such as on the profile page
  verifyAddress(address: IAddress): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!address.addressLine || !address.city || (!address.stateCode && !address.stateName) || !address.postalCode) {
        reject('Not a complete address.');
      }

      return mapsService.geocodeAddress(address).then(results => {
        this.trackGeocodeResponse(results);

        if (!results || results.length === 0) {
          reject();

          return;
        }

        let resultSame = this.validateSameAddress(results, address);

        if (!resultSame) {
          reject(results);

          return;
        }

        address.latitude = results[0].latitude;
        address.longitude = results[0].longitude;

        resolve('OK');
      });
    });
  }

  // Geocoding to validate for delivery for a restaurant.
  validateDeliveryAddress(address: IAddress, restaurant?: IRestaurant): Promise<IZoneArea | IRestaurant[]> {
    return validateDeliveryAddress(address, restaurants.list, restaurant, results => {
      this.trackGeocodeResponse(results);
    });
  }

  verifyPostalCode(postalCode: string): Promise<IAddress> {
    return new Promise((resolve, reject) => {
      if (!postalCode || !/^[A-Za-z0-9 ]{5,7}$/.test(postalCode)) {
        reject('Invalid postal code');

        return;
      }

      const address = new Address();
      address.postalCode = postalCode;

      mapsService.geocodeAddress(address)
        .then(results => {
          this.trackGeocodeResponse(results);

          if (results && results.length > 0) {
            resolve(results[0]);
          } else {
            reject();
          }
        }).catch(() => {
          reject('Invalid postal code');
        });
    });
  }

  getFormattedAddress(address: IAddress) {
    return address.addressLine + (address.addressLine2 ? ', ' + address.addressLine2 : '') + ', '
      + address.city + ', ' + address.stateName + ' ' + address.postalCode;
  }

  convertMeterToMile(meter: number): number {
    const feet = meter / 0.3048;
    const miles = feet / 5280;

    return miles;
  }

  formatDistance(meters: number) {
      return this.convertMeterToMile(meters).toFixed(1) + ' miles';
  }

  calculateDrivingDistance(userLocation: LatLngLiteral, restaurantLocation: LatLngLiteral): Promise<number> {
    return new Promise(resolve => {
      resolve(calculateDrivingDistance(userLocation, restaurantLocation));
    });
  }

  saveUserLocation(value: LatLngLiteral): boolean {
    storageService.localStorage.setItem('userLocation', JSON.stringify(value));

    return true;
  }

  get savedUserLocation(): LatLngLiteral {
    let result = storageService.localStorage.getItem('userLocation');

    if (result) {
      result = JSON.parse(result);
    }

    return result;
  }

  getUserLocation(): Promise<LatLngLiteral> {
    return new Promise((resolve, reject) => {
      let getCurrentPosition = () => {
        try {
          navigator.geolocation.getCurrentPosition(position => {
            let result = {lat: position.coords.latitude, lng: position.coords.longitude};

            resolve(result);

            this.saveUserLocation(result);
          }, error => {
            let result = this.savedUserLocation;

            if (result) {
              resolve(result);
            } else {
              reject(error.message);
            }
          }, {
            maximumAge: 300000,
            timeout: 5000,
            enableHighAccuracy: true
          });
        } catch (e) {
          let result = this.savedUserLocation;

          if (result) {
            resolve(result);
          } else {
            reject('Browser does not support geolocation: ' + e);
          }
        }
      };

      if (environmentService.isMobileApp) {
        let onDeviceReady = () => {
          document.removeEventListener('deviceready', onDeviceReady);

          getCurrentPosition();
        };

        document.addEventListener('deviceready', onDeviceReady, false);
      } else if (navigator.geolocation) {
        getCurrentPosition();
      } else {
        let result = this.savedUserLocation;

        if (result) {
          resolve(result);
        } else {
          reject('Browser does not support geolocation.');
        }
      }
    });
  }

  showMap(address: IAddress): void {
    if (!environmentService.isMobile) {
      return;
    }

    let url = '?q=' + util.getFullAddress(address);

    if (environmentService.isiOS) {
      url = 'maps://' + url;
    } else {
      url = 'geo://' + url;
    }

    window.open(url);
  }

  callPhone(phone: string): void {
    if (environmentService.isMobile) {
      window.open('tel:' + phone);
    }
  }
}

export default new Maps();
