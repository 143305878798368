/* Modern Notifications Vuex Store */

interface INotification {
  type: string;
  message: string;
  id?: string;
  excludeOnMenu?: boolean;
  timeToHide?: number;
}

const initialNotificationsState: Array<INotification> = [];

export default {
  name: 'notifications',
  namespaced: true,
  state: {
    notifications: initialNotificationsState
  },
  mutations: {
    ADD(state, notification: INotification) {
      if (!state.notifications.find(n => n.message === notification.message) ) {
        state.notifications.push(notification);
      }
    },

    REMOVE(state, notification: INotification) {
      const index = state.notifications.indexOf(notification);
      if (index > -1) {
        state.notifications.splice(index, 1);
      }
    },

    'REMOVE_BY_ID': (state, id) => {
      const index = state.notifications.findIndex(n => n.id === id);
      if (index > -1) {
        state.notifications.splice(index, 1);
      }
    },

    CLEAR(state) {
      state.notifications = [];
    }
  }
};
