export const API_SUBMIT_LOGIN_REQUEST_URL = '/slice/profile/login';
export const API_SUBMIT_REGISTER_REQUEST_URL = '/slice/profile/reg';
export const API_UPDATE_PROFILE_URL = '/slice/profile/update';
export const API_CHANGE_PASSWORD_URL = '/slice/profile/password/change';
export const API_LOAD_ADDRESS_TYPES_URL = '/slice/profile/address/types/list';
export const API_LOAD_ADDRESSES_URL = '/slice/profile/address/list';
export const API_CREATE_ADDRESS_URL = '/slice/profile/address/create';
export const API_UPDATE_ADDRESS_URL = '/slice/profile/address/update';
export const API_REMOVE_ADDRESS_URL = '/slice/profile/address/remove';
export const API_SET_PRIMARY_ADDRESS_URL = '/slice/profile/address/primary/set';
export const API_SET_DEVICE_TOKEN_URL = '/slice/profile/deviceToken';
export const API_RECEIVED_NOTIFICATION_URL = '/slice/admin/notification/updateClicked';
export const API_LOGOUT_URL = '/slice/profile/logout';
export const API_LOAD_PHONE_TYPES_URL = '/slice/profile/phone/types/list';
export const API_LOAD_PHONES_URL = '/slice/profile/phone/list';
export const API_CREATE_PHONE_URL = '/slice/profile/phone/create';
export const API_UPDATE_PHONE_URL = '/slice/profile/phone/update';
export const API_REMOVE_PHONE_URL = '/slice/profile/phone/remove';
export const API_LOAD_CREDIT_CARDS_URL = '/slice/profile/creditcard/list';
export const API_SAVE_CREDIT_CARD_URL = '/slice/profile/creditcard/create';
export const API_REMOVE_CREDIT_CARD_URL = '/slice/profile/creditcard/remove';
export const API_DELETE_ACCOUNT_URL = '/slice/profile/delete';
export const API_SWITCH_LOCATION_URL = '/slice/profile/location/switch';
export const API_RESET_PASSWORD_URL = '/slice/profile/password/reset';
export const API_UPDATE_EXPIRATION_DATE_URL = '/slice/profile/creditcard/expiration/update';
export const API_CHANGE_PASSWORD_WITH_TOKEN_URL = '/slice/profile/password/changeWithToken';
export const API_LOAD_COUPON_URL = '/slice/coupon/find';
export const API_UPDATE_LOCATION_URL = '/slice/profile/updateLocation';
export const API_REFRESH_URL = '/slice/profile/refresh';
export const API_GET_DETAILS_URL = '/slice/profile/getDetails';
