import store from '../../../store';
import { Subscriber } from '../services/Subscriber';

export class VuexModel extends Subscriber {
  // getters

  get state() {
    return store.state[this.storeName];
  }

  // override
  get storeName(): string {
    return null;
  }

  // methods

  commit(name, value) {
    return store.commit(this.storeName + '/' + name, value);
  }

  dispatch(action, payload?) {
    return store.dispatch(this.storeName + '/' + action, payload);
  }
}
