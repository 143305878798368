import baseMixin from '../../common/mixins/base-component.mixin';
import TolSelect from '../../common/components/TOLSelect.vue';
import HostedPaymentForm from '../components/HostedPaymentForm.vue';
import {IAddress} from '../../common/models/Address';
import cart from '../../cart/models/Cart';
import {ICreditCard} from '../../common/models/CreditCard';
import {IPhone} from '../../common/models/Phone';
import restaurants from '../../restaurants/models/Restaurants';
import {CreditCardInfo, ITenderRequest} from '../models/TenderRequest';
import profile from '../../profile/models/Profile';
import cartHelperService from '../../cart/services/cart-helper.service';
import googleService from '../../common/services/google.service';
import modalService from '../../common/services/modal.service';
import notifierService from '../../common/services/notifier.service';
import order from '../models/Order';
import profileBus, {IUpdateExpirationDateRequest} from '../../profile/profile.bus';
import routeService from '../../common/services/route.service';
import {IMenuItem} from '../../menu/models/Item';
import {ICart} from '../../cart/cart.types';
import {IUser} from '../../profile/stores/profile.store';
import account from '../../account/models/Account';
import GiftCardForm from '../components/GiftCardForm.vue';
import {Util} from '../../common/services/Util';
import {IRestaurant, ORDER_TYPES} from '../../restaurants/types/restaurant.types';
import ErrorModal from '../../common/components/ErrorModal.vue';
import {
  finalizeOrder,
  finishOrder,
  getPreGiftCardTotal,
  getRemainingTotal,
  hasConvenienceFee,
  isDeliveryOrder,
  isGiftCardBalanceInsufficient,
  isHostedPaymentEnabled
} from '../helpers/order.helpers';
import PhoneInput from '../../common/components/PhoneInput.vue';
import analyticsManager from '../../common/services/analytics-manager.service';
import { isValidName } from '../../common/helpers/text.helpers';

const moment = require('moment');

declare var dataLayer: any;

const TENDER_TYPES = {
    CASH: 'CASH',
    CREDIT_CARD: 'CREDIT_CARD',
    GIFT_CARD: 'GIFT_CARD',
    PAY_AT_LOCATION: 'PAY_AT_LOCATION'
};

const CREDIT_CARD_IDS = ['4', '5', '6', '7'];

const GIFT_CARD_TENDER_TYPE_ID = '9';

function parsePhoneNumber(text) {
  const match = text.match(/(.+) x([0-9]+)$/);
  let phone, ext;

  if (match) {
    phone = match[0];
    ext = match[1];
  } else {
    phone = text;
    ext = null;
  }

  return {
    phone,
    ext
  };
}

export default {
    mixins: [baseMixin],

    components: {
        ErrorModal,
        GiftCardForm,
        HostedPaymentForm,
        PhoneInput,
        TolSelect
    },

    props: {
        addresses: {
            type: Array as () => Array<IAddress>,
            required: false
        },

        cart: {
            type: Object as () => ICart,
            required: true
        },

        creditCards: {
            type: Array as () => Array<ICreditCard>,
            required: false
        },

        isCurbsideOrder: Boolean,

        phones: {
            type: Array as () => Array<IPhone>,
            required: false
        },

        phoneTypes: {
            type: Array as () => Array<string>,
            required: true
        },

        restaurant: {
            type: Object as () => IRestaurant,
            required: true
        },

        suggestedAddresses: {
            type: Array as () => Array<IAddress>,
            required: false
        },

        tenderRequest: {
            type: Object as () => ITenderRequest,
            required: true
        },

        updating: {
            type: Boolean,
            required: true
        },

        user: {
            type: Object as () => IUser,
            required: false
        }
    },

    computed: {
        // models

        _cart() {
            return cart;
        },

        _cartHelper() {
            return cartHelperService;
        },

        _googleService() {
            return googleService;
        },

        _modalService() {
            return modalService;
        },

        _notifierService() {
            return notifierService;
        },

        _order() {
            return order;
        },

        _profileBus() {
            return profileBus;
        },

        account() {
            return account;
        },

        profile() {
            return profile;
        },

        restaurants() {
            return restaurants;
        },

        routeService() {
            return routeService;
        },

        // getters

        creditCardForm() {
            return this.$refs.creditCardForm;
        },

        error() {
            return this._order.error;
        },

        fullPhoneNumber() {
          let result = this.phone;

          if (this.phoneExtension) {
            result += ' x' + this.phoneExtension;
          }

          return result;
        },

        hasLoyaltyProgram(): boolean {
            return this.loyaltySettings && this.loyaltySettings.externalProgram;
        },

        hostedPaymentCreditCards(): ICreditCard[] {
            if (!this.creditCards) {
                return null;
            }

            return this.creditCards.filter(card => {
                return card.locationId === this.locationId && card.cardUsingTP;
            });
        },

        isCreditCardSelected(): boolean {
            return this.selectedTenderType && this.selectedTenderType.id === TENDER_TYPES.CREDIT_CARD;
        },

        isGiftCardBalanceInsufficient(): boolean {
            return isGiftCardBalanceInsufficient(this.cart, this.tenderRequest, this.giftCardAmount);
        },

        isGiftCardsEnabled(): boolean {
            return this.isSameDayOrder;
        },

        isGiftCardsVisible(): boolean {
            if (this.restaurant.thrivePaymentsEnabled) { // only allow gift cards for asap orders
                const giftCardTenderType = this.restaurant.tenderTypes.find(type => type.objectId === GIFT_CARD_TENDER_TYPE_ID);

                return !!giftCardTenderType;
            } else {
                return false;
            }
        },

        isHostedPaymentEnabled(): boolean {
            return isHostedPaymentEnabled(this.restaurant, this.isOnlinePaymentSelected);
        },

        isOnlinePaymentSelected(): boolean {
            return this.selectedTenderType && this.selectedTenderType.id === TENDER_TYPES.CREDIT_CARD;
        },

        isSameDayOrder(): boolean {
            let isSameDayOrder;

            if (this.cart.orderTimeString) {
                isSameDayOrder = Util.isOrderTimeToday(
                    restaurants.currentTime,
                    this.cart.orderType,
                    this.restaurant.hours,
                    this.cart.orderTimeString
                );
            } else {
                isSameDayOrder = true;
            }

            return isSameDayOrder;
        },

        loyaltyProgramDescription(): string {
            return this._designService.loyaltyProgramDescription;
        },

        loyaltySettings() {
            return restaurants.loyalty;
        },

        nonHostedPaymentCreditCards(): ICreditCard[] {
            if (!this.creditCards) {
                return null;
            }

            return this.creditCards.filter(card => {
                return !card.cardUsingTP;
            });
        },

        registerRequest() {
            return this.profile.registerRequest;
        },

        preGiftCardTotal() {
            return getPreGiftCardTotal(this.cart, this.tenderRequest);
        },

        remainingTotal(): number {
            return getRemainingTotal(this.cart, this.tenderRequest, this.giftCardAmount);
        },

        onlySupportsOnlinePayment(): boolean {
            return this.restaurant.tenderTypes.filter(tender => tender.name.toUpperCase() === 'CASH').length <= 0;
        },

        // setters

        surchargeEnabled: {
          get() {
            return this._order.surchargeEnabled;
          },

          set(value) {
            this.$store.commit('order/SET_SURCHARGE_ENABLED', value);
          }
        }
    },

    data() {
        return {
            cancelDeliverySubscription: null, // ISubscription,
            cardInfo: null, // ICreditCardInfo,
            cashTenderType: null,
            creditCardTenderType: null,
            endDeliverySubscription: null, // ISubscription,
            enterNewPhoneNumber: false,
            giftCardAmount: 0, // can't use tenderRequest.giftCardAmount directly because vue doesn't seem to watch it
            hasDelivery: true,
            hasSubmitted: false,
            hasSubmittedOrder: false,
            initialized: false,
            isHostedPaymentComplete: false,
            isHostedPaymentVisible: false,
            isLoyaltyOrderToggleEnabled: true,
            isPasswordFormValid: false,
            passwordFormError: null, // string,
            phone: null,
            phoneExtension: null,
            savePhoneNumber: false,
            selectedPhoneType: null, // string,
            selectedTenderType: null, // any,
            showValidationAlert: false,
            submitDeliverySubscription: null, // ISubscription,
            surchargeMessages: null,
            tenderTypeOptions: null, // any[],
            valid: true,
            validationAlertMessage: '',
            validCreditCard: true,
            validFirstName: true,
            validLastName: true,
            validEmail: true,
            validPhone: true,
            validTenderType: true,
            validPhoneLength: true,
            validPhoneNumber: true,
            enterNewCreditCard: false
        };
    },
    watch: {
        error(error) {
            if (error) {
                this.hasSubmittedOrder = this.hasSubmitted = false;

                if (this.isHostedPaymentVisible) {
                    this._order.clearError();

                    this.tenderRequest.creditCardId = null;
                    this.tenderRequest.hostedPaymentId = null;

                    this.isHostedPaymentVisible = false;
                    this.isHostedPaymentComplete = false;
                }
            }
        }
    },

    created() {
        const parsedPhone = parsePhoneNumber(this.tenderRequest.phone);
        this.phone = parsedPhone.phone;
        this.phoneExtension = parsedPhone.ext;

        if (!this.phone && this.phones && this.phones.length > 0) {
            this.setSelectedPhone(this.phones[0].number);
        }

        this.clearGiftCard();
        if (this.isMobile) {
          document.addEventListener('visibilitychange', this.trackAbandonedCart);
        }
        window.addEventListener('beforeunload', this.trackAbandonedCart);
    },

    mounted() {
        this.tenderRequest.payByCash = undefined;
        this.cardInfo = new CreditCardInfo();
        this._setHasDelivery();

        this._setTenderTypeOptions();

        this.selectedTenderType = this.tenderTypeOptions.find(type => type.objectId === this.cart.tenderTypeId);

        if (!this.getAcceptedCards()) {
            this.tenderRequest.payByCash = true;
            this.tenderTypeOptions = [this.cashTenderType];
            this.selectedTenderType = this.cashTenderType;
        }

        if (this.onlySupportsOnlinePayment) {
            this.tenderRequest.payByCash = false;
            this.tenderTypeOptions = [this.creditCardTenderType];
            this.selectedTenderType = this.creditCardTenderType;
        }

        // pre-selected tender type so check for surcharge/discount immediately
        if (this.selectedTenderType) {
            this.setTenderType(this.selectedTenderType, false);
        }

        if (this.user && this.user.firstName) {
            this.tenderRequest.firstName = this.user.firstName;
        }

        if (this.user && this.user.lastName) {
            this.tenderRequest.lastName = this.user.lastName;
        }

        if (this.user && this.user.email) {
            this.tenderRequest.email = this.user.email;
        }

        if (this.isDeliveryOrder()) {
            if (!this.tenderRequest.deliveryAddress || !this.tenderRequest.deliveryAddress.addressLine) {
                this._cartHelper.openDeliveryModal();
            }
        }

        this.selectedPhoneType = this.phoneTypes[0];

        window.scrollTo(0, 0);

        let modal = this.$route.params.modal;

        if (modal === 'delivery') {
            setTimeout(() => { // wait until modal element exists
                this._cartHelper.openDeliveryModal();
            }, 500);
        }

        if (this.loyaltySettings && this.loyaltySettings.externalProgram && this.registerRequest) {
            const design = this.account.design;

            this.isLoyaltyOrderToggleEnabled = design.loyaltyOrderToggleEnabled;
            this.registerRequest.rewards = this.isLoyaltyOrderToggleEnabled;
        }
    },

    methods: {
        getKeys(obj: any): string[] {
            if (obj === null) {
                return [];
            }

            return Object.keys(obj);
        },

        getItemName(item: IMenuItem): string {
            if (item.alreadySplit) {
                return `1/2 ${item.split.left.name} 1/2 ${item.split.right.name}`;
            } else {
                return item.name;
            }
        },

        showTax(): boolean {
            return this.cart.tax !== null && this.cart.tax !== 0;
        },

        clearAlert() {
            this.showValidationAlert = false;
            this.validationAlertMessage = '';
        },

        setAddress() {
            this._cartHelper.openDeliveryModal();
        },

        updateDeliveryZone(result) {
            window.setTimeout(() => {
                if (this.updating) {
                    this.updateDeliveryZone(result);

                    return;
                }

                this.$emit('setDeliveryZone', result);
            }, 50);
        },

        getOrderType() {
            if (!this.cart || !this.cart.orderType) {
                return '';
            }

            return this.restaurant.orderTypes[this.cart.orderType].name.toUpperCase();
        },

        validateInputs(): boolean {
            this.validateTenderType();

            if (this.isCreditCardSelected && this.preGiftCardTotal > 0 && this.isGiftCardBalanceInsufficient) {
                this.validCreditCard = this.creditCardForm && this.creditCardForm.validate();
            } else {
                this.validCreditCard = true;
            }

            this.validateFirstName();
            this.validateLastName();
            this.validatePhone();
            this.validateEmail();

            this.valid = this.validCreditCard && this.validFirstName && this.validLastName
                && this.validEmail && this.validPhone && this.validTenderType;

            if (this.valid) {
                this.validationAlertMessage = null;
                this.showValidationAlert = null;
            } else {
                this.validationAlertMessage = 'Information needed to complete your order is missing.  '
                    + 'Please complete any indicated fields below and ensure you have selected a payment type.';
                this.showValidationAlert = true;
                this._notifierService.error('Please complete indicated fields.');

                window.scrollTo(0, 0);
            }

            return this.valid;
        },

        validateTenderType() {
            if (this.preGiftCardTotal > 0 && this.tenderRequest.payByCash === undefined && this.isGiftCardBalanceInsufficient) {
                this.validTenderType = false;
            }
        },

        validateFirstName() {
            this.validFirstName = isValidName(this.tenderRequest.firstName);
        },

        validateLastName() {
            this.validLastName = isValidName(this.tenderRequest.lastName);
        },

        validatePhone() {
            if (!this.phone) {
                this.validPhone = false;

                return;
            }

            let phone: string = this.phone.replace(/[^0-9]/g, '');

            if (phone.length < 10 || phone.length > 11) {
                this.validPhone = false;
                this.validPhoneLength = false;
                this.validPhoneNumber = false;

                return;
            } else if (!phone.match(/^[0-9]+$/)) {
                this.validPhone = false;
                this.validPhoneNumber = false;

                return;
            } else {
                this.validPhone = true;
            }
        },

        validateEmail() {
            if (!this.tenderRequest.email) {
                this.validEmail = false;

                return;
            } else {
                const pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

                this.validEmail = this.tenderRequest.email.match(pattern) && this.tenderRequest.email.length <= 255;
            }
        },

        resetInputValidation() {
            this.valid = true;
            this.validCreditCard = true;
            this.validFirstName = true;
            this.validLastName = true;
            this.validPhone = true;
            this.validEmail = true;
            this.validTenderType = true;
            this.validPhoneLength = true;
        },

        showHostedPaymentForm(): void {
            this.isHostedPaymentVisible = true;
            this.isHostedPaymentComplete = false;

            // Google tag manager tracking
            dataLayer.push({
              event: 'hostedpayment',
              data: {
                cartId: this._cart.cart.objectId,
                userId: this.profile.user ? this.profile.user.objectId : null,
                locationId: this.restaurant.objectId,
                locationName: this.restaurant.name
              }
            });

            // tracking
            analyticsManager.track('Hosted payment form shown', {
              cartId: this._cart.cart.objectId,
              userId: this.profile.user ? this.profile.user.objectId : null,
              locationId: this.restaurant.objectId,
              locationName: this.restaurant.name
            });
        },

        onHostedPaymentComplete(payload: { response: string, paymentId: string }): void {
            this.isHostedPaymentComplete = true;

            if (payload) {
                this.tenderRequest.hostedPaymentResponse = payload.response;
                this.tenderRequest.hostedPaymentId = payload.paymentId;
            }

            this.finishOrder();
        },

        onHostedPaymentCancel(): void {
            this.tenderRequest.hostedPaymentId = null;

            this.isHostedPaymentVisible = false;
        },

        onHostedPaymentError() {
            this.tenderRequest.hostedPaymentId = null;

            this.isHostedPaymentVisible = false;

            this._notifierService.error('Payment Error. Please try again.');

            const phone = restaurants.selectedRestaurant.address.phone;
            const msg = 'Credit Card payment is currently offline, please call the restaurant at ' +
              phone + ' or select a different form of payment if available';

            this.$refs.errorModal.open([msg]);
        },

        register(): Promise<IUser> {
            this.registerRequest.location = this.restaurants.selectedRestaurantId;
            this.registerRequest.firstName = this.tenderRequest.firstName;
            this.registerRequest.lastName = this.tenderRequest.lastName;
            this.registerRequest.username = this.tenderRequest.email;
            this.registerRequest.phoneNumber = this.fullPhoneNumber;

            return this.profile.register(this.registerRequest);
        },

        finalizeOrderGiftCard() {
            this.tenderRequest.payByCash = false;
            this.validTenderType = true;

            if (this.isGiftCardBalanceInsufficient) {
                return;
            }

            this.finalizeOrder();
        },

        saveCreditCard(creditCardId: string) {
            this.$emit('saveCreditCard', {
                billingAddress: this.tenderRequest.billingAddress,
                creditCardId,
                encryptedCardInfo: this.tenderRequest.encryptedCardInfo,
                userId: this.user.objectId
            });
        },

        finalizeOrder() {
            if (this.hasSubmittedOrder) {
                return;
            }

            this.hasSubmitted = true;

            finalizeOrder(
              this.restaurant,
              restaurants.currentTime,
              this.cart,
              this.tenderRequest,
              this.cardInfo,
              this.isCreditCardSelected,
              this.isOnlinePaymentSelected,
              this.creditCardForm && this.creditCardForm.enterNewCreditCard,
              this.creditCardForm && this.creditCardForm.selectedCreditCard,
              this.creditCardForm && this.creditCardForm.updatedExpirationDate,
              this.user,
              this.giftCardAmount,
              this.savePhoneNumber,
              this.selectedPhoneType,
              this.registerRequest,
              !!this.$refs.passwordForm,
              this.isPasswordFormValid,
              this.phoneTypes,
              this.passwordFormError,
              this.isHostedPaymentComplete,
              this.creditCardForm && this.creditCardForm.saveCard,
              this.creditCardForm && this.creditCardForm.saveBillingAddress,
              () => modalService.openScheduleOrderModal(false),
              () => this._cartHelper.openDeliveryModal(),
              (message: string, notification?: string) => this.$emit('showAlert', { message, notification }),
              (error: string) => this._notifierService.error(error),
              () => this.$emit('clearSuggestedAddresses', null),
              () => {
                  this.resetInputValidation();

                  return this.validateInputs();
              },
              (creditCardId: string, expirationDate: string, userId: string) => {
                  const payload: IUpdateExpirationDateRequest = {
                      userId,
                      expirationDate,
                      creditCardId
                  };

                  this._profileBus.holdUpdatedCardInfo$.next(payload);
              },
              () => this.register(),
              userId => this._cart.addUser(userId),
              (number: string, type: string, userId: string) => this.profile.savePhone({ number, type, userId }),
              () => this.showHostedPaymentForm(),
              () => this._modalService.progressBarModal(),
              (creditCardId: string) => this.saveCreditCard(creditCardId),
              () => this.submitOrder()
            ).catch(() => {
                if (!this.isPasswordFormValid && this.passwordFormError) {
                    const passwordFormEl = document.getElementById('password-form');

                    if (passwordFormEl) {
                        passwordFormEl.scrollIntoView();
                    }
                }
            });
        },

        submitOrder() {
            this.tenderRequest.phone = this.fullPhoneNumber;

            if (restaurants.selectedRestaurant.giftCvvRequired) {
                if (!this.tenderRequest.hasOwnProperty('giftCardCVV')) {
                    this.tenderRequest.giftCardCVV = null;
                }
            } else {
                delete this.tenderRequest.giftCardCVV;
            }

            this.$emit('submitOrder', {
                cart: this.cart,
                tenderRequest: this.tenderRequest
            });
        },

        finishOrder() {
            finishOrder(
              this.isCreditCardSelected,
              this.creditCardForm && this.creditCardForm.saveCard,
              this.creditCardForm && this.creditCardForm.saveBillingAddress,
              this.tenderRequest,
              this.selectedCreditCard,
              () => this._modalService.progressBarModal(),
              (creditCardId: string) => this.saveCreditCard(creditCardId),
              () => this.submitOrder()
            );

            this.hasSubmittedOrder = true;

            // TODO: Only clear out on successful order
            this.resetOrder();
            this.resetValidationAlert();
        },

        resetOrder(): void {
            this.enterNewPhoneNumber = false;
            this.cardInfo = new CreditCardInfo();
            this.cardInfo.expirationDate.month = '';
            this.cardInfo.expirationDate.year = '';
        },

        resetValidationAlert(): void {
            this.showValidationAlert = false;
            this.validationAlertMessage = '';
        },

        getAcceptedCards(): object[] {
            let types: object[] = [];
            const cardMap = {
              'American Express': require('../images/amex.png'),
              'Diner\'s Club': require('../images/dinersclub.png'),
              Discover: require('../images/discover.png'),
              MasterCard: require('../images/mastercard.png'),
              Visa: require('../images/visa.png'),
            };

            this.restaurant.tenderTypes.forEach((type) => {
                if (type.objectId > 3 && type.objectId < 9 && cardMap[type.name]) {
                    types.push({
                      name: type.name,
                      src: cardMap[type.name]
                    });
                }
            });

            return types;
        },

        getGrandTotal() {
            return  this.preGiftCardTotal - this.giftCardAmount;
        },

        toggleSavePhoneNumber(): void {
            this.savePhoneNumber = !this.savePhoneNumber;
        },

        getExpectedTime(): string {
          if (!this.cart || !this.cart.expectedTimeString)
            return Util.formatDate(moment(this.currentTime));

          return Util.formatDate(moment(this.cart.expectedTimeString));
        },

        getRestaurantAddress(): string {
            let address: IAddress = this.restaurant.address,
                addressArr: string[] = [];

            addressArr.push(address.addressLine);
            if (address.addressLine2) addressArr.push(address.addressLine2);
            addressArr.push(address.city);
            addressArr.push(address.stateCode);
            addressArr.push(address.postalCode);

            return addressArr.join(', ');
        },

        getTip() {
            return parseFloat(this.tenderRequest.tip);
        },

        getFulfilledCoupons(item: IMenuItem) {
            let fulfilledCoupons: any = item.fulfilledCoupons;

            for (let fulfilledCoupon of fulfilledCoupons) {
                for (let coupon of this.cart.coupons) {
                    if (fulfilledCoupon.couponObjectId !== coupon.objectId) {
                        continue;
                    }

                    fulfilledCoupon.name = coupon.name;
                    fulfilledCoupon.showAsLineItem = coupon.showAsLineItem;

                    break;
                }
            }

            return fulfilledCoupons;
        },

        getRemainingCoupons() {
            let remainingCoupons = [];

            for (let coupon of this.cart.coupons) {
                let showCoupon: boolean = true;

                for (let item of this.cart.items) {
                    if (item.fulfilledCoupons.length <= 0 || coupon.entireOffer) {
                        continue;
                    }

                    for (let fulfilledCoupon of item.fulfilledCoupons) {
                        if (fulfilledCoupon.couponObjectId === coupon.objectId) {
                            showCoupon = false;

                            break;
                        }
                    }

                    if (!showCoupon) {
                        break;
                    }
                }

                if (showCoupon) {
                    remainingCoupons.push(coupon);
                }
            }

            return remainingCoupons;
        },

        getOrderDisclaimerMessage(): string {
            if (!this.restaurant.communicationSettings || !this.restaurant.communicationSettings.orderDisclaimerMessage) {
              return '';
            }

            return this.restaurant.communicationSettings.orderDisclaimerMessage;
        },

        getTenderTypeFor(type) {
            return 'TenderType-' + type.id;
        },

        isDeliveryOrder() {
            return isDeliveryOrder(this.restaurant, this.cart, this.tenderRequest);
        },

        hasConvenienceFee() {
           return hasConvenienceFee(this.cart);
        },

        isSelectedTenderType(type: any) {
            return this.selectedTenderType === type;
        },

        changeTenderType(type: any, event) {
            if (!event || event.target.checked) {
                this.setTenderType(type);
                this.tenderRequest.payByCash = type.value;
                this.validTenderType = true;
                this.isHostedPaymentVisible = false;
            }
        },

        setTenderType(type, showSurchargeModal: boolean = true) {
            this.selectedTenderType = type;

            const promise = cart.setTenderType(type ? type.objectId : null);

            if (type) {
                promise.then(() => this.checkCartForSurcharge(showSurchargeModal));
            }
        },

        checkCartForSurcharge(showModal: boolean = true) {
            const surchargeCoupons = this.cart.coupons.filter(coupon => coupon.surchargeOffer && coupon.promptIfApplied);
            let msg = [];
            for (let i = 0; i < surchargeCoupons.length; i++) {
              const surchargeCoupon = surchargeCoupons[i];
              let amount = surchargeCoupon.defaultAmount + '';

              if (surchargeCoupon.couponType === 'PERCENT') {
                  amount += '%';
              } else {
                  amount = '$' + amount;
              }

              msg.push('A ' + amount + ' ' + surchargeCoupon.name + ' will be added to your order.');
            }

            if (msg.length > 0) {
              if (showModal) {
                this.$refs.surchargeModal.open(msg);
              }

              this.surchargeEnabled = true;
            } else {
              this.surchargeEnabled = false;
            }
            this.surchargeMessages = msg;
        },

        trackAbandonedCart(event): void {
          // force browsers to run this code without a popup asking user if they want to leave
          delete event['returnValue'];
          const windowIsHidden = this.isMobile ? document.visibilityState === 'hidden' : true;
          if (this.surchargeEnabled && windowIsHidden) {
            dataLayer.push({
              event: 'abandon',
              data: {
                cartId: this.cart.objectId,
                userId: this.user ? this.user.objectId : null,
                locationId: this.restaurant.objectId,
                locationName: this.restaurant.name
              }
            });

            /* analyticsManager.track('Abandoned cart after surcharge', {
              cartId: this.cart.objectId,
              userId: this.user ? this.user.objectId : null,
              locationId: this.restaurant.objectId,
              locationName: this.restaurant.name
            });*/
          }
        },

        clearGiftCard() {
            this.tenderRequest.giftCardNumber = null;
            this.tenderRequest.giftCardAmount = this.giftCardAmount = 0;
            this.tenderRequest.payByGiftCard = false;
        },

        isSelectedPhone(phoneNumber: string): boolean {
            return this.fullPhoneNumber === phoneNumber;
        },

        setSelectedPhone(phoneNumber: string): void {
            this.enterNewPhoneNumber = false;
            this.validPhone = true;
            this.validPhoneLength = true;
            this.validPhoneNumber = true;

            const parsedPhone = parsePhoneNumber(phoneNumber);
            this.phone = parsedPhone.phone;
            this.phoneExtension = parsedPhone.ext;
        },

        // Styling
        getLinkStyle(): any {
            return {
                color: this._designService.tertiaryColor,
                'text-decoration': 'underline'
            };
        },

        getHeaderBar(padding: number): any {
            let style: any = {
                'background-color': this._designService.backgroundColor,
                color: this._designService.tertiaryColor,
                'font-family': this._designService.getDesign().fontHeadline
            };

            if (padding) {
                style['padding'] = padding + 'px ' + padding + 'px';
            }

            return style;
        },

        getAlertStyle(): any {
            return this._designService.getAlertStyling();
        },

        goBack() {
            if (this.isHostedPaymentVisible) {
                this.onHostedPaymentCancel();
            } else {
                history.back();
            }
        },

        navigateToMenu() {
            this.$emit('goToMenu', true);
        },

        loadData(): boolean {
            return false;
        },

        onPasswordFormIsValidChange(isValid) {
            this.isPasswordFormValid = isValid;
        },

        onPasswordFormError(error) {
            this.passwordFormError = error;
        },

        onPasswordFormSubmit() {
            this.finalizeOrder();
        },

        _setHasDelivery(): void {
            this.hasDelivery = !!this.restaurant.orderTypes[ORDER_TYPES.DELIVERY]
                && this.restaurant.orderTypes[ORDER_TYPES.DELIVERY].active;
        },

        _setTenderTypeOptions(): void {
            let cashOnlyText: string = this.cart.orderType === ORDER_TYPES.DELIVERY ? 'Cash On Delivery' : 'Pay At Location';

            const cashTenderType = this.restaurant.tenderTypes.find(type => {
                return type.name.toLowerCase() === TENDER_TYPES.CASH.toLowerCase();
            });

            this.cashTenderType = {
                id: TENDER_TYPES.CASH,
                objectId: cashTenderType ? cashTenderType.objectId : null,
                text: cashOnlyText,
                value: true
            };

            const onlineTenderType = this.restaurant.tenderTypes.find(type => {
                return CREDIT_CARD_IDS.indexOf(type.objectId) !== -1;
            });

            this.creditCardTenderType = {
                id: TENDER_TYPES.CREDIT_CARD,
                objectId: onlineTenderType ? onlineTenderType.objectId : null,
                text: 'Pay Now with Credit/Debit Card',
                value: false
            };

            this.tenderTypeOptions = [
                this.cashTenderType,
                this.creditCardTenderType
            ];
        },

        clearSuggestedAddresses() {
            this.$emit('clearSuggestedAddresses', null);
        },

        onGiftCardBalanceApplied({ cardNumber, balance, cvv }) {
            this.tenderRequest.giftCardNumber = cardNumber;
            this.tenderRequest.giftCardAmount = this.giftCardAmount = balance;
            this.tenderRequest.giftCardCVV = cvv;
            this.tenderRequest.payByGiftCard = !this.isGiftCardBalanceInsufficient;
        },

        isCashTenderType(type) {
            return type.id === TENDER_TYPES.CASH;
        },

        isCreditCardTenderType(type) {
            return type.id === TENDER_TYPES.CREDIT_CARD;
        },

        isGiftCardTenderType(type) {
            return type.id === TENDER_TYPES.GIFT_CARD;
        },

        isOnSiteTenderType(type) {
            return type.id === TENDER_TYPES.PAY_AT_LOCATION;
        },

        onSurchargeCancel() {
            this.setTenderType(null);

            this.surchargeMessages = null;
        }
    },

    destroyed() {
        if (this.isMobile) {
            document.removeEventListener('visibilitychange', this.trackAbandonedCart);
        }

        window.removeEventListener('beforeunload', this.trackAbandonedCart);
    }
};
