import { IAddress } from '../../common/models/Address';
import { IUpdateExpirationDateRequest } from '../profile.bus';
import storageService from '../../common/services/storage.service';
import notifierService from '../../common/services/notifier.service';
import routeService from '../../common/services/route.service';
import cart from '../../cart/models/Cart';
import { ILoginRequest, IRegisterRequest, IUser, PROFILE_STORE } from '../stores/profile.store';
import { VuexModel } from '../../common/data/VuexModel';
import restaurants from '../../restaurants/models/Restaurants';
import restaurantLoaderService from '../../restaurants/services/restaurant-loader.service';
import account from '../../account/models/Account';
import { hasLoyalty } from '../../coupons/helpers/coupon.helpers';
import modalBus from '../../common/messaging/modal.bus';

export const LOG_OUT = 'LOG_OUT';
export const SESSION_EXPIRED = 'SESSION_EXPIRED';

export class Profile extends VuexModel {
  isExpired = false;
  logOutPromise = null;

  // getters

  get couponSuccess() {
    return this.state.couponSuccess;
  }

  get isAuthenticated() {
    return !!localStorage.getItem('auth_token');
  }

  get loginError() {
    return this.state.loginError;
  }

  get loginRequest() {
    return this.state.loginRequest;
  }

  get loginSuccess() {
    return this.state.loginSuccess;
  }

  get registerError() {
    return this.state.registerError;
  }

  get registerRequest() {
    return this.state.registerRequest;
  }

  get registerSuccess() {
    return this.state.registerSuccess;
  }

  get addresses() {
    return this.state.userAddresses;
  }

  get addressTypes() {
    return this.state.addressTypes;
  }

  get profileSuccess() {
    return this.state.profileSuccess;
  }

  get profileInfoError() {
    return this.state.profileInfoError;
  }

  get profileAddressError() {
    return this.state.profileAddressError;
  }

  get profileCreditError() {
    return this.state.profileCreditError;
  }

  get profilePhoneError() {
    return this.state.profilePhoneError;
  }

  get passwordResetError() {
    return this.state.passwordResetError;
  }

  get suggestedAddresses() {
    return this.state.suggestedAddresses;
  }

  get resetToken() {
    return this.state.resetToken;
  }

  get resetError() {
    return this.state.resetError;
  }

  get phoneTypes() {
    return this.state.phoneTypes;
  }

  get storeName() {
    return PROFILE_STORE;
  }

  get user() {
    let result;

    if (this.isAuthenticated) {
      result = this.state.user;
    } else if (this.state.user) {
      this.logOut();

      result = null;
    }

    return result;
  }

  get userAddresses() {
    return this.state.userAddresses;
  }

  get userCreditCards() {
    return this.state.userCreditCards;
  }

  get userValidCreditCards() {
    return this.state.userValidCreditCards;
  }

  get userPhones() {
    return this.state.userPhones;
  }

  get hasLoyalty() {
    return hasLoyalty(this.user, restaurants.selectedRestaurantId, restaurants.restaurants);
  }

  // models

  get routeService() {
    return routeService;
  }

  // methods

  getUserDetails() {
    return this.dispatch('getUserDetails', {
      accountId: account.accountInfo.objectId,
      locationId: restaurants.selectedRestaurantId
    });
  }

  initialize(accountId: string) {
    const result = this.dispatch('initialize', accountId);

    this.loadAddressTypes();
    this.loadPhoneTypes();

    return result;
  }

  loadAddressTypes() {
    return this.dispatch('loadAddressTypes');
  }

  loadPhoneTypes() {
    return this.dispatch('loadPhoneTypes');
  }

  loadUserData(user: IUser) {
    const promises = [];

    promises.push(this.loadAddresses(user.objectId));
    promises.push(this.loadPhones(user.objectId));
    promises.push(this.loadCreditCards(user.objectId));

    return Promise.all(promises);
  }

  logIn(payload: ILoginRequest, trackOrigin?: string, useFavoriteLocation: boolean = true) {
    payload.account = account.id;

    return this.dispatch('logIn', payload).then(user => {
      if (!user || !user.objectId) {
        throw false;
      }

      const promises = [];

      promises.push(this.loadUserData(user));

      let locationId = payload.location;

      if (!locationId && useFavoriteLocation) {
        locationId = user.favoriteLocationId;
      }

      if (locationId && restaurants.restaurants.findIndex(rest => rest.objectId === locationId && rest.active) < 0) {
        throw { error: 'This restaurant is not accepting online orders at the moment. Please select a new location to continue.' };
      }

      /* const track = () => {
        let name;

        if (trackOrigin === 'Location Finder') {
          name = 'Login at location finder';
        } else if (trackOrigin === 'Navigation Bar') {
          name = 'Login at Menu/location';
        } else if (trackOrigin === 'Check Out') {
          name = 'Login at check out';
        }

        if (name) {
          let restaurant;

          if (locationId) {
            restaurant = restaurants.restaurants.find(rest => rest.objectId === locationId);
          } else {
            restaurant = restaurants.selectedRestaurant;
          }

          analyticsManager.track(name, {
            email: user.email,
            locationId: locationId,
            locationName: restaurant ? restaurant.name : null
          });
        }
      };*/

      if (!restaurants.selectedRestaurant && locationId) {
        promises.push(restaurantLoaderService.selectRestaurantById(locationId)); // .then(track));
      } else {
        // track();
      }

      promises.push(cart.fetchBySession());

      return Promise.all(promises).then(() => {
        return user;
      });
    }).catch(error => error);
  }

  loadLoggedInUser(user, useFavoriteLocation) {
    this.setLoggedInUser(user);

    let locationId = localStorage.getItem('selectedRestaurant');

    if (locationId) {
      locationId = JSON.parse(locationId);
    }

    if (!locationId && useFavoriteLocation) {
      locationId = user.favoriteLocationId;
    }

    if (locationId
      && restaurants.restaurants.findIndex(rest => rest.objectId === locationId && rest.active) < 0) {
      throw { error: 'This restaurant is not accepting online orders at the moment. Please select a new location to continue.' };
    }

    const promises = [];

    if (!restaurants.selectedRestaurant && locationId) {
      promises.push(restaurantLoaderService.selectRestaurantById(locationId));
    }

    return Promise.all(promises).then(() => {
      return user;
    });
  }

  loadAddresses(userId: string) {
    return this.dispatch('loadAddresses', userId);
  }

  loadPhones(userId: string) {
    return this.dispatch('loadPhones', userId);
  }

  loadCreditCards(userId: string) {
    return this.dispatch('loadCreditCards', userId);
  }

  logOut(route: string = null) {
    if (this.logOutPromise) {
      return this.logOutPromise;
    }

    document.dispatchEvent(new CustomEvent(LOG_OUT, { detail: this.user ? this.user.objectId : null }));

    return this.logOutPromise = this.dispatch('logOut').then(() => {
      localStorage.removeItem('auth_token');

      if (cart.cart) {
        cart.addUser(null);
      }

      if (route) {
        routeService.route(route);
      } else {
        const privateRoutes = ['Order', 'Rewards'];
        const currentRoute = routeService.currentRoute.name;

        if (['Profile', 'User'].indexOf(currentRoute) !== -1) {
          routeService.route('MainMenu');
        } else if (privateRoutes.indexOf(currentRoute) !== -1) {
          routeService.route('RestaurantsList');
        }
      }

      const result = cart.fetchBySession();

      this.logOutPromise = null;

      return result;
    });
  }

  expireSession() {
    if (this.isExpired) {
      return;
    }

    this.isExpired = true;

    const isLoggedIn = !!this.user;

    this.logOut();

    document.dispatchEvent(new Event(SESSION_EXPIRED));

    let msg: string = 'Your cart has expired.';

    if (isLoggedIn) {
      msg = 'You’ve been logged out.';
    }

    modalBus.openSimpleMessageModal(msg);
  }

  addLogOutEventListener(listener: EventListener): void {
    document.addEventListener(LOG_OUT, listener);
  }

  removeLogOutEventListener(listener: EventListener): void {
    document.removeEventListener(LOG_OUT, listener);
  }

  register(payload: IRegisterRequest) {
    return this.dispatch('register', payload).then(user => {
      if (!user || !user.objectId) {
        return user;
      }

      this.loadUserData(user);

      let restaurant;

      if (payload.location) {
        restaurant = restaurants.restaurants.find(rest => rest.objectId === payload.location);
      } else {
        restaurant = restaurants.selectedRestaurant;
      }

      /* analyticsManager.track('Register', {
        email: payload.username,
        locationId: payload.location,
        locationName: restaurant ? restaurant.name : null,
        loyaltySelected: payload.rewards
      });*/

      return user;
    });
  }

  updateProfile(payload: any) {
    return this.dispatch('updateProfile', payload);
  }

  changePassword(payload: any) {
    return this.dispatch('changePassword', payload);
  }

  saveAddress(payload: any) {
    let action;

    if (payload.objectId) {
      action = 'updateAddress';
    } else {
      action = 'createAddress';
    }

    return this.dispatch(action, payload);
  }

  removeAddress(payload: any) {
    return this.dispatch('removeAddress', payload);
  }

  savePhone(payload: any) {
    let action;

    if (!payload.objectId) {
      action = 'createPhone';
    } else {
      action = 'updatePhone';
    }

    return this.dispatch(action, payload);
  }

  removePhone(payload: any) {
    return this.dispatch('removePhone', payload);
  }

  setPrimaryAddress(payload: any) {
    return this.dispatch('setPrimaryAddress', payload);
  }

  setDeviceToken(token: string) {
    let payload = {
      token: token,
      userId: this.user ? this.user.objectId : null
    };

    return this.dispatch('setDeviceToken', payload);
  }

  unsetDeviceToken(token: string, userId: string) {
    let payload = {
      token: token,
      userId
    };

    return this.dispatch('unsetDeviceToken', payload);
  }

  receivedNotification(id: string) {
    return this.dispatch('receivedNotification', id);
  }

  deleteAccount(payload: any) {
    return this.dispatch('deleteAccount', payload);
  }

  saveCreditCard(payload: {
    userId: string,
    encryptedCardInfo: string,
    creditCardId: string,
    billingAddress: IAddress
  }) {
    payload['locationId'] = restaurants.selectedRestaurantId;

    return this.dispatch('saveCreditCard', payload).then(response => {
      if (response && response.success === false) {
        throw false;
      }

      this.loadCreditCards(payload.userId);
      // tslint:disable-next-line:no-empty
    }).catch(() => {
    });
  }

  removeCreditCard(payload: any) {
    return this.dispatch('removeCreditCard', payload);
  }

  setLoggedInUser(user) {
    this.dispatch('setLoggedInUser', user);
  }

  setSuggestedAddresses(payload: any) {
    return this.dispatch('setSuggestedAddresses', payload);
  }

  clearSuggestedAddresses() {
    return this.dispatch('clearSuggestedAddresses');
  }

  switchLocations(restaurantId: string) {
    if (this.user && this.user.objectId && this.user) {
      let payload: any = {
        locationId: restaurantId,
        userId: this.user.objectId
      };

      return this.dispatch('switchLocation', payload);
    } else {
      return false;
    }
  }

  resetPassword(username: string) {
    let accountId = account.id,
      locationId: string = JSON.parse(storageService.localStorage.getItem('selectedRestaurant'));

    let payload: any = {
      username,
      accountId,
      locationId
    };

    return this.dispatch('resetPassword', payload);
  }

  changePasswordWithToken(payload: { password: string, token: string }) {
    return this.dispatch('changePasswordWithToken', payload).then(response => {
      if (response === true) {
        notifierService.success('Password has been changed successfully!');

        routeService.route('Login', { source: 'PasswordReset' });
      }

      return response;
    });
  }

  updateExpirationDate(payload: IUpdateExpirationDateRequest) {
    return this.dispatch('updateExpirationDate', payload);
  }

  setResetToken(token: string) {
    return this.dispatch('setResetToken', token);
  }

  clearProfileState() {
    return this.dispatch('clearProfileState');
  }

  loadCoupon(payload: { offerId: number, locationId: number }) {
    return this.dispatch('loadCoupon', payload);
  }

  updateLocation(locationId: string) {
    if (!this.user) {
      return Promise.reject();
    }

    const payload = {
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      location: locationId,
      rewards: this.user.rewards,
      userId: this.user.objectId
    };

    return this.dispatch('updateLocation', payload);
  }

  refresh(locationId: string) {
    if (!this.user) {
      return Promise.reject();
    }

    const payload =  {
      userId: this.user.objectId,
      locationId
    }

    return this.dispatch('refresh', payload);
  }
}

export default new Profile();
