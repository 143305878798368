import {Subject} from '../common/data/Subject';
import {IMenuItem} from './models/Item';

export interface ITrackSuggestionRequest {
    cartId: string;
    sourceItemId: string;
    suggestItemId: string;
    personalized: boolean;
    accepted: boolean;
}

export class MenuBus {
    clearSplitItemChoices$: Subject<any> = new Subject<any>();
    loadSplitItemChoices$: Subject<string> = new Subject<string>();
    showSuggestions$: Subject<IMenuItem[]> = new Subject<IMenuItem[]>();
    toggleDisplayItem$: Subject<IMenuItem> = new Subject<IMenuItem>();
    trackSuggestion$: Subject<ITrackSuggestionRequest> = new Subject<ITrackSuggestionRequest>();
}

export default new MenuBus();
