export interface IAddress {
    objectId: string;
    type: string;
    houseNumber: string;
    streetName: string;
    addressLine: string;
    addressLine2: string;
    city: string;
    phone: string;
    postalCode: string;
    stateName: string;
    stateCode: string;
    primaryAddress: boolean;
    note: string;
    latitude: number;
    longitude: number;
    country: string;

    compare(address: IAddress): boolean;
}

export class Address implements IAddress {
    objectId: string = '';
    type: string = '';
    houseNumber: string = '';
    streetName: string = '';
    addressLine: string = '';
    addressLine2: string = '';
    city: string = '';
    phone: string = '';
    postalCode: string = '';
    stateName: string = '';
    stateCode: string = '';
    primaryAddress: boolean = false;
    note: string = '';
    latitude: number = 0.0;
    longitude: number = 0.0;
    country: string = '';


  constructor(opts?: any) {
        if (opts) {
            for (let key in opts) {
                if (opts.hasOwnProperty(key)) {
                    this[key] = opts[key];
                }
            }
        }
    }

    compare(address: IAddress): boolean {
        const properties = ['addressLine', 'postalCode', 'stateCode'];

        if (this.standardizeAddressLine(this.addressLine) !== this.standardizeAddressLine(address.addressLine))
            return false;

        return !properties.find(prop => {
            let a = this[prop];

            if (a) {
                a = a.trim().toLowerCase();
            } else {
                a = null;
            }

            let b = address[prop];

            if (b) {
                b = b.trim().toLowerCase();
            } else {
                b = null;
            }

            return a !== b;
        });
    }

    standardizeAddressLine(address: string): string {
        if (!address)
            return null;

        const replacements = {
            east: 'e',
            south: 's',
            southeast: 'se',
            southwest: 'sw',
            north: 'n',
            northeast: 'ne',
            northwest: 'nw',
            west: 'w',

            este: 'e',
            sur: 's',
            sureste: 'se',
            suroeste: 'sw',
            norte: 'n',
            noreste: 'ne',
            noroeste: 'nw',
            oeste: 'w',

            alley: 'aly',
            anex: 'anx',
            arcade: 'arc',
            avenue: 'ave',
            bayou: 'byu',
            beach: 'bch',
            bend: 'bnd',
            bluff: 'blf',
            bluffs: 'blfs',
            bottom: 'btm',
            boulevard: 'blvd',
            branch: 'br',
            bridge: 'brg',
            brook: 'brk',
            brooks: 'brks',
            burg: 'bg',
            burgs: 'bgs',
            bypass: 'byp',
            camp: 'cp',
            canyon: 'cyn',
            cape: 'cpe',
            causeway: 'cswy',
            center: 'ctr',
            circle: 'cir',
            circles: 'cirs',
            cliff: 'clf',
            club: 'clb',
            common: 'cmn',
            commons: 'cmns',
            corner: 'cor',
            corners: 'cors',
            course: 'crse',
            court: 'ct',
            cove: 'cv',
            coves: 'cvs',
            creek: 'crk',
            crescent: 'cres',
            crest: 'crst',
            crossing: 'xing',
            crossroad: 'xrd',
            crossroads: 'xrds',
            curve: 'curv',
            dale: 'dl',
            dam: 'dm',
            divide: 'dv',
            drive: 'dr',
            drives: 'drs',
            estate: 'est',
            estates: 'ests',
            expressway: 'expy',
            extension: 'ext',
            extensions: 'exts',
            fall: 'fall',
            falls: 'fls',
            ferry: 'fry',
            field: 'fld',
            fields: 'flds',
            flat: 'flt',
            flats: 'flts',
            ford: 'frd',
            fords: 'frds',
            forest: 'frst',
            forge: 'frg',
            forges: 'frgs',
            fork: 'frk',
            forks: 'frks',
            fort: 'ft',
            freeway: 'fwy',
            garden: 'gdn',
            gardens: 'gdns',
            gateway: 'gtwy',
            glen: 'gln',
            glens: 'glns',
            green: 'grn',
            greens: 'grns',
            grove: 'grv',
            groves: 'grvs',
            harbor: 'hbr',
            harbors: 'hbrs',
            haven: 'hvn',
            heights: 'hts',
            highway: 'hwy',
            hill: 'hl',
            hills: 'hls',
            hollow: 'holw',
            inlet: 'inlt',
            island: 'is',
            islands: 'iss',
            isle: 'isle',
            junction: 'jct',
            junctions: 'jcts',
            key: 'ky',
            keys: 'kys',
            knoll: 'knl',
            knolls: 'knls',
            lake: 'lk',
            lakes: 'lks',
            land: 'land',
            landing: 'lndg',
            lane: 'ln',
            light: 'lgt',
            lights: 'lgts',
            loaf: 'lf',
            lock: 'lck',
            locks: 'lcks',
            lodge: 'ldg',
            loop: 'loop',
            mall: 'mall',
            manor: 'mnr',
            manors: 'mnrs',
            meadow: 'mdw',
            meadows: 'mdws',
            mews: 'mews',
            mill: 'ml',
            mills: 'mls',
            mission: 'msn',
            motorway: 'mtwy',
            mount: 'mt',
            mountain: 'mtn',
            mountains: 'mtns',
            neck: 'nck',
            orchand: 'orch',
            oval: 'oval',
            overpass: 'opas',
            park: 'park',
            parks: 'park',
            parkway: 'pkwy',
            parkways: 'pkwy',
            pass: 'pass',
            passage: 'psge',
            path: 'path',
            pike: 'pike',
            pine: 'pne',
            pines: 'pnes',
            place: 'pl',
            plain: 'pln',
            plains: 'plns',
            plaza: 'plz',
            point: 'pt',
            points: 'pts',
            port: 'prt',
            ports: 'prts',
            prairie: 'pr',
            radial: 'radl',
            ramp: 'ramp',
            ranch: 'rnch',
            rapid: 'rpd',
            rapids: 'rpds',
            rest: 'rst',
            ridge: 'rdg',
            ridges: 'rdgs',
            river: 'rv',
            road: 'rd',
            roads: 'rds',
            route: 'rte',
            row: 'row',
            rue: 'rue',
            run: 'run',
            shoal: 'shl',
            shoals: 'shls',
            shore: 'shr',
            shores: 'shrs',
            skyway: 'skwy',
            spring: 'spg',
            springs: 'spgs',
            spur: 'spur',
            spurs: 'spur',
            square: 'sq',
            squares: 'sqs',
            station: 'sta',
            stravenue: 'stra',
            stream: 'strm',
            street: 'st',
            streets: 'sts',
            summit: 'smt',
            terrace: 'ter',
            throughway: 'trwy',
            trace: 'trce',
            track: 'trak',
            trafficway: 'trfy',
            trail: 'trl',
            trailer: 'trlr',
            tunnel: 'tunl',
            turnpike: 'tpke',
            underpass: 'upas',
            union: 'un',
            unions: 'uns',
            valley: 'vly',
            valleys: 'vlys',
            viaduct: 'via',
            view: 'vw',
            views: 'vws',
            village: 'vlg',
            villages: 'vlgs',
            ville: 'vl',
            vista: 'vis',
            walk: 'walk',
            walks: 'walk',
            wall: 'wall',
            way: 'way',
            ways: 'ways',
            well: 'wl',
            wells: 'wls'
        };

        address = address.trim().toLowerCase();

        for (const key of Object.keys(replacements)) {
            address = address.replace(new RegExp('\\b' + key + '\\b', 'g'), replacements[key]);
        }

        return address;
    }
}
