import {BaseApiService} from '../../common/services/BaseApiService';
import * as ENDPOINTS from '../menu.constants';
import {ITrackSuggestionRequest} from '../menu.bus';
import storageService from '../../common/services/storage.service';
import {RequestMethod} from '../../common/services/axios-http.service';
import storage from '../../common/services/storage.service';

const moment = require('moment');

export class MenuService extends BaseApiService {
  getCategoriesForRestaurant(restaurantId: string) {
    return this.request({
      method: RequestMethod.Get,
      url: ENDPOINTS.API_GET_CATEGORIES_FOR_RESTAURANT_URL,
      search: `location=${restaurantId}`
    });
  }

  getItemsForCategory(categoryId: string) {
    const restaurantId: string = JSON.parse(storageService.localStorage.getItem('selectedRestaurant'));

    return this.request({
      method: RequestMethod.Get,
      url: ENDPOINTS.API_GET_ITEMS_FOR_CATEGORY_URL,
      search: `location=${restaurantId}&category=${categoryId}`
    });
  }

  getItemsForDepartment(departmentId: string) {
    const restaurantId: string = JSON.parse(storageService.localStorage.getItem('selectedRestaurant'));

    return this.request({
      method: RequestMethod.Get,
      url: ENDPOINTS.API_GET_ITEMS_FOR_DEPARTMENT_URL,
      search: `location=${restaurantId}&department=${departmentId}`
    });
  }

  getStylesForItem(itemId: string) {
    const restaurantId: string = JSON.parse(storageService.localStorage.getItem('selectedRestaurant'));

    return this.request({
      method: RequestMethod.Get,
      url: ENDPOINTS.API_GET_STYLES_FOR_ITEM_URL,
      search: `location=${restaurantId}&item=${itemId}`
    });
  }

  getToppingsForItem(itemId: string, sizeId?: string, styleId?: string) {
    const restaurantId: string = JSON.parse(storageService.localStorage.getItem('selectedRestaurant'));

    let url = `location=${restaurantId}&item=${itemId}`;

    if (sizeId) {
      url += '&size=' + sizeId;
    }

    if (styleId) {
      url += '&style=' + styleId;
    }

    return this.request({
      method: RequestMethod.Get,
      url: ENDPOINTS.API_LOAD_TOPPINGS_LIST_URL,
      search: url
    });
  }

  getCategoryDesign(categoryId: string) {
    const restaurantId: string = JSON.parse(storageService.localStorage.getItem('selectedRestaurant'));

    return this.request({
      method: RequestMethod.Get,
      url: ENDPOINTS.API_FIND_CATEGORY_DESIGN_URL,
      search: `location=${restaurantId}&category=${categoryId}`
    });
  }

  getCategoryDesigns(locationId: string) {
    return this.request({
      method: RequestMethod.Get,
      url: ENDPOINTS.API_FIND_CATEGORY_DESIGNS_URL,
      search: `location=${locationId}`
    });
  }

  getSuggestions(payload: { cartId: string, itemId: string }) {
    return this.request({
      method: RequestMethod.Get,
      url: ENDPOINTS.API_GET_SUGGESTIONS_URL,
      search: `cartId=${payload.cartId}&itemId=${payload.itemId}`
    });
  }

  trackSuggestion(payload: ITrackSuggestionRequest) {
    return this.request({
      method: RequestMethod.Get,
      url: ENDPOINTS.API_TRACK_SUGGESTION_URL,
      search: 'cartId=' + payload.cartId + '&sourceItemId=' + payload.sourceItemId +
        '&suggestItemId=' + payload.suggestItemId + '&personalized=' + payload.personalized +
        '&accepted=' + payload.accepted
    });
  }

  loadItemMap(locationId: string) {
    return this.request({
      method: RequestMethod.Get,
      url: ENDPOINTS.API_GET_ITEM_MAP_URL,
      search: `location=${locationId}`
    });
  }

  loadCoupons(categoryId: string) {
    const restaurantId: string = JSON.parse(storage.localStorage.getItem('selectedRestaurant'));

    return this.request({
      method: RequestMethod.Get,
      url: ENDPOINTS.API_GET_ITEMS_FOR_CATEGORY_URL,
      search: `location=${restaurantId}&category=${categoryId}`
    });
  }
}

export default new MenuService();
