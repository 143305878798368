import { Subject } from '../data/Subject';
import { MODAL_ACTIONS } from '../common.constants';

export class ModalBus {
    orderProgressModal$: Subject<boolean> = new Subject<boolean>();
    orderSubmissionTimeoutModal$: Subject<boolean> = new Subject<boolean>();
    selectOrderTypeModal$: Subject<boolean> = new Subject<boolean>();
    simpleMessageModal$: Subject<{ action: boolean, message?: string }> = new Subject<{ action: boolean, message?: string }>();
    splitItemChoicesModal$: Subject<boolean> = new Subject<boolean>();

    openSimpleMessageModal(message: string): void {
      this.simpleMessageModal$.next({
        action: MODAL_ACTIONS.OPEN,
        message
      });
    }
}

export default new ModalBus();
