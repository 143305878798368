
    import Vue from 'vue';
    import scheduleOrderMixin from '../../common/mixins/schedule-order.mixin';
    import designService from '../../common/services/design.service';
    import notifierService from '../../common/services/notifier.service';
    import storageService from '../../common/services/storage.service';
    import routeService from '../../common/services/route.service';
    import googleService from '../../common/services/google.service';
    import {Util} from '../../common/services/Util';
    import {ITenderRequest} from '../models/TenderRequest';
    import cartHelperService from '../../cart/services/cart-helper.service';
    import designMixin from '../../common/mixins/design.mixin';
    import { ICart } from '../../cart/cart.types';
    import { IUser } from '../../profile/stores/profile.store';
    import { IOrderType, ORDER_TYPES } from '../../restaurants/types/restaurant.types';

    const moment = require('moment');

    export default Vue.extend({
        mixins: [scheduleOrderMixin, designMixin],
        props: {
            cart: {
                type: Object as () => ICart,
                required: true
            },
            cartError: {
                type: String,
                required: false
            },
            restaurantCount: {
                type: Number,
                required: true
            },
            tenderRequest: {
                type: Object as () => ITenderRequest,
                required: true
            },
            updating: {
                type: Boolean,
                required: true
            },
            user: {
                type: Object as () => IUser,
                required: false
            }
        },
        computed: {
            // services

            _designService () {
                return designService;
            },

            _googleService () {
                return googleService;
            },

            _notifierService () {
                return notifierService;
            },

            routeService () {
                return routeService;
            },

            cartHelperService() {
                return cartHelperService;
            },

            // getters

            isSchedulingAllowed(): boolean {
                return this.restaurant.allowScheduling;
            },

            orderTime(): string {
                return this.cart.orderTimeString;
            },

            orderType(): string {
                return this.cart.orderType;
            },

            orderTypesLength(): number {
              return this.getOrderTypesAsArray().length;
            }
        },
        data() {
            return {
                endDeliverySubscription: null,
                showChangeLink: true,
                showOrderTimeSelection: false,
                showOrderTypeSelection: false,
                submitDeliverySubscription: null
            };
        },
        created () {
            if (this.restaurant.orderTypes[this.cart.orderType].objectId === ORDER_TYPES.DELIVERY) {
              if (!this.tenderRequest.deliveryAddress || !this.tenderRequest.deliveryAddress.addressLine) {
                this.chooseOrderType(this.restaurant.orderTypes[this.cart.orderType]);
              }
            }

            if (!!JSON.parse(storageService.sessionStorage.getItem('singleLocation'))) {
              this.showChangeLink = false;
            }
        },
        mounted () {
            let modal = this.routeService.currentRoute.params.modal;

            if (modal === 'delivery') {
                setTimeout(() => { // wait until modal element exists
                    this.openDeliveryModal();
                }, 500);
            }
        },
        methods: {
            getOrderTypesAsArray(): IOrderType[] {
                let values: IOrderType[] = [];

                for (let key of Object.keys(this.restaurant.orderTypes)) {
                    values.push(this.restaurant.orderTypes[key]);
                }

                return values;
            },

            chooseOrderType(type: IOrderType) {
                this.$emit('chooseType', {
                    restaurantId: this.restaurant.objectId,
                    orderTypeId: type.objectId
                });

                this.showOrderTypeSelection = false;
            },

            openDeliveryModal() {
                this.cartHelperService.openDeliveryModal();
            },

            showTimeSelection() {
                this.showOrderTimeSelection = true;
            },

            shouldShowError(): boolean {
                return this.cartError && this.cartError.indexOf('Order Time Invalid: ') >= 0;
            },

            getCartError(): string {
                if (this.cartError && this.cartError.indexOf(':') >= 0) {
                  return this.cartError.substring(this.cartError.indexOf(':') + 1);
                }

                return '';
            },

            getReadyTime() {
                if (this.cart.expectedTimeString) {
                    return Util.formatDate(moment(this.cart.expectedTimeString));
                } else {
                    return Util.formatDate(moment(this.currentTime).add(20, 'minutes'));
                }
            },

            scheduleOrderTimeAsap() {
                this.scheduleAsap();

                this.showOrderTimeSelection = false;
            },

            scheduleOrderTimeDeferred() {
                this.setOrderTime();

                this.showOrderTimeSelection = false;
            },

            isDeliveryOrderType() {
                if (!this.restaurant || !this.restaurant.orderTypes || !this.cart.orderType) {
                  return false;
                }

                return this.restaurant.orderTypes[this.cart.orderType].objectId === ORDER_TYPES.DELIVERY;
            },

            changeRestaurant () {
                this.$emit('changeRestaurant');
            }
        }
    });
