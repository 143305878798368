
import Vue from 'vue';
import cart from '../../cart/models/Cart';
import { IFeaturedItem } from '../stores/menu.store';
import notifierService from '../../common/services/notifier.service';
import routeService from '../../common/services/route.service';
import { ICart } from '../../cart/cart.types';
import { isItemInStock, isModifierInStock } from '../../cart/helpers/cart.helpers';
import AnalyticsManager from '../../common/services/analytics-manager.service';
import restaurants from '../../restaurants/models/Restaurants';
import { VALIDATIONS } from '../menu.constants';

export default Vue.extend({
  name: 'FeaturedItemCard',

  props: {
    item: {
      type: Object as () => IFeaturedItem,
      required: true
    }
  },

  computed: {
    // services
    _notifierService() {
      return notifierService;
    },

    _routeService() {
      return routeService;
    },

    // getters
    cart() {
      return cart;
    },

    isItemInStock() {
      return isItemInStock(this.cart.cart, this.item);
    },

    itemHasImage() {
      return this.item.imageLink !== null;
    }
  },
  methods: {
    addItem() {
      const cartPayload = {
        item: this.item,
        styleId: isModifierInStock(this.cart.cart, this.item.objectId, this.item.styleId) ? this.item.styleId: null,
        sizeId: isModifierInStock(this.cart.cart, this.item.objectId, this.item.sizeId) ? this.item.sizeId: null,
        showSuggestion: false,
        showAutoApplyMessage: false,
        quantity: 1
      };

      this.cart.addItem(cartPayload).then((cart: ICart) => {
        let data: any = {
          itemId: cartPayload.item.objectId
        };

        if (!cartPayload.styleId || !cartPayload.sizeId) {
          this.routeToCustomize(data).then(() => {
            if (cartPayload.styleId) {
              this.addStyleToItem(cartPayload.item.objectId, cartPayload.styleId);
            }
          });

          return;
        } else if (cartPayload.styleId) {
          const newItem = cart.items[cart.items.length - 1];

          this.addStyleToItem(newItem.objectId, cartPayload.styleId);
        }

        const hasRequirementValidations = cart.validations.find(validation => {
          return validation.type === VALIDATIONS.REQUIRED && validation.objectId === cart.items[cart.items.length - 1].objectId;
        });

        if (hasRequirementValidations) {
          this.routeToCustomize(data);
        }
      }).catch(error => {
        this._notifierService.error(error);
      });

      /* AnalyticsManager.track('Featured Item Selected', {
        cartId: this.cart.cart.objectId,
        itemId: this.item.itemId,
        itemName: this.item.name,
        locationId: restaurants.selectedRestaurantId
      });*/
    },

    addStyleToItem(itemId: string, styleId: string) {
      this.cart.updateStyle(itemId, styleId).catch(error => {
        this._notifierService.error(error);
      });
    },

    routeToCustomize(data: any) {
      return this._routeService.route('CustomizeItem', data);
    }
  }
});
