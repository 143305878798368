import Vue from 'vue';
import { ISubscription } from '../data/ISubscription';
import { ISubscriptionCallback, Subscribable } from '../data/Subscribable';
import notificationService from '../../common/messaging/notification.service';

export default {
  data () {
    return {
      observers: {},
      subscriptions: []
    };
  },
  methods: {
    mapSubscriptionsToData (map) {
      Object.keys(map).forEach(key => {
        this.subscribeToData(map[key], key);
      });
    },

    observe(notification: string, callback: () => void) {
      let callbacks = this.observers[notification];

      if (!callbacks) {
        callbacks = [];

        this.observers[notification] = callbacks;
      }

      callbacks.push(callback);

      notificationService.addObserver(notification, callback);
    },

    removeObserver(notification: string, callback?: () => void) {
      let callbacks = this.observers[notification];

      if (!callbacks) {
        return;
      }

      if (callback) {
        const i = callbacks.indexOf(callback);

        if (i !== -1) {
          callbacks.splice(i, 1);

          notificationService.removeObserver(notification, callback);
        }
      } else {
        callbacks.forEach(c => {
          notificationService.removeObserver(notification, c);
        });

        this.observers[notification].splice(0);
      }
    },

    subscribe<T>(observer: Subscribable<T>, handler: ISubscriptionCallback<T> | ((value: T) => void)): ISubscription {
      let sub = observer.subscribe(handler);

      this.subscriptions.push(sub);

      return sub;
    },

    subscribeToData<T>(observer: Subscribable<T>, name: string) {
      this.subscribe(observer, value => {
        Vue.set(this, name, value);
      });
    },

    unsubscribe(sub?: ISubscription | Array<ISubscription>): void {
      if (!sub) {
        return;
      }

      let i;

      if (Array.isArray(sub)) {
        for (let s of sub) {
          s.unsubscribe();

          i = this.subscriptions.indexOf(s);

          if (i !== -1) {
            this.subscriptions.splice(i, 1);
          }
        }
      } else {
        sub.unsubscribe();

        i = this.subscriptions.indexOf(sub);

        if (i !== -1) {
          this.subscriptions.splice(i, 1);
        }
      }
    }
  },
  destroyed () {
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }

    const notifications = Object.keys(this.observers);

    notifications.forEach(notification => {
      this.removeObserver(notification);
    });
  }
};
