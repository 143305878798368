import { LatLngLiteral } from '../../common/models/LatLng';
import { IAddress } from '../../common/models/Address';
import mapsService from '../services/maps.service';
import { List } from 'immutable';
import { IRestaurant, IZoneArea, ORDER_TYPES } from '../../restaurants/types/restaurant.types';
import inside from 'point-in-polygon-hao';
import distance from '@turf/distance';

const H = require('@here/maps-api-for-javascript').default;

declare var L: any;

function getLatLng(point: LatLngLiteral) {
  return new L.LatLng(point.lat, point.lng);
}

function getDistance(geocode: LatLngLiteral, latLng: LatLngLiteral) {
  return getLatLng(geocode).distanceTo(getLatLng(latLng));
}

export function calculateDrivingDistance(userLocation: LatLngLiteral, restaurantLocation: LatLngLiteral) {
  return getDistance(userLocation, restaurantLocation);
}

export function calculateClosestRestaurant(userLocation: LatLngLiteral, restaurantsList: IRestaurant[]) {
  let closestRestaurant: IRestaurant = null,
    count = 0,
    restaurantCount = restaurantsList.length,
    closestRestaurantDistance = Number.POSITIVE_INFINITY;

  for (let i = 0; i < restaurantsList.length; i++) {
    const restaurant = restaurantsList[i];
    const restaurantLocation = { lat: restaurant.latitude, lng: restaurant.longitude };

    const drivingDistance = calculateDrivingDistance(userLocation, restaurantLocation);

    if (drivingDistance && drivingDistance < closestRestaurantDistance) {
      closestRestaurantDistance = drivingDistance;

      closestRestaurant = restaurant;
    }

    count++;

    if (count === restaurantCount) {
      if (closestRestaurant === null) {
        throw 'Unable to calculate the closest restaurant.';
      } else {
        return closestRestaurant;
      }
    }
  }
}

function rectangleFromOppositeCorners(a, b) {
  return [
    a,
    [b[0], a[1]],
    b,
    [a[0], b[1]]
  ];
}

function polygonFromRectangle(rect) {
  const result = rect.slice(0);

  result.push(rect[0]);

  return result;
}

function existsInZone(zoneArea: IZoneArea, geocode: LatLngLiteral) {
  if (!zoneArea.shapeType) {
    return null;
  }

  function point(lat: string, lng: string) {
    return new H.geo.Point(parseFloat(lat), parseFloat(lng));
  }

  switch (zoneArea.shapeType.toLowerCase()) {
    case 'circle':
      const centerLatLng = zoneArea.shapeArea.split(',');
      const comparePoint = point(centerLatLng[0], centerLatLng[1]);
      const herePoint = new H.geo.Point(geocode.lat, geocode.lng);
      const distanceInMiles = distance(herePoint.toGeoJSON(), comparePoint.toGeoJSON(), {units: 'miles'});

      return (distanceInMiles <= zoneArea.radius);
    case 'rectangle':
      const bounds = zoneArea.shapeArea.split(',').map(b => parseFloat(b));
      const rectangle = rectangleFromOppositeCorners([bounds[0], bounds[1]], [bounds[2], bounds[3]]);
      const polygon = polygonFromRectangle(rectangle);

      return inside([geocode.lat, geocode.lng], [polygon]);
    case 'polygon':
      const polyPoints = [];
      const points = JSON.parse(zoneArea.shapeArea);

      for (let path of points) {
        polyPoints.push([path.lat, path.lng]);
      }

      const firstPoint = polyPoints[0];
      const lastPoint = polyPoints[polyPoints.length - 1];

      // last point must connect to first point
      if (firstPoint[0] !== lastPoint[0] || firstPoint[1] !== lastPoint[1]) {
        polyPoints.push(firstPoint);
      }

      return inside([geocode.lat, geocode.lng], [polyPoints]);
    case 'zip':
      return null;
    default:
      return null;
  }
}

function stringEquals(value1: string, value2: string) {
  if (value1 && value2) {
    return value1.toLowerCase() === value2.toLowerCase();
  }

  return false;
}

function isBlacklisted(address: IAddress, invalidAddresses: IAddress[]) {
  for (let value of invalidAddresses) {
    if (stringEquals(address.addressLine, value.addressLine)
      && stringEquals(address.city, value.city)
      && stringEquals(address.postalCode, value.postalCode)
      && stringEquals(address.stateCode, value.stateCode)) {
      if (value.addressLine2) {
        if (stringEquals(value.addressLine2, address.addressLine2)) {
          return true;
        }

        continue;
      }

      if (address.addressLine2) {
        return false;
      }

      return true;
    }
  }

  return false;
}

function getDeliveryZone(address: IAddress, restaurant: IRestaurant, geocode: LatLngLiteral): IZoneArea {
  if (!restaurant.deliveryCharge || !restaurant.deliveryCharge.zoneAreas ||
    restaurant.deliveryCharge.zoneAreas.length <= 0 ||
    restaurant.deliveryCharge.zoneAreas.filter(area => area.shapeArea && area.shapeArea.length > 0).length <= 0) {
    throw null;
  }

  let zoneAreas: IZoneArea[] = [];

  for (let zoneArea of restaurant.deliveryCharge.zoneAreas) {
    if (existsInZone(zoneArea, geocode)) {
      zoneAreas.push(zoneArea);
    }
  }

  if (zoneAreas.length <= 0) {
    throw 'Selected address not in delivery zone.';
  } else if (isBlacklisted(address, restaurant.invalidAddresses)) {
    throw 'Address not available for deliveries.';
  } else {
    let minPricedZone: IZoneArea = zoneAreas[0];

    for (let area of zoneAreas) {
      if (minPricedZone.amount && area.amount < minPricedZone.amount) {
        minPricedZone = area;
      }
    }

    return minPricedZone;
  }
}

function getDeliveryZoneRestaurants(address: IAddress, geocode: LatLngLiteral, restaurants: List<IRestaurant>): IRestaurant[] {
  const restaurantList: IRestaurant[] = [];

  restaurants.forEach((r: IRestaurant) => {
    if (isBlacklisted(address, r.invalidAddresses)) {
      return;
    }

    if (!r.orderTypes[ORDER_TYPES.DELIVERY]) {
      return;
    }

    r.deliveryCharge.zoneAreas.forEach((zone: IZoneArea) => {
      if (existsInZone(zone, geocode) && restaurantList.indexOf(r) === -1) {
        restaurantList.push(r);
      }
    });
  });

  return restaurantList;
}

export function validateSameAddress(results: IAddress[], address: IAddress): boolean {
  if (!results || !results.length) {
    return false;
  }

  return !!results.find(result => result.compare(address));
}

export function validateDeliveryAddress(
  address: IAddress,
  restaurants: List<IRestaurant>,
  restaurant?: IRestaurant,
  geocodeCallback?: (results: IAddress[]) => void): Promise<IZoneArea | IRestaurant[]> {
  return new Promise((resolve, reject) => {
    if (!address.addressLine || !address.city || (!address.stateCode && !address.stateName) || !address.postalCode) {
      reject('Not a complete address.');

      return;
    }

    const finish = () => {
      let result;
      const geocode = {lat: address.latitude, lng: address.longitude};

      if (restaurant) {
        result = getDeliveryZone(address, restaurant, geocode);
      } else {
        result = getDeliveryZoneRestaurants(address, geocode, restaurants);
      }

      resolve(result);
    };

    if (typeof address.latitude === 'number'
      && typeof address.longitude === 'number'
      && !(address.latitude === 0 && address.longitude === 0)) {
      finish();
    } else {
      mapsService.geocodeAddress(address).then(results => {
        if (geocodeCallback)
          geocodeCallback(results);

        if (!results || results.length === 0) {
          reject(results);

          return;
        }

        const resultSame = validateSameAddress(results, address);

        if (!resultSame) {
          reject(results);

          return;
        }

        address.latitude = results[0].latitude;
        address.longitude = results[0].longitude;

        finish();
      }).catch(reject);
    }
  });
}
