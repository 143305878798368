
  import Vue from 'vue';
  import {ICoupon} from '../../coupons/models/Coupon';
  import CouponModern from './CouponModern.vue';
  import routeService from '../../common/services/route.service';
  import NotificationsModern from '../../menu/components/NotificationsModern.vue';

  export default Vue.extend({
    components: {
      CouponModern,
      NotificationsModern
    },
    props: {
      coupons: {
        type: Array as () => Array<ICoupon>,
        required: true
      },

      points: {
        type: Number,
        required: true
      },

      pointsDescription: {
        type: String,
        required: true
      },

      programName: {
        type: String,
        required: false
      }
    },
    methods: {
      chooseCoupon(coupon) {
        this.$emit('chooseCoupon', coupon);
      },

      goBack() {
        routeService.route('MainMenu');
      }
    }
  });
