import cart from '../models/Cart';
import notifierService from '../../common/services/notifier.service';
import menu from '../../menu/models/Menu';
import profile from '../../profile/models/Profile';
import restaurants from '../../restaurants/models/Restaurants';
import routeService from '../../common/services/route.service';
import { emptyAddress } from '../../order/models/TenderRequest';
import order from '../../order/models/Order';
import {Subscriber} from '../../common/services/Subscriber';
import modalService from '../../common/services/modal.service';
import {IAddress} from '../../common/models/Address';
import googleService from '../../common/services/google.service';
import {Util} from '../../common/services/Util';
import notificationService from '../../common/messaging/notification.service';
import maps from '../../maps/models/Maps';
import { CLEAR_ALERT, SHOW_ALERT } from '../../common/messaging/notifications';
import { ISubscription } from '../../common/data/ISubscription';
import {
    CHECKED_RESTAURANT_DELIVERY_OPEN,
    CHECKED_RESTAURANT_OPEN,
    IRestaurant,
    ORDER_TYPES
} from '../../restaurants/types/restaurant.types';
import { checkOrderTime, isDelivery, isOrderTimeValid, isSchedulingAllowed, reviewOrder } from '../helpers/cart.helpers';
import { IUnavailableMessage } from '../cart.types';
import loyaltyService from '../../coupons/services/loyalty.service';

const moment = require('moment');

export class CartHelperService extends Subscriber {
    _isDeliveryOpen: boolean;
    _isOpen: boolean;
    deliverySubscriptions: Array<ISubscription>;

    constructor() {
        super();

        notificationService.addObserver(CHECKED_RESTAURANT_OPEN, open => {
            this._isOpen = open;
        });

        notificationService.addObserver(CHECKED_RESTAURANT_DELIVERY_OPEN, open => {
            this._isDeliveryOpen = open;
        });
    }

    // getters

    get isSchedulingAllowed(): boolean {
        return isSchedulingAllowed(this.selectedRestaurant);
    }

    get todayFormatted () {
      return new Date().toLocaleDateString('en-US', {day: '2-digit', month: 'numeric'});
    }

    get formattedStoreHourRange(): String {
      if (!this.selectedRestaurant) {
          return;
      }

      return Util.getFormattedHourRangeFromHours(moment(this.currentTime), this.selectedRestaurant.hours, ORDER_TYPES.DEFAULT)
        .split('\n').map(range => '(' + range + ')').join(' - ');
    }

    get currentTime() {
        return restaurants.currentTime;
    }

    get isDeliveryOpen() {
        if (typeof this._isDeliveryOpen !== 'undefined') {
            return this._isDeliveryOpen;
        }

        return restaurants.isDeliveryOpen;
    }

    get isOpen() {
        if (typeof this._isOpen !== 'undefined') {
            return this._isOpen;
        }

        return restaurants.isOpen;
    }

    get selectedRestaurant() {
        return restaurants.selectedRestaurant;
    }

    get sizeMap() {
        return restaurants.sizeMap;
    }

    get tenderRequest() {
        return order.tenderRequest;
    }

    get updating() {
        return cart.updating;
    }

    // methods

    isStoreClosedAllDay(restaurant: IRestaurant): boolean {
      return Util.isHourRangeClosedAllDay(moment(this.currentTime), restaurant.hours, ORDER_TYPES.DEFAULT);
    }

    clearAlert(): void {
        notificationService.notify(CLEAR_ALERT);
    }

    isOrderTimeValid(orderType?: string): boolean {
        return isOrderTimeValid(this.selectedRestaurant, this.currentTime, cart.cart, orderType);
    }

    get selectedOrderType(): string {
        return cart.cart.orderType;
    }

    get isDelivery(): boolean {
        return isDelivery(cart.cart);
    }

    reviewOrder(force: boolean = false, route: boolean = true) {
        const result: any = reviewOrder(
            this.selectedRestaurant,
            this.currentTime,
            cart.cart,
            profile.user,
            this.tenderRequest,
            this.sizeMap,
            (userId: string) => {
                cart.addUser(userId);
            }, (message: string, notification?: string) => {
                notificationService.notify(SHOW_ALERT, { message, notification });
            }, (error: string, id?: string, payload?: object) => {
                notifierService.error(error, id, payload);
            }, () => {
                this.openDeliveryModal(true);
            }, (invalidCoupons: string[]) => {
                this.removeItems(invalidCoupons);
            },
            () => {
                modalService.openScheduleOrderModal(true);
            },
            (messages: IUnavailableMessage[]) => {
                modalService.openItemUnavailableModal(messages);
            },
            menu.categories,
            routeService.currentRoute.name
        );

        if (route) {
          if (force || (result && result.success)) {
            cart.fetchBySession().then(() => {
              routeService.route('OrderReview').then(() => {
                if (result.failureMessage && result.failureMessage !== '') {
                  notificationService.notify(SHOW_ALERT, { message: result.failureMessage });
                }
              });
            });
          }
        } else {
          if (result.failureMessage && result.failureMessage !== '') {
            notificationService.notify(SHOW_ALERT, { message: result.failureMessage });
          }
        }

        return result;
    }

    openDeliveryModal(isCheckingOut: boolean = false): Promise<any> {
        return new Promise<any>(resolve => {
            this.unsubscribe(this.deliverySubscriptions);

            this.deliverySubscriptions = [];

            this.deliverySubscriptions.push(this.subscribe(modalService.cancelDelivery$, () => {
                this.unsubscribe(this.deliverySubscriptions);
            }));

            this.deliverySubscriptions.push(modalService.submitDelivery$.subscribe(
              (payload: { saveDeliveryAddress: boolean, deliveryAddress: IAddress }) => {
                this.chooseDelivery(payload.saveDeliveryAddress, payload.deliveryAddress, isCheckingOut, this.selectedRestaurant)
                    .then(resolve);
            }));

            this.deliverySubscriptions.push(modalService.endDeliverySubscriptions$.subscribe(() => {
                this.unsubscribe(this.deliverySubscriptions);
            }));

            modalService.selectDeliveryModal();
        });
    }

    chooseDelivery(saveAddress: boolean, address: IAddress, isCheckingOut: boolean, restaurant: IRestaurant): Promise<any> {
        return maps.validateDeliveryAddress(address, restaurant).then(result => {
                this.tenderRequest.deliveryAddress = address;

                const deliveryPromise = this.updateDeliveryZone(result);

                if (isCheckingOut) {
                    deliveryPromise.then(() => {
                        this.reviewOrder();
                    });
                } else {
                    if (loyaltyService.linkOffer) {
                        const found = cart.cart.coupons.find(c => c.couponId === loyaltyService.linkOffer.couponId);

                        if (!found) {
                            deliveryPromise.then(cartObj => {
                                loyaltyService.chooseCoupon(loyaltyService.linkOffer, false);
                            });
                        }
                    }
                }

                if (saveAddress && profile.user) {
                    this.saveAddressToProfile(address, profile.user.objectId);
                }

                modalService.closeDeliveryModal();
                this.unsubscribe(this.deliverySubscriptions);

                return deliveryPromise;
            })
            .catch(result => {
                if (result) {
                    let notification: string = '';

                    if ((typeof result) === 'string') {
                        notification = result;
                    } else if (result && (typeof result) === 'object' && result.length && result.length > 0) {
                        this.setSuggestedAddresses(result);

                        return;
                    } else if (result && result.hasOwnProperty('length') && result.length === 0) {
                        notification = 'Address not found.';
                    } else {
                        notification = googleService.getNotificationMessage(result);
                    }

                    notifierService.error(notification, 'DELIVERY_MODAL', null, true);
                }

                this.removeDeliveryZone();
            });
    }

    saveAddressToProfile(address: IAddress, userId: string) {
        let payload = {
            userId,
            addressLine: address.addressLine,
            addressLine2: address.addressLine2,
            city: address.city,
            latitude: address.latitude,
            longitude: address.longitude,
            stateCode: address.stateCode,
            stateName: address.stateName,
            postalCode: address.postalCode,
            type: 'DELIVERY'
        };

        profile.saveAddress(payload);
    }

    setSuggestedAddresses(payload: any) {
        profile.setSuggestedAddresses(payload);
    }

    removeDeliveryZone() {
        cart.removeDeliveryZone();
    }

    removeItems(itemIds: string[]) {
        cart.removeItemsThenReview(itemIds);
    }

    updateDeliveryZone(result) {
        return Util.waitUntil(() => !this.updating).then(() => {
            return cart.setDeliveryZone(result);
        });
    }

    checkOrderTime(orderType?: string, isCheckingOut: boolean = false, openScheduleOrderModal: boolean = true): boolean {
        return checkOrderTime(
          this.selectedRestaurant,
          this.currentTime,
          cart.cart,
          orderType,
          isCheckingOut,
          openScheduleOrderModal ? () => {
              modalService.openScheduleOrderModal(isCheckingOut);
          } : null,
          (message: string, notification?: string) => {
              notificationService.notify(SHOW_ALERT, { message, notification });
          },
          (error: string) => {
            notifierService.error(error);
          }
        );
    }

    setOrderType(orderType: string, openDeliveryModal: (boolean | Function) = true) {
        if (orderType === cart.cart.orderType) {
            if (orderType === ORDER_TYPES.DELIVERY && openDeliveryModal) {
                if (typeof openDeliveryModal === 'boolean') {
                    this.openDeliveryModal();
                } else if (typeof openDeliveryModal === 'function') {
                    openDeliveryModal();
                }
            }

            return false;
        }

        const result = cart.setOrderType(orderType);

        if (orderType === ORDER_TYPES.DELIVERY) {
            this.tenderRequest.deliveryAddress = emptyAddress();

            if (typeof openDeliveryModal === 'boolean') {
                this.openDeliveryModal();
            } else if (typeof openDeliveryModal === 'function') {
                openDeliveryModal();
            }
        } else {
            this.checkOrderTime(orderType);
        }

        modalService.selectOrderType(orderType);

        return result;
    }

    get doesCartHaveItems(): boolean {
        return !!cart.cart
            && ((cart.cart.items && cart.cart.items.length > 0)
                || (cart.cart.coupons && cart.cart.coupons.length > 0));
    }
}

export default new CartHelperService();
