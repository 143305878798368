
import Vue from 'vue';
import designMixin from '../mixins/design.mixin';
import modalMixin from '../mixins/modal.mixin';

export default Vue.extend({
  mixins: [designMixin, modalMixin],
  props: {
    closeOnBackdropClick: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      required: false,
      default: true
    },
    title: String
  },
  methods: {
    onHide() {
      localStorage.removeItem('auth_token');

      location.reload();
      // this.$emit('hide');
    },

    onShow() {
      this.$emit('show');
    }
  }
});
