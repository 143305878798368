import routeService from './route.service';
import profile from '../../profile/models/Profile';
import { Subscriber } from './Subscriber';
import storageService from './storage.service';
import { navigateToMenu } from '../mixins/base-component.mixin';
import getRouter from '../../router';
import { Util } from './Util';
import store from '../../../store';
import { isAndroid, isiOS, isMobileApp } from '../helpers/environment.helpers';

declare var cordova: any;
declare var PushNotification: any;

const APP_RESUMED = 'APP_RESUMED';

export class EnvironmentService extends Subscriber {
    _forceLocationFinder: boolean;
    protected _directRoute: string;
    protected _isInForeground = true;
    _launchNotificationPayload: { notificationId: string, locationId: number, offerId: number, orderId: any };
    protected _notificationId: string;
    protected couponCount: number;
    protected deviceToken: string;
    protected logOutListener: EventListener;
    protected onResume: EventListener;
    protected pushNotification: any;
    _orderId: string;
    _offerId: string;

    constructor() {
        super();

        if (this.isMobileApp) {
            let onDeviceReady;

            onDeviceReady = () => {
                document.removeEventListener('deviceready', onDeviceReady);

                this.initializeCordova();
            };

            document.addEventListener('deviceready', onDeviceReady, false);

            store.watch(() => profile.user, () => { // TODO: test
                const user = profile.user;

                if (typeof cordova !== 'undefined' && cordova.plugins
                  && cordova.plugins.notification && cordova.plugins.notification.badge) {
                    cordova.plugins.notification.badge.set((user && user.coupons) ? user.coupons.length : 0);
                } else {
                    this.couponCount = (user && user.coupons) ? user.coupons.length : 0;
                }
            });

            let loggedIn = (result) => {
                if (!result) {
                    return;
                }

                if (this.deviceToken) {
                    profile.setDeviceToken(this.deviceToken);
                }
            };

            // TODO: test
            store.watch(() => profile.loginSuccess, loggedIn);
            store.watch(() => profile.registerSuccess, loggedIn);

            this.logOutListener = (event: CustomEvent) => {
                if (this.deviceToken && event.detail) {
                    profile.unsetDeviceToken(this.deviceToken, event.detail);

                    this.deviceToken = null;
                }
            };

            profile.addLogOutEventListener(this.logOutListener);

            // wait until first route finishes to handle notification
            Util.waitUntil(() => getRouter()).then(() => {
                const router = getRouter();

                router.onReady(() => {
                    if (this._launchNotificationPayload) {
                        this.handleNotification(this._launchNotificationPayload);
                    }
                });
            });
        }
    }

    // methods

    initializeCordova () {
        if (cordova.plugins && cordova.plugins.Keyboard) {
            window['cordova'].plugins.Keyboard.hideKeyboardAccessoryBar(false);
        }

        if (typeof this.couponCount !== 'undefined' && cordova.plugins
          && cordova.plugins.notification && cordova.plugins.notification.badge) {
            cordova.plugins.notification.badge.set(this.couponCount);

            this.couponCount = undefined;
        }

        this.pushNotification = PushNotification.init({
            android: {
                senderID: 792016323816
            },
            ios: {
                alert: 'true',
                badge: 'true',
                sound: 'true'
            }
        });

        this.pushNotification.on('registration', data => {
            if (profile.user && profile.user.objectId) {
                if (this.deviceToken) {
                    profile.unsetDeviceToken(this.deviceToken, profile.user.objectId);
                }

                profile.setDeviceToken(data.registrationId);
            }

            this.deviceToken = data.registrationId;
        });

        this.pushNotification.on('error', e => {
            console.log(e.message);
        });

        let backgroundNotification;

        const onNotification = payload => {
            backgroundNotification = null;
            this._notificationId = null;
            this._launchNotificationPayload = null;

            if (!payload.foreground) {
                if (payload.notificationId) {
                    this._notificationId = payload.notificationId;

                    profile.receivedNotification(this._notificationId);
                }

                if (payload.coldstart) {
                    this._launchNotificationPayload = payload;
                }

                if (routeService.hasInitiallyRouted) {
                    this.handleNotification(payload);
                }
            }
        };

        this.pushNotification.on('notification', data => {
            const payload = data.additionalData;

            if (!this._isInForeground) {
                backgroundNotification = payload;

                return;
            }

            onNotification(payload);
        });

        document.addEventListener('pause', () => {
            this._isInForeground = false;
        }, false);

        document.addEventListener('resume', () => {
            this._isInForeground = true;

            if (backgroundNotification) {
                onNotification(backgroundNotification);
            }
        }, false);

        this.onResume = () => {
            document.dispatchEvent(new Event(APP_RESUMED));
        };

        document.addEventListener('resume', this.onResume, false);
        document.addEventListener('active', this.onResume, false);

        navigator['splashscreen'].hide();
    }

    get isMobile(): boolean {
        return this.isiOS || this.isAndroid || this.isMobileApp;
    }

    get isiOS(): boolean {
        return isiOS();
    }

    get isAndroid(): boolean {
        return isAndroid();
    }

    get isMobileApp(): boolean {
        return isMobileApp();
    }

    get launchNotificationPayload(): Object {
        return this._launchNotificationPayload;
    }

    get notificationId(): string {
        return this._notificationId;
    }

    get isInForeground(): boolean {
        return this._isInForeground;
    }

    handleNotification(payload: { notificationId: string, locationId: number, offerId: number, orderId: any }): void {
        if (profile.user && profile.user.objectId && payload.orderId) {
            if (routeService.hasInitiallyRouted) {
                routeService.route('Historical');
            }

            return;
        }

        if (payload.offerId && payload.locationId) {
            const data = {
                offerId: payload.offerId,
                locationId: payload.locationId
            };

            const evt = $.Event('pushnotification', data);

            $(window).trigger(evt);
        }
    }

    clearNotificationId(): void {
        this._notificationId = null;
    }

    navigateToLandingPage(): boolean {
        let selectedRestaurant: string = JSON.parse(storageService.localStorage.getItem('selectedRestaurant'));

        if (selectedRestaurant) {
            navigateToMenu();
        } else {
            routeService.route('RestaurantsList');
        }

        return true;
    }

    addAppResumedListener(listener: EventListener): void {
        document.addEventListener(APP_RESUMED, listener);
    }

    open(url: string): void {
        if (this.isMobileApp) {
            window.open(url, '_system');
        } else {
            window.location.href = url;
        }
    }

    set directRoute(routeName: string) {
        this._directRoute = routeName;
    }

    get directRoute() {
        return this._directRoute;
    }

    get orderId() {
        return this._orderId;
    }

    set orderId(value: string) {
      this._orderId = value;
    }

    get forceLocationFinder() {
        return this._forceLocationFinder;
    }

    set forceLocationFinder(value: boolean) {
        this._forceLocationFinder = value;
    }

    get offerId() {
        return this._offerId;
    }

    set offerId(value) {
        this._offerId = value;
    }
}

export default new EnvironmentService();
