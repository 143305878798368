import {IAddress} from '../../common/models/Address';

export const emptyAddress = () => ({
    objectId: '',
    type: null,
    houseNumber: null,
    streetName: null,
    addressLine: '',
    addressLine2: '',
    city: '',
    phone: '',
    postalCode: '',
    stateName: '',
    stateCode: '',
    primaryAddress: false,
    note: null,
    latitude: 0.0,
    longitude: 0.0,
    compare: null,
    country: ''
});

const emptyCreditCardDate = {
    month: '',
    year: ''
};

export interface ITenderRequest {
    billingAddress: IAddress;
    comments: string;
    couponCodes: string[];
    creditCardId: string;
    deliveryAddress: IAddress;
    encryptedCardInfo: string;
    expirationDate: string;
    cvvCode: string;
    email: string;
    firstName: string;
    lastName: string;
    payByCash: boolean;
    phone: string;
    tip: number;
    total: number;
    userId: string;
    notificationId: string;
    hostedPaymentId: string;
    hostedPaymentResponse: Object;
    saveCreditCard: boolean;
    giftCardNumber: string;
    giftCardAmount: number;
    payByGiftCard: boolean;
    giftCardCVV: string;
}

export class TenderRequest implements ITenderRequest {
    comments: string = '';
    couponCodes: string[] = [];
    tip: number;
    total: number = 0;
    userId: string = '';
    firstName: string = '';
    lastName: string = '';
    email: string = '';
    phone: string = '';
    creditCardId: string = '';
    encryptedCardInfo: string = '';
    expirationDate: string =  null;
    cvvCode: string = null;
    payByCash: boolean = true;
    billingAddress: IAddress = emptyAddress();
    deliveryAddress: IAddress = emptyAddress();
    notificationId: string = null;
    hostedPaymentId: string;
    hostedPaymentResponse: Object;
    saveCreditCard: boolean = false;
    giftCardNumber: string;
    giftCardAmount: number;
    payByGiftCard: boolean = false;
    giftCardCVV: string = '';

    constructor(opts?: ITenderRequest) {
        if (opts) {
            for (let key in opts) {
                if (opts.hasOwnProperty(key)) {
                    this[key] = opts[key];
                }
            }
        }
    }
}

export interface ICreditCardInfo {
    cardholderName: string;
    cardNumber: string;
    cvvCode: string;
    expirationDate: ICreditCardDate;
    savedCard: boolean;
}

export interface ICreditCardDate {
    month: string;
    year: string;
}

export class CreditCardInfo implements ICreditCardInfo {
    billingAddress: IAddress = emptyAddress();
    cardholderName: string = '';
    cardNumber: string = '';
    cvvCode: string = '';
    expirationDate: ICreditCardDate = emptyCreditCardDate;
    savedCard: boolean = false;

    constructor(opts?: ICreditCardInfo) {
        for (let key in opts) {
            if (opts.hasOwnProperty(key)) {
                this[key] = opts[key];
            }
        }
    }
}
