import {LatLngLiteral} from '../../common/models/LatLng';
import maps from '../../maps/models/Maps';
import Vue from 'vue';
import {IAddress} from '../../common/models/Address';
import { sortLocations } from '../helpers/restaurant-list.helpers';
import { IOrderType, IRestaurant } from '../types/restaurant.types';
import { ORDER_TYPES } from '../../cart/cart.types';

const FIFTY_MILES_TO_METERS = 50 * 1.6 * 1000;

export default {
  props: {
    deliveryAddress: Object as () => IAddress,
    geocodedOrigin: Object as () => LatLngLiteral,
    orderType: String,
    restaurants: {
      type: Array as () => Array<IRestaurant>,
      required: true
    },
    userInputOrigin: {
      type: String,
      required: false
    },
    userLocation: {} // LatLngLiteral | boolean
  },
  computed: {
    deliveryAddressString(): string {
      const address = this.deliveryAddress;

      if (!address) {
        return null;
      }

      let addressLine = `${address.addressLine}`;

      if (address.addressLine2) {
        addressLine = addressLine.concat(`, ${address.addressLine2}`);
      }

      return `${addressLine}, ${address.city}, ${address.stateName} ${address.postalCode}`;
    },

    normalizedUserLocation() {
      if (!this.userLocation) {
        return null;
      }

      // check for user location and sort restaurants
      // for some reason, userLocation is sometimes a POJO and sometimes an immutableJS object
      // it is consistent, though, as to which configuration returns which
      return this.userLocation && this.userLocation.hasOwnProperty('size')
        ? this.userLocation.toObject()
        : this.userLocation;
    },

    origin() {
      let origin = this.normalizedUserLocation;

      if (!origin && this.geocodedOrigin && Object.keys(this.geocodedOrigin).length > 0) {
        origin = this.geocodedOrigin;
      }

      if (!origin) {
        if (this.deliveryAddress) {
          origin = {
            lat: this.deliveryAddress.latitude,
            lng: this.deliveryAddress.longitude
          };
        } else {
          origin = null;
        }
      }

      return origin;
    },

    restaurantsForOrderType() {
      let result = this.restaurants;

      if (this.orderType) {
        if (this.locationFinderActive) {
          if (this.orderType !== ORDER_TYPES.DELIVERY) {
            // all non-delivery order types are the same for location finder
            return result.filter(rest => Object.values(rest.orderTypes).find(
              (type: IOrderType) => type.active && type.objectId !== ORDER_TYPES.DELIVERY)
            );
          }
        }

        return result.filter(rest => rest.orderTypes[this.orderType]
          && rest.orderTypes[this.orderType].active);
      }

      return result;
    }
  },
  watch: {
    geocodedOrigin(val) {
      if (val) {
        this.calculateDistances();
      }
    },

    restaurants(val) {
      this.restaurantList = val.slice(0);

      if (this.restaurantList) {
        for (let i = 0; i < this.restaurantList.length; i++) {
          this._setDistances(this.restaurantList[i].objectId, null);
        }
      }

      if (this.userInputOrigin || this.userLocation || this.geocodedOrigin) {
        this.calculateDistances();
      }
    },

    userInputOrigin(val) {
      if (val) {
        this.calculateDistances();
      }
    },

    userLocation(val) {
      if (val) {
        this.calculateDistances();
      }
    }
  },
  data: () => ({
    calculateClosestRestaurantIntervalId: null,
    calculatedDistances: false,
    restaurantDistances: {},
    restaurantDistanceStrings: {},
    restaurantList: null
  }),
  created() {
    if (this.restaurants) {
      this.restaurantList = this.restaurantsForOrderType.slice(0);

      if (this.restaurantList) {
        for (let i = 0; i < this.restaurantList.length; i++) {
          this._setDistances(this.restaurantList[i].objectId, null);
        }
      }
    }

    if (this.geocodedOrigin || this.userInputOrigin || this.userLocation) {
      this.calculateDistances();
    }
  },
  methods: {
    getDistanceString(restaurant: IRestaurant): string {
      return this.restaurantDistanceStrings[restaurant.objectId];
    },

    calculateDistances() {
      if ((this.origin === null || this.origin === false) && !this.locationFinderActive) {
        this.restaurantList = this._sortRestaurantListByDistance(this.restaurantsForOrderType);
      } else if (this.origin === null || Object.keys(this.origin).length > 0) {
        this.calculatedDistances = true;

        const promises = [];

        this.restaurantsForOrderType.forEach(location => {
          promises.push(this._getRestaurantDistance(location));
        });

        Promise.all(promises).then(() => {
          if (this.showNoLocationsFoundMessage || this.findLocations) {
            this.restaurantList = this.restaurantsForOrderType.reduce((prev, next) => {
              if (this.restaurantDistances[next.objectId] <= FIFTY_MILES_TO_METERS) {
                prev.push(next);
              }

              return prev;
            }, []);
          } else {
            this.restaurantList = this.restaurantsForOrderType.slice(0);
          }

          this.restaurantList = this._sortRestaurantListByDistance(this.restaurantList);
        });
      }
    },

    // Private methods
    _getRestaurantDistance(restaurant) {
      const restaurantLocation = {lat: restaurant.latitude, lng: restaurant.longitude};

      const origin = this.origin;

      if (origin) {
        return maps.calculateDrivingDistance(origin, restaurantLocation)
          .then(distance => {
            this._setDistances(restaurant.objectId, distance);
          })
          .catch(() => {
            // Hmmmm
          });
      } else {
        return new Promise<void>(resolve => {
          this._setDistances(restaurant.objectId, null);

          resolve();
        });
      }
    },

    _sortRestaurantListByDistance(restaurantList): any {
      return sortLocations(restaurantList, this.userInputOrigin, this.restaurantDistances);
    },

    _setDistances(restaurantId: string, distance): void {
      if (typeof distance === 'number') {
        Vue.set(this.restaurantDistanceStrings, restaurantId, maps.formatDistance(distance));
        Vue.set(this.restaurantDistances, restaurantId, distance);
      } else {
        Vue.set(this.restaurantDistanceStrings, restaurantId, null);
        Vue.set(this.restaurantDistances, restaurantId, null);
      }
    }
  }
};
