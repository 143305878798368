import './vendor/jquery';
import 'bootstrap/js/modal'; // or just 'bootstrap'
import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueRouter from 'vue-router';
import Notifications from 'vue-notification';
import smHidden from './app/common/directives/sm-hidden';
import Vuex from 'vuex';
import { setUpRouter } from './app/router';

Vue.use(Vuex);

setUpRouter(require('./app/app.routes').default);

import routeService from './app/common/services/route.service';
const test = routeService; // force import to avoid circular dependency

declare var VENDOR_PREFIX: string;

Vue.use(VueGoogleMaps, {
    load: {
        client: 'gme-diamondtouchincdba',
        libraries: 'geometry'
    },
    installComponents: true
});

Vue.directive('sm-hidden', smHidden);

function isMobile () {
    return /iphone|ipad|android/.test(window.navigator.userAgent.toLowerCase());
}

function loadStylesheet (url) {
    const link = $('<link>');
    link.attr('rel', 'stylesheet');
    link.attr('href', url);

    $('head').append(link);
}

loadStylesheet('//cdnjs.cloudflare.com/ajax/libs/toastr.js/latest/css/toastr.min.css');

Vue.use(VueRouter);
Vue.use(Notifications);

const app = new Vue({
    el: '#main',
    store: require('./store').default,
    components: {
        AppContainer: require('./app/AppContainer').default
    }
});
