export default {
    props: {
        showFractions: {
            type: Boolean,
            required: true
        },

        showSizes: {
            type: Boolean,
            required: true
        },

        showStyles: {
            type: Boolean,
            required: true
        }
    }
};
