import routeService from '../../common/services/route.service';
import { ERROR_DETAILS } from '../../common/common.constants';
import storageService from '../../common/services/storage.service';
import { RESTAURANTS_STORE } from '../../restaurants/stores/restaurants.store';
import { ACCOUNT_STORE} from '../stores/account.store';
import store from '../../../store';
import { VuexModel } from '../../common/data/VuexModel';
import environmentService from '../../common/services/environment.service';
import { IFeedbackRequest } from '../types/account.types';

export class Account extends VuexModel {
  // getters

  get accountInfo() {
    return this.state.entities.accountInfo;
  }

  get design() {
    return this.state.entities.accountDesign;
  }

  get feedbackRequest() {
    return this.state.feedbackRequest;
  }

  get id(): string {
    return this.state.id;
  }

  get iosLogo() {
    return this.state.iosLogo;
  }

  get loading() {
    return this.state.loading;
  }

  get locationFinderActive(): boolean {
    return this.state.entities.accountInfo.locationFinderActive || environmentService.forceLocationFinder;
  }

  get orderSubmissionTimeout(): number {
    return this.state.entities.accountInfo ? this.state.entities.accountInfo.orderSubmissionTimeout : null;
  }

  get storeName() {
    return ACCOUNT_STORE;
  }

  // methods

  initialize(accountId: string, previewMode: boolean) {
    // let Restaurants model handle this action
    store.dispatch(RESTAURANTS_STORE + '/setLoading', true);

    return this.dispatch('initialize', { accountId, previewMode }).then(payload => {
      let result;

      if (payload.hasOwnProperty('success') && !payload.success) {
        storageService.localStorage.removeItem('tolAccountId');

        routeService.route('ErrorDetails', {details: ERROR_DETAILS.INVALID_ACCOUNT});

        result = null;
      } else {
        // override account query parameter with internal id
        storageService.localStorage.setItem('tolAccountId', payload.account.objectId);

        result = payload;

        // let Restaurants model handle this action
        store.dispatch(RESTAURANTS_STORE + '/setRestaurants', { restaurants: payload.restaurants, previewMode });
      }

      return result;
    });
  }

  load(accountId: string) {
    return this.dispatch('loadAccount', accountId).then(payload => {
      if (payload.hasOwnProperty('success') && !payload.success) {
        storageService.localStorage.removeItem('tolAccountId');
        routeService.route('ErrorDetails', {details: ERROR_DETAILS.INVALID_ACCOUNT});

        return null;
      } else {
        return payload;
      }
    });
  }

  submitFeedback(feedbackRequest: IFeedbackRequest) {
    feedbackRequest.account = this.id;

    return this.dispatch('submitFeedback', feedbackRequest);
  }
}

export default new Account();
