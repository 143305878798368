
import Vue from "vue";
import '@fortawesome/fontawesome-pro';

export default Vue.extend({
  name: 'ModalModern',

  props: {
    allowClose: {
      type: Boolean,
      default: true
    },

    title: {
      required: true,
      type: String
    }
  },

  data: () => ({
    isOpen: false
  }),

  created(): void {
    this.adjustDocumentVhUnit();

    window.addEventListener('resize', this.adjustDocumentVhUnit);
  },

  beforeDestroy () {
    if (this.isOpen) {
      this.close();
    }

    window.removeEventListener('resize', this.adjustDocumentVhUnit);
  },

  methods: {
    /*
     * Adjust vh property so we can calculate the real viewport height.
     * Fix for browsers showing the browser address bar.
     * https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
     * */
    adjustDocumentVhUnit () {
      let vh = window.innerHeight * 0.01;

      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },

    close () {
      document.body.classList.remove('modal-open');

      this.isOpen = false;

      this.$emit('close');
    },

    onBackdropClick() {
      if (this.allowClose) {
        this.close();
      }
    },

    onEscape() {
      if (this.allowClose) {
        this.close();
      }
    },

    open () {
      this.$el.setAttribute('tabindex', '0');
      this.$el.focus();

      document.body.classList.add('modal-open');

      this.isOpen = true;
    }
  }
});
