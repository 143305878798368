import { isEntireOrderCoupon } from '../../coupons/helpers/coupon.helpers';
import { ICoupon } from 'app/coupons/models/Coupon';
import { ICart } from '../../cart/cart.types';

export function decideNavigationToInnerLandingPage(
  directRoute: string,
  offerId: string,
  loadCouponByPLU: (plu: string) => Promise<ICoupon>,
  getLinkOffer: () => ICoupon,
  setLinkOffer: (coupon: ICoupon) => void,
  route: (name: string) => void,
  routeToMenu: () => void,
  navigateToMenu: () => Promise<void>,
  startCouponWalkthrough: (coupon: ICoupon) => void,
  getCart: () => ICart,
  isLinkOfferFulfilled: boolean): Promise<any> {
  if (directRoute) {
    route(directRoute);

    return Promise.resolve();
  }

  const loadedOffer = getLinkOffer();

  if (loadedOffer) {
    if (isEntireOrderCoupon(loadedOffer) || isLinkOfferFulfilled) {
      navigateToMenu();
    } else {
      startCouponWalkthrough(loadedOffer);
    }

    return Promise.resolve(loadedOffer);
  } else {
    let promise;

    if (offerId) {
      promise = loadCouponByPLU(offerId);
    } else {
      promise = Promise.resolve();
    }

    return promise.then(coupon => {
      setLinkOffer(coupon);

      if (coupon) {
        if (isEntireOrderCoupon(coupon)) {
          const cart = getCart();

          if (cart && cart.orderType) {
            return navigateToMenu().then(() => {
              return startCouponWalkthrough(coupon);
            });
          } else {
            return navigateToMenu(); // order type modal will add coupon
          }
        } else {
          return startCouponWalkthrough(coupon);
        }
      } else {
        if (routeToMenu) {
          return routeToMenu();
        } else {
          return navigateToMenu();
        }
      }
    });
  }
}
