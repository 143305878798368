import { ICoupon, ICouponGroup } from './Coupon';
import { IMenuItem } from '../../menu/models/Item';
import { COUPON_STORE } from '../stores/coupon.store';
import { VuexModel } from '../../common/data/VuexModel';
import { ICart } from '../../cart/cart.types';
import routeService from '../../common/services/route.service';
import analyticsManager from '../../common/services/analytics-manager.service';
import cartModel from '../../cart/models/Cart';
import profile from '../../profile/models/Profile';
import restaurants from '../../restaurants/models/Restaurants';

let couponWalkthrough;

declare var dataLayer: any;

export class CouponWalkthrough extends VuexModel {
  static initiateCouponWalkthrough(c: ICoupon) {
    if (c.groups && c.groups.length > 0) {
      couponWalkthrough.selectCoupon(c);

      routeService.route('SelectCouponItem', {couponId: c.couponId, itemNumber: 1});
    }
  }

  // getters

  get couponDescription() {
    return this.state.couponDescription;
  }

  get couponGroup() {
    return this.state.couponGroup;
  }

  get itemsByGroupMap() {
    return this.state.itemsByGroupMap;
  }

  get requirementsMet() {
    return this.state.requirementsMet;
  }

  get requirementsMetMap() {
    return this.state.requirementsMetMap;
  }

  get selectedCoupon() {
    return this.state.selectedCoupon;
  }

  get selectedItemsMap() {
    return this.state.selectedItemsMap;
  }

  get storeName() {
    return COUPON_STORE;
  }

  // methods

  buildSelectedItemsMap(groups: ICouponGroup[]) {
    for (let i = 0, len = groups.length; i < len; i++) {
      this.loadItemsForCoupon(this.selectedCoupon.couponId, groups[i].objectId);
    }

    return this.dispatch('buildSelectedItemsMap', groups);
  }

  clearSelectedItemsMap() {
    return this.dispatch('clearSelectedItemsMap');
  }

  clearSelectedCoupon() {
    return this.dispatch('clearSelectedCoupon');
  }

  loadCouponByPLU(plu: string) {
    return this.dispatch('loadCouponByPLU', { locationId: restaurants.selectedRestaurantId, plu });
  }

  loadItemsForCoupon(couponId: string, groupId: string) {
    return this.dispatch('loadItemsForCoupon', {couponId, groupId});
  }

  trackCoupon(coupon: ICoupon) {
    if (!coupon) {
      return;
    }
    // Google tag manager tracking
    dataLayer.push({
      event: 'couponstarted',
      data: {
        couponId: coupon.couponId,
        couponName: coupon.name,
        cartId: cartModel.cart.objectId,
        userId: profile.user ? profile.user.objectId : null,
        locationId: restaurants.selectedRestaurant.objectId,
        locationName: restaurants.selectedRestaurant.name
      }
    });

    // tracking
    analyticsManager.track('Coupon walkthrough started', {
      couponId: coupon.couponId,
      couponName: coupon.name,
      cartId: cartModel.cart.objectId,
      userId: profile.user ? profile.user.objectId : null,
      locationId: restaurants.selectedRestaurant.objectId,
      locationName: restaurants.selectedRestaurant.name
    });
  }

  trackCouponItem(item: IMenuItem, itemNumber: number) {
    // Google tag manager tracking
    dataLayer.push({
      event: 'couponitemadded',
      data: {
        itemNumber,
        itemId: item.objectId,
        itemName: item.name,
        couponId: this.selectedCoupon.couponId,
        couponName: this.selectedCoupon.name,
        cartId: cartModel.cart.objectId,
        userId: profile.user ? profile.user.objectId : null,
        locationId: restaurants.selectedRestaurant.objectId,
        locationName: restaurants.selectedRestaurant.name
      }
    });

    // tracking
    /* analyticsManager.track('Coupon item added during walkthrough', {
      itemNumber,
      itemId: item.objectId,
      itemName: item.name,
      couponId: this.selectedCoupon.couponId,
      couponName: this.selectedCoupon.name,
      cartId: cartModel.cart.objectId,
      userId: profile.user ? profile.user.objectId : null,
      locationId: restaurants.selectedRestaurant.objectId,
      locationName: restaurants.selectedRestaurant.name
    });*/
  }

  selectCoupon(c: ICart | ICoupon) {
    this.clearSelectedItemsMap();

    let selectedCoupon: ICoupon;

    if (c['coupons']) {
      const cart = c as ICart;

      selectedCoupon = cart.coupons.filter((coupon: ICoupon) => {
        return coupon.objectId === cart.selectedItem;
      })[0];
    } else {
      selectedCoupon = c as ICoupon;
    }

    this.trackCoupon(selectedCoupon);

    return this.dispatch('selectCoupon', selectedCoupon);
  }

  selectCouponItem(item: IMenuItem, itemNumber: number, group: ICouponGroup) {
    let payload: any = {
      item,
      itemNumber,
      group
    };

    this.trackCouponItem(item, itemNumber);

    return this.dispatch('selectCouponItem', payload);
  }

  selectCouponGroup(group: ICouponGroup) {
    return this.dispatch('selectCouponGroup', group);
  }
}

couponWalkthrough = new CouponWalkthrough();

export default couponWalkthrough;
