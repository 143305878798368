import { getDateRangeMessage, getIncludeDescription, getTimeRangeMessage } from '../../coupons/helpers/coupon.helpers';
import { IMenuItem, IRequiredItem, ITopping } from '../../menu/models/Item';
import { ICouponGroup, IStyle } from '../../coupons/models/Coupon';
import { ICart, IUnavailableMessage, IValidation, ORDER_TYPES } from '../cart.types';
import { Util } from '../../common/services/Util';
import { ALERT } from '../../common/common.constants';
import { IUser } from '../../profile/stores/profile.store';
import { ITenderRequest } from '../../order/models/TenderRequest';
import { IRestaurant } from '../../restaurants/types/restaurant.types';
import { OPEN_ORDER_TYPE_MODAL, OPEN_SCHEDULE_MODAL } from '../../common/messaging/notifications';
import notificationService from '../../common/messaging/notification.service';
import { ICategory } from '../../menu/stores/menu.store';
import { IUpdateRequirementState } from '../services/cart.service';
import { VALIDATIONS } from '../../menu/menu.constants';
const moment = require('moment');

export function createRequirementsUpdatePayload(requiredItems: IRequiredItem[]): IUpdateRequirementState[] {
  const requirementsToUpdate: IUpdateRequirementState[] = [];

  requiredItems.forEach(r => {
    const item = {
      parentInstanceId: r.parentId,
      quantity: 1,
      requiredItemId: r.itemId,
      requirementId: r.requirementId
    };

    if (r.objectId && !r.objectId.match(/^[0-9]+$/)) {
      item['instanceId'] = r.objectId;
    }

    requirementsToUpdate.push(item);
  });

  return requirementsToUpdate;
}

export function getFulfilledAutoApplyCoupons(cart: ICart, item: IMenuItem) {
  return cart.coupons.filter(coupon =>
    item.fulfilledCoupons.some(fulfilled => coupon.objectId === fulfilled.couponObjectId
      && coupon.autoApply
      && coupon.promptIfApplied));
}

export const getNonSizeStyle = (item: IMenuItem): IStyle => {
  return item.styles.find(style => style.type.toLowerCase() !== 'size');
};

function getAvailableValidations(validations: IValidation[]) {
  return validations.filter(v => v.type === VALIDATIONS.AVAILABLE);
}

export const getUnavailableMessages = (cart: ICart, categories: ICategory[]): IUnavailableMessage[] => {
  const availableValidations = getAvailableValidations(cart.validations);
  const unavailableMessages: IUnavailableMessage[] = [];

  for (let i = 0; i < availableValidations.length; i++) {
    let categoryId = cart.items.find(item => item.objectId === availableValidations[i].objectId);

    if (!categoryId) {
      continue;
    }

    categoryId = categoryId.menuCategoryId;

    const category = categories.find(c => c.objectId === categoryId);
    const day = new Date().toISOString().split('T')[0];

    unavailableMessages.push({
      objectId: availableValidations[i].objectId,
      message: availableValidations[i].message,
      availableTime: moment(day + 'T' + category.available.startString).format('h:mm a') +
        ' to ' + moment(day + 'T' + category.available.endString).format('h:mm a')
    });
  }

  return unavailableMessages;
};

export function getValidationMessage(error) {
  if (error.orderTypes && error.orderTypes.length > 0) {
    let orderTypes = error.orderTypes.map(orderType => orderType.name).join(', ');

    return error.message + orderTypes + '.';
  }

  if (error.group && error.group.includes) {
    if (error.group.includes.length === 1) {
      return error.message + getIncludeDescription(error.group, error.group.includes[0]);
    } else if (error.group.includes.length > 1) {
      let includesDescriptions: string[] = [];

      for (let include of error.group.includes) {
        includesDescriptions.push(getIncludeDescription(error.group, include));
      }

      return error.message + includesDescriptions.join(' or ') + '.';
    }
  }

  if (error.dateRange || error.timeRange) {
    let dateRangeMessage: string = getDateRangeMessage(error.dateRange);
    let timeRangeMessage: string = getTimeRangeMessage(error.timeRange);

    return error.message + dateRangeMessage + (dateRangeMessage ? ' ' : '') + timeRangeMessage;
  }

  return error.message;
}

export function reorder(
  oldCart: ICart,
  cartId: string,
  reorder: (cartId: string) => Promise<ICart>,
  isOnMainMenu: () => boolean,
  removeItems: (itemIds: string[]) => void,
  showErrors: (errors: string[]) => void,
  setOrderType: (orderTypeId: string) => void,
  setOrderTime: (day: string, time: string) => void,
  scheduleAsap: (cartId: string) => void, // TODO: remove cartId?
  setDeliveryZone: (zoneId: string) => void,
  removeCoupons: (cart: ICart) => void,
  track: (cart: ICart) => void,
  openUnavailableModal: (unavailableMessages: IUnavailableMessage[]) => void,
  categories: ICategory[]): Promise<ICart> {
  return new Promise((resolve, reject) => {
    const orderTypeId = oldCart.orderType;
    const zoneId = oldCart.zoneId;
    const oldOrderTime = oldCart.orderTimeString;

    reorder(cartId).then(cart => {
      if (cart.items && cart.items.length > 0) {
        if (cart.validations && cart.validations.length > 0) {
          const unavailableMessages = getUnavailableMessages(cart, categories);

          if (unavailableMessages.length > 0) {
            Util.waitUntil(isOnMainMenu).then(() => {
              openUnavailableModal(unavailableMessages);
            });
          }
        }

        // restore order type and zone
        if (cart.orderType !== orderTypeId) {
          setOrderType(orderTypeId);
        }

        if (oldOrderTime) {
          const parts = oldOrderTime.split('T');
          const day = parts[0];
          const time = parts[1] + ':00';

          setOrderTime(day, time);
        } else if (cart.orderTimeString) {
          scheduleAsap(cart.objectId);
        }

        if (orderTypeId === ORDER_TYPES.DELIVERY && zoneId !== cart.zoneId) {
          setDeliveryZone(zoneId);
        }

        let couponIds = cart.coupons.map(coupon => coupon.objectId);

        if (couponIds.length > 0) {
          removeCoupons(cart);
        }

        // wait until order type and time are corrected before going to menu
        setTimeout(() => {
          const outOfStockItems = cart.items.filter((item: IMenuItem) => !isItemInStock(cart, item));

          if (outOfStockItems.length > 0) {
            const outOfStockIds: string[] = outOfStockItems.map(i => i.objectId);
            removeItems(outOfStockIds);

            reject(outOfStockItems);
          } else {
            track(cart);

            resolve(cart);
          }
        }, 1000);
      } else {
        setTimeout(() => {
          showErrors(['No valid items in cart, please start a new order']);
        }, 500);
      }
    });
  });
}

export function isDelivery(cart: ICart): boolean {
  return cart.orderType === ORDER_TYPES.DELIVERY;
}

export function isOrderTimeValid(selectedRestaurant: IRestaurant, currentTime: string, cart: ICart, orderType?: string): boolean {
  if (!selectedRestaurant || !currentTime || !cart || !selectedRestaurant.hours) {
    return false;
  }

  if (!orderType) {
    orderType = cart.orderType;
  }

  return Util.isOrderTimeValid(
    orderType,
    selectedRestaurant.hours,
    selectedRestaurant.promiseTimes,
    currentTime,
    selectedRestaurant.usePromiseTime,
    cart.orderTimeString
  );
}

export function isSchedulingAllowed(restaurant: IRestaurant): boolean {
  return restaurant && restaurant.allowScheduling;
}

export function getClosedMessage(restaurant: IRestaurant) {
  let msg = ALERT.STORE_CLOSED;
  let i = msg.indexOf(' Please select an available time.');
  msg = msg.substr(0, i);

  if (isSchedulingAllowed(restaurant)) {
    msg += '  <span class="please-schedule underline">Please Schedule your order for an available time</span>.';
  }

  return msg;
}

export function checkOrderTime(
  selectedRestaurant: IRestaurant,
  currentTime: string,
  cart: ICart,
  orderType: string = null,
  isCheckingOut: boolean = false,
  openScheduleOrderModal: (isCheckingOut: boolean) => void,
  showAlert: (message: string, notification?: string) => void,
  showError: (error: string) => void): boolean {
  if (!isOrderTimeValid(selectedRestaurant, currentTime, cart, orderType)) {
    let notification;

    if (isSchedulingAllowed(selectedRestaurant)) {
      notification = OPEN_SCHEDULE_MODAL;
    }

    showAlert(getClosedMessage(selectedRestaurant), notification);

    return false;
  }

  let orderTime = cart.orderTimeString;

  if (orderTime) {
    orderTime = moment(orderTime);

    // soonest time order can be ready
    const currentTimeMoment = moment(currentTime);
    const promiseTime = Util.getPromiseTime(cart.orderType, selectedRestaurant.promiseTimes);
    currentTimeMoment.add(promiseTime, 'minutes');

    // not enough time for order to be ready
    if (Util.isMomentBefore(orderTime, currentTimeMoment)) {
      showError('Your scheduled time is in the past. Please choose a new order time.');

      if (openScheduleOrderModal && isSchedulingAllowed(selectedRestaurant)) {
        openScheduleOrderModal(isCheckingOut);
      }

      return false;
    }
  }

  return true;
}

export function reviewOrder(
  restaurant: IRestaurant,
  currentTime: string,
  cart: ICart,
  user: IUser,
  tenderRequest: ITenderRequest,
  sizeMap: Object,
  addUser: (userId: string) => void,
  showAlert: (message: string, notification?: string) => void,
  showError: (error: string, id?: string, payload?: object) => void,
  openDeliveryModal: () => void,
  removeItems: (coupons: string[]) => void,
  openScheduleOrderModal: () => void,
  openUnavailableItemModal: (unavailableMessages: IUnavailableMessage[]) => void,
  categories: ICategory[],
  currentRouteName?: string): {success: boolean, failureMessage: string, itemId?: string} {

  let result: {success: boolean, failureMessage: string};

  if (cart.items.length === 0) {
    return {
      success: false,
      failureMessage: null
    };
  }

  let userId;

  if (user) {
    userId = user.objectId;
  }

  if (!userId) {
    userId = null;
  }

  if (cart.userId !== userId && user) {
    addUser(user.objectId);
  }

  if (cart.orderType === null) {
    showAlert(ALERT.MISSING_ORDER_TYPE, OPEN_ORDER_TYPE_MODAL);

    return {
      success: false,
      failureMessage: null
    };
  }

  if (isDelivery(cart)) {
    if (!tenderRequest || !tenderRequest.deliveryAddress ||
      (!tenderRequest.deliveryAddress.objectId && !tenderRequest.deliveryAddress.addressLine)) {
      showError('Please select a delivery address to continue.');

      openDeliveryModal();

      return {
        success: false,
        failureMessage: null
      };
    }
  }

  if (!checkOrderTime(restaurant, currentTime, cart, null, true, openScheduleOrderModal, showAlert, showError)) {
    if (isDelivery(cart)) {
      showError(ALERT.STORE_NOT_DELIVERING);
      showAlert(ALERT.STORE_NOT_DELIVERING);
    }

    if (isSchedulingAllowed(restaurant)) {
      notificationService.notify(OPEN_SCHEDULE_MODAL);
    }

    return {
      success: false,
      failureMessage: null
    };
  }

  let failureMessage: string;

  if (cart.validations) {
    let failures = validateItems(cart);

    if (failures && failures.length > 0) {
      const unavailableMessages = getUnavailableMessages(cart, categories);

      for (let validation of failures) {
        if (currentRouteName && currentRouteName === 'MainMenu') {
          return {
            success: false,
            failureMessage: `${validation.message} <u>Please edit the item to continue.</u>`,
            itemId: validation.objectId
          };
        } else {
          showError(validation.message, validation.type, validation);
        }
      }

      if (unavailableMessages.length > 0) {
        openUnavailableItemModal(unavailableMessages);
      }

      return {
        success: false,
        failureMessage: null
      };
    }

    failures = validateCart(cart);

    if (failures && failures.length > 0) {
      failureMessage = '';

      for (let validation of failures) {
        if (validation.type === 'DELIVERY' || validation.type.objectId === ORDER_TYPES.DELIVERY) {
          showError(validation.message, validation.type, validation);
          openDeliveryModal();

          return {
            success: false,
            failureMessage: null
          };
        } else {
          failureMessage += validation.message + '<br/>';
        }
      }

      if (failureMessage.length > 0) {
        result = {
          success: false,
          failureMessage
        };
      }

      if (!failureMessage.match(/Your order must be at least (\$[0-9]\d*(\.\d+)?) for delivery/g)) {
        showAlert(failureMessage);

        return result;
      }
    }
  }

  failureMessage = '';

  for (let item of cart.items) {
    if (sizeMap[item.itemId] && sizeMap[item.itemId].length > 0 &&
      item.styles.filter(style => style.type.toUpperCase() === 'SIZE').length <= 0) {
      if (currentRouteName && currentRouteName === 'MainMenu') {
        return {
          success: false,
          failureMessage: `${item.name} requires a size selection. <u>Please edit the item to continue.</u>`,
          itemId: item.objectId
        };
      } else {
        failureMessage += `${item.name} requires a size selection.<br/>`;
      }
    }
  }

  if (failureMessage.length > 0) {
    if (!currentRouteName || (currentRouteName && currentRouteName !== 'MainMenu')) {
      showError(failureMessage);

      return {
        success: false,
        failureMessage
      };
    }
  }

  let invalidCoupons: { objectId: string, message: string, group: ICouponGroup }[] = validateCoupons(cart);

  if (invalidCoupons && invalidCoupons.length > 0) {
    // remove coupons with group, others will be validated at checkout
    removeItems(invalidCoupons.filter(c => c.group).map(c => c.objectId));

    return {
      success: false,
      failureMessage: null
    };
  }

  return {
    success: true,
    failureMessage: result && result.failureMessage ? result.failureMessage : null
  };
}

export const unableToPriceItem = (cart: ICart): boolean => {
  return cart.items && cart.items.length > 0
    && cart.validations
    && (cart.validations.length === 1
      && cart.validations[0].type === 'CART'
      && cart.validations[0].message === 'Error with service. Unable to calculate price.');
};

function validateCart(cart: ICart) {
  let failures = [];

  for (let validation of cart.validations) {
    if (validation.type === 'CART' || validation.type === 'DELIVERY') {
      failures.push(validation);
    }
  }

  return failures;
}

function validateCoupons(cart: ICart): { objectId: string, message: string, group: ICouponGroup }[] {
  let invalidCoupons: { objectId: string, message: string, group: ICouponGroup }[] = [];

  for (let validation of cart.validations) {
    if (validation.type === VALIDATIONS.COUPON) {
        invalidCoupons.push({objectId: validation.objectId, message: validation.message, group: validation.group});
    }
  }

  return invalidCoupons;
}

export function validateItems(cart: ICart) {
  let failures = [];

  for (let validation of cart.validations) {
    if (validation.type === VALIDATIONS.REQUIRED || validation.type === VALIDATIONS.AVAILABLE || validation.type === VALIDATIONS.STYLE) {
      failures.push(validation);
    }
  }

  return failures;
}

function isOutOfStock(outOfStock: { [key: string]: string[] }, itemId: string, styleIds: string[]): boolean {
  const outOfStockStyleIds = outOfStock[itemId];

  if (outOfStockStyleIds) {
    if (outOfStockStyleIds.length > 0) {
      if (styleIds) {
        return !!styleIds.find(id => outOfStockStyleIds.indexOf(id) !== -1);
      } else {
        return false;
      }
    } else {
      return true;
    }
  } else {
    return false;
  }
}

function isObjectOutOfStock(cart: ICart, item: IMenuItem, object: { categoryId?: string, departmentId?: string, itemId: string },
                            styleIds?: string[]): boolean {
  if (!cart.outOfStock) {
    return false;
  }

  if (typeof styleIds === 'undefined') {
    styleIds = item.styles ? item.styles.map(style => style.styleId) : null;
  }

  return isOutOfStock(cart.outOfStock.items, object.itemId, styleIds)
    || isOutOfStock(cart.outOfStock.categories, object.categoryId, styleIds)
    || isOutOfStock(cart.outOfStock.departments, object.departmentId, styleIds);
}

export function isItemInStock(cart: ICart, item: IMenuItem, styles?: Array<{ styleId: string }>) {
  return !isObjectOutOfStock(cart, item, item, styles ? styles.map(style => style.styleId) : null);
}

export function isModifierInStock(cart: ICart, item: IMenuItem, modifierId: string) {
  return !isObjectOutOfStock(cart, item, item, [modifierId]);
}

export function isRequiredItemInStock(cart: ICart, item: IMenuItem, requiredItem: IRequiredItem) {
  return !isObjectOutOfStock(cart, item, requiredItem);
}

export function isToppingInStock(cart: ICart, item: IMenuItem, topping: ITopping) {
  return !isObjectOutOfStock(cart, item, topping);
}

export function itemsInStock(cart: ICart, items: IMenuItem[]) {
  return items.filter(item => isItemInStock(cart, item));
}
