
  import Vue from 'vue';
  import designService from '../../common/services/design.service';
  import {navigateToMenu} from '../../common/mixins/base-component.mixin';
  import designMixin from '../../common/mixins/design.mixin';

  export default Vue.extend({
      mixins: [designMixin],
      props: {
          error: {
              type: String,
              required: false
          },
          token: {
              type: String,
              required: true
          }
      },
    computed: {
      designService () {
        return designService;
      },
      isPasswordValid (): boolean {
          return this.password && this.password.length >= 6 && this.password.length <= 255;
      },
      isConfirmPasswordValid (): boolean {
          return this.passwordMatch && this.passwordMatch === this.password;
      }
    },
    data () {
      return {
          password: null,
          passwordMatch: null,
          isPasswordChanged: false,
          isConfirmPasswordChanged: false
      };
    },
    methods: {
      onPasswordBlur (): void {
          this.isPasswordChanged = true;
      },

      onConfirmPasswordBlur (): void {
          this.isConfirmPasswordChanged = true;
      },

      isReadyToChangePassword(): boolean {
        return this.isPasswordValid;
      },

      cancelPasswordChange(): void {
        navigateToMenu();
      },

      changePassword(): void {
        if (this.isReadyToChangePassword()) {
          const payload = {
            password: this.password,
            token: this.token
          };

          this.$emit('onChangePassword', payload);
        }
      }
    }
  });
