import restaurants from '../models/Restaurants';
import { Util } from '../../common/services/Util';
import cart from '../../cart/models/Cart';
import profile from '../../profile/models/Profile';
import order from '../../order/models/Order';
import { navigateToInnerLandingPage } from '../../common/mixins/base-component.mixin';
import { IUser } from '../../profile/stores/profile.store';
import { IRestaurant } from '../types/restaurant.types';

export class RestaurantLoader {
    // methods

    loadRestaurantDetails(restaurantId: string): Promise<IRestaurant> {
        const promise = restaurants.initialize(restaurantId).then(restaurant => {
            restaurants.checkIsOpen(restaurantId);
            restaurants.checkIsDeliveryOpen(restaurantId);

            return restaurant;
        });

        restaurants.checkCurrentTime(restaurantId);

        this.fetchHistoricalOrders(restaurantId);

        return promise;
    }

    selectRestaurant(restaurant: IRestaurant): Promise<IRestaurant> {
        return this.selectRestaurantById(restaurant.objectId);
    }

    selectRestaurantById(restaurantId: string, switchLocations: boolean = true): Promise<IRestaurant> {
        const oldCartId = cart.cart ? cart.cart.objectId : null;
        const clearPromise = restaurants.setSelectedRestaurantIdAndGetCartBySession(restaurantId);
        const detailsPromise = this.loadRestaurantDetails(restaurantId);

        // this way nothing tries to set anything on the cart before the new object id is in the store
        // const cartPromise = Util.waitUntil(() => !oldCartId || (cart.cart && cart.cart.objectId !== oldCartId));

        if (switchLocations) {
            profile.switchLocations(restaurantId);
        }

        return Promise.all([clearPromise, detailsPromise]).then(values => {
            return values[1]['restaurant'];
        });
    }

    chooseRestaurant(restaurant: IRestaurant, routeToMenu: boolean = true): Promise<IRestaurant> {
        return this.chooseRestaurantById(restaurant.objectId, routeToMenu);
    }

    chooseRestaurantById(id: string, routeToMenu: boolean = true): Promise<IRestaurant> {
        return this.selectRestaurantById(id).then(restaurant => {
            if (routeToMenu) {
                navigateToInnerLandingPage();
            }

            return restaurant;
        });
    }

    reloadSelectedRestaurant(): Promise<IRestaurant> {
        let restaurantId = restaurants.selectedRestaurantId;

        if (!restaurantId) {
            return null;
        }

        // restaurants.reloadSelectedRestaurant();
        return this.loadRestaurantDetails(restaurantId);
    }

    fetchHistoricalOrders(restaurantId: string) {
        let user: IUser = profile.user;

        if (user && user.objectId) {
            order.fetchHistoricalOrders(restaurantId, user.objectId);
        }
    }
}

export default new RestaurantLoader();
