
import Vue from 'vue';
import designMixin from '../../common/mixins/design.mixin';
import subscriberMixin from '../../common/mixins/subscriber.mixin';
import cart from '../../cart/models/Cart';
import designService from '../../common/services/design.service';
import LoadingCircle from '../../common/components/LoadingCircle.vue';
import ModalModern from '../../common/components/ModalModern.vue';
import notifierService from '../../common/services/notifier.service';
import filtersMixin from '../../common/mixins/filters.mixin';
import recaptchaMixin from '../../captcha/mixins/recaptcha.mixin';

export default Vue.extend({
    mixins: [designMixin, subscriberMixin, filtersMixin, recaptchaMixin],
    components: { LoadingCircle, ModalModern },
    props: {
      cvvRequired: Boolean,
      disabled: Boolean,
      total: {
        type: Number,
        required: true
      }
    },
    computed: {
      // models

      _designService() {
        return designService;
      },

      // getters

      giftCardBalance() {
        return cart.giftCardBalance;
      },

      isGiftCardValid() {
        return this.giftCardCode && (!this.cvvRequired || this.giftCardCvv);
      },

      giftCardAppliedBalance(): number {
        if (this.giftCardBalance) {
          return Math.max(0, this.giftCardBalance - this.total);
        } else {
          return 0;
        }
      },

      remainingTotal(): number {
        if (this.giftCardBalance) {
          return Math.max(0, this.total - this.giftCardBalance);
        } else {
          return 0;
        }
      },

      isGiftCardBalanceInsufficient(): boolean {
        return this.remainingTotal > 0;
      },
    },
    data() {
      return {
        error: null, // string
        giftCardCode: null, // string
        giftCardCvv: null, // string
        giftCardStatus: null, // string
        isApplied: false,
        loadingBalance: false
      };
    },
    methods: {
      getAlertStyle(): any {
        return {
          ...this._designService.getAlertStyling(),
          'margin-left': '10px !important',
          'margin-right': '10px !important'
        };
      },

      applyGiftCardCode(recaptchaToken) {
        this.viewGiftCardBalance(recaptchaToken).then(res => {
          if (res.responseCode === 'SUCCESS' && this.giftCardBalance > 0) {
            this.$refs.modal.open();
          }
        });
      },

      applyBalance() {
        let applied;

        if (this.total >= this.giftCardBalance) {
          applied = this.giftCardBalance;
        } else {
          applied = this.total;
        }

        this.$emit('onGiftCardBalanceApplied', {
          balance: applied,
          cardNumber: this.giftCardCode,
          cvv: this.giftCardCvv
        });

        this.isApplied = true;

        this.$refs.modal.close();
      },

      applyAndFinalize() {
        this.applyBalance();

        this.$emit('finalizeOrder');
      },

      confirmApply() {
        if (!this.validateCVV()) {
          return;
        }

        this.applyBalance();
      },

      onExecuteGiftCardApplyRecaptcha() {
        this.performRecaptcha(this.applyGiftCardCode);
      },

      onExecuteGiftCardCheckBalanceRecaptcha() {
        this.performRecaptcha(this.viewGiftCardBalance);
      },

      validateCVV() {
        if (this.cvvRequired && !this.giftCardCvv) {
          notifierService.error('Gift card CVV is required.');

          return false;
        }

        if (this.giftCardCvv && (this.giftCardCvv.length < 3 || this.giftCardCvv.length > 8)) {
          notifierService.error('Gift card CVV must be 3-8 digits.');

          return false;
        }

        return true;
      },

      viewGiftCardBalance(recaptchaToken) {
        if (!this.validateCVV()) {
          return Promise.reject();
        }

        this.loadingBalance = true;
        this.giftCardStatus = null;

        return cart.fetchGiftCardBalance(this.giftCardCode, this.giftCardCvv, recaptchaToken)
          .then(res=> {
            this.loadingBalance = false;
            this.giftCardStatus = res.responseCode; // 'SUCCESS' | 'FAILED'

            return res;
          }).catch(error => {
            this.loadingBalance = false;
            this.giftCardStatus = error;
          });
      }
    }
  });
