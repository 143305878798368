
import Vue from 'vue';
import baseMixin from '../../common/mixins/base-component.mixin';
import ModalModern from '../../common/components/ModalModern.vue';

export default Vue.extend({
  mixins: [baseMixin],
  components: {
    ModalModern
  },
  methods: {
    show() {
      this.$refs.modal.open();
    },

    hide() {
      this.$refs.modal.close();
    },

    onClickYesButton() {
      this.$emit('onSubmit', true);

      this.hide();
    },

    onClickNoButton() {
      this.$emit('onSubmit', false);

      this.hide();
    }
  }
});
