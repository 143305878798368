export const API_ADD_COMMENTS_TO_ITEM_URL = '/slice/cart/item/message/add';
export const API_ADD_COUPON_URL = '/slice/cart/coupon/add';
export const API_ADD_COUPON_BY_CODE_URL = '/slice/cart/coupon/byCode/add';
export const API_FETCH_GIFT_CARD_BALANCE_URL = '/slice/giftcard/balance';
export const API_ADD_ITEM_URL = '/slice/cart/item/add';
export const API_ADD_ITEM_WITH_SIZE_URL = '/slice/cart/item/withSize/add';
export const API_ADD_REQUIRED_ITEM_URL = '/slice/cart/required/add';
export const API_ADD_NO_REQUIRED_ITEM_URL = '/slice/cart/required/no/choose';
export const API_ADD_STYLE_URL = '/slice/cart/style/add';
export const API_CHANGE_TOPPINGS_URL = '/slice/cart/toppings/change';
export const API_CLEAR_URL = '/slice/cart/clear';
export const API_GET_CART_UPDATE_TIME_URL = '/slice/cart/getCartUpdatetime';
export const API_FETCH_BY_SESSION_URL = '/slice/cart/getCart';
export const API_REMOVE_ITEM_URL = '/slice/cart/item/remove';
export const API_REMOVE_ITEMS_URL = '/slice/cart/items/remove';
export const API_SET_QUANTITY_URL = '/slice/cart/item/qty/set';
export const API_SET_ORDER_TYPE_URL = '/slice/cart/order/type/set';
export const API_SET_ORDER_TIME_URL = '/slice/cart/order/time/set';
export const API_SET_TENDER_TYPE_URL = '/slice/cart/tender/set';
export const API_CHECK_ORDER_TIME_URL = '/slice/cart/order/time/check';
export const API_SCHEDULE_ASAP_URL = '/slice/cart/order/time/asap';
export const API_SPLIT_ITEM_URL = '/slice/cart/item/split';
export const API_UNSPLIT_ITEM_URL = '/slice/cart/item/split/remove';
export const API_ADD_USER_URL = '/slice/cart/user/set';
export const API_SET_ORDER_NAME_URL = '/slice/cart/order/name/set';
export const API_FIND_HISTORICAL_URL = '/slice/cart/historical/find';
export const API_REORDER_URL = '/slice/cart/reorder';
export const API_CHECK_CART_STATUS_URL = '/slice/cart/status/check';
export const API_SET_DELIVERY_ZONE_URL = '/slice/cart/zone/set';
export const API_REMOVE_DELIVERY_ZONE_URL = '/slice/cart/zone/remove';
export const API_REMOVE_REQUIREMENT_INSTANCES_URL = '/slice/cart/required/instance/remove/all';
export const API_CHANGE_REQUIREMENTS_URL = '/slice/cart/requirements/change';
