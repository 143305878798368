
    import Vue from 'vue';
    import {IOutOfStockRequirement} from './OrderReview.vue';
    import designService from '../../common/services/design.service';
    import StyledModal from '../../common/components/StyledModal.vue';

    export default Vue.extend({
        components: {
            StyledModal
        },
        props: {
            displayOutOfStockRequirement: {
                type: Object as () => IOutOfStockRequirement,
                required: false
            }
        },
        computed: {
            _designService () {
                return designService;
            }
        },
        methods: {
            open() {
                this.$refs.modal.open();
            },

            close() {
                this.$refs.modal.close();
            },

            keepAndEditItem () {
                this.$emit('keepAndEditItem', this.displayOutOfStockRequirement.parentItemObjectId);
            },

            removeItem () {
                this.close();

                this.$emit('removeItem', this.displayOutOfStockRequirement.parentItemObjectId);
            },

            // styling

            getButtonClass(): any {
                return this._designService.getButtonClass();
            },

            getButtonStyle(fontSize?: string): any {
                let style: any = this._designService.getButtonStyle();

                if (fontSize) {
                    style['font-size'] = fontSize;
                }

                return style;
            }
        }
    });
