import { IMenuItem, IMenuItemSize, IRequiredOption } from '../models/Item';
import { ICoupon } from '../../coupons/models/Coupon';
import { Util } from '../../common/services/Util';
import { ICart, IValidation } from '../../cart/cart.types';
import { VALIDATIONS } from '../menu.constants';
import { IRestaurant } from '../../restaurants/types/restaurant.types';

const lodashGet = require('lodash.get');

export function canLeaveItemCustomization (to: { name?: string }, cartObject: ICart, item: IMenuItem,
                                           restaurant: IRestaurant, removing: boolean,
                                           shouldCheckForSuggestions: boolean,
                                           editingCheckoutItem: boolean,
                                           setEditingCheckoutItem: (value: boolean) => void,
                                           mustRoute: boolean,
                                           clearReturnRoute: Function,
                                           fromSuggestion: boolean,
                                           setFromSuggestion: (value: boolean) => void,
                                           checkForSuggestions: (item: IMenuItem, restaurant: IRestaurant) => Promise<any>,
                                           loadSuggestions: (cartId: string, itemId: string) => Promise<any>,
                                           showError: (message: string, type: string, validation: IValidation) => void) {
  const validationError = lodashGet(cartObject, 'validations', [])
    .find(val =>
      val.objectId === item.objectId &&
      (val.type === VALIDATIONS.REQUIRED || val.type === VALIDATIONS.STYLE || val.type === VALIDATIONS.SIZE)
    );

  let canRoute: boolean = !validationError;

  if (mustRoute) {
    canRoute = true;
  }

  if (!canRoute && removing) {
    canRoute = true;
  }

  if (!canRoute && to && to.name === 'RestaurantsListContainer') {
    canRoute = true;
  }

  if (canRoute) {
    if (to && to.name !== 'SelectCouponItem') {
      clearReturnRoute();
    }

    if (fromSuggestion) {
      if (shouldCheckForSuggestions) {
        // tslint:disable-next-line:no-empty
        checkForSuggestions(item, restaurant).catch(() => {
        });
      }

      setFromSuggestion(false);
    } else {
      loadSuggestions(cartObject.objectId, item.itemId).then(() => {
        if (shouldCheckForSuggestions) {
          checkForSuggestions(item, restaurant).catch(() => {});
        }
      });
    }
  } else {
    showError(validationError.message, validationError.type, validationError);
  }

  if (canRoute) {
    if (editingCheckoutItem) {
      setEditingCheckoutItem(false);
    }

    return true;
  } else {
    return false;
  }
}

export function isItemAllowedForSplit(
  item: IMenuItem,
  splitItem: IMenuItem,
  coupon: ICoupon,
  sizeMap: { [key: string]: Array<IMenuItemSize> },
  categoriesMap: object,
  cart: ICart,
  currentTime: string): boolean {
  if (coupon && coupon.excluded) {
    const found = coupon.excluded.find(excludedItem => {
      if (excludedItem.categorizationType === 'DEPARTMENT') {
        return excludedItem.categorizationId === splitItem.departmentId;
      } else if (excludedItem.categorizationType === 'CATEGORY') {
        return excludedItem.categorizationId === splitItem.categoryId;
      } else if (excludedItem.categorizationType === 'ITEM') {
        return excludedItem.categorizationId === splitItem.objectId;
      }
    });

    if (found) {
      return false;
    }
  }

  if (!!sizeMap[splitItem.itemId]) {
    return sizeMap[splitItem.itemId].some((size: IMenuItemSize) => {
      return size.styleId === item.size.styleId && size.allowFraction
        && Util.validateAvailable(categoriesMap[item.categoryId], cart, currentTime)
        && splitItem.itemId !== item.itemId;
    });
  } else {
    return splitItem.categoryId === item.categoryId && splitItem.itemId !== item.itemId;
  }
}

export function getMaxRequirements(option: IRequiredOption): number {
  let upTo = option.upTo;

  if (upTo === null) {
    if (option.numChoices === 0) {
      upTo = Number.MAX_SAFE_INTEGER;
    } else {
      upTo = 0;
    }
  }

  return option.numChoices + upTo;
}
