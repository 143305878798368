import { hoverScheme } from '../../menu/directives/ItemButtonHover';
import { ICouponGroup } from '../models/Coupon';
import { IItemDesign } from '../../menu/stores/menu.store';
import { IRestaurant } from '../../restaurants/types/restaurant.types';
import designService from '../../common/services/design.service';
import { IMenuItem, IMenuItemSize } from '../../menu/models/Item';
import { getAvailableSizes } from '../helpers/coupon.helpers';
import { isItemInStock } from '../../cart/helpers/cart.helpers';
import cart from '../../cart/models/Cart';

export default {
  directives: { hoverScheme },
  props: {
    group: {
      type: Object as () => ICouponGroup,
      required: true
    },
    item: {
      type: Object as () => IMenuItem,
      required: true
    },
    itemDesign: {
      type: Object as () => IItemDesign,
      required: true
    },
    selectedRestaurant: {
      type: Object as () => IRestaurant,
      required: true
    }
  },
  computed: {
    // services

    _designService() {
      return designService;
    },

    // getters
    cart() {
      return cart.cart;
    }
  },
  methods: {
    getAvailableSizes(group: ICouponGroup) {
      return getAvailableSizes(this.item, group);
    },

    getOutOfStockMessage(): string {
      return 'Currently out of stock';
    },

    isDisplayDescription(): boolean {
      return this.itemDesign && this.itemDesign.displayDescription;
    },

    isItemInStock(item: IMenuItem): boolean {
      return isItemInStock(this.cart, item);
    }
  }
};
