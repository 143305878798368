
    import Vue from 'vue';
    import subscriberMixin from '../../common/mixins/subscriber.mixin';
    import designService from '../../common/services/design.service';
    import {IFulfilledCoupon} from '../../menu/models/Item';
    import modalService from '../../common/services/modal.service';
    import ModalModern from '../../common/components/ModalModern.vue';

    export default Vue.extend({
        mixins: [subscriberMixin],
        components: {
            ModalModern
        },
        computed: {
            // getters
            getTitle() {
              return this.couponToRemove ? 'Remove ' + this.couponToRemove.name : ''
            },

            // services
            _designService () {
                return designService;
            },

            _modalService () {
                return modalService;
            }
        },
        data() {
            return {
                couponToRemove: null // ICoupon
            };
        },
        created () {
            this.subscribe(this._modalService.removeCouponModal$, (coupon: IFulfilledCoupon) => {
                this.couponToRemove = coupon;
                this.open();
                this._designService.setModalStyling();
            });
        },
        methods: {
            open() {
                this.$refs.modal.open();
            },
            close() {
                this.$refs.modal.close();
            },

            confirmRemoval(coupon: IFulfilledCoupon) {
                this.close();
                this._modalService.removeCoupon(coupon);
                this.couponToRemove = undefined;
            },

            // styling

            getButtonClass(): any {
                return this._designService.getButtonClass();
            },

            getButtonStyle(): any {
                return this._designService.getButtonStyle();
            }
        }
    });
