import { Subscription } from './Subscription';

export interface ISubscriptionCallback<T> {
  (value: T): void;
}

export interface ISubscribable<T> {
  subscribe(observerOrNext?: ISubscriptionCallback<T> | ((value: T) => void),
            error?: (error: any) => void,
            complete?: () => void);
}

export class Subscribable<T> implements ISubscribable<T> {
  callbacks: Array<ISubscriptionCallback<T>>;

  subscribe(callback: ISubscriptionCallback<T>, error?: (error: any) => void, complete?: () => void): Subscription { // TODO: error
    if (!this.callbacks) {
      this.callbacks = [];
    }

    this.callbacks.push(callback);

    return new Subscription(() => {
      const i = this.callbacks.indexOf(callback);

      if (i !== -1) {
        this.callbacks.splice(i, 1);
      }
    });
  }

  notify(value: T) {
    if (!this.callbacks) {
      return;
    }

    this.callbacks.forEach(callback => {
      callback(value);
    });
  }
}
