import {
    API_INITIALIZE_RESTAURANT,
    API_LOAD_RESTAURANTS_URL,
    API_LOAD_RESTAURANT_URL,
    API_RESTAURANT_TIME_URL,
    API_FIND_LOCATION_LOYALTY_URL, API_RESTAURANT_OPEN_URL, API_RESTAURANT_OPEN_BYTYPE_URL
} from '../restaurants.constants';
import {API_LOAD_SIZE_MAP_URL} from '../../menu/menu.constants';
import {BaseApiService} from '../../common/services/BaseApiService';
import { RequestMethod } from '../../common/services/axios-http.service';

export class RestaurantsService extends BaseApiService {
    initialize(restaurantId: string) {
        return this.request({
            method: RequestMethod.Get,
            url: API_INITIALIZE_RESTAURANT,
            search: `location=${restaurantId}`
        });
    }

    loadRestaurants(payload: { accountId: string, previewMode: boolean }) {
        let search: string = `account=${payload.accountId}`;

        if (payload.previewMode)
            search = `${search}&preview=true`;

        return this.request({
            method: RequestMethod.Get,
            url: API_LOAD_RESTAURANTS_URL,
            search
        });
    }

    loadRestaurant(restaurantId: string) {
        return this.request({
            method: RequestMethod.Get,
            url: API_LOAD_RESTAURANT_URL,
            search: `location=${restaurantId}`
        });
    }

    loadSizeMap(restaurantId: string) {
        return this.request({
            method: RequestMethod.Get,
            url: API_LOAD_SIZE_MAP_URL,
            search: `location=${restaurantId}`
        });
    }

    findLoyalty(locationId: string) {
        return this.request({
            method: RequestMethod.Get,
            url: API_FIND_LOCATION_LOYALTY_URL,
            search: `location=${locationId}`
        });
    }

    currentTime(restaurantId: string) {
        return this.request({
            method: RequestMethod.Get,
            url: API_RESTAURANT_TIME_URL,
            search: `location=${restaurantId}`
        });
    }

    isOpen(payload: any) {
        let search: string;

        if (payload.orderTypeId) {
            search = `location=${payload.restaurantId}&orderType=${payload.orderTypeId}&date=${payload.date}&time=${payload.time}`;
        } else {
            search = `location=${payload.restaurantId}&date=${payload.date}&time=${payload.time}`;
        }

        let url: string = API_RESTAURANT_OPEN_URL;

        if (payload.orderTypeId) {
            url = API_RESTAURANT_OPEN_BYTYPE_URL;
        }

        return this.request({
            method: RequestMethod.Get,
            url: url,
            search: search
        });
    }
}

export default new RestaurantsService();
