
  import Vue from 'vue';
  import baseItemMixin from '../../common/mixins/base-item-component.mixin';
  import selectedRestaurantOutletMixin from '../../common/mixins/selected-restaurant-outlet.mixin';
  import MenuSidebarContainer from './MenuSidebarContainer.vue';
  import MenuModals from '../components/MenuModals.vue';
  import cart from '../../cart/models/Cart';
  import { IFulfilledCoupon, IMenuItem } from '../models/Item';
  import { ALERT, MODAL_ACTIONS } from '../../common/common.constants';
  import restaurants from '../../restaurants/models/Restaurants';
  import couponWalkthrough from '../../coupons/models/CouponWalkthrough';
  import menu from '../models/Menu';
  import order from '../../order/models/Order';
  import modalService from '../../common/services/modal.service';
  import menuBus from '../menu.bus';
  import restaurantLoader from '../../restaurants/services/restaurant-loader.service';
  import cartHelperService from '../../cart/services/cart-helper.service';
  import account from '../../account/models/Account';
  import { ICart } from '../../cart/cart.types';
  import notificationService from '../../common/messaging/notification.service';
  import { CLEAR_ALERT, SHOW_ALERT } from '../../common/messaging/notifications';
  import profile from '../../profile/models/Profile';
  import { IZoneArea, ORDER_TYPES } from '../../restaurants/types/restaurant.types';
  import AlertModern from '../../common/components/AlertModern.vue';
  import loyaltyService from '../../coupons/services/loyalty.service';

  export default Vue.extend({
    mixins: [baseItemMixin, selectedRestaurantOutletMixin],
    components: {
      AlertModern,
      MenuModals,
      MenuSidebarContainer
    },
    computed: {
      // services

      _menuBus() {
        return menuBus;
      },

      _modalService() {
        return modalService;
      },

      account() {
        return account;
      },

      cart() {
        return cart;
      },

      cartHelperService() {
        return cartHelperService;
      },

      couponWalkthrough() {
        return couponWalkthrough;
      },

      menu() {
        return menu;
      },

      order() {
        return order;
      },

      restaurantLoader() {
        return restaurantLoader;
      },

      restaurants() {
        return restaurants;
      },

      loyaltyService() {
        return loyaltyService;
      },

      // getters

      accountInfo() {
        return this.account.accountInfo;
      },

      addresses() {
        return profile.userAddresses;
      },

      currentSuggestions() {
        return this.menu.currentSuggestions;
      },

      currentTime() {
        return this.restaurants.currentTime;
      },

      defaultItemDesign() {
        return this.menu.defaultItemDesign;
      },

      locationFinderActive(): boolean {
        return account.locationFinderActive;
      },

      restaurantCount() {
        return this.restaurants.count;
      },

      selected() {
        return this.restaurants.selected;
      },

      selectedRestaurant() {
        return this.restaurants.selectedRestaurant;
      },

      sizeMap() {
        return this.restaurants.sizeMap;
      },

      suggestedAddresses() {
        return this.profile.suggestedAddresses;
      },

      tenderRequest() {
        return this.order.tenderRequest;
      },

      updating() {
        return this.cart.updating;
      },

      user () {
        return profile.user;
      },

      showStoreClosedMessage(): boolean {
        return this.selectedRestaurant &&
          (this.cartHelperService.isStoreClosedAllDay(this.selectedRestaurant) ||
            (!this.selectedRestaurant.restaurantOpenInitialLoad)
          );
      },

      forceShowAllSuggestions(): boolean {
        return this.user ? this.loyaltyService.getAvailableCoupons().length <= 2 : false;
      },

      doesCartHaveItems(): boolean {
        return this.cartHelperService.doesCartHaveItems;
      }
    },
    data() {
      return {
        alert: { show: false, message: '', clickable: false, notification: null },
        errorAlert: { show: false, message: '', clickable: false, notification: null, itemId: null },
        couponToRemove: null, // IFulfilledCoupon;
        initialized: false,
        removeClearAlertObserver: null, // function
        removeShowAlertObserver: null, // function
        restaurantSubscription: null, // ISubscription;
        selectedItem: null, // IMenuItem;
        showAlertSubscription: null, // ISubscription;
      };
    },
    created() {
      window.scrollTo(0, 0);

      this.initialized = true;

      if (this.cart.cart) {
        if (this._unableToPriceItem(this.cart.cart)) {
          this._modalService.unableToPriceItem(this._getCurrentItem());
        }
      }

      this.removeClearAlertObserver = notificationService.addObserver(CLEAR_ALERT, () => {
        this.clearAlert();
      });

      this.removeShowAlertObserver = notificationService.addObserver(SHOW_ALERT, payload => {
        this.showAlert(payload.message, payload.notification);
      });
    },
    mounted() {
      if (this.cart.cart
        && this.cart.cart.orderType === ORDER_TYPES.DELIVERY
        && (!this.cart.cart.zoneId || this.cart.cart.zoneId === '0')
        && !couponWalkthrough.selectedCoupon) {
        this.cartHelperService.openDeliveryModal();
      }
    },
    methods: {
      loadData(): boolean {
        if (this.isInitialized) {
          this.restaurantLoader.reloadSelectedRestaurant();
        }

        return true;
      },

      changeRestaurant(): void {
        this.restaurants.changeRestaurant();
        this.routeService.route('RestaurantsList');
      },

      clearAlert() {
        this.alert.show = false;
        this.alert.message = '';
        this.alert.notification = null;
      },

      clearSuggestedAddresses() {
        this.profile.clearSuggestedAddresses();
      },

      doAlertAction(alert = null): void {
        if (!alert) {
          alert = this.alert;
        }

        if (alert.show && alert.notification) {
          notificationService.notify(this.alert.notification);

          this.clearAlert();
        }
      },

      showErrorAlert(message: any) {
        if (ALERT.STORE_CLOSED.includes(this.errorAlert.message) && !this.cartHelperService.isSchedulingAllowed) {
          window.scrollTo(0, 0);

          return;
        }

        this.errorAlert.show = true;
        this.errorAlert.message = message.error;
        this.errorAlert.notification = true;
        this.errorAlert.itemId = message.itemId;

        window.scrollTo(0, 0);
      },

      doErrorAlertAction(alert = null): void {
        if (!alert) {
          alert = this.alert;
        }

        if (alert.show && alert.notification) {
          notificationService.notify(this.errorAlert.notification);

          this.clearErrorAlert();
        }

        const item: IMenuItem = this.cart.cart.items.find((cartItem) => {
          return cartItem.objectId === this.errorAlert.itemId;
        });
        if (item) {
          this.editItem(item);
        }
      },

      clearErrorAlert() {
        this.errorAlert.show = false;
        this.errorAlert.message = '';
        this.errorAlert.notification = null;
      },

      // being used in base-item-component.mixin.ts
      closeCart(event) {
        this.$emit('closeMobileCart', event);
      },

      removeWalkthroughCoupon(couponId: string): void {
        this.cart.removeItem(couponId);
      },

      showAlert(message: string, notification?: string) {
        if (ALERT.STORE_CLOSED.includes(this.alert.message) && !this.cartHelperService.isSchedulingAllowed) {
          window.scrollTo(0, 0);

          return;
        }

        this.alert.show = true;
        this.alert.message = message;
        this.alert.notification = notification;

        window.scrollTo(0, 0);
      },

      setCouponToRemove(coupon: IFulfilledCoupon) {
        this.couponToRemove = coupon;
      },

      removeDeliveryZone() {
        this.cart.removeDeliveryZone();
      },

      saveAddress(payload: any) {
        this.profile.saveAddress(payload);
      },

      setDeliveryZone(payload: IZoneArea) {
        this.cart.setDeliveryZone(payload);
      },

      setSuggestedAddresses(payload: any[]) {
        this.profile.setSuggestedAddresses(payload);
      },

      setSelectedItem(item: IMenuItem) {
        this.selectedItem = item;
      },

      getAlertMessage() {
        return this.alert.message;
      },

      isAlertShow() {
        return this.alert.show;
      },

      addZeroPricedItem(item: IMenuItem): void {
        this.cart.addItem({item});
      },

      removeZeroPricedItem(itemId: string): void {
        this.cart.removeItem(itemId);
      },

      // Private Functions

      _getCurrentItem() {
        return cart.selectedItem;
      },

      _unableToPriceItem(cart: ICart): boolean {
        return cart.items && cart.items.length > 0
          && cart.validations
          && (cart.validations.length === 1
            && cart.validations[0].type === 'CART'
            && cart.validations[0].message === 'Error with service. Unable to calculate price.');
      }
    },
    destroyed(): any {
      if (this.showAlertSubscription) {
        this.showAlertSubscription.unsubscribe();
      }

      if (this.removeClearAlertObserver) {
        this.removeClearAlertObserver();
      }

      if (this.removeShowAlertObserver) {
        this.removeShowAlertObserver();
      }
    }
  });
