import OrderReviewContainer from './containers/OrderReviewContainer.vue';
import CheckoutContainer from './containers/CheckoutContainer.vue';
import ConfirmationContainer from './containers/ConfirmationContainer.vue';
import OrderHistoryContainer from './containers/OrderHistoryContainer.vue';
import HostedPayment from './components/HostedPayment.vue';
import CurbsideConfirmation from './components/CurbsideConfirmation.vue';

export default [
    {path: '', name: 'OrderReview', component: OrderReviewContainer, alias: 'review'},
    {path: 'checkout', name: 'Checkout', component: CheckoutContainer},
    {
        path: 'confirmation',
        name: 'Confirmation',
        component: ConfirmationContainer,
        props: true
    },
    {path: 'historical', name: 'Historical', component: OrderHistoryContainer},
    {path: 'hostedpayment', name: 'HostedPayment', component: HostedPayment},
    {path: 'curbsideconfirmation', name: 'CurbsideConfirmation', component: CurbsideConfirmation}
];
