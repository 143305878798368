import {ITopping} from '../models/Item';

export default {
    methods: {
        getToppingQuantity(topping: ITopping, type?: string): number {
            // override

            return NaN;
        },

        getToolTip(topping: ITopping, threshold: number, comparison: Function, equal: string, notEqual: string): string {
            let qty = this.getToppingQuantity(topping);

            if (qty === threshold) {
                return equal;
            } else if (comparison(qty, threshold)) {
                return notEqual;
            } else {
                return null;
            }
        },

        getMinusToolTip(topping: ITopping): string {
            return this.getToolTip(topping, 1, (qty, threshold) => qty > threshold,
                'Remove this item', 'Reduce this item');
        },

        getLeftToolTip(topping: ITopping): string {
            return this.getToolTip(topping, 3, (qty, threshold) => qty < threshold,
                'Remove this item', 'Add this item to left side only');
        },

        getWholeToolTip(topping: ITopping): string {
            return this.getToolTip(topping, 3, (qty, threshold) => qty < threshold,
                'Remove this item', 'Add this item to the whole');
        },

        getRightToolTip(topping: ITopping): string {
            return this.getToolTip(topping, 3, (qty, threshold) => qty < threshold,
                'Remove this item', 'Add this item to right side only');
        },

        getPlusToolTip(topping: ITopping): string {
            return this.getToolTip(topping, 3, (qty, threshold) => qty < threshold,
                'Remove this item', 'Add more of this item');
        }
    }
};
