
  import Vue from 'vue';
  import toppingMixin from '../mixins/topping.mixin';
  import modalMixin from '../../common/mixins/modal.mixin';
  import { SPLIT_SIDES, VALIDATIONS } from '../menu.constants';
  import {LAYOUTS} from './item-customization-layouts/item-customization-layouts.constants';
  import menu from '../models/Menu';
  import {ICoupon, ICouponGroup, IStyle} from '../../coupons/models/Coupon';
  import { IMenuItem, IMenuItemSize } from '../models/Item';
  import designService from '../../common/services/design.service';
  import menuBus from '../menu.bus';
  import modalBus from '../../common/messaging/modal.bus';
  import notifierService from '../../common/services/notifier.service';
  import {Util} from '../../common/services/Util';
  import CustomizeItemHeader from './CustomizeItemHeader.vue';
  import CustomizeItemDetails from './CustomizeItemDetails.vue';
  import ItemCard from './ItemCard.vue';
  import CustomizeItemStepModern from './item-customization-layouts/StepSectionHeadings.vue';
  import StepSectionHeadings from './item-customization-layouts/StepSectionHeadings.vue';
  import StyledModal from '../../common/components/StyledModal.vue';
  import SelectSplitItem from './SelectSplitItem.vue';
  import StickyActions from '../../common/components/StickyActions.vue';
  import { ICategory, IItemDesign } from '../stores/menu.store';
  import { ICart } from '../../cart/cart.types';
  import itemCardListMixin from '../mixins/item-card-list.mixin';
  const _ = require('lodash');
  import NotificationsModern from './NotificationsModern.vue';
  import { IRestaurant } from '../../restaurants/types/restaurant.types';
  const sortBy = require('lodash/sortBy');
  import { isItemAllowedForSplit } from '../helpers/item-customization.helpers';
  import { IAccountDesign } from '../../account/types/account.types';
  import { getAvailableCrustForCouponItem } from "../../coupons/helpers/coupon.helpers";
  import AlertModern from '../../common/components/AlertModern.vue';
  import { removeEmojis } from '../../common/helpers/text.helpers';

  declare var $: any;
  const moment = require('moment');

  const BUTTON_LAYOUTS = {
    VERTICAL: 'VERTICAL',
    HORIZONTAL: 'HORIZONTAL'
  };

  export default Vue.extend({
    mixins: [modalMixin, toppingMixin, itemCardListMixin],
    components: {
      AlertModern,
      CustomizeItemHeader,
      CustomizeItemDetails,
      ItemCard,
      CustomizeItemStepModern,
      SelectSplitItem,
      StyledModal,
      StickyActions,
      NotificationsModern
    },
    props: {
      accountDesign: {
        type: Object as () => IAccountDesign,
        required: true
      },
      cart: {
        type: Object as () => ICart,
        required: true
      },
      currentTime: {
        type: String,
        required: true
      },
      categories: {
        type: Array as () => Array<ICategory>,
        required: true
      },
      couponGroup: {
        type: Object as () => ICouponGroup,
        required: false
      },
      defaultItemDesign: {
        type: Object as () => IItemDesign,
        required: true
      },
      item: {
        type: Object as () => IMenuItem,
        required: true
      },
      screenWidth: {
        type: String,
        required: false
      },
      selectedCoupon: {
        type: Object as () => ICoupon,
        required: false
      },
      selectedRestaurant: {
        type: Object as () => IRestaurant,
        required: true
      },
      sizeMap: {
        type: Object,
        required: true
      },
      splitItems: {
        type: Array as () => Array<IMenuItem>,
        required: false
      },
      splitSide: {
        type: String,
        required: false
      },
      styleList: {
        type: Object,
        required: true
      },
      toppingsList: {
        type: Object,
        required: true
      },
      updating: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      // services

      _designService() {
        return designService;
      },

      _menuBus() {
        return menuBus;
      },

      _modalBus() {
        return modalBus;
      },

      _notifier() {
        return notifierService;
      },

      menu() {
        return menu;
      },

      // getters

      category() {
        return this.categories.find(cat => {
          return cat.objectId === this.item.menuCategoryId;
        });
      },

      categoryDesigns() {
        return this.menu.categoryDesigns;
      },

      isStepBuilder() {
        if (!this.category) {
          return false;
        }

        const catDesign = this.categoryDesigns.find(cat => cat.objectId === this.category.objectId);

        return _.get(catDesign, 'builderEnabled') === true;
      },

      isSplitEnabled() {
        if (!this.category) {
          return false;
        }

        const catDesign = this.categoryDesigns.find(cat => cat.objectId === this.category.objectId);

        return _.get(catDesign, 'halvesEnabled') === true;
      },

      isModifiersTypeNumeric() {
        if (!this.category) {
          return false;
        }

        const catDesign = this.categoryDesigns.find(cat => cat.objectId === this.category.objectId);

        return _.get(catDesign, 'modifiersControlType') === 'NUMERIC';
      },

      isLoaded(): boolean {
        return !Util.isEmpty(this.styleList) && this.styleList['map'];
      },

      items() {
        return this.getAvailableSplitItems();
      },

      sortedSplitItems() {
        return sortBy(this.items, ['categoryId', 'sortNumber']);
      },

      isEditingCheckoutItem() {
        return this.$store.state.cart.editingCheckoutItem;
      }
    },
    data() {
      return {
        categoriesMap: {},
        comments: '',
        displayItem: null,
        isSelectSplitItemOpen: false,
        LAYOUTS: LAYOUTS,
        SPLIT_SIDES: SPLIT_SIDES,
        splitItemChoicesModalMQ: null,
        step: 0,
        alert: {show: false, message: ''}
      };
    },
    created() {
      this._buildCategoriesMap();

      this.displayItem = this.item;

      this._menuBus.loadSplitItemChoices$.next(this.item.departmentId);

      window.scrollTo(0, 0);

      this.comments = this.item.comments;
    },
    mounted() {
      if ($('.modal-backdrop').length > 0) {
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');
      }
    },
    destroyed() {
      if (this.splitItemChoicesModalMQ) {
        this.splitItemChoicesModalMQ.unsubscribe();
      }
    },
    methods: {
      openSplitModal() {
        this.$refs.splitItemModal.open();
      },

      closeSplitModal() {
        this.$refs.splitItemModal.close();
      },

      activeLayout(layoutType: string): boolean {
        return LAYOUTS[this.accountDesign.itemCustomizationLayout] === layoutType;
      },

      buildSizeAvailableMap(): any {
        if (!this.isLoaded) {
          return null;
        }

        if (!this.item || Object.keys(this.item).length === 0) {
          return {};
        }

        return this._buildSizeAvailableMap(this.item);
      },

      chooseSplitItem(splitItemId: string): void {
        this.$emit('splitItem', {
          item: this.item,
          splitItemId
        });
      },

      unsplitItem() {
        this.$emit('unsplitItem', this.item);
      },

      onUnsplitItem() {
        this.unsplitItem();
      },

      scrollToElementHandler(elementHandler: Function) {
        this.step = 0;
        this.$nextTick(function() {
          let element = elementHandler();

          let ntContainer = this.$refs.notifications;

          if (element && ntContainer) {
            window.scrollTo({
              top: window.scrollY + element.getBoundingClientRect().top -
                ntContainer.$el.getBoundingClientRect().height,
              left: 0,
              behavior: 'smooth'
            });
          }
        });
      },

      scrollToSizes() {
        this.scrollToElementHandler( () => this.$refs.layoutProps.$el);
      },

      finishEdit(): void | boolean {
        this.comments = removeEmojis(this.comments);

        if (this.sizeMap[this.item.itemId] && !this.item.size.styleId) {
          this._notifier.error('Please select a size to continue.', 'NO_SIZE');
          this.scrollToSizes();

          return false;
        }

        if (this.selectedCoupon && this.couponGroup) {
          const crustsAvailable = getAvailableCrustForCouponItem(this.couponGroup, false);
          const crustNames = crustsAvailable.map(c => c.name).join(', ');
          const isCrustAvailable = (styleId) => {
            return crustsAvailable.some(c => c.styleId === styleId);
          };

          if (crustsAvailable.length > 0 &&
            this.item.styles.findIndex(s => isCrustAvailable(s.styleId)) < 0) {
            this._notifier.error(`This coupon requires one of the following styles: ${crustNames}`, 'INVALID_CRUST');
            this.scrollToSizes();

            return false;
          }
        }

        this.$emit('finishItemCustomization', this.item, this.comments);
      },

      getAvailableSplitItems(): IMenuItem[] {
        let items: IMenuItem[] = [].slice();

        if (this.splitItems) {
          this.splitItems.forEach((item: IMenuItem) => {
            if (this._isItemAllowedForSplit(item))
              items.push(item);
          });
        }

        return items;
      },

      getCouponText(group: ICouponGroup) {
        if (!group || !group.objectId) {
          return '';
        }

        if (group.includes && group.includes[0] && group.includes[0].toppingLimit) {
          let itemToppingCount: number = this.item.toppingCount;

          let nonStandardToppings = this.item.includedItems.filter(topping => topping.standard === false);
          itemToppingCount += nonStandardToppings.length;

          let toppingLimit: string = group.includes[0].toppingLimit;
          let toppingCount: number = group.includes[0].toppingCount;
          let neededCount: number = toppingCount - itemToppingCount;

          switch (toppingLimit) {
            case 'EXACT':
              if (neededCount < 0) {
                return 'Remove ' + Math.abs(neededCount) + ' toppings.';
              } else if (neededCount > 0) {
                return 'Select ' + neededCount + ' toppings.';
              } else if (neededCount === 0) {
                return '';
              }

              break;
            case 'ATLEAST':
              if (neededCount < 0 || neededCount === 0) {
                return '';
              } else if (neededCount > 0) {
                return 'Select at least ' + neededCount + ' more toppings.';
              }

              break;
            case 'NOMORE':
              if (neededCount < 0) {
                return 'Remove at least ' + Math.abs(neededCount) + ' toppings.';
              } else if (neededCount === 0) {
                return '';
              } else if (neededCount > 0) {
                return 'Select up to ' + neededCount + ' more toppings.';
              }

              break;
            default:
              return '';
          }
        }

        return '';
      },

      getImageUrl(showImage: boolean, imageLink: string, defaultImageLink: string) {
        if (!showImage) {
          return '';
        }

        if (imageLink === null || imageLink === '' || !imageLink) {
          return defaultImageLink;
        }

        return imageLink;
      },

      cancel() {
        this.$emit('onCancel');
      },

      removeSelectedItem(itemObjectId: string): void {
        this.$emit('removeItem', itemObjectId);
      },

      toggleSplitSide(splitSide: string): void {
        if (splitSide === this.splitSide) {
          return;
        }

        const side = this.splitSide === SPLIT_SIDES.LEFT ? SPLIT_SIDES.RIGHT : SPLIT_SIDES.LEFT;

        this.$emit('toggleSplit', side);
        this.displayItem = this.item.split[side];
        this._menuBus.toggleDisplayItem$.next(this.displayItem);
      },

      getItemMessage() {
        if (!this.selectedRestaurant.communicationSettings || !this.selectedRestaurant.communicationSettings.itemMessage) {
          return '';
        }

        return this.selectedRestaurant.communicationSettings.itemMessage;
      },

      getItemMessageEnabled(): boolean {
        return this.selectedRestaurant.communicationSettings.itemMessageEnabled;
      },

      onSelectSplitItemOpen(value: boolean) {
        this.isSelectSplitItemOpen = value;
      },

      // Styling

      getButtonStyle(): any {
        return this._designService.getButtonStyle();
      },

      getButtonClass() {
        return this._designService.getButtonClass();
      },

      getButtonTypeClass(): string {
        if (this.screenWidth && this.screenWidth === 'xs') {
          return 'tol-item-mobile';
        } else {
          return 'tol-item-single';
        }
      },

      getDisplayToggleStyle(splitSide: string): any {
        if (splitSide === this.splitSide) {
          return this._designService.getSplitTabSelectedStyle();
        } else {
          return this._designService.getSplitTabStyle();
        }
      },

      getMenuLayoutClass() {
        let vertical: string = 'col-xs-10 col-xs-offset-1 col-sm-6 col-sm-offset-0',
          horizontal: string = 'col-xs-12';

        return this.selectedRestaurant.buttonLayout === BUTTON_LAYOUTS.HORIZONTAL ? horizontal : vertical;
      },

      getSplitTabStyle(): any {
        return this._designService.getSplitTabStyle();
      },

      getSplitTabSelectedStyle(): any {
        return this._designService.getSplitTabSelectedStyle();
      },

      getItemCardProps(item: IMenuItem) {
        return {
          buttonClass: this.getButtonTypeClass(),
          buttonLayout: this.selectedRestaurant.buttonLayout,
          cart: this.cart,
          // category: this.categoriesMap[item.categoryId],
          currentTime: this.currentTime,
          defaultItemDesign: this.defaultItemDesign,
          hasNoItemImages: this.hasNoItemImages,
          image: this.getImageUrl(item.showImage, item.imageLink, this.selectedRestaurant.imageLink),
          isSplitOption: true,
          item: item,
          orderTypes: this.selectedRestaurant.orderTypes,
          sizes: this.sizeMap[item.objectId],
          forceShort: false // this.forceShort
        };
      },

      setQty($event) {
        this.$emit('setQuantity', $event);
      },

      removeThisItem() {
        this.removeSelectedItem(this.item.objectId);
      },

      // vue component helpers

      getLayoutProps() {
        return {
          cart: this.cart,
          is: StepSectionHeadings,
          item: this.item,
          itemDesign: this.defaultItemDesign,
          selectedCoupon: this.selectedCoupon,
          selectedCouponGroup: this.couponGroup,
          showFractions: true,
          showSizes: true,
          showStyles: true,
          sizeAvailableMap: this.buildSizeAvailableMap(),
          sizes: this.sizeMap[this.item.itemId],
          splitSide: this.splitSide ? this.splitSide.toUpperCase() : null,
          styleList: this.styleList,
          toppingsList: this.toppingsList,
          toppingsManager: this.toppingsManager,
          updating: this.updating,
          isStepBuilder: this.isStepBuilder,
          isModifiersTypeNumeric: this.isModifiersTypeNumeric,
          step: this.step
        };
      },

      getLayoutListeners() {
        return {
          addNoRequiredItem: this.addNoRequired.bind(this),
          removeNoRequiredItem: this.removeNoRequiredItem.bind(this),
          updateRequirements: this.onUpdateRequirements.bind(this),
          updateSize: this.setSize.bind(this),
          updateStyle: this.setStyle.bind(this),
          updateToppings: this.onUpdateToppings.bind(this),
          validationError: this.showAlert.bind(this),
          resetValidation: this.clearAlert.bind(this)
        };
      },

      // output emitter proxies for vue component listeners

      onUpdateRequirements(value) {
        this.$store.commit('notifications/REMOVE_BY_ID', VALIDATIONS.REQUIRED);

        this.$emit('updateRequirements', value);
      },

      onUpdateToppings(value) {
        this.$emit('updateToppings', value);
      },

      addNoRequired($event) {
        this.$emit('addNoRequiredItem', $event);
      },

      removeNoRequiredItem($event) {
        this.$emit('removeNoRequiredItem', $event);
      },

      setSize($event) {
        this.$store.commit('notifications/REMOVE_BY_ID', 'NO_SIZE');

        this.$emit('updateSize', {itemId: this.item.objectId, sizeId: $event});
      },

      setStyle($event) {
        this.$store.commit('notifications/REMOVE_BY_ID', VALIDATIONS.STYLE);
        this.$store.commit('notifications/REMOVE_BY_ID', 'INVALID_CRUST');

        this.$emit('updateStyle', {itemId: this.item.objectId, styleId: $event.objectId});
      },

      // Private functions

      _buildCategoriesMap(): void {
        this.categories.forEach((category: ICategory) => {
          this.categoriesMap[category.objectId] = category;
        });
      },

      _buildSizeAvailableMap(item: IMenuItem): any {
        let sizeAvailableMap: any = {};
        let itemSizes: IMenuItemSize[] = this.sizeMap[item.itemId];

        if (itemSizes) {
          let itemStyles: IStyle[] = [];

          for (let style of item.styles) {
            if (style.type.toLowerCase() !== 'size') {
              itemStyles.push(style);
            }
          }

          if (itemStyles.length > 0) {
            for (let style of itemStyles) {
              for (let itemSize of itemSizes) {
                if (!this.styleList.map[itemSize.name] || !this.styleList.map[itemSize.name][style.type]) {
                  continue;
                }

                for (let itemStyle of this.styleList.map[itemSize.name][style.type]) {
                  if (itemStyle.styleId === style.styleId) {
                    if (sizeAvailableMap[itemSize.name] === false) {
                      break;
                    }

                    // non-splittable sizes not allowed for split items
                    if (item.alreadySplit && !itemSize.allowFraction) {
                      break;
                    }

                    if (itemStyle.price === -1) {
                      sizeAvailableMap[itemSize.name] = false;

                      break;
                    } else {
                      sizeAvailableMap[itemSize.name] = true;
                    }
                  }
                }
              }
            }
          } else if (itemSizes) {
            itemSizes
              .filter(size => sizeAvailableMap[size.name] !== false && (size.allowFraction || !item.alreadySplit))
              .forEach(size => {
                sizeAvailableMap[size.name] = +size.price !== -1;
              });
          }
        }

        return sizeAvailableMap;
      },

      _isItemAllowedForSplit(item: IMenuItem): boolean {
        return isItemAllowedForSplit(
          this.item,
          item,
          this.selectedCoupon,
          this.sizeMap,
          this.categoriesMap,
          this.cart,
          this.currentTime
        );
      },

      nextStep() {
        const sizes = this.sizeMap[this.item.objectId];

        if (sizes && !this.item.size.styleId) {
          this._notifier.error('Please select a size to continue.', 'NO_SIZE');
          this.step = 0;

          return false;
        }

        this.step++;
      },

      clearAlert() {
        this.alert.show = false;
        this.alert.message = '';
      },

      onNotificationClick(notification) {
        if (notification.id === VALIDATIONS.REQUIRED) {
          this.$refs.layoutProps.scrollToRequirement(notification.requirementId);
        }
      },

      showAlert(message: string, notification?: string) {
        this.alert.show = true;
        this.alert.message = message;
        this.alert.notification = notification;

        window.scrollTo(0, 0);
      }
    }
  });
