
    import Vue from 'vue';
    import {ICreditCard} from '../../common/models/CreditCard';
    import designService from '../../common/services/design.service';
    import ModalModern from '../../common/components/ModalModern.vue';
    import designMixin from '../../common/mixins/design.mixin';
    import { IUser } from '../stores/profile.store';
    import restaurants from '../../restaurants/models/Restaurants';

    export default Vue.extend({
        components: {
            ModalModern
        },
        mixins: [designMixin],
        props: {
            creditCards: {
                type: Array as () => Array<ICreditCard>,
                required: true
            },
            user: {
                type: Object as () => IUser,
                required: true
            }
        },
        computed: {
            // services

            _designService () {
                return designService;
            },

            // getters

            disclaimerText() {
              return '* Credit card saved to Locations only. If cards used in more than one location, they may be listed more than once.';
            }
        },
        data () {
            return {
                initialized: false,
                card: null
            }
        },
        created () {
            this.initialized = true;
        },
        methods: {
            open() {
                this.$refs.removeCreditCardModal.open();
            },

            close() {
                this.$refs.removeCreditCardModal.close();
            },

            cancelModal() {
                this.close();
            },

            getLocationName(locationId: string) {
                return restaurants.restaurants.find(loc => loc.objectId === locationId).name;
            },

            remove(creditCard: ICreditCard) {
                this.close();
                this.$emit('removeEmitter', {creditCardId: creditCard.objectId, userId: this.user.objectId});
            },

            removeCreditCard(creditCard: ICreditCard) {
                this.card = creditCard;
                this.open();
            }
        }
    });
