import { createSetterMutations, getSetterMutationName } from '../../common/data/store.helpers';

export const MAPS_STORE = 'maps';

const initialState = {
  closestRestaurant: undefined,
  userLocation: undefined
};

export default {
  name: MAPS_STORE,
  namespaced: true,
  state: initialState,
  mutations: {
    ...createSetterMutations(initialState)
  },
  actions: {
    setClosestRestaurant({ commit }, payload) {
      commit(getSetterMutationName('closestRestaurant'), payload);

      return payload;
    },

    setUserLocation({ commit }, userLocation) {
      commit(getSetterMutationName('userLocation'), userLocation);

      return userLocation;
    }
  }
};
