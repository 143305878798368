import Vue from 'vue';
import notificationService from '../messaging/notification.service';

export enum TOAST_STATES {
    ERROR = 'error',
    INFO = 'info',
    SUCCESS = 'success',
    WAIT = 'wait',
    WARNING = 'warn'
}

export class NotifierService {
    notify(type: string, message: string, id?: string, payload?: object, excludeOnMenu = false, timeToHide = -1) {
        notificationService.notify('notify', { ...payload, type, message, id, excludeOnMenu, timeToHide });

        // @ts-ignore
        Vue.notify({
            type: type,
            title: '',
            text: message
        });

        window.scrollTo(0, 0);
    }

    error(message: string, id?: string, payload?: object, excludeOnMenu = false, timeToHide = -1) {
        this.notify(TOAST_STATES.ERROR, message, id, payload, excludeOnMenu, timeToHide);
    }

    success(message: string, id?: string, excludeOnMenu = false, timeToHide = -1) {
        this.notify(TOAST_STATES.SUCCESS, message, id, null, excludeOnMenu, timeToHide);
    }

    warning(message: string, id?: string, excludeOnMenu = false, timeToHide = -1) {
        this.notify(TOAST_STATES.WARNING, message, id, null, excludeOnMenu, timeToHide);
    }
}

export default new NotifierService();
