
  const themeColors = require('../../../theme-colors.js');
  import Vue from 'vue';
  import {Chrome} from 'vue-color';
  import CheckboxModern from './CheckboxModern.vue';
  const debounce = require('lodash/debounce');


  export default Vue.extend({
    components: {'colorSelector': Chrome, CheckboxModern},
    computed: {
      colorVars () {
        return this.$store.state.modernTheme.colors;
      },
      colorsOnlyShades () {
        return Object.keys(this.colorVars).filter(color =>
          color !== 'background' &&
          color !== 'primary' &&
          color !== 'secondary' &&
          color !== 'tertiary'
        ).reduce((colors, colorName) => {
          colors[colorName] = this.colorVars[colorName];
          return colors;
        }, {});
      }
    },
    data: () => ({
      currentColor: 'primary',
      isEditColorShades: false,
      isSelectorVisible: false,
      isComputeShades: true,
      colorDesc: {}
    }),
    watch: {
      colorVars() {
        window.top.postMessage({'themeColors': this.colorVars }, '*')
      }
    },
    created() {
      Object.keys(themeColors).forEach(colorName => {
        this.colorDesc[colorName] = themeColors[colorName].description;
      });
    },
    methods: {
      updateValue: debounce(function (value) {
        const payload = { [this.currentColor]: value.hex };
        this.$store.dispatch('modernTheme/updateColors', payload);
      }, 250),
      showColorSelector(evt, colorName) {
        const {top, left } = evt.target.getBoundingClientRect()
        if (left < window.innerWidth - 200) {
          this.$refs.colorSelector.style.top = top + 'px';
          this.$refs.colorSelector.style.left = left + 'px';
        } else {
          this.$refs.colorSelector.style.top = top + 'px';
          this.$refs.colorSelector.style.left = (window.innerWidth - 240) + 'px';
        }
        this.currentColor = colorName;
        this.isSelectorVisible = true;
      }
    }
  });
