
import Vue from 'vue';
import userRestaurantDistanceMixin from '../mixins/user-restaurant-distance.mixin';
import ModalModern from '../../common/components/ModalModern.vue';
import HereMap from '../../maps/components/HereMap.vue';
import environmentService from '../../common/services/environment.service';
import maps from '../../maps/models/Maps';
import { IRestaurant } from '../types/restaurant.types';

export default Vue.extend({
  mixins: [userRestaurantDistanceMixin],
  components: {ModalModern, HereMap},
  data: () => ({
    currentRestaurantMap: null
  }),
  computed: {
    mapCenter() {
      if (this.currentRestaurantMap) {
        return {
          lat: this.currentRestaurantMap.latitude,
          lng: this.currentRestaurantMap.longitude
        };
      } else {
        return {
          lat: 0,
          lng: 0
        };
      }
    },
    mapMarkers() {
      if (this.restaurantList) {
        return this.restaurantList.filter(restaurant => restaurant === this.currentRestaurantMap).map(restaurant => {
          return {
            lat: restaurant.latitude,
            lng: restaurant.longitude,
            label: restaurant.name
          }
        });
      }
    }
  },
  methods: {
    isRestaurantOpen(restaurant: IRestaurant) {
      return restaurant.restaurantOpenInitialLoad;
    },

    isSchedulingAllowed(restaurant: IRestaurant): boolean {
      return restaurant.allowScheduling;
    },

    onClickUseMyCurrentLocation() {
      maps.loadUserLocation().then(loc => {
        this.$emit('updateUserLocation', loc);

        if (loc) {
          setTimeout(() => {
            this.calculateDistances();
          });
        }
      });
    },

    selectRestaurant(restaurant: IRestaurant) {
      if (restaurant.redirectUrl !== null && restaurant.redirectUrl !== '') {
        environmentService.open(restaurant.redirectUrl);
      } else {
        this.$emit('selectRestaurant', {
          restaurant,
          distance: this.getDistanceString(restaurant)
        });
      }
    },

    showRestaurantMap(restaurant: IRestaurant) {
      this.currentRestaurantMap = restaurant;
      this.$refs.mapModal.open();
    }
  }
});
