import { IAddress } from '../../common/models/Address';
import { Record } from 'immutable';

export const ORDER_TYPES = {
  DEFAULT: '9',
  DELIVERY: '7'
};

export const RestaurantRecord = Record({
  active: false,
  address: {},
  tipSettings: undefined,
  buttonLayout: undefined,
  cvvRequired: false,
  defaultImageLink: '',
  deliveryCharge: null,
  geocoding: {},
  hasSeparateDeliveryHours: false,
  hours: {
    Delivery: {
      days: [],
      ranges: {},
      hoursAreConsistent: true
    },
    default: {
      days: [],
      ranges: {},
      hoursAreConsistent: true
    }
  },
  imageLink: '',
  latitude: 0.0,
  longitude: 0.0,
  loyalty: null,
  name: '',
  objectId: '',
  orderTypes: [],
  redirectUrl: null,
  restaurantOpenInitialLoad: false,
  tenderTypes: {},
  timeZone: '',
  suggestionSettings: {},
  itemSelectedPostTask: '',
  invalidAddresses: [],
  allowScheduling: false,
  thrivePaymentsEnabled: false,
  communicationSettings: {},
  giftCvvRequired: false
});

export interface IZoneArea {
  zoneId: string;
  shapeType: string;
  shapeArea: string;
  radius: number;
  amount: number;
}

export interface IDeliveryCharge {
  chargeMethod: string;
  zoneAreas: IZoneArea[];
}

export interface IStoreHours {
  Delivery: {
    days: any[],
    ranges: any,
    hoursAreConsistent: boolean,
    holidays: any
  };
  default: {
    days: any[],
    ranges: any,
    hoursAreConsistent: boolean,
    holidays: any
  };
}

export interface IOrderType {
  active: boolean;
  curbside: string;
  objectId: string;
  name: string;
  promiseTime: number;
}

export interface ISuggestionConfig {
  active: boolean;
  message: string;
}

export interface ISuggestionSettings {
  locationId: string;
  personalized: ISuggestionConfig;
  specified: ISuggestionConfig;
  frequentlyPaired: ISuggestionConfig;
  max: number;
}

export interface ICommunicationSettings {
  itemMessage: string;
  itemMessageEnabled: boolean;
  orderBottomEmailMessage: string;
  orderConfirmationMessage: string;
  orderDisclaimerMessage: string;
  orderMessage: string;
  orderMessageEnabled: boolean;
}

export interface ITipSettings {
  allowTips: boolean;
  showTipCalculator: boolean;
  tipPercentages: number[];
}

export interface IRestaurant {
  active: boolean;
  address: IAddress;
  tipSettings: ITipSettings;
  buttonLayout: string;
  cvvRequired: boolean;
  defaultImageLink: string;
  deliveryCharge: IDeliveryCharge;
  geocoding: any;
  hasSeparateDeliveryHours: boolean;
  hours: IStoreHours;
  imageLink: string;
  latitude: number;
  longitude: number;
  loyalty: ILoyalty;
  name: string;
  objectId: string;
  orderTypes: object;
  promiseTimes: any;
  redirectUrl: string;
  restaurantOpenInitialLoad: boolean;
  tenderTypes: any;
  timeZone: string;
  suggestionSettings: ISuggestionSettings;
  itemSelectedPostTask: string;
  invalidAddresses: IAddress[];
  allowScheduling: boolean;
  thrivePaymentsEnabled: boolean;
  communicationSettings: ICommunicationSettings;
  usePromiseTime: boolean;
  legacyId: string;
  giftCvvRequired: boolean;
}

export const LoyaltyRecord = Record({
  allowMobileEnrollment: false,
  externalProgram: true,
  programName: null,
  promptAvailableOffer: true
});

export interface ILoyalty {
  allowMobileEnrollment: boolean;
  externalProgram: boolean;
  programName: string;
  promptAvailableOffer: boolean;
}

export const CHECKED_RESTAURANT_OPEN = 'CHECKED_RESTAURANT_OPEN';
export const CHECKED_RESTAURANT_DELIVERY_OPEN = 'CHECKED_RESTAURANT_DELIVERY_OPEN';
