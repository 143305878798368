import {ALERT} from '../../common/common.constants';
import {checkOrderTime, getValidationMessage} from '../../cart/helpers/cart.helpers';
import {ICart, IValidation} from '../../cart/cart.types';
import {IRestaurant, ORDER_TYPES} from '../../restaurants/types/restaurant.types';
import {emptyAddress, ICreditCardInfo, ITenderRequest} from '../models/TenderRequest';
import {ICreditCard} from '../../common/models/CreditCard';
import {IRegisterRequest, IUser} from '../../profile/stores/profile.store';
import AesUtil from '../../../vendor/AesUtil';
import { VALIDATIONS } from '../../menu/menu.constants';

export function attemptToCheckOut(
  cart: ICart,
  tenderRequest: ITenderRequest,
  restaurant: IRestaurant,
  currentTime: string,
  showAlert: (error: string) => void,
  openDeliveryModal: () => void,
  openScheduleOrderModal: (isCheckingOut: boolean) => void,
  showCheckOrderTimeAlert: (message: string, notification?: string) => void,
  showError: (error: string) => void): boolean {
  if (cart.orderType === null) {
    showAlert(ALERT.MISSING_ORDER_TYPE);

    return false;
  }

  if (isDelivery(cart)) {
    if (!tenderRequest || !tenderRequest.deliveryAddress ||
      (!tenderRequest.deliveryAddress.objectId && !tenderRequest.deliveryAddress.addressLine)) {
      showAlert('Selected order type of Delivery, but no address found. Please enter a valid delivery address.');

      openDeliveryModal();

      return false;
    }
  }

  if (!checkOrderTime(restaurant, currentTime, cart, null, false, openScheduleOrderModal, showCheckOrderTimeAlert, showError)) {
    if (isDelivery(cart)) {
      showAlert(ALERT.STORE_NOT_DELIVERING);
    }

    return false;
  }

  if (cart.validations) {
    let failures = validateItems(cart);

    if (failures && failures.length > 0) {
      let failureMessage: string = '';

      for (let validation of failures) {
        failureMessage += validation.message + '<br/>';
      }

      showAlert(failureMessage);

      return false;
    }

    failures = validateCart(cart);

    if (failures && failures.length > 0) {
      let failureMessage: string = '';

      for (let validation of failures) {
        failureMessage += validation.message + '<br/>';
      }

      showAlert(failureMessage);

      return false;
    }
  }

  let invalidCoupons: IValidation[] = validateCoupons(cart);

  if (invalidCoupons && invalidCoupons.length > 0) {
    for (let validation of invalidCoupons) {
      showAlert(getValidationMessage(validation));
    }

    return false;
  }

  return true;
}

function _buildCreditCardString(creditCardInfo: ICreditCardInfo, restaurant: IRestaurant) {
  let name: string = creditCardInfo.cardholderName;
  let number: string = creditCardInfo.cardNumber.replace(/[^0-9]+/g, '');
  let month: string = creditCardInfo.expirationDate.month;
  let year: string = creditCardInfo.expirationDate.year;
  let cvvCode: string = creditCardInfo.cvvCode;

  let creditCardString: string = '';

  if (restaurant.cvvRequired && creditCardInfo.cvvCode) {
    creditCardString = `${name}#$%${number}#$%${month}/${year}#$%${cvvCode}`;
  } else {
    creditCardString = `${name}#$%${number}#$%${month}/${year}`;
  }

  return creditCardString;
}

function _encrypt(cardString: string) {
  let keySize = 128,
    iterationCount = 1000,
    pass = 'aesSecretKey123',
    iv = 'F27D5C9927726BCEFE7510B1BDD3D137',
    salt = '3FF2EC019C627B945225DEBAD71A01B6985FE84C95A70EB132882F88C0A59A55';

  let aesUtil = new AesUtil(keySize, iterationCount);

  return aesUtil.encrypt(salt, iv, pass, cardString);
}

export function finalizeOrder(
  restaurant: IRestaurant,
  currentTime: string,
  cart: ICart,
  tenderRequest: ITenderRequest,
  cardInfo: ICreditCardInfo,
  isCreditCardSelected: boolean,
  isOnlinePaymentSelected: boolean,
  enterNewCreditCard: boolean,
  selectedCreditCard: ICreditCard,
  updatedExpirationDate: { month: string, year: string },
  user: IUser,
  giftCardAmount: number,
  savePhoneNumber: boolean,
  selectedPhoneType: string,
  registerRequest: IRegisterRequest,
  isPasswordFormVisible: boolean,
  isPasswordFormValid: boolean,
  phoneTypes: string[],
  passwordFormError: string,
  isHostedPaymentComplete: boolean,
  saveCard: boolean,
  saveBillingAddress: boolean,
  openScheduleOrderModal: () => void,
  openDeliveryModal: () => void,
  showAlert: (message: string, notification?: string) => void,
  showError: (error: string) => void,
  clearSuggestedAddresses: () => void,
  validate: () => boolean,
  updateExpirationDate: (creditCardId: string, expirationDate: string, userId: string) => void,
  register: () => Promise<IUser>,
  addUser: (userId: string) => void,
  savePhone: (number: string, type: string, userId: string) => void,
  showHostedPaymentForm: () => void,
  showProgressBar: () => void,
  saveCreditCard: (creditCardId: string) => void,
  submitOrder: () => void): Promise<void> {
  return new Promise((resolve, reject) => {
    const isDeliveryActive = isDeliveryOrder(restaurant, cart, tenderRequest);

    if (!checkOrderTime(restaurant, currentTime, cart, null, false, openScheduleOrderModal, showAlert, showError)) {
      if (isDeliveryActive) {
        showAlert(ALERT.STORE_NOT_DELIVERING);
      }

      reject();

      return;
    }

    clearSuggestedAddresses();

    const valid = validate();

    if (!valid) {
      reject();

      return;
    }

    let cardString: string;

    const preGiftCardTotal = getPreGiftCardTotal(cart, tenderRequest);

    // force cash for free orders
    if (preGiftCardTotal === 0) {
      tenderRequest.payByCash = true;
      tenderRequest.cvvCode = null;
      tenderRequest.creditCardId = null;
      tenderRequest.encryptedCardInfo = null;
      tenderRequest.expirationDate = null;
    } else {
      if (restaurant.cvvRequired) {
        tenderRequest.cvvCode = _encrypt(cardInfo.cvvCode);
      }

      if (isCreditCardSelected && selectedCreditCard) {
        if (selectedCreditCard.expired) {
          const expirationDate: string = updatedExpirationDate.month + '/' + updatedExpirationDate.year;

          tenderRequest.expirationDate = _encrypt(expirationDate);

          updateExpirationDate(selectedCreditCard.objectId, expirationDate, user.objectId);
        }
      }

      if (isCreditCardSelected && enterNewCreditCard && !selectedCreditCard && cardInfo.cardNumber) {
        cardString = _buildCreditCardString(cardInfo, restaurant);
      }
    }

    if (user && user.objectId) {
      tenderRequest.userId = user.objectId;

      if (user.firstName) {
        tenderRequest.firstName = user.firstName;
      }

      if (user.lastName) {
        tenderRequest.lastName = user.lastName;
      }

      if (user.email) {
        tenderRequest.email = user.email;
      }
    }

    if (tenderRequest.tip) {
      // @ts-ignore
      tenderRequest.tip = parseFloat(tenderRequest.tip);
    } else {
      tenderRequest.tip = 0;
    }

    tenderRequest.total = preGiftCardTotal;

    // @ts-ignore
    if (tenderRequest.payByCash === 'false') {
      tenderRequest.payByCash = false;
      // @ts-ignore
    } else if (tenderRequest.payByCash === 'true') {
      tenderRequest.payByCash = true;
    }

    const hostedPaymentsEnabled = isHostedPaymentEnabled(restaurant, isOnlinePaymentSelected);
    const giftCardBalanceInsufficient = isGiftCardBalanceInsufficient(cart, tenderRequest, giftCardAmount);

    if (tenderRequest.payByCash) {
      tenderRequest.billingAddress = emptyAddress();
    } else if (isCreditCardSelected) {
      if (isCreditCardSelected) {
        if (enterNewCreditCard && cardString) {
          tenderRequest.encryptedCardInfo = _encrypt(cardString);
        } else if (selectedCreditCard) {
          tenderRequest.creditCardId = selectedCreditCard.objectId;
        }
      }

      if (!hostedPaymentsEnabled
        && isCreditCardSelected
        && !selectedCreditCard
        && !tenderRequest.encryptedCardInfo
        && giftCardBalanceInsufficient) {
        showError('Missing credit card info.');

        reject();

        return;
      }
    }

    if (isDeliveryActive) {
      if (!tenderRequest.deliveryAddress || !tenderRequest.deliveryAddress.addressLine) {
        showError('Please enter a valid delivery address.');
        openDeliveryModal();

        reject();

        return;
      }

      if (restaurant.deliveryCharge
        && restaurant.deliveryCharge.zoneAreas
        && restaurant.deliveryCharge.zoneAreas.length > 0
        && !cart.zoneId) {
        const hasShapeAreas = restaurant.deliveryCharge.zoneAreas
          .filter(area => area.shapeArea && area.shapeArea.length > 0).length > 0;

        if (hasShapeAreas) {
          const errorMessage = 'The current address is outside of the location\'s delivery zone.';

          showError(errorMessage);

          openDeliveryModal();

          reject();

          return;
        }
      }
    }

    tenderRequest.phone = tenderRequest.phone.replace(/-/g, '')
      .replace(/\./g, '')
      .replace(/\(/g, '')
      .replace(/\)/g, '');

    if (savePhoneNumber) {
      savePhone(tenderRequest.phone, selectedPhoneType, user.objectId);
    }

    if (!isLoggedIn(user) && registerRequest.rewards && isPasswordFormVisible) {
      if (isPasswordFormValid) {
        register().then((registeredUser: IUser) => {
          setTimeout(() => {
            addUser(registeredUser.objectId);

            const cellPhoneType = 'MOBILE';

            if (phoneTypes.indexOf(cellPhoneType) !== -1) {
              savePhone(tenderRequest.phone, cellPhoneType, registeredUser.objectId);
            }

            finalizeOrder(
              restaurant,
              currentTime,
              cart,
              tenderRequest,
              cardInfo,
              isCreditCardSelected,
              isOnlinePaymentSelected,
              enterNewCreditCard,
              selectedCreditCard,
              updatedExpirationDate,
              registeredUser,
              giftCardAmount,
              savePhoneNumber,
              selectedPhoneType,
              registerRequest,
              isPasswordFormVisible,
              isPasswordFormValid,
              phoneTypes,
              passwordFormError,
              isHostedPaymentComplete,
              saveCard,
              saveBillingAddress,
              openScheduleOrderModal,
              openDeliveryModal,
              showAlert,
              showError,
              clearSuggestedAddresses,
              validate,
              updateExpirationDate,
              register,
              addUser,
              savePhone,
              showHostedPaymentForm,
              showProgressBar,
              saveCreditCard,
              submitOrder
            ).then(() => resolve())
              .catch(() => reject());
          });
        }).catch(error => {
          showError(error);
        });
      } else if (passwordFormError) {
        showError(passwordFormError);

        reject();
      }

      return;
    }

    if (hostedPaymentsEnabled && !isHostedPaymentComplete
      && (!isCreditCardSelected || !selectedCreditCard)
      && giftCardBalanceInsufficient) {
      showHostedPaymentForm();

      reject();

      return;
    }

    finishOrder(
      isCreditCardSelected,
      saveCard,
      saveBillingAddress,
      tenderRequest,
      selectedCreditCard,
      showProgressBar,
      saveCreditCard,
      submitOrder
    );

    resolve();
  });
}

export function finishOrder(
  isCreditCardSelected: boolean,
  saveCard: boolean,
  saveBillingAddress: boolean,
  tenderRequest: ITenderRequest,
  selectedCreditCard: ICreditCard,
  showProgressBar: () => void,
  saveCreditCard: (creditCardId: string) => void,
  submitOrder: () => void) {
  showProgressBar();

  if (isCreditCardSelected) {
    if ((saveCard || saveBillingAddress) && tenderRequest.payByCash === false) {
      let creditCardId;

      if (selectedCreditCard) {
        creditCardId = selectedCreditCard.objectId;
      } else {
        creditCardId = null;
      }

      saveCreditCard(creditCardId);
    }
  }

  submitOrder();
}

export function getPreGiftCardTotal(cart: ICart, tenderRequest: ITenderRequest) {
  let total: number = cart.grandTotal;

  if (tenderRequest.tip) {
    // @ts-ignore
    total += parseFloat(tenderRequest.tip);
  }

  return total;
}

export function getRemainingTotal(cart: ICart, tenderRequest: ITenderRequest, giftCardAmount: number): number {
  const preGiftCardTotal = getPreGiftCardTotal(cart, tenderRequest);

  if (giftCardAmount) {
    return Math.max(preGiftCardTotal - giftCardAmount, 0);
  } else {
    return preGiftCardTotal;
  }
}

export function isDelivery(cart: ICart): boolean {
  return cart.orderType === ORDER_TYPES.DELIVERY;
}

export function hasConvenienceFee(cart: ICart): boolean {
  let convenienceFee: number = cart.convenienceFee;
  return convenienceFee > 0;
}

export function isDeliveryOrder(restaurant: IRestaurant, cart: ICart, tenderRequest: ITenderRequest) {
  if (!restaurant || !restaurant.orderTypes || !cart || !cart.orderType) {
    return false;
  }

  return restaurant.orderTypes[cart.orderType].objectId === ORDER_TYPES.DELIVERY
    && !!tenderRequest.deliveryAddress;
}

export function isGiftCardBalanceInsufficient(cart: ICart, tenderRequest: ITenderRequest, giftCardAmount: number): boolean {
  return getRemainingTotal(cart, tenderRequest, giftCardAmount) > 0;
}

export function isHostedPaymentEnabled(restaurant: IRestaurant, isOnlinePaymentSelected: boolean): boolean {
  return restaurant && restaurant.thrivePaymentsEnabled && isOnlinePaymentSelected;
}

function isLoggedIn(user: IUser): boolean {
  return user && user.objectId && user.objectId.length > 0;
}

function validateCart(cart: ICart) {
  let failures = [];

  for (let validation of cart.validations) {
    if (validation.type === 'CART') {
      failures.push(validation);
    }
  }

  return failures;
}

function validateItems(cart: ICart): IValidation[] {
  let failures: IValidation[] = [];

  for (let validation of cart.validations) {
    if (validation.type === VALIDATIONS.REQUIRED || validation.type === VALIDATIONS.AVAILABLE) {
      failures.push(validation);
    }
  }

  return failures;
}

function validateCoupons(cart: ICart): Array<IValidation> {
  let invalidCoupons: IValidation[] = [];

  for (let validation of cart.validations) {
    if (validation.type === VALIDATIONS.COUPON) {
      invalidCoupons.push(validation);
    }
  }

  return invalidCoupons;
}
