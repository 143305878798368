
  import Vue from 'vue';
  import { IMenuItem, IMenuItemSize } from '../models/Item';
  import filtersMixin from '../../common/mixins/filters.mixin';
  import { isItemInStock } from '../../cart/helpers/cart.helpers';
  import cart from '../../cart/models/Cart';

  export default Vue.extend({
    mixins: [filtersMixin],
    name: 'SelectSizes',
    props: {
      defaultFirst: {
        type: Boolean,
        required: false
      },

      defaultSizeId: {
        type: String,
        required: false
      },

      displayPrice: {
        type: Boolean,
        required: false
      },

      item: {
        type: Object as () => IMenuItem,
        required: true
      },

      sizes: {
        type: Array as () => Array<IMenuItemSize>,
        required: true
      }
    },

    data: () => ({
      selectedSize: null,
      showSizes: false
    }),

    created() {
      /* Set initial Selected Size */
      if (this.defaultSizeId) {
        this.selectedSize = this.sizes.find(size => this.defaultSizeId === size.styleId);
      } else if (this.sizes.length === 1 || this.defaultFirst) {
        this.selectedSize = this.sizes[0];
      }
    },

    methods: {
      keyDown() {
        this.toggleMenuOrSelect();

        let options = <Element>this.$refs.sizeOptions;

        Vue.nextTick(() => {
          (<HTMLElement>options.firstChild).focus();
        });
      },
      /* TODO: Finish accessibility using Aria listbox
       https://www.w3.org/TR/wai-aria-practices/examples/listbox/listbox-collapsible.html
       */

      isSizeInStock(size: IMenuItemSize): boolean {
        return isItemInStock(cart.cart, this.item, [size]);
      },

      onSizesHideClick() {
        this.showSizes = false;
      },

      selectSize(size: IMenuItemSize) {
        if (!this.isSizeInStock(size)) {
          return;
        }

        this.selectedSize = size;

        this.$emit('selectSize', size);
      },

      toggleMenuOrSelect() {
        if (this.sizes.length > 1) {
          this.showSizes = !this.showSizes;
        } else {
          this.selectSize(this.selectedSize);
        }
      }
    }
  });
