
  import Vue from 'vue';
  import designService from '../../common/services/design.service';
  import { IMenuItem, IRequiredItem, IRequiredOption, ITopping } from '../../menu/models/Item';
  import { ICoupon } from '../../coupons/models/Coupon';
  import { hoverScheme } from '../../menu/directives/ItemButtonHover';
  import { IValidation } from '../cart.types';
  import filtersMixin from '../../common/mixins/filters.mixin';
  import { isToppingInStock } from '../helpers/cart.helpers';
  import cart from '../models/Cart';

  export default Vue.extend({
      mixins: [filtersMixin],
      directives: { hoverScheme },
      props: {
          coupons: {
              type: Array as () => Array<ICoupon>,
              required: true
          },

          item: {
              type: Object as () => IMenuItem,
              required: true
          },

          selectedItem: Object as () => IMenuItem,

          validations: {
              type: Array as () => Array<IValidation>,
              required: true
          }
      },

      computed: {
          _designService () {
              return designService;
          },

          linkColor(): string {
              return this._designService.linkColor;
          },

          requiredItems() {
              return this.getRequiredItems();
          },

          nonSizeStyle() {
              return this.getNonSizeStyle();
          },

          hasDetails(): boolean {
              return this.item.includedItems.length > 0 || this.item.comments && this.item.comments.length > 0 ||
                  (this.item.requiredItemsFlat && this.item.requiredItemsFlat.length > 0);
          },

          fulfilledCoupons() {
              return this.getFulfilledCoupons();
          },

          couponHoverScheme() {
              return this.getCouponHoverScheme();
          },

          errorStyle() {
              return this.getErrorStyle();
          }
      },

      data () {
          return {
              initialized: false,
              detailsToggled: false,
              wholeToppingMap: [],
              leftToppingMap: [],
              rightToppingMap: []
          }
      },

      created () {
          if (this.selectedItem && this.selectedItem.objectId === this.item.objectId) {
              this.detailsToggled = true;
          }

          this.buildToppingMaps();

          this.initialized = true;
      },

      watch: {
          item () {
              this.buildToppingMaps();
          }
      },

      methods: {
          /* borrowed from base-item-customization-layout */

          getRequiredOptions(item: IMenuItem) {
              if (!item.requiredOptions) {
                  return [];
              }

              let options: IRequiredOption[] = [];

              for (let requiredOption of item.requiredOptions) {
                  options.push(Object.assign({}, requiredOption, {
                      parentInstanceId: item.objectId
                  }));

                  if (!item.requiredItems) {
                      continue;
                  }

                  for (let requiredItem of item.requiredItems) {
                      if (requiredItem.requirementId === requiredOption.objectId) {
                          this.addNestedRequiredOptionsToArray(options, requiredItem);
                      }
                  }
              }
              return options;
          },

          getUniqueRequiredOptions(item: IMenuItem) {
              const options = this.getRequiredOptions(item);
              const result = [];

              options.forEach(option => {
                  const existing = result.find(op => op.objectId === option.objectId);

                  if (!existing) {
                      result.push(option);
                  }
              });

              return result;
          },

          addNestedRequiredOptionsToArray(options: IRequiredOption[], item: IRequiredItem) {
              if (!item.requiredOptions) {
                  return;
              }

              for (let requiredOption of item.requiredOptions) {
                  options.push(Object.assign({}, requiredOption, {
                      parentInstanceId: item.objectId
                  }));

                  if (!item.requiredItems) {
                      continue;
                  }

                  for (let requiredItem of item.requiredItems) {
                      if (requiredItem.requirementId === requiredOption.objectId) {
                          this.addNestedRequiredOptionsToArray(options, requiredItem);
                      }
                  }
              }
          },

          /* */

          getRequiredItems() {
              if (!this.item || !this.item.requiredItemsFlat) {
                  return [];
              }

              let results;

              const options = this.getUniqueRequiredOptions(this.item);

              // sort by option order
              if (options.length > 0) {
                  results = [];

                  options.forEach(option => {
                      this.item.requiredItemsFlat.forEach(item => {
                          if (item.requirementId === option.objectId)
                              results.push(item);
                      });
                  });
              } else {
                  results = this.item.requiredItemsFlat;
              }

              return results;
          },

          getCouponHoverScheme() {
              return {
                  background: this._designService.pageBackgroundColor,
                  text: this._designService.backgroundColor
              };
          },

          getSplitText(): string {
              return `1/2 ${this.item.split.left.name} 1/2 ${this.item.split.right.name}`;
          },

          isToppingInStock(topping: ITopping): boolean {
            return isToppingInStock(cart.cart, this.item, topping);
          },

          buildToppingMaps() {
              this.wholeToppingMap.splice(0);
              this.leftToppingMap.splice(0);
              this.rightToppingMap.splice(0);

              if (this.item.consolidatedToppingList) {
                  for (let topping of this.item.consolidatedToppingList.items) {
                      this.addToToppingMap(topping, 'WHOLE', this.wholeToppingMap);
                      this.addToToppingMap(topping, 'LEFT', this.leftToppingMap);
                      this.addToToppingMap(topping, 'RIGHT', this.rightToppingMap);
                  }
              }
          },

          addToToppingMap(topping: any, type: string, toppingMap: any[]) {
              if (topping.fractionType.toUpperCase() === type.toUpperCase()) {
                  let existingTopping = this.getTopping(topping, toppingMap);

                  if (existingTopping) {
                      existingTopping.quantity = existingTopping.quantity + topping.quantity;

                      if (!existingTopping.price && topping.price) {
                          existingTopping.price = topping.price;
                      }
                  } else {
                      let newTopping = {
                          itemId: topping.itemId,
                          name: topping.name,
                          price: topping.price,
                          quantity: topping.quantity
                      };

                      toppingMap.push(newTopping);
                  }
              }
          },

          getTopping(topping: { itemId: string, name: string, quantity: number }, toppingMap: any[]) {
              for (let existingTopping of toppingMap) {
                  if (existingTopping.itemId === topping.itemId) {
                      return existingTopping;
                  }
              }

              return undefined;
          },

          getNonSizeStyle(): string {
              for (let style of this.item.styles) {
                  if (style.type.toUpperCase() !== 'SIZE') return style.name;
              }

              return '';
          },

          getDollarAmount(dollars: number): number {
              return dollars === null ? 0 : Math.round(dollars * 100) / 100;
          },

          toggleDetails() {
              this.detailsToggled = !this.detailsToggled;
          },

          getToggleStatus(): string {
              if (this.detailsToggled) {
                  return 'Hide';
              } else {
                  return 'Show';
              }
          },

          remove(itemId: string) {
              let editingItem: boolean = false;

              if (this.selectedItem && itemId === this.selectedItem.objectId
                  && /\/item\/[0-9]+\/customize/.test(window.location.pathname)) {
                  editingItem = true;
              }

              let payload: any = {
                  itemId,
                  editingItem
              };

              this.$emit('removeItem', payload);
          },

          getFulfilledCoupons() {
              let fulfilledCoupons: any = this.item.fulfilledCoupons;

              for (let fulfilledCoupon of fulfilledCoupons) {
                  for (let coupon of this.coupons) {
                      if (fulfilledCoupon.couponObjectId !== coupon.objectId) {
                          continue;
                      }

                      fulfilledCoupon.name = coupon.name;
                      fulfilledCoupon.showAsLineItem = coupon.showAsLineItem;
                      break;
                  }
              }

              return fulfilledCoupons;
          },

          getAdjustedItemPrice(item: IMenuItem) {
              let itemPrice = item.price;
              const fulfilledCoupons = this.getFulfilledCoupons().filter(c => c.eachPrice != 0 && c.showAsLineItem === false);
              for (let i = 0; i < fulfilledCoupons.length; i++) {
                itemPrice += fulfilledCoupons[i].eachPrice;
              }
              return itemPrice;
          },

          itemHasError(itemObjectId: string): boolean {
              return !!this.validations.find(v => v.objectId === itemObjectId);
          },

          getValidationMessage(itemObjectId: string): string {
              const validation = this.validations.find(v => v.objectId === itemObjectId);
              return validation.message;
          },

          onQuantityChange(item: IMenuItem) {
              if (typeof item.quantity === 'string') {
                  item.quantity = parseInt(item.quantity);
              }

              if (typeof item.quantity !== 'number' || isNaN(item.quantity)) {
                  item.quantity = 0;
              }

              this.$emit('setQuantity', item);
          },

          editItem (item) {
              this.$emit('editItem', item);
          },

          removeCoupon (coupon) {
              this.$emit('removeCoupon', coupon);
          },

          // Styling
          getSidebarStyle(): any {
              return this._designService.getSidebarStyle();
          },

          getButtonStyle(): any {
              let style: any = this._designService.getButtonStyle();
              style['font-size'] = 'large';

              return style;
          },

          getButtonClass(): any {
              return this._designService.getButtonClass();
          },

          getHeaderBorderBottom(): any {
              return {
                  'border-bottom': '1px solid ' + this._designService.backgroundColor
              };
          },

          getHeaderBorderTop(): any {
              return {
                  'border-top': '1px solid ' + this._designService.backgroundColor
              };
          },

          getErrorStyle() {
              return this._designService.getAlertStyling();
          }
      }
  })
