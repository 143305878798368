import { Subject } from '../common/data/Subject';

export interface IDeleteCardRequest {
    userId: string;
    creditCardId: string;
}

export interface IUpdateExpirationDateRequest {
    userId: string;
    expirationDate: string;
    creditCardId: string;
}

export class ProfileBus {
    deleteCard$: Subject<IDeleteCardRequest> = new Subject<IDeleteCardRequest>();
    holdUpdatedCardInfo$: Subject<IUpdateExpirationDateRequest> = new Subject<IUpdateExpirationDateRequest>(null);
    updateExpirationDate$: Subject<IUpdateExpirationDateRequest> = new Subject<IUpdateExpirationDateRequest>();
}

export default new ProfileBus();
