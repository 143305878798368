
  import Vue from 'vue';
  import {Util} from '../../common/services/Util';
  import { IUser } from '../../profile/stores/profile.store';

  export default Vue.extend({
    mixins: [],
    props: {
      buttonColor: {
        type: String,
        required: true
      },
      programDescription: {
        type: String,
        required: true
      },
      programName: {
        type: String,
        required: true
      },
      textColor: {
        type: String,
        required: true
      },
      user: {
        type: Object as () => IUser,
        required: true
      }
    },
    computed: {},
    data() {
      return {
        dateOfBirth: null, // string
        errorMessage: null // string
      };
    },
    methods: {
      join(dateOfBirth) {
        if (!dateOfBirth || Util.parseDate(dateOfBirth)) {
          this.errorMessage = null;
        } else {
          this.errorMessage = 'Please enter a valid date of birth.';

          return;
        }

        dateOfBirth = Util.getDateString(Util.parseDate(dateOfBirth));

        this.$emit('joinLoyalty', dateOfBirth);
      }
    }
  });
