import { ISuggestionItem } from '../../common/models/Suggestion';
import { Record } from 'immutable';
import { createSetterMutations, setStateThroughMutation, setStateThroughMutationFromPromise } from '../../common/data/store.helpers';
import menuService from '../services/menu.service';
import { ITrackSuggestionRequest } from '../menu.bus';
import CartOptimization from '../../cart/services/cart-optimization';
import { IMenuItem } from '../models/Item';
const deepClone = require('lodash.clonedeep');

export const MENU_STORE = 'menu';

export const CategoryRecord = Record({
  description: '',
  imageLink: '',
  imageName: '',
  name: '',
  objectId: null,
  otherCategoryIdList: null,
  sortOrder: null,
  type: '',
  available: {}
});

export interface ICategory {
  available: any;
  description: string;
  imageLink: string;
  imageName: string;
  name: string;
  objectId: string;
  otherCategoryIdList: string[] | null;
  showDescription: boolean;
  sortOrder: number;
  type: string;
}

export const CategoryDesignRecord = Record({
  objectId: '',
  header: {},
  body: {}
});

export interface ICategoryDesign {
  objectId: string;
  header: IDesign;
  body: IDesign;
}

export interface IFeaturedItem extends IMenuItem {
  index: number;
  itemId: string;
  itemName: string;
  sizeId: string;
  sizeName: string;
  styleId: string;
  styleName: string;
}

export interface IItemDesign {
  objectId: string;
  design: IDesign;

  displayDescription: boolean;
  displayPrice: boolean;
  displayModifierPrice: boolean;
  displaySizeChooser: boolean;
  displaySizeDescription: boolean;
  includeOrderButton: boolean;
  itemSelectedPostTask: string;
}

export interface IDesign {
  buttonStyle: number;
  buttonBorderWidth: string;
  fontStyle: string;
  fontSize: string;
  headerImageInsteadOfText: boolean;
}

const initialState = {
  currentSuggestions: null,
  entities: {
    categories: null,
    categoryDesign: null,
    categoryDesigns: null,
    defaultItemDesign: null,
    featuredItems: null,
    selectedCategory: null,
    selectedStyleList: null,
    selectedToppingsList: null,
    splitCategories: null,
    splitItemChoices: null,
    splitToppingsList: null,
  },
  itemMap: null, // { string: IMenuItem }
  items: null,
  loading: false,
  selected: false,
  suggestions: null,
  suggestionsShown: []
};

export default {
  name: MENU_STORE,
  namespaced: true,
  state: JSON.parse(JSON.stringify(initialState)),
  mutations: {
    ...createSetterMutations(initialState),
    ...createSetterMutations(initialState, 'entities'),

    RESET(state) {
      const keys = Object.keys(initialState);

      keys.forEach(key => {
        state[key] = initialState[key];
      });
    }
  },
  getters: {
    items(state) {
      if (!state.entities.selectedCategory || !state.itemMap) {
        return null;
      }

      // use coupons response items
      if (!state.entities.selectedCategory.otherCategoryIdList ||
        state.entities.selectedCategory.otherCategoryIdList.length === 0) {
        return state.items;
      }

      const items = [];
      const categoryId = state.entities.selectedCategory.objectId;

      for (let key in state.itemMap) {
        if (state.itemMap.hasOwnProperty(key)) {
          const item = state.itemMap[key];

          if (item.menuCategoryId === categoryId) {
            const itemClone = deepClone(item);
            itemClone.objectId = key;

            items.push(itemClone);
          }
        }
      }

      return items;
    },
    categories(state) {
      return state.entities.categories;
    }
  },
  actions: {
    clearItemOptions({ commit }) {
      setStateThroughMutation(commit, ['entities', 'selectedToppingsList'], null);
      setStateThroughMutation(commit, ['entities', 'splitToppingsList'], null);
    },
    clearSplitItemChoices({ commit }) {
      return setStateThroughMutation(commit, ['entities', 'splitItemChoices'], null);
    },
    initialize({ commit, dispatch, state }, payload) {
      dispatch('loadItemMap', payload.restaurant.objectId).then(() => {
        const featuredItemsMap = [...payload.menuSettings.featuredItems];
        const featuredItems = featuredItemsMap.map(feat => {
          return {
            ...state.itemMap[feat.itemId],
            sizeId: feat.sizeId,
            styleId: feat.styleId
          };
        });

        dispatch('setLoading', false);
        setStateThroughMutation(commit, ['entities', 'featuredItems'], [...featuredItems]);
        setStateThroughMutation(commit, ['entities', 'categories'], [...payload.menuSettings.categories]);
        setStateThroughMutation(commit, ['entities', 'categoryDesigns'], payload.menuSettings.categoryDesigns);
        setStateThroughMutation(commit, ['entities', 'defaultItemDesign'], payload.menuSettings.defaultItemDesign);
      });

      return payload;
    },
    loadCategories({ commit, dispatch }, locationId) {
      dispatch('setLoading', true);

      return menuService.getCategoriesForRestaurant(locationId).then(response => {
        dispatch('setLoading', false);

        const categories = [...response];
        setStateThroughMutation(commit, ['entities', 'categories'], categories);

        return categories;
      });
    },
    loadCategoryDesign({ commit }, categoryId) {
      return menuService.getCategoryDesign(categoryId).then(response => {
        return setStateThroughMutation(commit, ['entities', 'categoryDesign'], new CategoryDesignRecord(response));
      });
    },
    loadCategoryDesigns({ commit }, locationId) {
      return setStateThroughMutationFromPromise(commit, menuService.getCategoryDesigns(locationId), ['entities', 'categoryDesigns']);
    },
    loadCoupons({ commit }, categoryId) {
      return setStateThroughMutationFromPromise(commit, menuService.loadCoupons(categoryId), 'items');
    },
    loadItemMap({ commit }, locationId: string) {
      return menuService.loadItemMap(locationId).then(response => {
        Object.values(response).forEach((item: IMenuItem) => item.objectId = item.itemId);

        CartOptimization.handleResponse(response, response, true);

        setStateThroughMutation(commit, 'itemMap', response);
      });
    },
    loadItemsForSplit({ commit, dispatch }, departmentId: string) {
      return menuService.getItemsForDepartment(departmentId).then(payload => {
        dispatch('setLoading', false);
        setStateThroughMutation(commit, ['entities', 'splitItemChoices'], [...payload]);

        return payload;
      });
    },
    loadStyleList({ commit }, itemId) {
      return setStateThroughMutationFromPromise(commit, menuService.getStylesForItem(itemId), ['entities', 'selectedStyleList']);
    },
    loadSplitToppingsList({ commit }, itemId: string) {
      return setStateThroughMutationFromPromise(commit, menuService.getToppingsForItem(itemId), ['entities', 'splitToppingsList']);
    },
    loadSuggestions({ state, commit }, payload: { cartId: string, itemId: string }) {
      return menuService.getSuggestions(payload).then(result => {
        if (result.personalized) {
          result.personalized = result.personalized.map(id => state.itemMap[id]);
        }

        if (result.specified) {
          result.specified = result.specified.map(id => state.itemMap[id]);
        }

        return setStateThroughMutation(commit, 'suggestions', result);
      });
    },
    loadToppingsList({ commit }, payload: { itemId: string, sizeId: string, styleId: string }) {
      return setStateThroughMutationFromPromise(
        commit,
        menuService.getToppingsForItem(payload.itemId, payload.sizeId, payload.styleId),
        ['entities', 'selectedToppingsList']
      );
    },
    reset({ commit }) {
      commit('RESET');
    },
    selectCategory({ commit }, category: ICategory) {
      setStateThroughMutation(commit, 'selected', true);

      const result = new CategoryRecord(category);
      setStateThroughMutation(commit, ['entities', 'selectedCategory'], result);

      return result;
    },
    setLoading({ commit }, value: boolean) {
      return setStateThroughMutation(commit, 'loading', value);
    },
    showSuggestions({ state, commit }, suggestions: ISuggestionItem[]) {
      setStateThroughMutation(commit, 'currentSuggestions', suggestions);

      let suggestionsShown = state.suggestionsShown;

      if (suggestionsShown.toArray) {
        suggestionsShown = suggestionsShown.toArray();
      }

      suggestions.forEach(suggestion => {
        suggestionsShown.push(suggestion);
      });

      setStateThroughMutation(commit, 'suggestionsShown', suggestionsShown);

      return suggestions;
    },
    trackSuggestion({ commit }, suggestion: ITrackSuggestionRequest) {
      return menuService.trackSuggestion(suggestion);
    }
  }
};
