function testUserAgent(pattern: RegExp, userAgent?: string): boolean {
  if (!userAgent) {
    userAgent = window.navigator.userAgent;
  }

  return pattern.test(userAgent.toLowerCase());
}

export function isMobile(userAgent?: string): boolean {
  return isiOS(userAgent) || isAndroid(userAgent) || isMobileApp(userAgent);
}

export function isiOS(userAgent?: string): boolean {
  return testUserAgent(/iphone|ipad/, userAgent);
}

export function isAndroid(userAgent?: string): boolean {
  return testUserAgent(/android/, userAgent);
}

export function isMobileApp(userAgent?): boolean {
  if (!userAgent) {
    userAgent = window.navigator.userAgent;
  }

  return /Thr!ve/.test(userAgent);
}
