
  import Vue from 'vue';
  import baseMixin from '../mixins/base-component.mixin';
  import ModalModern from './ModalModern.vue';

  export default Vue.extend({
      mixins: [baseMixin],
      components: {
          ModalModern
      },
      methods: {
          open() {
              if (this.$refs.modal) {
                  this.$refs.modal.open();
              }
          },
          close() {
              if (this.$refs.modal) {
                  this.$refs.modal.close();
              }
          },
          submit () {
              this.$emit('submit');
          },
          cancel () {
              this.$emit('cancel');
          },
          onHide () {
              this.$emit('onHide');
          }
      }
  });
