import getRouter from '../../router';

export class RouteService {
    _hasInitiallyRouted: boolean;
    fromSuggestion: boolean = false;
    returnRoute: { route: string, routeData?: any, payload?: any } = null;
    willRoute: boolean = false;

    route(routeName: string, data?: any, query: boolean = false): Promise<any> {
        let promise: Promise<any>;

        const router = getRouter();

        if (router.currentRoute && routeName === router.currentRoute.name) {
            return Promise.resolve();
        }

        if (router.app) {
            let pushPromise;

            try {
                if (data) {
                    if (query) {
                        pushPromise = router.push({ name: routeName, query: data });
                    } else {
                        pushPromise = router.push({ name: routeName, params: data });
                    }
                } else {
                    pushPromise = router.push({ name: routeName });
                }

                // don't error on routing to current route
                pushPromise.catch(() => {
                    return;
                });

                promise = pushPromise.then(() => {
                    this.willRoute = false;
                });

                this._hasInitiallyRouted = true;
            } catch (e) {
                return Promise.resolve();
            }
        } else {
            promise = new Promise(resolve => {
                router.onReady(() => {
                    resolve(this.route(routeName, data));
                });
            });
        }

        return promise;
    }

    get hasInitiallyRouted() {
        return this._hasInitiallyRouted;
    }

    clearReturnRoute() {
        this.returnRoute = null;
    }

    getReturnRoute() {
        return this.returnRoute;
    }

    setReturnRoute(route: string, routeData?: any, payload?: any) {
        this.returnRoute = {
            route,
            routeData,
            payload
        };
    }

    mustRoute(): boolean {
        return this.willRoute;
    }

    forceRoute() {
        this.willRoute = true;
    }

    get currentRoute() {
        const router = getRouter();

        return router.currentRoute;
    }
}

export default new RouteService();
