import { ISuggestionItem, ISuggestions } from '../models/Suggestion';
import { IMenuItem } from '../../menu/models/Item';
import { IRestaurant } from '../../restaurants/types/restaurant.types';
import { IUser } from '../../profile/stores/profile.store';

export default class SuggestionsManager {
  getSuggestionsShown: () => ISuggestionItem[];
  restaurant: IRestaurant;
  showSuggestions: (suggestionItems: ISuggestionItem[]) => void;
  user: IUser;

  constructor(showSuggestions: (suggestionItems?: ISuggestionItem[]) => void, getSuggestionsShown: () => ISuggestionItem[]) {
    this.showSuggestions = showSuggestions;
    this.getSuggestionsShown = getSuggestionsShown;
  }

  get suggestionsShown() {
    return this.getSuggestionsShown();
  }

  checkForSuggestions(
    getSuggestions: () => Promise<ISuggestions>,
    cartItems: IMenuItem[],
    item?: IMenuItem,
    restaurant?: IRestaurant,
    addItem?: Function): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (restaurant) {
        if (!restaurant.suggestionSettings) {
          reject(false);

          return;
        }

        if (this.suggestionsShown.length >= restaurant.suggestionSettings.max) {
          reject(false);

          return;
        }
      }

      const check = () => {
        getSuggestions().then(suggestions => {
          if (!suggestions || !this._hasSuggestions(suggestions)) {
            resolve(false);

            return;
          }

          let items;

          if (item) {
            items = [item];
          } else {
            items = cartItems;
          }

          for (let i = 0; i < items.length; i++) {
            item = items[i];

            let isSuggestionItem;

            if (suggestions.specified) {
              isSuggestionItem = suggestions.specified.find(suggestion => {
                return suggestion && suggestion.itemId === item.itemId;
              });

              if (isSuggestionItem) {
                item = null;

                continue;
              }
            }

            if (suggestions.personalized) {
              isSuggestionItem = suggestions.personalized.find(suggestion => {
                return suggestion && suggestion.itemId === item.itemId;
              });

              if (isSuggestionItem) {
                item = null;
              }
            }
          }

          let result;

          if (item) {
            result = this._initSuggestions(suggestions, cartItems, restaurant.suggestionSettings.max);
          } else {
            result = false;
          }

          resolve(result);
        });
      };

      if (addItem) {
        addItem(true).then(() => { // wait until suggestions are loaded to attempt to show them
          check();
        });
      } else {
        check();
      }
    });
  }

  // private

  private _hasSuggestions(suggestions: ISuggestions): boolean {
    return !!suggestions.personalized || !!suggestions.specified;
  }

  private _suggestionInCart(suggestionId: string, items: IMenuItem[]): boolean {
    let matchingItems: IMenuItem[] = items.filter((item: IMenuItem) => {
      return item.itemId === suggestionId;
    });

    return matchingItems.length > 0;
  }

  private _filterSuggestions(suggestions: ISuggestionItem[], items: IMenuItem[]): IMenuItem[] {
    let result = suggestions.filter((suggestion: ISuggestionItem) => {
      return !this._suggestionInCart(suggestion.objectId, items);
    });

    result = result.filter((suggestion: ISuggestionItem) => {
      return !this.suggestionsShown.find(sug => sug.objectId === suggestion.objectId);
    });

    return result;
  }

  private _getWording(personalized: boolean): string {
    const user = this.user;
    const restaurant = this.restaurant;

    const userName: string = (user && user.firstName && user.firstName.length) > 0 ? `${user.firstName}, ` : '',
      personalizedMessage: string = restaurant.suggestionSettings.personalized.message,
      specifiedMessage: string = restaurant.suggestionSettings.specified.message,
      defaultMessage: string = 'Would you like to try';

    const personalizedWording: string = !!personalizedMessage ? personalizedMessage : defaultMessage,
      specifiedWording: string = !!specifiedMessage ? specifiedMessage : defaultMessage;

    const wording = personalized ? personalizedWording : specifiedWording;

    return (user && user.objectId) ? `${userName}${wording.toLowerCase()}` : wording;
  }

  private _getSuggestionData(personalized: boolean): { personalized: boolean, wording: string } {
    return {
      personalized,
      wording: this._getWording(personalized)
    };
  }

  private _buildSuggestionsList(suggestions: ISuggestions, items: IMenuItem[], maxSuggestions: number): ISuggestionItem[] {
    let personalized: ISuggestionItem[] = suggestions.personalized || [],
      specified: ISuggestionItem[] = suggestions.specified || [];

    personalized = personalized.map((suggestion: ISuggestionItem) => Object.assign({}, suggestion, this._getSuggestionData(true)));
    specified = specified.map((suggestion: ISuggestionItem) => Object.assign({}, suggestion, this._getSuggestionData(false)));

    let result = [].concat(this._filterSuggestions(personalized, items)).concat(this._filterSuggestions(specified, items));

    let maxPerModal = 2;

    const remainingSuggestions = Math.min(maxSuggestions - this.suggestionsShown.length, maxPerModal);

    result = result.slice(0, Math.min(result.length, remainingSuggestions));

    return result;
  }

  private _initSuggestions(suggestions: ISuggestions, items: IMenuItem[], maxSuggestions: number): boolean {
    let suggestionList: ISuggestionItem[] = this._buildSuggestionsList(suggestions, items, maxSuggestions);

    if (suggestionList.length > 0) {
      if (this.showSuggestions) {
        this.showSuggestions(suggestionList);
      }

      return true;
    } else {
      return false;
    }
  }
}
