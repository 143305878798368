import { ILoginRequest } from '../stores/profile.store';

export class LoginRequest implements ILoginRequest {
    username: string = '';
    password: string = '';
    account: string = '';
    location: string = '';

    constructor(opts?) {
        if (opts) {
            for (let key in opts) {
                if (opts.hasOwnProperty(key) && key === 'account') {
                    this[key] = opts[key];
                }
            }
        }
    }
}