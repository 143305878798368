
import Vue from 'vue';
import { IMenuItemSize } from '../../menu/models/Item';
import SelectSizes from '../../menu/components/SelectSizes.vue';
import itemCardMixin from '../../menu/mixins/item-card.mixin';
import { ADD_TO_CART_ACTIONS } from '../../common/services/Util';
import couponItemMixin from '../mixins/coupon-item.mixin';

const _ = require('lodash');

export default Vue.extend({
  mixins: [itemCardMixin, couponItemMixin],
  components: { SelectSizes },
  computed: {
    // getters

    isDisplaySizeChooser(): boolean {
      return this.itemDesign && this.itemDesign.displaySizeChooser && this.itemHasSizes;
    },

    itemNeedsStyles(): boolean {
      if (!this.item.hasStyles) {
        return false;
      }

      // has a style set
      const nonSizeStyle = this.item.styles.find(style => style.type.toLowerCase() !== 'size');

      return !nonSizeStyle;
    },

    itemHasSizes(): boolean {
      let hasSizes = false;

      for (let style of this.group.includes[0].styles) {
        if (style.type.toLowerCase() === 'size') {
          hasSizes = true;

          break;
        }
      }

      return hasSizes;
    },

    showCustomizeButtonLayout() {
      if (this.selectedRestaurant.itemSelectedPostTask === 'ASK_USER'
        && this.item.customizable
        && !this.item.hasRequirements
        && this.itemHasSizes
        && !this.itemNeedsStyles
      ) {
        return true; // Show the "customize / add to cart" buttons
      }

      return false; // Only show the Order Button
    }
  },
  methods: {
    forceAddToCart(sizeId?: string) {
      this.selectItem(sizeId, ADD_TO_CART_ACTIONS.FORCE_ADD);
    },

    forceCustomize(sizeId?: string) {
      this.selectItem(sizeId, ADD_TO_CART_ACTIONS.FORCE_CUSTOMIZE);
    },

    getSelectedSizeId() {
      return this.$refs.selectSizes ? this.getSizeId(this.$refs.selectSizes.selectedSize) : null;
    },

    getSizeId(size: IMenuItemSize) {
      return size ? size.sizeId : null;
    },

    selectItem(sizeId?: string, addToCartAction = ADD_TO_CART_ACTIONS.COMPUTE) {
      if (!sizeId && this.itemHasSizes) {
        sizeId = this.getSizeId(this.getAvailableSizes(this.group)[0]) ||
          this.getSizeId(this.size);
      }

      this.$emit('selectItem', {
        item: this.item, sizeId,
        group: this.group, addToCartAction
      });
    }
  }
});
